import React, { memo, useEffect, useState } from 'react';

import { useGuest } from '@/guest/hooks/useGuest';
import { useStore } from '@/store/hooks/useStore';
import { Cart, Store } from '@/types';
import FBStorage from '@/utils/FBStorage';
import { isBrowser } from '@/utils/isBrowser';

import { CartContext } from './CartContext';

interface Props {
  children: React.ReactChild;
  store: Store;
}

export const CartProvider = memo<Props>(({ children, store }) => {
  const [cart, setCart] = useState<Opt<Cart>>(undefined);
  useEffect(() => {
    if (isBrowser()) {
      setCart(FBStorage.getCart());
      FBStorage.clearOrderCart(store.id);
    }
  }, []);
  return <CartContext.Provider value={{ cart: cart, setCart }}>{children}</CartContext.Provider>;
});
