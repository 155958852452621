import {Button, createStyles, makeStyles, Modal, Theme, Typography} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import classNames from 'classnames';
import React, {memo} from 'react';

import CustomButton from '@/common/components/CustomButton';
import {t} from '@/i18n/translate';
import {LocationInputMapContainer} from '@/location/mobile/containers/LocationInputMapContainer';
import LocationHelper from '@/location/utils/LocationHelper';
import {DeliveryLocation} from '@/types';

import {StaticMapView} from './StaticMapView';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      position: 'absolute',
      width: '70%',
      borderRadius: '5px',
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(3),
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      display: 'flex',
      outline: 'none',
      top: '50%',
      left: '50%',
      maxWidth: '350px',
      transform: `translate(-50%, -50%)`,
    },
    img: {
      width: 80,
      height: 80,
      borderRadius: 10,
    },
    name: {
      marginTop: theme.spacing(1),
    },
    centerText: {
      alignText: 'center',
    },
    welcome: {
      marginTop: theme.spacing(2),
    },
    welcomeMessage: {
      marginTop: theme.spacing(2),
    },
    address: {
      marginTop: theme.spacing(1),
    },
    buttons: {
      marginTop: theme.spacing(4),
    },
    howItWorks: {
      marginTop: theme.spacing(1),
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(1),
      width: '100%',
      bottom: 5,
    },
    button: {
      borderRadius: 0,
      marginTop: theme.spacing(1),
      fontSize: 13,
    },
    check: {
      backgroundColor: '#2aad4b',
      color: 'white',
    },
  }),
);

interface Props {
  open: boolean;
  onClosePress: () => void;
  headerTitle: string;
  headerProfilePic: string;
  rtl: boolean;
  onRequestLocationClick: () => void;
  location: Opt<DeliveryLocation>;
  disableMap: boolean;
  showStartButton: boolean;
  loading: boolean;
  showAddress: boolean;
  showNotSupportedLocation: boolean;
  onChangeLocationClick: () => void;
  locationInputRef?: React.Ref<any>;
  onMapClick: () => void;
  onStartClick: () => void;
}

export const RequestLocationView = memo<Props>(
  ({
    open,
    showStartButton,
    onClosePress,
    rtl,
    location,
    disableMap,
    onRequestLocationClick,
    loading,
    showNotSupportedLocation,
    showAddress,
    onChangeLocationClick,
    locationInputRef,
    onMapClick,
    onStartClick,
  }) => {
    const classes = useStyles();

    return (
      <>
        <Modal open={open} onClose={onClosePress}>
          <div className={classes.dialog} dir={rtl ? 'rtl' : 'ltr'}>
            <StaticMapView
              disabled={disableMap}
              center={
                location?.coordinates && {
                  lat: location?.coordinates.latitude,
                  lng: location?.coordinates.longitude,
                }
              }
              zoom={15}
              onClick={onMapClick}
            />

            {showAddress && location && (
              <div>
                <Typography
                  align={'center'}
                  variant="subtitle1"
                  color="textPrimary"
                  className={classes.address}
                  style={{ whiteSpace: 'pre-line' }}>
                  {`${LocationHelper.getReadableLocation(location)} ${location.address?.city}`}
                </Typography>
              </div>
            )}

            <Typography
              align={'center'}
              variant="body1"
              color={showNotSupportedLocation ? 'secondary' : 'textPrimary'}
              className={classes.welcomeMessage}
              style={{ whiteSpace: 'pre-line' }}>
              {t(
                `${
                  showNotSupportedLocation
                    ? 'location:notSupportedLocationMessage'
                    : 'location:requestLocationTitle'
                }`,
              )}
            </Typography>

            <div className={classes.buttonContainer}>
              {showNotSupportedLocation ? (
                <Button
                  className={classes.button}
                  fullWidth
                  color="default"
                  variant="outlined"
                  size="large"
                  startIcon={<LocationOnIcon color="secondary" fontSize="small" />}
                  onClick={onChangeLocationClick}>
                  {t('location:changeLocation')}
                </Button>
              ) : showStartButton ? (
                <Button
                  className={classNames(classes.button, classes.check)}
                  fullWidth
                  variant="contained"
                  size="large"
                  startIcon={<CheckIcon />}
                  onClick={onStartClick}>
                  {t('common:start')}
                </Button>
              ) : (
                <CustomButton
                  className={classes.button}
                  fullWidth
                  loading={loading}
                  color="secondary"
                  variant="contained"
                  size="large"
                  startIcon={<LocationOnIcon />}
                  onClick={onRequestLocationClick}>
                  {t('location:requestLocation')}
                </CustomButton>
              )}
            </div>
          </div>
        </Modal>
        <LocationInputMapContainer ref={locationInputRef} />
      </>
    );
  },
);
