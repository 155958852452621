export const en = {
  name: 'Name',
  phoneNumber: 'Phone Number',
  signup: 'Sign Up',
  checkIn: 'Check In',
  checkInDescription: 'Check In for a chance to receive a special offer',
  signupSubtitle: 'Receive special offers and updates 🎉',
  seeReward: 'See Reward',
  noRewardDescription: 'Sorry, no luck this time. Try again at your next visit',
  checkinMessage: 'Check in for a chance to win a special offer today 🎉',
  signupForUpdates: 'Signup for special offers 🎉 and updates',
  thankYouForSigningUp: 'Thank you for Signing Up!',
  seeIfYouWon: 'See if You Won a Reward',
};

export const ar: typeof en = {
  name: 'الأسم',
  checkIn: 'سجل بياناتك',
  phoneNumber: 'رقم الجوال',
  signup: 'سجل بياناتك',
  checkInDescription: 'سجل بياناتك وادخل فرصة السحب على عرض خاص او عرض حصري',
  signupSubtitle: 'واحصل على عروض خاص 🎉',
  seeReward: 'اعرض المفاجأة',
  noRewardDescription: 'عفوا، لم تربح العرض الخاص! الرجاء المحاولة في الزيارة القادمة',
  checkinMessage: 'سجل بياناتك وادخل فرصة السحب على عرض خاص 🎉',
  signupForUpdates: 'سجل معلوماتك للحصول على عروض خاصة 🎉',
  thankYouForSigningUp: 'تم استلام معلوماتك، شكراً لك',
  seeIfYouWon: 'اكتشف اذا فزت بالعرض',
};
