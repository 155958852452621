import { useConfirm } from 'material-ui-confirm';
import React, { memo, useRef, useState } from 'react';

import Api from '@/api/Api';
import { useUpdateCart } from '@/cart/hooks/useUpdateCart';
import URLHelper from '@/common/utils/URLHelper';
import { DriveThruStatus, OrderStatus } from '@/const/enums';
import { t } from '@/i18n/translate';
import { useFetchOrderInterval } from '@/orders/hooks/useFetchOrderIntervals';
import { useOrder } from '@/orders/hooks/useOrder';
import { useTrackOrder } from '@/orders/hooks/useTrackOrder';
import ContactUserDialog from '@/orders/mobile/components/ContactUserDialog';
import { OnlineCourierOrderPage } from '@/orders/mobile/components/OnlineCourierOrderPage';
import { OrderTrackingPageContainerMethods } from '@/orders/mobile/containers/IFrameOrderTrackingPageContainer';
import PaymentHelper from '@/payment/utils/PaymentHelper';
import { useRTL } from '@/theme/hooks/useRTL';
import DateHelper from '@/utils/DateHelper';

export const OnlineCourierOrderPageContainer = memo(() => {
  const urlParams = new URLSearchParams(window.location.search);
  const { updateCart } = useUpdateCart();
  const redirect = urlParams.get('redirect');
  const [showContactCourier, setShowContactCourier] = useState(false);
  const [loading, setLoading] = useState(false);
  const rtl = useRTL();
  const { order, refetchOrder } = useOrder();
  const tax = order?.tax;
  const trackOrder = useTrackOrder();
  console.log('online order page', rtl);
  const trackingOrderRef = useRef<OrderTrackingPageContainerMethods>();

  const { stopInterval, startInterval } = useFetchOrderInterval(
    DateHelper.convertMinutesToMilliseconds(1.5),
  );
  const confirm = useConfirm();

  // useEffect(() => {
  //   if (redirect === 'true' && order) {
  //     // Log events
  //     // trackOrder(order);
  //     // updateCart([], UpdateCartType.Clear);
  //     // const newURL = URLHelper.removeURLParameter(
  //     //   `${window.location.pathname}${window.location.search}`,
  //     //   'redirect',
  //     // );
  //     // window.location.assign(newURL);
  //   }
  // }, [order]);

  // useEffect(() => {
  //   if (success && !loading) {
  //     window.location.reload();
  //   }
  // }, [success, loading]);

  if (!order) {
    return <></>;
  }

  const handlerCallback = () => window.open(`tel:${order.store!!.phoneNumber}`, '_self');
  const handleStoreMapClick = () => {
    const { latitude, longitude } = order.store!!.location.coordinates;
    window.open(`https://maps.google.com/maps?daddr=${latitude},${longitude}&amp;ll=`, '_blank');
  };

  const handleDeliveryMapClick = () => URLHelper.goToMap(order.delivery!!.location!!.coordinates);

  const handleContactCourier = () => setShowContactCourier(true);

  const isPaidOnline = () => PaymentHelper.isOnlinePayment(order.paymentTransaction?.method);

  const getReadyForPickupButtonLabel = () => {
    if (
      order.pickup?.driveThru?.status === DriveThruStatus.ReadyForPickup ||
      order.status === OrderStatus.DriveThruReadyForPickup
    ) {
      return t('order:notifiedReadyForPickup');
    }
    return t('order:readyForPickup');
  };

  const handleActionClick = async () => {
    const courierId = URLHelper.getURLParams('courierId');
    console.log('handleActionClick: ', courierId);
    setLoading(true);
    try {
      if (order.status === OrderStatus.DeliveryInProgress) {
        await Api.markDelivered(order, courierId!!);
      } else {
        await Api.markDeliveryInProgress(order, courierId!!);
      }
    } catch (e) {
      alert(e.message);
    }
    // }
    window.location.reload();
    setLoading(false);
  };

  return (
    <>
      <OnlineCourierOrderPage
        disableReadyForPickupButton={
          order.pickup?.driveThru?.status === DriveThruStatus.ReadyForPickup
        }
        showDeliveredAction={order.status === OrderStatus.DeliveryInProgress}
        showDeliveryInProgressAction={
          order.status === OrderStatus.New || order.status === OrderStatus.Accepted
        }
        arrivedAtStoreButtonLabel={getReadyForPickupButtonLabel()}
        loading={loading}
        onContactCustomerClick={handleContactCourier}
        trackingModalRef={trackingOrderRef}
        order={order}
        rtl={rtl}
        onStoreMapClick={handleStoreMapClick}
        onCallStoreClick={handlerCallback}
        onDeliveryMapClick={handleDeliveryMapClick}
        onActionClick={handleActionClick}
        tax={tax}
      />
      <ContactUserDialog
        isDriver={false}
        onClose={() => setShowContactCourier(false)}
        open={showContactCourier}
        onPhoneClick={() =>
          order?.guest?.phoneNumber && URLHelper.goToPhone(order.guest?.phoneNumber)
        }
        onWhatsAppClick={() =>
          order?.guest?.phoneNumber && URLHelper.goToWhatsApp(order?.guest?.phoneNumber)
        }
        onMessageClick={() =>
          order?.guest?.phoneNumber && URLHelper.goToMessage(order?.guest?.phoneNumber)
        }
      />
    </>
  );
});
