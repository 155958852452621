import StorefrontIcon from '@material-ui/icons/Storefront';
import React from 'react';

import {AmericanExpressIcon, ApplePayIcon, CashIcon, MadaIcon, MastercardIcon, OtherIcon, PickupIcon, VisaIcon,} from '@/assets/images/tsxSVGIcons';
import {PaymentMethod, PickupType} from '@/const/enums';
import {t} from '@/i18n/translate';
import {PaymentMethodLabel, PaymentTransaction} from '@/types';

export default class PaymentHelper {
  public static async sha256(message: string): Promise<string> {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(message);

    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convert bytes to hex string
    const hashHex = hashArray.map(b => ('00' + b.toString(16)).slice(-2)).join('');
    return hashHex.toString();
  }

  public static getLabelFromPaymentMethod(
    paymentMethod: PaymentMethod,
    pickupType?: PickupType,
  ): string {
    switch (paymentMethod) {
      case PaymentMethod.Cash:
        return t('payment:cash');
      case PaymentMethod.PayAtStore:
        switch (pickupType) {
          case PickupType.DriveThru:
            return t('payment:payAtPickup');
          case PickupType.Curbside:
            return t('payment:payAtPickup');
          default:
            return t('payment:payAtStore');
        }

      case PaymentMethod.ApplePay:
        return t('payment:applePay');
      default:
        return '';
    }
  }

  public static getIconFromPaymentMethod(
    paymentMethod: PaymentMethod,
    pickupType?: PickupType,
  ): React.ElementType[] {
    switch (paymentMethod) {
      case PaymentMethod.Cash:
        return [CashIcon];
      case PaymentMethod.PayAtStore:
        switch (pickupType) {
          case PickupType.DriveThru:
            return [PickupIcon];
          case PickupType.Curbside:
            return [PickupIcon];
          default:
            return [StorefrontIcon];
        }
      case PaymentMethod.ApplePay:
        return [ApplePayIcon];
      default:
        return [MadaIcon, VisaIcon, MastercardIcon];
    }
  }

  public static isOnlinePayment(method: Opt<PaymentMethod>): boolean {
    if (!method) {
      return false;
    }

    return this.isPaymentDebitCreditCard(method) || method === PaymentMethod.ApplePay;
  }

  public static isPaymentDebitCreditCard(method: Opt<PaymentMethod>): boolean {
    if (!method) {
      return false;
    }
    return (
      method === PaymentMethod.AmericanExpress ||
      method === PaymentMethod.Mada ||
      method === PaymentMethod.Visa ||
      method === PaymentMethod.MasterCard ||
      method === PaymentMethod.Discover
    );
  }

  public static hasDebitCardPaymentMethods(paymentMethods: PaymentMethod[]): boolean {
    return paymentMethods.filter(method => this.isPaymentDebitCreditCard(method)).length > 0;
  }

  public static getOrderViewPaymentMethodLabelAndIcon(
    payment: Opt<PaymentTransaction>,
  ): PaymentMethodLabel {
    const label = `xxxxxxxxxxxx${payment?.last4Digits}`;
    if (!payment || !payment.method) {
      return { label: '', method: PaymentMethod.Unknown, PaymentIcons: [OtherIcon] };
    }
    const method = payment?.method;
    switch (payment?.method) {
      case PaymentMethod.ApplePay:
        return {
          label: t('payment:applePay'),
          method,
          PaymentIcons: [ApplePayIcon],
        };
      case PaymentMethod.PayAtStore:
        return {
          label: t('payment:payAtStore'),
          method,
          PaymentIcons: [StorefrontIcon],
        };
      case PaymentMethod.Cash:
        return {
          label: t('payment:cash'),
          method,
          PaymentIcons: [CashIcon],
        };
      case PaymentMethod.MasterCard:
        return {
          label,
          method,
          PaymentIcons: [MastercardIcon],
        };
      case PaymentMethod.Visa:
        return {
          label,
          method,
          PaymentIcons: [VisaIcon],
        };
      case PaymentMethod.AmericanExpress:
        return {
          label,
          method,
          PaymentIcons: [AmericanExpressIcon],
        };
      case PaymentMethod.Mada:
        return {
          label,
          method,
          PaymentIcons: [MadaIcon],
        };
      default:
        return {
          label,
          method,
          PaymentIcons: [OtherIcon],
        };
    }
    // }

    // return { label: '' };
  }

  public static getFinalizeOrderPaymentMethodLabelsAndIcons(
    paymentMethods: Opt<PaymentMethod[]>,
    currentPickupType: Opt<PickupType>,
    isApplePayAvailable: boolean,
  ): Opt<PaymentMethodLabel[]> {
    const labelsAndIcons: Array<PaymentMethodLabel> = [];
    if (!paymentMethods) {
      return undefined;
    }
    let addedCreditCardPaymentMethod = false;
    paymentMethods.forEach(paymentMethod => {
      if (paymentMethod === PaymentMethod.ApplePay && !isApplePayAvailable) {
        return;
      }
      // If we already added credit/debit card payment (i.e. VISA, MASTERCARD), ignore adding duplicates.
      if (
        (!addedCreditCardPaymentMethod && this.isPaymentDebitCreditCard(paymentMethod)) ||
        paymentMethod === PaymentMethod.Cash ||
        paymentMethod === PaymentMethod.PayAtStore ||
        paymentMethod === PaymentMethod.ApplePay
      ) {
        labelsAndIcons.push({
          label: this.getLabelFromPaymentMethod(paymentMethod, currentPickupType),
          method: paymentMethod,
          PaymentIcons: this.getIconFromPaymentMethod(paymentMethod, currentPickupType),
        });

        if (this.isPaymentDebitCreditCard(paymentMethod)) {
          addedCreditCardPaymentMethod = true;
        }
      }
    });

    if (
      paymentMethods.find(method => method === PaymentMethod.Online) &&
      !labelsAndIcons.find(label => label.method === PaymentMethod.Visa)
    ) {
      if (isApplePayAvailable) {
        labelsAndIcons.push({
          label: t('payment:applePay'),
          method: PaymentMethod.ApplePay,
          PaymentIcons: this.getIconFromPaymentMethod(PaymentMethod.ApplePay, currentPickupType),
        });
      }
      labelsAndIcons.push({
        label: '',
        method: PaymentMethod.Visa,
        PaymentIcons: this.getIconFromPaymentMethod(PaymentMethod.Visa, currentPickupType),
      });
    }
    return labelsAndIcons;
  }

  public static getPTObjectSrtValue(param: any) {
    let strBuilder = '';
    Object.keys(param).forEach(key => {
      strBuilder = strBuilder.concat(`${key}=${param[key]}, `);
    });

    if (strBuilder.length > 0) {
      strBuilder = strBuilder.slice(0, -2);
    }
    return `{${strBuilder}}`;
  }

  public static async getPayfortSignature(request: any, passPhrase: string): Promise<string> {
    let strBuilder = passPhrase;
    Object.keys(request)
      .sort()
      .forEach((key: string) => {
        if (key !== 'signature') {
          let value = request[key];
          if (typeof request[key] === 'object') {
            value = this.getPTObjectSrtValue(request[key]);
          }
          strBuilder = strBuilder.concat(`${key}=${value}`);
        }
      });
    strBuilder = strBuilder + passPhrase;
    return PaymentHelper.sha256(strBuilder);
  }
}
