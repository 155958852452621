import { createStyles, withStyles } from '@material-ui/core';
import React from 'react';
import Favicon from 'react-favicon';
import { Helmet } from 'react-helmet';

import { Event } from '@/analytics/Event';
import { AnalyticsProvider } from '@/analytics/providers/AnalyticsProvider';
import SafaryAnalytics from '@/analytics/SafaryAnalytics';
import { BrowseTypeProvider } from '@/browse/providers/BrowseTypeProvider';
import { BrowseType } from '@/const/enums';
import { GuestProvider } from '@/guest/provider/GuestProvider';
import { OnlineOrdersPageContainer } from '@/orders/mobile/containers/OnlineOrdersPageContainer';
import { DineInProvider } from '@/orders/providers/DineInProvider';
import { OrdersProvider } from '@/orders/providers/OrdersProvider';
import { getInitialProps, Props } from '@/store/components/StorePageRouter';
import { StoreProvider } from '@/store/providers/StoreProvider';
import StoreHelper from '@/store/utils/StoreHelper';
import MetaHelper from '@/utils/MetaHelper';

const styles = createStyles({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});

interface State {
  openDialog: boolean;
}

class OrdersPageRouter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openDialog: false,
    };
  }

  public static getInitialProps = ({ _, __, match, req }: any) => {
    return getInitialProps({ _, __, match, req });
  };

  componentDidMount(): void {
    SafaryAnalytics.logEvent(Event.ViewOrderPage);
    const { browseType, isDesktop } = this.props;
    if (!isDesktop && browseType !== BrowseType.online) {
      // document.body.style.overflow = 'hidden';
    }
  }

  public render() {
    const isSSR = typeof window === 'undefined';
    if (isSSR) {
      return <></>;
    }
    const { stores, error, classes, siteUrl, handle, lng, browseType, dineInId } = this.props;
    if (error || !stores || stores.length === 0) {
      return <></>;
    }

    const defaultStore = StoreHelper.getDefaultStore(stores);
    const { profilePic } = defaultStore;
    return (
      <div className={classes.root}>
        <Favicon url={profilePic} />
        <Helmet>
          <meta charSet="utf-8" />
          <title>{MetaHelper.getTitle(defaultStore)}</title>
          <meta
            name="description"
            content={MetaHelper.getDescription(defaultStore, lng, browseType)}
          />
          <link rel="canonical" href={`${siteUrl}/${handle}`} />
          <link rel="apple-touch-icon" sizes="57x57" href={profilePic} />
          <link rel="apple-touch-icon" href={profilePic} sizes="128x128" />
          <link rel="apple-touch-icon" href={profilePic} sizes="144x144" />
          <link rel="apple-touch-icon" href={profilePic} sizes="152x152" />
        </Helmet>
        <StoreProvider defaultStore={defaultStore} defaultStores={stores}>
          <GuestProvider shouldCreateGuest={false}>
            <AnalyticsProvider>
              <BrowseTypeProvider browseType={browseType}>
                <DineInProvider dineInId={dineInId}>
                  <OrdersProvider>
                    <OnlineOrdersPageContainer />
                  </OrdersProvider>
                </DineInProvider>
              </BrowseTypeProvider>
            </AnalyticsProvider>
          </GuestProvider>
        </StoreProvider>
      </div>
    );
  }
}

export default withStyles(styles)(OrdersPageRouter);
