import { List, ListItem, ListItemIcon } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { BugReport, ContactSupport, Home, Language, ListAlt } from '@material-ui/icons';
import React, { memo } from 'react';

import { LanguageSelection } from '@/common/components/LanguageSelection';
import { t } from '@/i18n/translate';
import { useRTL } from '@/theme/hooks/useRTL';
import { Languages } from '@/types';

import { MenuLink } from './MenuLink';

interface Props {
  onContactTechnicalSupport: () => void;
  onStoreSupportClick: () => void;
  onOrdersClick: () => void;
  onHomeClick: () => void;
  showMenu: boolean;
  languages?: Languages;
}
export const MenuLinks = memo<Props>(
  ({ onContactTechnicalSupport, onStoreSupportClick, onOrdersClick, onHomeClick, showMenu }) => {
    const rtl = useRTL();
    return (
      <div>
        <List>
          <Divider />
          <MenuLink icon={<Home />} text={t('common:homeMenuTitle')} onClick={onHomeClick} />
          {showMenu && (
            <MenuLink
              icon={<ListAlt />}
              text={t('common:ordersMenuTitle')}
              onClick={onOrdersClick}
            />
          )}
          <MenuLink
            icon={<ContactSupport />}
            text={t('common:contact')}
            onClick={onStoreSupportClick}
          />
          {showMenu && (
            <MenuLink
              icon={<BugReport />}
              text={t('common:supportMenuTitle')}
              onClick={onContactTechnicalSupport}
            />
          )}
        </List>
        <Divider />
      </div>
    );
  },
);
