import mixpanel, { Dict } from 'mixpanel-browser';

import { Analytics } from './Analytics';

export default class MixpanelAnalytics implements Analytics {
  constructor(key: string) {
    mixpanel.init(key);
  }
  public logEvent(event: string, params: Dict): void {
    mixpanel.track(event, params);
  }

  public getProp(key: string): string {
    return mixpanel.get_property(key);
  }

  public registerSuperProperties(params: Dict): void {
    // mixpanel.people.set(params);
    mixpanel.register(params);
  }

  public loginUser(userId: string) {
    mixpanel.alias(userId);
    mixpanel.identify(userId);
  }

  public logout() {}
}
