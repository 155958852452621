import {
  Button,
  createStyles,
  Dialog,
  Grid,
  IconButton,
  makeStyles,
  Slide,
  Theme,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { Call, Message } from '@material-ui/icons';
import RefreshIcon from '@material-ui/icons/Refresh';
import React, { memo } from 'react';
import Iframe from 'react-iframe';

import { Space } from '@/common/components/Space';
import HeaderContainer from '@/common/containers/HeaderContainer';
import { t } from '@/i18n/translate';
import { useRTL } from '@/theme/hooks/useRTL';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100vh',
    },
    refreshIcon: {
      position: 'absolute',
      top: '9%',
      border: '1.5px solid rgb(186,186,186)',
      backgroundColor: '#f5f5f6',
      outline: 'none',
    },
    buttonsContainer: {
      position: 'fixed',
      height: '14%',
      backgroundColor: 'white',
      bottom: 0,
    },
    button: {
      fontWeight: 'bold',
      borderRadius: 0,
    },
  }),
);

interface Props {
  url: string;
  onRefreshClick: () => void;
  onCloseClick: () => void;
  onCallCourier: () => void;
  onMessageCourier: () => void;
  open: boolean;
  rtl: boolean;
  showContactCourier: boolean;
}

const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  // @ts-ignore
  <Slide direction="up" ref={ref} {...props} />
));

export const IFrameOrderTrackingPage = memo<Props>(
  ({
    open,
    url,
    showContactCourier,
    onRefreshClick,
    onCloseClick,
    onCallCourier,
    onMessageCourier,
  }) => {
    const rtl = useRTL();
    const classes = useStyles();
    return (
      <Dialog
        fullScreen
        open={open}
        onClose={onCloseClick}
        onEscapeKeyDown={onCloseClick}
        TransitionComponent={Transition}>
        <div className={classes.root}>
          <HeaderContainer fixed hideLanguage onCloseClick={onCloseClick} />
          <Space height={2.4} />
          <IconButton
            style={{
              left: 10,
            }}
            color={'primary'}
            className={classes.refreshIcon}
            onClick={onRefreshClick}>
            <RefreshIcon />
          </IconButton>
          <Iframe
            id="iframeid"
            url={url}
            styles={{
              overflow: 'auto',
              overflowScrolling: 'touch',
              WebkitOverflowScrolling: 'touch',
            }}
            width="100%"
            height="93%"
            allowFullScreen
            scrolling={'yes'}
            onLoad={() => {}}
            frameBorder={0}
          />
          {showContactCourier && (
            <Grid className={classes.buttonsContainer} container direction="column">
              <Grid item container alignItems="stretch">
                <Button
                  dir={rtl ? 'rtl' : 'ltr'}
                  fullWidth
                  color="primary"
                  variant="contained"
                  size="large"
                  startIcon={<Call />}
                  className={classes.button}
                  onClick={onCallCourier}>
                  {t('order:callDriver')}
                </Button>
              </Grid>
              <Grid item container alignItems="stretch">
                <Button
                  dir={rtl ? 'rtl' : 'ltr'}
                  fullWidth
                  color="default"
                  variant="contained"
                  size="large"
                  startIcon={<Message />}
                  className={classes.button}
                  onClick={onMessageCourier}>
                  {t('order:messageDriver')}
                </Button>
              </Grid>
            </Grid>
          )}
        </div>
      </Dialog>
    );
  },
);
