import {
  Button,
  createStyles,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React, { memo } from 'react';

import { useRTL } from '@/theme/hooks/useRTL';
import { MenuCategory } from '@/types';

export const drawerWidth = 250;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    categoryText: {
      color: '#8d8d8d',
    },
    selectedCategory: {
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
    button: {
      fontWeight: 'bold',
      flexGrow: 1,
    },
  }),
);

interface Props {
  open: boolean;
  onCloseDrawer: () => void;
  categories: Opt<MenuCategory[]>;
  selectedCategory: MenuCategory;
  onCategorySelect: (category: MenuCategory) => void;
  showLanguageButton: boolean;
  showCollapseMenu: boolean;
  onLanguageButtonClick: () => void;
}

export const SideMenu = memo<Props>(
  ({
    open,
    onCloseDrawer,
    categories,
    selectedCategory,
    onCategorySelect,
    onLanguageButtonClick,
    showLanguageButton,
    showCollapseMenu,
  }) => {
    const classes = useStyles();
    const rtl = useRTL();

    const drawerClasses = {
      paper: classes.drawerPaper,
    };

    const renderCategories = () => (
      <List>
        {categories &&
          categories.map(category => (
            <ListItem button key={category.id} onClick={() => onCategorySelect(category)}>
              <ListItemText
                primary={
                  <Typography
                    variant="h5"
                    noWrap
                    className={
                      selectedCategory && selectedCategory.id === category.id
                        ? classes.selectedCategory
                        : classes.categoryText
                    }>
                    {rtl ? category.name.ar : category.name.en}
                  </Typography>
                }
              />
            </ListItem>
          ))}
      </List>
    );
    return (
      <nav className={classes.drawer}>
        <Drawer open={open} variant={'persistent'} classes={drawerClasses}>
          <div className={classes.drawerHeader}>
            {showLanguageButton && (
              <Button
                variant="outlined"
                color="default"
                size="large"
                className={classes.button}
                onClick={onLanguageButtonClick}>
                {rtl ? 'English' : 'عربي'}
              </Button>
            )}
            {showCollapseMenu && (
              <IconButton onClick={onCloseDrawer}>
                {rtl ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            )}
          </div>
          <Divider />
          {renderCategories()}
        </Drawer>
      </nav>
    );
  },
);
