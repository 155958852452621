import { Button, Card, createStyles, Dialog, makeStyles, Slide, Theme } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import ReceiptIcon from '@material-ui/icons/Receipt';
import React, { memo } from 'react';

import CartMenuItemsList from '@/cart/mobile/components/CartMenuItemsList';
import EmptyAnimation from '@/cart/mobile/components/EmptyAnimation';
import CartHelper from '@/cart/utils/CartHelper';
import { Loader } from '@/common/components/Loader';
import { Space } from '@/common/components/Space';
import HeaderContainer from '@/common/containers/HeaderContainer';
import { t } from '@/i18n/translate';
import { useStore } from '@/store/hooks/useStore';
import { useRTL } from '@/theme/hooks/useRTL';
import { Cart, Tax } from '@/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: 'flex',
      position: 'absolute',
      width: '100%',
      bottom: 0,
    },
    button: {
      borderRadius: 0,
      bottom: 0,
    },
    dialog: {
      flex: 1,
      backgroundColor: theme.palette.type === 'light' ? 'white' : theme.palette.primary.dark,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
    },
    checkoutButtonContainer: {
      backgroundColor: theme.palette.type === 'light' ? 'white' : theme.palette.primary.dark,
      display: 'flex',
      flexDirection: 'column',
      position: 'fixed',
      width: '100%',
      bottom: 0,
    },
    cancel: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    checkoutButton: {
      borderRadius: 0,
    },
    cartContainer: { height: '82vh', overflowY: 'scroll' },
  }),
);

interface Props {
  open: boolean;
  onClosePress: () => void;
  cart: Cart;
  showEmpty: boolean;
  onClearCartClick: () => void;
  onNextClick: () => void;
  onCancelCheckoutClick: () => void;
  loading: boolean;
  editable: boolean;
  showCancelButton: boolean;
  transitionToOrderPage: boolean;
  showNextButton: boolean;
  minimumDeliveryOrder: Opt<number>;
  showMinimumDeliveryOrder: boolean;
  tax: Opt<Tax>;
}

const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  // @ts-ignore
  <Slide direction="up" ref={ref} {...props} />
));

export const CartView = memo<Props>(
  ({
    open,
    onClosePress,
    cart,
    showEmpty,
    onClearCartClick,
    loading,
    onNextClick,
    editable,
    transitionToOrderPage,
    showNextButton,
    minimumDeliveryOrder,
    showMinimumDeliveryOrder,
    tax,
  }) => {
    const classes = useStyles();
    const rtl = useRTL();
    const { selectedStore: store, discount } = useStore();
    const { theme } = store?.settings || {};
    const { basic } = theme || {};

    return (
      <Dialog fullScreen open={open} onClose={onClosePress} TransitionComponent={Transition}>
        <div className={classes.dialog} dir={rtl ? 'rtl' : 'ltr'}>
          <HeaderContainer hideMenu fixed onCloseClick={onClosePress} />
          {transitionToOrderPage ? (
            <div>
              <Space height={12} />
              <Loader />
            </div>
          ) : (
            <>
              <Space height={8} />
              <div className={classes.cartContainer}>
                <CartMenuItemsList
                  rtl={rtl}
                  tax={tax}
                  totalPriceForItems={CartHelper.getTotalPriceForItems(cart.items, discount)}
                  quantity={CartHelper.getQuantity(cart.items)}
                  menuItems={cart.items}
                  onClearCartClick={onClearCartClick}
                  loading={loading}
                  editable={editable}
                  minimumDeliveryOrder={minimumDeliveryOrder}
                  showMinimumDeliveryOrder={showMinimumDeliveryOrder}
                  showDelivery={false}
                />
                {showEmpty && <EmptyAnimation />}
              </div>
              <Card square elevation={2} className={classes.checkoutButtonContainer}>
                {showNextButton && (
                  <div className={classes.row}>
                    <Button
                      disableElevation
                      disabled={loading}
                      className={classes.checkoutButton}
                      fullWidth
                      color="secondary"
                      variant="contained"
                      size="large"
                      style={basic?.color ? { backgroundColor: basic?.color, color: 'white' } : {}}
                      startIcon={<ReceiptIcon />}
                      onClick={onNextClick}>
                      {t('common:next')}
                    </Button>
                  </div>
                )}
                <Button
                  className={classes.cancel}
                  variant="text"
                  size="large"
                  onClick={onClosePress}>
                  {t('common:cancel')}
                </Button>
              </Card>
            </>
          )}
        </div>
      </Dialog>
    );
  },
);
