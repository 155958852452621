import { useConfirm } from 'material-ui-confirm';
import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useAlert } from 'react-alert';

import { useBrowseType } from '@/browse/hooks/useBrowseType';
import { useUpdateCart } from '@/cart/hooks/useUpdateCart';
import CartHelper from '@/cart/utils/CartHelper';
import { BrowseType, UpdateCartType } from '@/const/enums';
import { t } from '@/i18n/translate';
import MenuItemOptionsView, {
  MenuItemOptionsViewMethods,
} from '@/menu/mobile/components/MenuItemOptionsView';
import MenuItemView, { CounterProps } from '@/menu/mobile/components/MenuItemView';
import MenuItemHelper from '@/menu/utils/MenuItemHelper';
import { StartCheckView } from '@/orders/components/StartCheckView';
import { useStore } from '@/store/hooks/useStore';
import { useRTL } from '@/theme/hooks/useRTL';
import { MenuCategory, MenuItem, MenuItemOptions, TimeRange } from '@/types';

interface Props {
  item: MenuItem;
  ref?: React.Ref<any>;
  category?: MenuCategory;
  isUpdating?: boolean;
  categoryAvailability?: Opt<TimeRange>;
  isCategoryAvailable?: boolean;
  showCategoryTempUnavailable?: boolean;
  setShowStartCheck?: (value: boolean) => void;
  showSushiWarning: boolean;
}

export interface MenuItemViewMethods {
  setOpenModal: (open: boolean) => void;
}

export const MenuItemViewContainer = memo<Props>(
  forwardRef<MenuItemViewMethods, Props>(
    (
      {
        item,
        isUpdating,
        categoryAvailability,
        isCategoryAvailable,
        showCategoryTempUnavailable,
        category,
        setShowStartCheck,
        showSushiWarning,
      },
      ref,
    ) => {
      const menuItem = item;
      const alert = useAlert();
      const confirm = useConfirm();
      const [open, setOpen] = useState(false);
      const [notes, setNotes] = useState(menuItem.notes);
      const [openOptions, setOpenOptions] = useState(false);
      const { updateCart, loading, error, success } = useUpdateCart();
      const [optionsToDisplay, setOptionsToDisplay] = useState<MenuItemOptions>();
      const { selectedStore, notAcceptingOrders, storeClosed, discount } = useStore();
      const { settings } = selectedStore || {};
      const { disableOrderNotes, currency } = settings || {};
      const rtl = useRTL();
      const { type: browseType } = useBrowseType();

      const menuItemVieOptionsRef = useRef<MenuItemOptionsViewMethods>();
      const counterRef = useRef<CounterProps>();

      // const { isTemporarilyUnavailable, temporarilyUnavailableForStoresIds } = menuItem;
      // const { isUnAvailable, unavailableForStoresIds } = menuItem?.availability || {};

      const unavailable = MenuItemHelper.isItemUnavailable(item, selectedStore?.id);

      // isUnAvailable || !!unavailableForStoresIds?.find(id => id === selectedStore?.id);

      useImperativeHandle(ref, () => ({ setOpenModal: setOpen }), []);

      const openModal = useCallback((open: boolean) => setOpen(open), []);

      const handleAddItemClick = async () => {
        const optionsError = MenuItemHelper.getOptionsError(menuItem, rtl, currency);
        // console.log('handleAddItemClick', counterRef?.current?.state.inputValue);
        if (optionsError) {
          try {
            await confirm({
              title: t('common:alert'),
              description: optionsError,
              confirmationText: t('common:ok'),
              cancellationText: '',
              confirmationButtonProps: {
                color: 'default',
              },
            });
          } catch (e) {
            console.log(e);
          }
        } else {
          const quantity = Number(counterRef?.current?.state.inputValue);
          const item = {
            ...menuItem,
            categoryId: category ? category.id : menuItem.categoryId,
            category: category ? category.name : menuItem.category,
            secondaryId: isUpdating
              ? menuItem.secondaryId
              : Math.random().toString().replace('0.', ''),
            quantity,
            total: MenuItemHelper.getMenuItemWithOptionsPrice({
              ...menuItem,
              quantity,
            }),
            notes,
          };
          updateCart([item], isUpdating ? UpdateCartType.Update : UpdateCartType.Add);
        }
        // setShowStartCheck && setShowStartCheck(true);
      };

      useEffect(() => {
        if (success && !loading) {
          setOpen(false);
          menuItemVieOptionsRef.current?.resetOptionsToDefault();
        }
      }, [success, loading]);

      if (!selectedStore) {
        return <></>;
      }
      // const { isReceivingOrders } = selectedStore!!;

      if (error) {
        alert.error(error);
      }

      const handleAddOptionsClick = () => {
        setOpenOptions(false);
      };

      const handleOptionsClick = (options: MenuItemOptions) => {
        setOptionsToDisplay(options);
        menuItemVieOptionsRef.current?.setOptions(options);
        setOpenOptions(true);
      };

      const isNotesDisabled = () => {
        return disableOrderNotes || isOnlineOrderingDisabled();
      };

      const isOnlineOrderingDisabled = () => {
        return (
          unavailable ||
          showCategoryTempUnavailable ||
          notAcceptingOrders ||
          storeClosed ||
          browseType === BrowseType.basic ||
          (isCategoryAvailable !== undefined && !isCategoryAvailable)
        );
      };

      return (
        <div>
          <MenuItemView
            showSushiWarning={showSushiWarning}
            quantityRef={counterRef}
            open={open}
            notes={notes}
            isUpdating={isUpdating}
            loading={loading}
            menuItem={menuItem}
            showCalories={menuItem.calories !== undefined && menuItem.calories !== null}
            onClosePress={() => openModal(false)}
            onAddItemClick={handleAddItemClick}
            onOptionsClick={handleOptionsClick}
            onNotesChange={setNotes}
            showTempUnavailable={unavailable || !!showCategoryTempUnavailable}
            showTimeUnavailable={
              categoryAvailability !== undefined &&
              categoryAvailability?.start !== undefined &&
              categoryAvailability?.start !== null
            }
            isTimeUnavailableError={!isCategoryAvailable}
            timeAvailability={categoryAvailability}
            hideNotes={isNotesDisabled()}
            hideAddToCartButton={isOnlineOrderingDisabled()}
          />
          {optionsToDisplay && (
            <MenuItemOptionsView
              itemPrice={menuItem.price}
              ref={menuItemVieOptionsRef}
              defaultOptions={optionsToDisplay}
              open={openOptions}
              loading={loading}
              onClosePress={() => setOpenOptions(false)}
              onAddOptionClick={handleAddOptionsClick}
              onCancelClick={() => setOpenOptions(false)}
              type={optionsToDisplay.type}
            />
          )}
        </div>
      );
    },
  ),
);
