import { StoreType } from '@/const/enums';
import { t } from '@/i18n/translate';
import { SupportedLanguages } from '@/i18n/types';
import LocationHelper from '@/location/utils/LocationHelper';
import { useRTL } from '@/theme/hooks/useRTL';
import { getLang } from '@/theme/utils/getLangDirection';
import { Coordinates, Store } from '@/types';
import DateHelper from '@/utils/DateHelper';
import StringsHelper from '@/utils/StringsHelper';

export default class StoreHelper {
  public static getStoreName(store: Store): string {
    const isRTL = useRTL();
    return !isRTL ? store.name.en : store.name.ar ?? '';
  }

  public static hasOnlineOrdering(store: Opt<Store>): boolean {
    if (store?.settings?.orderTypes) {
      return true;
    }
    return false;
  }

  public static getStoreAddressCopy(store: Store): string {
    return `${this.getStoreName(store)} ${this.getStoreAddress(store)}`;
  }

  public static getDefaultStore(stores: Store[]): Store {
    let defaultStore = this.sortStores(stores).find(store => store.isDefault);
    if (!defaultStore) {
      defaultStore = stores[0];
    }
    return defaultStore;
  }

  public static nearestStore(myLocation: Coordinates, stores: Store[]): Opt<Store> {
    // stores.forEach(store => {
    //   store.distance = LocationHelper.distance(myLocation, store.location.coordinates, 'K');
    // });

    if (stores.length === 0) {
      return undefined;
    }

    for (let i = 0; i < stores.length; i++) {
      stores[i].distance = LocationHelper.distance(myLocation, stores[i].location.coordinates, 'K');
    }

    const sorted = [
      ...stores.sort((a, b) => {
        return a.distance!! - b.distance!!;
      })!!,
    ];

    return sorted[0];
  }

  // public static findNearestStore(myLocation: Coordinates, stores: Store[]): Opt<Store> {
  //   // let storeDistances: any[] = [];
  //
  //   stores = stores.sort((a, b) => {
  //     if (a.distance!! < b.distance!!) return -1;
  //     else if (b.distance!! < a.distance!!) return 1;
  //     return 0;
  //   })!!;
  //
  //   if (stores.length < 1) {
  //     return undefined;
  //   }
  //
  //   // const nearestStoreId = stores[0].id;
  //   console.log('storeDistances', stores);
  //   return stores[0];
  // }

  public static sortStores(stores: Store[]): Store[] {
    return stores.sort((a, b) => {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    });
  }

  public static sortStoresByDistance(stores: Store[]): Store[] {
    return stores.sort((a, b) => {
      if (a.distance && b.distance && a.distance < b.distance) {
        return -1;
      }
      if (a.distance && b.distance && a.distance > b.distance) {
        return 1;
      }
      return 0;
    });
  }

  public static isStoreClosed(store: Store): { isOpen: boolean; opensAfter: Opt<string> } {
    const today = new Date();
    const storeSettings = store.settings;
    if (!storeSettings?.hoursOfOperations || !storeSettings.hoursOfOperations?.zoneId) {
      return { isOpen: true, opensAfter: '' };
    }
    let todayHours = storeSettings?.hoursOfOperations?.monday;
    switch (today.getDay()) {
      case 2:
        todayHours = storeSettings?.hoursOfOperations?.tuesday;
        break;
      case 3:
        todayHours = storeSettings?.hoursOfOperations?.wednesday;
        break;
      case 4:
        todayHours = storeSettings?.hoursOfOperations?.thursday;
        break;
      case 5:
        todayHours = storeSettings?.hoursOfOperations?.friday;
        break;
      case 6:
        todayHours = storeSettings?.hoursOfOperations?.saturday;
        break;
      case 7:
        todayHours = storeSettings?.hoursOfOperations?.sunday;
        break;
    }
    let isOpenArray: { isOpen: boolean; opensAfter: Opt<string> }[] = [];
    todayHours?.forEach(hours => {
      isOpenArray.push({
        isOpen: DateHelper.isNowBetweenHours(
          hours.start,
          hours.end,
          storeSettings?.hoursOfOperations?.zoneId,
        ),
        opensAfter: hours.start,
      });
    });
    isOpenArray = isOpenArray
      .filter(item => item.isOpen)
      .sort((a, b) => Number(b.opensAfter)!! - Number(a.opensAfter)!!);
    return isOpenArray && isOpenArray.length > 0
      ? isOpenArray[0]
      : { isOpen: false, opensAfter: '' };
  }

  public static isOnlineStore(store: Store): boolean {
    if (store.location.isHidden) {
      return true;
    }
    return store.type === StoreType.OnlineRestaurant || store.type === StoreType.OnlineCoffeeShop;
  }

  public static getStoreTitle(store: Store): string {
    // const isRTL = useRTL();
    const lang = getLang();
    if (this.isOnlineStore(store)) {
      const title = store.name[lang];
      //!isRTL ? `${store.name.en}` : `${store.name.ar}`;

      return `${title} - ${t('store:onlineStore')}`;
    }
    if (!store || !store.location) {
      return '';
    }
    const neighborhood = store?.location?.address?.neighborhood;
    // ? store?.location?.address?.neighborhood[lang]
    // : undefined;
    //!isRTL
    //   ? store.location.address?.neighborhood?.en
    //   : store.location.address?.neighborhood?.ar;
    let title = '';
    if (neighborhood) {
      title = `${store.name[lang]} - ${neighborhood[lang]}`;
      //!isRTL ? `${store.name.en} - ${neighborhood}` : `${store.name.ar} - ${neighborhood}`;
    }
    // else {
    // title = !isRTL ? store.name.en : store.name.ar ?? '';
    // }

    // TODO: Check if we have the neighborhood twice (TEMP HACK)
    const subString = `- ${neighborhood}`;
    const count = neighborhood ? StringsHelper.occurrences(title, subString, true) : 0;
    if (count > 1) {
      title = title.replace(subString, '');
    }
    return title;
  }

  public static getStoreStreet(store: Store): string {
    const isRTL = useRTL();
    if (!store || !store.location) {
      return '';
    }
    return !isRTL
      ? `${store.location.address?.street.en}${
          store.location.address?.neighborhood?.en!!
            ? ` - ${store?.location.address?.neighborhood?.en}`
            : ''
        }`
      : `${store.location?.address?.street.ar}${
          store.location.address?.neighborhood?.ar!!
            ? ` - ${store.location.address?.neighborhood?.ar}`
            : ''
        }`;
  }

  public static getStoreDeliveryLocationName(
    store: Store,
    language: SupportedLanguages,
  ): Opt<string> {
    const { name, branchName } = store;
    return branchName ? branchName[language] : name[language];
  }

  public static getStoreAddress(store: Store): string {
    return `${StoreHelper.getStoreStreet(store)}, ${StoreHelper.getStoreCity(store)}`;
  }

  public static getStoreCity(store: Store): string {
    const isRTL = useRTL();
    if (!store.location.address) {
      return '';
    }
    return !isRTL ? store.location.address?.city.en!! : 'الرياض';
  }
}
