import React, { memo, useEffect, useState } from 'react';

import Api from '@/api/Api';
import URLHelper from '@/common/utils/URLHelper';
import { useDineIn } from '@/orders/hooks/useDineIn';
import { useStore } from '@/store/hooks/useStore';
import { Check, CheckLocation, DineIn, StartCheck } from '@/types';
import DateHelper from '@/utils/DateHelper';
import FBStorage from '@/utils/FBStorage';
import { isBrowser } from '@/utils/isBrowser';

import { DineInContext } from './DineInContext';

interface Props {
  children: React.ReactChild;
  dineInId?: string;
}

export const DineInProvider = memo<Props>(({ dineInId, children }) => {
  const [dineIn, setDineIn] = useState<Opt<DineIn>>(undefined);
  const [checkLocation, setCheckLocation] = useState<Opt<string>>(undefined);
  const [check, setCheck] = useState<Opt<Check>>();

  const { selectedStore } = useStore();

  const handleSetDineIn = (value: Opt<DineIn>) => {
    FBStorage.setDineIn(value);
    setDineIn(value);
  };

  const handleSetCheck = (value: Opt<Check>) => {
    FBStorage.setCheck(value);
    setCheck(value);
  };

  const handleSetCheckLocation = (value: Opt<string>) => {
    FBStorage.setCheckLocation(value);
    setCheckLocation(value);
  };

  const startCheck = async (startCheck: StartCheck) => {
    const savedCheck = await Api.startCheck(startCheck);
    handleSetCheck(savedCheck);
    setDineIn({ ...dineIn, tableNumber: checkLocation });
    return savedCheck;
  };

  const fetchCheck = async (): Promise<Opt<Check>> => {
    let storeCheck = FBStorage.getCheck();
    if (storeCheck || dineInId) {
      let apiCheck;
      try {
        if (storeCheck) {
          apiCheck = await Api.fetchCheck(dineInId ?? storeCheck!.id!);
        }
      } catch (e) {
        console.error(e.message);
      }
      if (apiCheck) {
        storeCheck = apiCheck;
      }
    }
    setCheckLocation(FBStorage.getCheckLocation());
    setCheck(storeCheck);
    return storeCheck;
  };

  const fetchDineIn = async (): Promise<Opt<DineIn>> => {
    let storedDineIn = FBStorage.getDineIn();
    if (storedDineIn || dineInId) {
      let apiDineIn;
      try {
        if (storedDineIn) {
          apiDineIn = await Api.fetchDineIn(dineInId ?? storedDineIn.id!);
        }
      } catch (e) {
        console.error(e.message);
      }
      if (apiDineIn) {
        storedDineIn = apiDineIn;
      }
    }
    setDineIn(storedDineIn);
    return storedDineIn;
  };

  const checkIsCheckOpened = async (code: string): Promise<Opt<Check>> => {
    // const savedCheck = FBStorage.getCheck();
    // console.log('checkIsCheckOpened savedCheck:', code);
    // debugger;
    const locations = await Api.findCheckByStoreAndCode(selectedStore!.id, code);
    console.log('checkIsCheckOpened: ', locations);
    if (locations.list.length > 0) {
      // debugger;
      const currentCheckLocation = locations.list[0];
      const currentCheck = currentCheckLocation?.currentCheck;
      if (currentCheck) {
        const currentCheckDate = DateHelper.convertZFormatStringDateToDate(
          currentCheck.createdDate!,
        );
        const currentNowDate = new Date();
        const elapsed = (currentNowDate.getTime() - currentCheckDate.getTime()) / 60000;
        // If the check is current and it is open tab, we can let the customer know that someone else started a tab
        if (
          !!currentCheck &&
          currentCheck.canJoinCheck &&
          !!elapsed &&
          elapsed < 60 * 2 /* 2 Hours */
        ) {
          return currentCheck;
        }
      }
    }
    return undefined;
  };

  // useEffect(() => {
  //   const checkCheck = async () => {
  //     const locations = await Api.findCheckByStoreAndCode(selectedStore!.id, checkLocation!);
  //     if (locations.list.length > 0) {
  //       const currentCheckLocation = locations.list[0];
  //       const currentCheck = currentCheckLocation?.currentCheck;
  //
  //       // If we have an existing check, set it
  //       if (currentCheck != null) {
  //         setCheck(currentCheck);
  //         setCheckLocation(currentCheckLocation.code);
  //       }
  //     }
  //   };
  // }, [selectedStore, checkLocation]);

  useEffect(() => {
    const asyncObj = async () => {
      let getDineIn;

      if (isBrowser()) {
        getDineIn = await fetchDineIn();
        await fetchCheck();
      }
      if (
        getDineIn &&
        getDineIn.startDate &&
        !window.location.pathname.includes('/dine-in') &&
        DateHelper.isDateNDaysInThePast(
          DateHelper.convertZFormatStringDateToDate(getDineIn.startDate!),
          1,
        )
      ) {
        setCheck(undefined);
        FBStorage.setCheck(undefined);
        setDineIn(undefined);
        FBStorage.setDineIn(undefined);
        FBStorage.setCheckLocation(undefined);
        // FBStorage.reset();
      }
    };
    asyncObj();
    // FBStorage.reset();
  }, []);

  return (
    <DineInContext.Provider
      value={{
        dineIn,
        check,
        checkLocation,
        setCheckLocation: handleSetCheckLocation,
        setCheck: handleSetCheck,
        startCheck,
        checkIsCheckOpened,
        setDineIn: handleSetDineIn,
      }}>
      {children}
    </DineInContext.Provider>
  );
});
