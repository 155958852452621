import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

import { MenuCategory, MenuItem } from '@/types';

import MenuItemCardContainer from '../containers/MenuItemCardContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '0 25px', // 0 padding for bottom and top and 25 fot left and right
      [theme.breakpoints.down('sm')]: {
        padding: '0 0px', // 0 padding for bottom and top and 25 fot left and right
      },
      alignItems: 'center',
      justifyContent: 'center',
    },
    reviewCard: {
      margin: '0 auto',
    },
  }),
);
interface Props {
  category: MenuCategory;
}

const MenuItemsGrid = ({ category }: Props) => {
  const classes = useStyles();
  const renderItems = () =>
    category.items
      .sort((a: MenuItem, b: MenuItem) => {
        if (a.price > b.price) {
          return 1;
        } else if (a.price < b.price) {
          return -1;
        }
        return 0;
      })
      .map(item => (
        <Grid key={item.id} item xs={12} sm={6} md={4}>
          <MenuItemCardContainer className={classes.reviewCard} menuItem={item} />
        </Grid>
      ));

  return (
    <div className={classes.root}>
      <Grid container spacing={4} justify="flex-start">
        {renderItems()}
      </Grid>
    </div>
  );
};

export default MenuItemsGrid;
