import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import axios from 'axios';
import React from 'react';
import Favicon from 'react-favicon';
import { Helmet } from 'react-helmet';

import { AnalyticsProvider } from '@/analytics/providers/AnalyticsProvider';
import { BrowseTypeProvider } from '@/browse/providers/BrowseTypeProvider';
import { CartProvider } from '@/cart/providers/CartProvider';
import { EmptyView } from '@/common/components/EmptyView';
import { BrowseType } from '@/const/enums';
import users from '@/const/users';
import { GuestProvider } from '@/guest/provider/GuestProvider';
import { LocationProvider } from '@/location/providers/LocationProvider';
import { DineInProvider } from '@/orders/providers/DineInProvider';
import { OrdersProvider } from '@/orders/providers/OrdersProvider';
import { PaymentProvider } from '@/payment/providers/PaymentProvider';
import { StorePageContainer as StorePageDesktopContainer } from '@/store/desktop/containers/StorePageContainer';
import { StorePageContainer as StorePageMobileContainer } from '@/store/mobile/containers/StorePageContainer';
import { StoreProvider } from '@/store/providers/StoreProvider';
import StoreHelper from '@/store/utils/StoreHelper';
import { Store } from '@/types';
import { isBrowser } from '@/utils/isBrowser';
import MetaHelper from '@/utils/MetaHelper';

const styles = createStyles({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'standalone',
  },
});

export interface Props extends WithStyles<typeof styles> {
  stores?: Store[];
  error?: boolean;
  siteUrl: string;
  handle: string;
  apiUrl: string;
  location: { [key: string]: string };
  showAppBar: boolean;
  lng: string;
  username: string;
  password: string;
  browseType: Opt<BrowseType>;
  orderId?: string;
  params: string;
  orderType: string;
  isDesktop: boolean;
  paymentTransactionId?: string;
  dineInId?: string;
  referer?: string;
  merchantId?: string;
}

interface State {
  openDialog: boolean;
}

export const getInitialProps = async ({ _, __, match, req }: any) => {
  try {
    // @ts-ignore
    if (process.browser) {
      history.go();
    }
    const { referer } = req.headers || {};
    const { handle, orderId, paymentTransactionId, dineInId } = match.params;
    const siteUrl = process.env.SITE_URL;
    const apiUrl = process.env.API_URL;
    const url = `${apiUrl}/v1/stores/handle2/${handle}`;
    const showAppBar = req.query.showAppBar !== 'false';
    const isDesktop = req.query.desktop === 'true';
    const browseType = req.query.type ? parseInt(req.query.type) : undefined;
    const userIndex: string = req.query.userIndex ? req.query.userIndex : '0';
    const { username, password } = users[Number(userIndex)];
    const params = req.query;
    const lng = req.query.lng;
    const { data } = await axios.get(url, {});
    let themeColor;
    if (data?.settings) {
      themeColor = data?.settings.themeColor;
    }
    let merchantId;
    if (data && data.length > 0) {
      merchantId = data[0].merchantId;
    }
    console.log('data', data);

    return {
      stores: data,
      merchantId,
      dineInId,
      siteUrl,
      handle,
      apiUrl,
      showAppBar,
      lng,
      username,
      password,
      browseType,
      orderId,
      params,
      themeColor,
      isDesktop,
      referer,
      paymentTransactionId,
    };
  } catch (error) {
    return { error: true };
  }
};

class StorePageRouter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openDialog: false,
    };
  }

  public static getInitialProps = async ({ _, __, match, req }: any) => {
    return getInitialProps({ _, __, match, req });
  };

  renderBody = () => {
    const isSSR = typeof window === 'undefined';
    if (isSSR) {
      return <></>;
    }
    const { showAppBar, isDesktop } = this.props;
    return isDesktop ? (
      <StorePageDesktopContainer showAppBar={showAppBar} />
    ) : (
      <StorePageMobileContainer />
    );
  };

  public render() {
    const { merchantId, stores, error, classes, siteUrl, handle, lng, browseType, referer } =
      this.props;

    if (isBrowser()) {
      console.log('props: ', this.props);
      let url;
      console.log('render handle', handle);
      if (handle === encodeURI('terra’s-table')) {
        url =
          'https://app1.foodbit.io/terrastable/f4738e77-2731-4ebb-82a9-ab7b6e118280?menuId=dd0dcf67-a218-4f9a-8efc-ca58dc5e70f7&type=DINE_IN';
      } else if (handle === 'la-plume-noire') {
        url = 'https://app2.foodbit.io/ar/lpn?storeId=0bfb3b62-94a4-41c0-9c72-67fb7153bd36';
      }
      else if (handle === 'oishiisushi') {
        url = "https://app2.foodbit.io/oishiisushi?storeId=03432273-2f66-4c0b-9784-98fb895f7d70&code="
      }
      else if (handle === 'lacabine-tt') {
        url = "https://app1.foodbit.io/lacabine"
      }
      if (url && url.length) {
        window.location.replace(url);
        return <></>;
      }
    }

    if (error || !stores || stores.length === 0) {
      return <EmptyView />;
    }
    const defaultStore = StoreHelper.getDefaultStore(stores);
    const { profilePic } = defaultStore;
    return (
      <div className={classes.root}>
        <Favicon url={profilePic} />
        <Helmet>
          <meta charSet="utf-8" />
          <title>{MetaHelper.getTitle(defaultStore)}</title>
          <meta
            name="description"
            content={MetaHelper.getDescription(defaultStore, lng, browseType)}
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, minimum-scale=1.0, maximum-scale=1.0, minimal-ui"
          />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-title" content={defaultStore.name.en} />
          <meta name="apple-mobile-web-app-status-bar-style" content="black" />
          <link rel="canonical" href={`${siteUrl}/${handle}`} />
          <link rel="apple-touch-icon" sizes="57x57" href={profilePic} />
          <link rel="apple-touch-icon" href={profilePic} sizes="128x128" />
          <link rel="apple-touch-icon" href={profilePic} sizes="144x144" />
          <link rel="apple-touch-icon" href={profilePic} sizes="152x152" />
        </Helmet>
        {/*<UserProvider username={username} password={password}>*/}
        <StoreProvider defaultStores={stores} defaultStore={defaultStore} referer={referer}>
          <BrowseTypeProvider browseType={browseType}>
            <GuestProvider shouldCreateGuest={true}>
              <AnalyticsProvider>
                <DineInProvider>
                  <OrdersProvider>
                    <LocationProvider>
                      <PaymentProvider>
                        <CartProvider store={defaultStore}>{this.renderBody()}</CartProvider>
                      </PaymentProvider>
                    </LocationProvider>
                  </OrdersProvider>
                </DineInProvider>
              </AnalyticsProvider>
            </GuestProvider>
          </BrowseTypeProvider>
        </StoreProvider>

        {/*</UserProvider>*/}
      </div>
    );
  }
}

export default withStyles(styles)(StorePageRouter);
