import React, { memo, useCallback, useEffect, useState } from 'react';

import URLHelper from '@/common/utils/URLHelper';
import { uuid } from '@/common/utils/uuid';
import { Language } from '@/const/enums';
import { useGuest } from '@/guest/hooks/useGuest';
import { PaymentPageCards } from '@/payment/mobile/components/PaymentPageCards';
import { usePayment } from '@/payment/mobile/hooks/usePayment';
import PaymentHelper from '@/payment/utils/PaymentHelper';
import { useStore } from '@/store/hooks/useStore';
import { useRTL } from '@/theme/hooks/useRTL';
import FBStorage from '@/utils/FBStorage';
import { isBrowser } from '@/utils/isBrowser';
import StringsHelper from '@/utils/StringsHelper';

interface Props {
  total: number;
  paymentReference: string;
}

export const getOrigin = () => {
  let origin = window.location.origin;
  if (origin.includes('localhost')) {
    origin = 'https://www.stage.app.foodbit.io';
  }
  return origin;
};

export const PaymentPageContainer = memo<Props>(({ total, paymentReference }) => {
  const { guest } = useGuest();
  const { selectedStore } = useStore();
  const rtl = useRTL();

  // const [signature, setSignature] = useState<Opt<string>>(undefined);
  const [signature, setSignature] = useState<Opt<string>>(undefined);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const redirectUrl = isBrowser()
    ? window.env.PAYFORT_REDIRECT_URL
    : process.env.PAYFORT_REDIRECT_URL!;
  const { payfort } = usePayment();

  const amountStr = Number((total * 100.0).toFixed(2));

  useEffect(() => {}, [guest]);

  console.log('URLHelper.getURLHandle()', encodeURI(URLHelper.getURLHandle()));

  const requestParams: any = {
    command: 'PURCHASE',
    access_code: payfort.accessCode,
    merchant_identifier: payfort.merchantId,
    merchant_reference: paymentReference,
    amount: amountStr,
    currency: 'SAR',
    customer_email: `${paymentReference}@foodbit.io`,
    language: rtl ? Language.Arabic : Language.English,
    order_description: `Foodbit order`,
    signature: signature,
    merchant_extra: URLHelper.getURLHandle().replace(/['%']/g, '--P--').replace('!', '--X--'),
    // merchant_extra1: `${StringsHelper.replaceAll(
    //   getOrigin(),
    //   ':',
    //   '--SM--',
    // )}/${URLHelper.getURLHandle()}/order/${FBStorage.getOrderId()}--Q--redirect--E--true`,
    merchant_extra2: guest?.id,
    merchant_extra3: selectedStore?.id,
    merchant_extra4: paymentReference && URLHelper.getQueryParamsForPayfortRequest(selectedStore),
  };

  useEffect(() => {
    const asyncObj = async () => {
      const sig = await PaymentHelper.getPayfortSignature(requestParams, payfort.passPhrase);
      setSignature(sig);
    };

    // @ts-ignore
    asyncObj();
  }, []);

  useEffect(() => {
    if (signature) {
      forceUpdate();
    }
  }, [signature]);

  if (!guest || !guest.id) {
    return <></>;
  }

  if (!signature) {
    return <></>;
  }

  return <PaymentPageCards redirectUrl={redirectUrl} requestParams={requestParams} />;
});
