import React from 'react';

import { Guest } from '@/types';

interface GuestContextPros {
  guest: Opt<Guest>;
  setGuest?: (guest: Guest) => void;
}
export const GuestContext = React.createContext<GuestContextPros>({
  guest: undefined,
});
