import {
  createStyles,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import React, { memo } from 'react';

import { CartMenuItemRowContainer } from '@/cart/mobile/containers/CartMenuItemRowContainer';
import CartHelper from '@/cart/utils/CartHelper';
import { Loader } from '@/common/components/Loader';
import { Space } from '@/common/components/Space';
import { t } from '@/i18n/translate';
import MenuItemHelper from '@/menu/utils/MenuItemHelper';
import { useStore } from '@/store/hooks/useStore';
import { MenuItem, Tax } from '@/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(1),
    },
    emptyCart: {
      color: '#7e7e7e',
    },
    total: {
      fontWeight: 'bold',
    },
    column: {
      flexDirection: 'column',
      display: 'flex',
    },
    minimumDelivery: {
      marginTop: theme.spacing(0.2),
    },
  }),
);
interface Props {
  menuItems: MenuItem[];
  onClearCartClick?: () => void;
  deliveryFee?: Opt<number>;
  serviceFee?: Opt<number>;
  paidTax?: number;
  loading?: boolean;
  editable?: boolean;
  minimumDeliveryOrder?: Opt<number>;
  showMinimumDeliveryOrder?: boolean;
  hideTitle?: boolean;
  hideTotal?: boolean;
  hideBottomSpace?: boolean;
  orderTotal?: number;
  rtl: boolean;
  quantity: number;
  totalPriceForItems?: number;
  tax?: Opt<Tax>;
  showDelivery: boolean;
}

const CartMenuItemsList = memo<Props>(
  ({
    menuItems,
    onClearCartClick,
    loading,
    editable,
    deliveryFee,
    serviceFee,
    paidTax,
    minimumDeliveryOrder,
    showMinimumDeliveryOrder,
    hideTitle,
    hideTotal,
    hideBottomSpace,
    orderTotal,
    rtl,
    quantity,
    totalPriceForItems,
    tax,
    showDelivery,
  }) => {
    const classes = useStyles();
    const { selectedStore, discount } = useStore();
    const { currency } = selectedStore?.settings || {};
    if (menuItems.length === 0) {
      return <></>;
    }
    const showDefaultPic = MenuItemHelper.shouldShowDefaultPic(menuItems);

    return (
      <List>
        {/* Header */}
        {!hideTitle && (
          <ListItem button>
            <ListItemText>
              <div className={classes.row}>
                <Typography component="span" variant="h6" color="textPrimary">
                  {t('cart:items')}
                </Typography>
              </div>
            </ListItemText>

            {onClearCartClick && editable && (
              <ListItemSecondaryAction onClick={onClearCartClick}>
                <IconButton edge="end" size="small">
                  <DeleteForeverIcon color="disabled" fontSize="small" />
                </IconButton>
                <Typography component="span" variant="caption" className={classes.emptyCart}>
                  {t('cart:clearCart')}
                </Typography>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        )}
        {loading ? (
          <Loader />
        ) : (
          menuItems
            .sort((a: MenuItem, b: MenuItem) => {
              if (a.price > b.price) {
                return 1;
              } else if (a.price < b.price) {
                return -1;
              }
              return 0;
            })
            .map((item, index) => (
              <CartMenuItemRowContainer
                showDefaultPic={showDefaultPic}
                key={item.id}
                menuItem={item}
                editable={editable}
              />
            ))
        )}
        {/* Total */}
        {!hideTotal && (
          <>
            <Space height={4} />
            <ListItem button divider={false}>
              <ListItemText>
                {totalPriceForItems !== undefined && totalPriceForItems >= 0 && (
                  <>
                    <div className={classes.row}>
                      <Typography component="span" variant="subtitle1" color="textPrimary">
                        {rtl
                          ? `${t('order:orderPrice')}`
                          : `${t('order:orderPrice')} (${quantity ? quantity : 1}x ${t(
                              'menu:items',
                            )})`}
                      </Typography>

                      <Typography component="span" variant="subtitle1" color="textPrimary">
                        {MenuItemHelper.getPriceWithCurrency(
                          Number(totalPriceForItems.toFixed(2)),
                          currency,
                        )}
                      </Typography>
                    </div>
                    <Divider />
                  </>
                )}
                {deliveryFee !== undefined && showDelivery && (
                  <>
                    <div className={classes.row}>
                      <Typography component="span" variant="subtitle1" color="textPrimary">
                        {t('cart:deliveryFee')}
                      </Typography>

                      <Typography component="span" variant="subtitle1" color="textPrimary">
                        {MenuItemHelper.getPriceWithCurrency(deliveryFee, currency)}
                      </Typography>
                    </div>
                    <Divider />
                  </>
                )}
                {serviceFee && serviceFee > 0 ? (
                  <>
                    <div className={classes.row}>
                      <Typography component="span" variant="subtitle1" color="textPrimary">
                        {t('cart:serviceFee')}
                      </Typography>

                      <Typography component="span" variant="subtitle1" color="textPrimary">
                        {MenuItemHelper.getPriceWithCurrency(serviceFee, currency)}
                      </Typography>
                    </div>
                    <Divider />
                  </>
                ) : (
                  <></>
                )}

                {paidTax && (
                  <>
                    <div className={classes.row}>
                      <div className={classes.column}>
                        <Typography component="span" variant="subtitle1" color="textPrimary">
                          {`${t('common:tax')} (${tax?.percentage ?? CartHelper.TAX}%)`}
                        </Typography>
                        <Typography component="span" variant="caption" color="textSecondary">
                          {tax && !tax.isTaxIncludedInPrices
                            ? t('common:taxNotIncludedInPrices')
                            : t('common:taxIncludedInPrices')}
                        </Typography>
                      </div>
                      <Typography component="span" variant="subtitle1" color="textPrimary">
                        {MenuItemHelper.getPriceWithCurrency(paidTax, currency)}
                      </Typography>
                    </div>
                    <Divider />
                  </>
                )}

                {orderTotal && (
                  <>
                    <div className={classes.row}>
                      <Typography
                        className={classes.total}
                        component="span"
                        variant="subtitle1"
                        color="textPrimary">
                        {t('cart:total')}
                      </Typography>

                      <Typography
                        className={classes.total}
                        component="span"
                        variant="subtitle1"
                        color="textPrimary">
                        {MenuItemHelper.getPriceWithCurrency(orderTotal, currency)}
                      </Typography>
                    </div>
                    <Divider />
                  </>
                )}

                <div className={classes.column}>
                  <Typography component="span" variant="caption" color="textSecondary">
                    {`${
                      tax && !tax.isTaxIncludedInPrices
                        ? t('cart:taxNotIncluded')
                        : t('cart:taxIncluded')
                    } (${tax?.percentage ?? CartHelper.TAX}%)`}
                  </Typography>
                  {showMinimumDeliveryOrder && minimumDeliveryOrder && minimumDeliveryOrder > 0 ? (
                    <Typography
                      component="span"
                      variant="caption"
                      color="textSecondary"
                      className={classes.minimumDelivery}>
                      {`${t('order:minimumDeliveryOrder')} ${MenuItemHelper.getPriceWithCurrency(
                        minimumDeliveryOrder,
                        currency,
                      )}`}
                    </Typography>
                  ) : (
                    <></>
                  )}
                </div>
              </ListItemText>
            </ListItem>
          </>
        )}
        {!hideBottomSpace && <Space height={14} />}
      </List>
    );
  },
);

export default CartMenuItemsList;
