import { useConfirm } from 'material-ui-confirm';
import React, { memo, useEffect, useState } from 'react';

import { Event } from '@/analytics/Event';
import SafaryAnalytics from '@/analytics/SafaryAnalytics';
import Api from '@/api/Api';
import { useBrowseType } from '@/browse/hooks/useBrowseType';
import CartHelper from '@/cart/utils/CartHelper';
import URLHelper from '@/common/utils/URLHelper';
import { BrowseType, CheckLocationType, DineInStatus, OrderStatus } from '@/const/enums';
import { useGuest } from '@/guest/hooks/useGuest';
import { t } from '@/i18n/translate';
import { useCheckoutDineIn } from '@/orders/hooks/useCheckoutDineIn';
import { useDineIn } from '@/orders/hooks/useDineIn';
import { useFetchOrdersInterval } from '@/orders/hooks/useFetchOrdersIntervals';
import { useOrders } from '@/orders/hooks/useOrders';
import { DineInOrdersPage } from '@/orders/mobile/components/DineInOrdersPage';
import { useStore } from '@/store/hooks/useStore';
import { useRTL } from '@/theme/hooks/useRTL';
import { MenuItem, Order } from '@/types';
import DateHelper from '@/utils/DateHelper';
import FBStorage from '@/utils/FBStorage';

export const DineInOrdersPageContainer = memo(() => {
  const { stopInterval, startInterval, loading } = useFetchOrdersInterval(90000);
  const { orders, loading: initialOrdersLoading } = useOrders();
  const { guest } = useGuest();
  const tax = orders && orders[0] ? orders[0].tax : undefined;
  const confirm = useConfirm();
  const { selectedStore } = useStore();
  const { settings } = selectedStore || {};
  const { postDineInSurvey } = settings || {};
  const { setDineIn, dineIn, setCheck, startCheck, checkLocation, setCheckLocation } = useDineIn();
  const { checkoutDineIn } = useCheckoutDineIn();
  const [loadingCheckout, setLoadingCheckout] = useState(false);
  const [internalDineInStatus, setInternalDineInStatus] = useState(DineInStatus.New);
  const { setType } = useBrowseType();
  const rtl = useRTL();
  const [showCheckout, setShowCheckout] = useState(
    internalDineInStatus === DineInStatus.RequestCheckout,
  );
  const [dineInOrders, setDineInOrders] = useState<Order[]>([]);
  const [showNewTabButton, setShowNewTabButton] = useState(
    internalDineInStatus === DineInStatus.Completed ||
      internalDineInStatus === DineInStatus.RequestCheckout,
  );

  // This service fee is taken from the store rather than the order
  const serviceFee = selectedStore?.settings?.serviceFee;

  const handleAddToOrderClick = () => {
    stopInterval();
    URLHelper.goHome();
  };

  const handleRequestWaiterClick = async () => {
    dineIn?.id && (await Api.requestWaiter(dineIn?.id));
    await confirm({
      title: t('common:alert'),
      description: t('order:requestWaiterSuccess'),
      confirmationText: t('common:ok'),
      confirmationButtonProps: {
        color: 'default',
      },
    });
  };

  // console.log('newOrders', orders);

  const updateCurrentDineInStatus = () => {
    const newOrders = dineInOrders.filter(
      order => order.status === OrderStatus.New || order.status === OrderStatus.PendingReview,
    );
    const accepted = dineInOrders.filter(order => order.status === OrderStatus.Accepted);
    const completed = dineInOrders.filter(order => order.status === OrderStatus.Completed);

    if (dineIn?.status === DineInStatus.RequestCheckout || dineIn?.checkingOut === true) {
      setInternalDineInStatus(DineInStatus.RequestCheckout);
    } else if (dineIn?.status === DineInStatus.Completed) {
      setInternalDineInStatus(DineInStatus.Completed);
    } else if (newOrders.length > 0) {
      setInternalDineInStatus(DineInStatus.InProgress);
    } else if (accepted.length > 0) {
      setInternalDineInStatus(DineInStatus.Serving);
    } else if (
      completed.length > 0 &&
      dineIn?.status !== DineInStatus.New &&
      dineIn?.status !== DineInStatus.InProgress
    ) {
      setInternalDineInStatus(DineInStatus.Completed);
    }

    // console.log('dineIn updating status: ', newOrders);
  };

  console.log('dineIn internalDineInStatus', internalDineInStatus);

  useEffect(() => {
    if (dineIn) {
      setShowCheckout(
        dineIn?.status === DineInStatus.RequestCheckout || dineIn?.checkingOut === true,
      );
      setShowNewTabButton(
        dineIn?.status === DineInStatus.Completed ||
          dineIn.status === DineInStatus.RequestCheckout ||
          dineIn?.checkingOut === true,
      );
    }
    if (dineIn && orders) {
      const _orders = orders.filter(
        order =>
          order.dineIn?.id === dineIn?.id &&
          !DateHelper.isDateNMinutesInThePast(
            DateHelper.convertZFormatStringDateToDate(order.createdDate!),
            60 * 8 /* 8 hours */,
          ),
      );
      // console.log('dineInOrders dineIn', dineIn, _orders);
      setDineInOrders(_orders);
    }
  }, [orders, dineIn]);

  useEffect(() => {
    if (dineInOrders && dineIn) {
      updateCurrentDineInStatus();
    }
  }, [dineInOrders, dineIn]);

  console.log('dineIn orders', dineInOrders);

  useEffect(() => {
    if (dineIn) {
      setType && setType(BrowseType.dinein);
      const newURL = URLHelper.updateURLParameter(window.location.href, 'type', '1');
      window.history.replaceState({}, '', newURL);
    }
  }, [dineIn]);

  const handleNewTabClick = async () => {
    try {
      stopInterval();
      await confirm({
        title: t('common:youSure'),
        description: t('order:startNewTabConfirmDescription'),
        confirmationText: t('common:yes'),
        cancellationText: t('common:cancel'),
        confirmationButtonProps: {
          color: 'default',
        },
      });
      setDineIn(undefined);

      const savedCheck = await startCheck({
        location: {
          merchantId: selectedStore?.merchantId,
          storeId: selectedStore?.id,
          code: dineIn?.tableNumber,
          type: CheckLocationType.Table,
        },
        check: {
          merchantId: selectedStore?.merchantId,
          storeId: selectedStore?.id,
          customer: guest,
          isOpenTab: selectedStore?.settings?.orders?.dineIn?.isOpenTab,
        },
      });
      setCheckLocation(dineIn?.tableNumber);
      setCheck(savedCheck);
      FBStorage.clearOrderIds();
      // FBStorage.reset();
      handleAddToOrderClick();
      SafaryAnalytics.logEvent(Event.StartNewTab);
    } catch (e) {
      startInterval();
    }
  };

  const handleCheckoutClick = async () => {
    try {
      stopInterval();
      await confirm({
        title: t('common:youSure'),
        description: t('order:checkoutConfirmDescription'),
        confirmationText: t('common:yes'),
        cancellationText: t('common:cancel'),
        confirmationButtonProps: {
          color: 'default',
        },
      });
      setLoadingCheckout(true);
      dineIn?.id && (await checkoutDineIn(dineIn.id!));
      setShowCheckout(true);
      setLoadingCheckout(false);
    } catch (e) {
      startInterval();
      setLoadingCheckout(false);
    }
  };

  const handleFeedbackClick = () =>
    window.open(rtl ? postDineInSurvey?.ar : postDineInSurvey?.en, '_blank');

  const menuItemsArr: MenuItem[][] = dineInOrders.map(order => order!.items!);
  const allMenuItems: MenuItem[] = ([] as MenuItem[]).concat(...menuItemsArr);

  return dineIn ? (
    <DineInOrdersPage
      showAddToOrderButton={
        dineIn.status !== DineInStatus.RequestCheckout &&
        dineIn?.checkingOut !== true &&
        dineIn.status !== DineInStatus.Completed
      }
      onRequestWaiterClick={handleRequestWaiterClick}
      serviceFee={CartHelper.calculateServiceFee(allMenuItems, undefined, serviceFee)}
      orderTotal={CartHelper.getTotalPrice(allMenuItems, undefined, serviceFee, tax)}
      paidTax={CartHelper.getPaidTax(allMenuItems, undefined, tax)}
      dineInStatus={internalDineInStatus}
      orders={dineInOrders}
      activeStep={showCheckout ? 1 : 0}
      onAddToOrderClick={handleAddToOrderClick}
      onCheckoutClick={handleCheckoutClick}
      showOrders={!loading && !loadingCheckout}
      showFeedbackButton={
        (showCheckout || internalDineInStatus === DineInStatus.Completed) && !!postDineInSurvey
      }
      onNewTabClick={handleNewTabClick}
      showNewTapButton={showNewTabButton}
      showLoading={loading || loadingCheckout || initialOrdersLoading}
      quantity={CartHelper.getQuantity(allMenuItems)}
      totalPriceForItems={CartHelper.getTotalPriceForItems(allMenuItems)}
      rtl={rtl}
      tax={tax}
      onFeedbackClick={handleFeedbackClick}
    />
  ) : (
    <></>
  );
});
