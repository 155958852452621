import {
  Button,
  createStyles,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { Phone, Sms, WhatsApp } from '@material-ui/icons';
import React from 'react';

import { t } from '@/i18n/translate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    button: {
      marginTop: theme.spacing(1),
    },
  }),
);

interface Props {
  onClose: () => void;
  open: boolean;
  onWhatsAppClick?: () => void;
  onPhoneClick?: () => void;
  onMessageClick?: () => void;
  isDriver?: boolean;
}

const ContactUserDialog = ({
  onClose,
  open,
  onWhatsAppClick,
  onMessageClick,
  onPhoneClick,
  isDriver = true,
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle dir={theme.direction}>
        {t(isDriver ? 'order:contactDriver' : 'order:contactCustomer')}
      </DialogTitle>
      <DialogContent className={classes.content}>
        {onWhatsAppClick && (
          <Button
            dir={theme.direction}
            style={{ backgroundColor: '#25D366', color: 'white' }}
            className={classes.button}
            variant="contained"
            color="primary"
            startIcon={<WhatsApp />}
            type="submit"
            onClick={onWhatsAppClick}>
            {t('common:messageWhatsApp')}
          </Button>
        )}
        {onMessageClick && (
          <Button
            dir={theme.direction}
            className={classes.button}
            variant="contained"
            startIcon={<Sms />}
            type="submit"
            onClick={onMessageClick}>
            {t('common:messageSMS')}
          </Button>
        )}
        {onPhoneClick && (
          <Button
            dir={theme.direction}
            className={classes.button}
            variant="contained"
            type="submit"
            startIcon={<Phone />}
            onClick={onPhoneClick}>
            {t('common:call')}
          </Button>
        )}
        <Button
          className={classes.button}
          color="primary"
          data-cy="cancel-create-user"
          onClick={onClose}>
          {t('common:cancel')}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default ContactUserDialog;
