import React, { memo, useEffect } from 'react';

import { Event } from '@/analytics/Event';
import { useAnalytics } from '@/analytics/hooks/useAnalytics';
import { StorePage } from '@/store/mobile/components/StorePage';

export const StorePageContainer = memo(() => {
  const { logEvent } = useAnalytics();
  useEffect(() => {
    logEvent(Event.LaunchSite);
  });
  return <StorePage />;
});
