import './theme/client.css';

import { After, ensureReady } from '@jaredpalmer/after';
import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { init } from '@/i18n/init';
import { ThemeProvider } from '@/theme/providers/ThemeProvider';
import { getLangDirection, setLangDirection } from '@/theme/utils/getLangDirection';
import { getThemeType } from '@/theme/utils/getThemeType';

require('../public/fonts/fonts.css');

import * as queryString from 'querystring';

import routes from './routes';

ensureReady(routes).then(data => {
  setLangDirection(data, queryString.parse(window.location.search));
  return hydrate(
    <BrowserRouter>
      <ThemeProvider direction={getLangDirection()} defaultThemeType={getThemeType()}>
        <After data={data} routes={routes} />
      </ThemeProvider>
    </BrowserRouter>,
    document.getElementById('root'),
  );
});

if (module.hot) {
  module.hot.accept();
}

init();
