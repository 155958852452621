import React, { memo } from 'react';

import { BottomActionsContainer } from '@/bottomActions/mobile/containers/BottomActionsContainer';
import { FooterContainer } from '@/common/containers/FooterContainer';
import HeaderContainer from '@/common/containers/HeaderContainer';
import { GreetViewContainer } from '@/greet/mobile/containers/GreetViewContainer';
import MenuViewContainer from '@/menu/mobile/containers/MenuViewContainer';

export const StorePage = memo(() => {
  return (
    <>
      <HeaderContainer fixed hideElevation disableSelection={false} />
      <MenuViewContainer />
      <BottomActionsContainer />
      <GreetViewContainer />
      <FooterContainer />
    </>
  );
});
