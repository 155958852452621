import React, { memo } from 'react';

import { useBrowseType } from '@/browse/hooks/useBrowseType';
import Header from '@/common/components/Header';
import { MenuLinks } from '@/common/components/MenuLinks';
import URLHelper from '@/common/utils/URLHelper';
import { BrowseType } from '@/const/enums';
import { useStore } from '@/store/hooks/useStore';

export const MenuLinksContainer = memo(() => {
  const { selectedStore } = useStore();

  const handleTechnicalSupportClick = () => URLHelper.goToTechnicalSupport();

  const handleStoreSupportClick = () => URLHelper.goToStoreSupport(getStoreSupport());

  const getStoreSupport = () =>
    selectedStore?.settings?.supportLink ?? `tel:${selectedStore?.phoneNumber}`;

  const handleOrdersClick = () => URLHelper.goToRegularOrdersPage();

  const handleHomeClick = () => URLHelper.goHome();
  const { type: browseType } = useBrowseType();

  return (
    <MenuLinks
      showMenu={browseType !== BrowseType.basic}
      languages={selectedStore?.menu?.languages}
      onHomeClick={handleHomeClick}
      onContactTechnicalSupport={handleTechnicalSupportClick}
      onStoreSupportClick={handleStoreSupportClick}
      onOrdersClick={handleOrdersClick}
    />
  );
});
