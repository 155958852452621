import { Dict } from 'mixpanel-browser';
import React, { memo, useCallback, useEffect } from 'react';

import { Event } from '@/analytics/Event';
import { useBranch } from '@/analytics/hooks/useBranch';
import SafaryAnalytics from '@/analytics/SafaryAnalytics';
import { useGuest } from '@/guest/hooks/useGuest';
import { useStore } from '@/store/hooks/useStore';
import StoreHelper from '@/store/utils/StoreHelper';

import { AnalyticsContext } from './AnalyticsContext';

export const AnalyticsProvider = memo(({ children }) => {
  const { params } = useBranch();
  const { guest } = useGuest();
  const { selectedStore, merchant } = useStore();

  useEffect(() => {
    if (selectedStore) {
      registerSuperProperties({
        $store_id: selectedStore.id,
        $store_name: selectedStore.name.en ?? selectedStore.name.ar ?? 'undefined',
        $store_branch: selectedStore.branchName?.en,
        $merchant_name: merchant,
      });
    }
    if (guest) {
      registerSuperProperties({
        $guest_id: guest.id,
      });
    }
  }, [guest, selectedStore]);

  useEffect(() => {
    if (params) {
      registerSuperProperties(params);
    }
  }, [params]);

  const logEvent = useCallback(
    (event: Event, params: Dict = {}) => SafaryAnalytics.logEvent(event, params),
    [],
  );

  const registerSuperProperties = useCallback(
    (params: Dict = {}) => SafaryAnalytics.registerSuperProperties(params),
    [],
  );

  return (
    <AnalyticsContext.Provider value={{ logEvent, registerSuperProperties }}>
      {children}
    </AnalyticsContext.Provider>
  );
});
