const PNF = require('google-libphonenumber').PhoneNumberFormat;
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

export default class PhoneHelper {
  /**
   * Parse the given phone number from a region (i.e. US, SA) and
   * return the E164 format (i.e. +15038060682)
   *
   * @param phone The phone number
   * @param region The country code (i.e. US, SA)
   */
  public static toE164Format(phone: string, region = 'US'): Opt<string> {
    try {
      phone = PhoneHelper.replace00WithPlusIfNeeded(phone);
      return phoneUtil.format(phoneUtil.parse(phone, region), PNF.E164);
    } catch (error) {}
    return undefined;
  }

  /**
   * Replace the '00' at beginning of the number with '+' if needed.
   *
   * @param number The number
   * @return The number with '+' at the beginning.
   */
  public static replace00WithPlusIfNeeded(number: string): string {
    if (number.startsWith('00')) {
      number = number.substr(2, number.length);
      number = '+'.concat(number);
    }

    return number;
  }

  public static getRegionFromE164Format(e164FormattedNumber: string): Opt<string> {
    try {
      // If the number starts with 00, replace with + to make it work with the libphone
      e164FormattedNumber = PhoneHelper.replace00WithPlusIfNeeded(e164FormattedNumber);
      const phone = phoneUtil.parse(e164FormattedNumber);
      return phoneUtil.getRegionCodeForNumber(phone);
    } catch (error) {
      return undefined;
    }
  }

  public static isValidPhone = (phoneE164Format: string) => {
    try {
      const phone = phoneUtil.parse(phoneE164Format, undefined);
      return phoneUtil.isValidNumber(phone);
    } catch (error) {
      return false;
    }
  };

  public static toNationalFormat(phone: string, region = 'US'): Opt<string> {
    try {
      return phoneUtil.format(phoneUtil.parse(phone, region), PNF.NATIONAL);
    } catch (error) {}
    return undefined;
  }
}
