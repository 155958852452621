import React, { useEffect, useState } from 'react';

import { BrowseType } from '@/const/enums';
import { useBrowseType } from '@/browse/hooks/useBrowseType';
import FBStorage from '@/utils/FBStorage';

export const useShowGreeting = () => {
  const [showGreeting, setShowGreeting] = useState(false);
  //useState(!FBStorage.hasShowGreeting());
  // const type = useBrowseType();

  useEffect(() => {
    // if (type === BrowseType.online) {
    //   setShowGreeting(false);
    //   return;
    // }
    FBStorage.setHasShownGreeting(true);
  }, []);

  return { showGreeting, setShowGreeting };
};
