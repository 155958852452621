import { Cookies } from 'react-cookie';

import URLHelper from '@/common/utils/URLHelper';
import { Language, URLThemeType } from '@/const/enums';
import { isBrowser } from '@/utils/isBrowser';

import {
  CarInfo,
  Cart,
  Check,
  DeliveryLocation,
  DineIn,
  Guest,
  Location,
  LocationField,
  Order,
  Token,
} from '../types';

const cookies = new Cookies();

export default class FBStorage {
  public static setLanguage(language: string) {
    cookies.set('primaryLanguage521', language);
  }

  public static getLanguage(): Opt<string> {
    const lang = cookies.get('primaryLanguage521');
    if (!lang) {
      return undefined;
    }
    return lang;
  }
  public static setTheme(theme: URLThemeType) {
    window.localStorage.setItem('theme', theme);
  }

  public static getTheme(): URLThemeType {
    if (!isBrowser()) {
      return URLThemeType.Light;
    }
    const theme = window.localStorage.getItem('theme');
    if (theme !== URLThemeType.Dark) {
      return URLThemeType.Light;
    }
    return theme as URLThemeType;
  }

  public static getCart(): Opt<Cart> {
    const handle = URLHelper.getURLHandle();
    const value = window.localStorage.getItem(handle);
    if (!value) {
      return undefined;
    }
    return JSON.parse(value);
  }

  public static clearOrderCart(id: string) {
    window.localStorage.removeItem(id);
  }

  public static setCart(value: Cart) {
    const handle = URLHelper.getURLHandle();
    window.localStorage.setItem(handle, JSON.stringify(value));
  }

  public static getOrderId(): Opt<string> {
    return window.localStorage.getItem('orderId') ?? undefined;
  }

  public static setOrderId(orderId: string) {
    window.localStorage.setItem('orderId', orderId);
  }

  public static getMerchantName(key: string): Opt<string> {
    return window.localStorage.getItem(`${key}-merchant`) ?? undefined;
  }

  public static setMerchantName(key: string, name: string) {
    window.localStorage.setItem(`${key}-merchant`, name);
  }

  public static getCarInfo(): Opt<CarInfo> {
    const str = window.localStorage.getItem('carInfo');
    return str ? JSON.parse(str) : undefined;
  }

  public static setCarInfo(carInfo: CarInfo) {
    window.localStorage.setItem('carInfo', JSON.stringify(carInfo));
  }

  public static getDeliveryLocationFields(): Opt<LocationField[]> {
    const str = window.localStorage.getItem('deliveryCustomFields');
    return str ? JSON.parse(str) : undefined;
  }

  public static setDeliveryLocationFields(deliveryCustomFields: LocationField[]) {
    window.localStorage.setItem('deliveryCustomFields', JSON.stringify(deliveryCustomFields));
  }

  public static getLocation(): Opt<DeliveryLocation> {
    const value = window.localStorage.getItem('location5');
    if (!value) {
      return undefined;
    }
    return JSON.parse(value);
  }

  public static setLocation(value: DeliveryLocation) {
    window.localStorage.setItem('location5', JSON.stringify(value));
  }

  public static getGuest(): Opt<Guest> {
    const value = window.localStorage.getItem('guest');
    if (!value || value === 'undefined') {
      return undefined;
    }
    return JSON.parse(value);
  }

  public static setGuest(value: Opt<Guest>) {
    value && value.id && window.localStorage.setItem('guest', JSON.stringify(value));
  }

  public static getDineIn(): Opt<DineIn> {
    const value = window.localStorage.getItem('dineIn1');
    if (!value || value === '') {
      return undefined;
    }
    return JSON.parse(value);
  }

  public static setDineIn(value: Opt<DineIn>) {
    window.localStorage.setItem('dineIn1', value ? JSON.stringify(value) : '');
  }

  public static getCheck(): Opt<Check> {
    const value = window.localStorage.getItem('check');
    if (!value || value === '') {
      return undefined;
    }
    return JSON.parse(value);
  }

  public static setCheck(value: Opt<Check>) {
    window.localStorage.setItem('check', value ? JSON.stringify(value) : '');
  }

  public static getCheckLocation(): Opt<string> {
    const value = window.localStorage.getItem('checkLocation');
    if (!value || value === '') {
      return undefined;
    }
    return value;
  }

  public static setCheckLocation(value: Opt<string>) {
    // debugger;
    window.localStorage.setItem('checkLocation', value ?? '');
  }

  public static hasShowGreeting(): boolean {
    const value = window.localStorage.getItem('greeting2');
    return value === 'true';
  }

  public static setHasShownGreeting(value: boolean) {
    return window.localStorage.setItem('greeting2', `${value}`);
  }

  public static didCheckOut(): boolean {
    const value = window.localStorage.getItem('checkout');
    return value === 'true';
  }

  public static setCheckout(value: boolean) {
    return window.localStorage.setItem('checkout', `${value}`);
  }

  public static getShowSignupBanner(): Opt<boolean> {
    const value = window.localStorage.getItem('showBanner');
    if (!value) {
      return true;
    }
    return value === 'true';
  }

  public static setShowSignupBanner(value: boolean) {
    return window.localStorage.setItem('showBanner', `${value}`);
  }

  public static getSavedCartName(): string {
    const id = window.localStorage.getItem('savedCart');
    if (!id) {
      return '';
    }
    return id;
  }

  public static setSavedCartName(cartId: string) {
    return window.localStorage.setItem('savedCart', cartId);
  }

  public static getStoreId(): string {
    const id = window.localStorage.getItem('storeId');
    if (!id) {
      return '';
    }
    return id;
  }

  public static setStoreId(storeId: string) {
    return window.localStorage.setItem('storeId', storeId);
  }

  public static getOrderIds(): string[] {
    const idsString = window.localStorage.getItem('orderIds11');
    if (!idsString) {
      return [];
    }
    return JSON.parse(idsString);
  }

  public static clearOrderIds() {
    return window.localStorage.removeItem('orderIds11');
  }

  public static addOrderId(orderId: string) {
    let orderIds = FBStorage.getOrderIds();
    if (orderIds == undefined) {
      orderIds = [];
    }
    orderIds.push(orderId);
    window.localStorage.setItem('orderIds11', JSON.stringify(orderIds));
  }

  public static getAccessToken(): Opt<Token> {
    const tokenString = cookies.get('token2');
    if (!tokenString) {
      return undefined;
    }
    return tokenString;
  }

  public static setLastVisitTime(date: Opt<Date>) {
    if (!date) {
      return;
    }
    const d = new Date();
    // 24 hours
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000);
    const dateString = date.toISOString();
    window.localStorage.setItem('last_visit', dateString);
  }

  public static getLastVisitTime(): Opt<Date> {
    const dateString = window.localStorage.getItem('last_visit');
    if (dateString == null) {
      return undefined;
    }
    return new Date(dateString);
  }

  /**
   * Set the access token and save it to storage.
   *
   * @param token The access token to be saved
   * @returns {*}
   */
  public static setAccessToken(token: object | null) {
    return window.localStorage.setItem('token2', JSON.stringify(token));
  }

  /**
   * Set the access token expiration date.
   *
   * @param token The expiration date for the token.
   * @returns {*}
   */
  public static setTokenExpirationDate(date: Opt<Date>) {
    if (!date) {
      return;
    }
    const dateString = date.toISOString();
    window.localStorage.setItem('token_expiration', dateString);
  }

  /**
   *  Get the access token expiration date.
   *
   * @returns {*}
   */
  public static getTokenExpirationDate(): Opt<Date> {
    const dateString = window.localStorage.getItem('token_expiration');
    if (dateString == null) {
      return undefined;
    }
    return new Date(dateString);
  }

  public static reset() {
    const rootOptions = { path: '/' };
    window.localStorage.clear();
    cookies.remove('token', rootOptions);
  }
}
