import {
  Button,
  Card,
  createStyles,
  Divider,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Done, Update } from '@material-ui/icons';
import React, { memo } from 'react';

import CartMenuItemsList from '@/cart/mobile/components/CartMenuItemsList';
import CartHelper from '@/cart/utils/CartHelper';
import { FooterContainer } from '@/common/containers/FooterContainer';
import HeaderContainer from '@/common/containers/HeaderContainer';
import { uuid } from '@/common/utils/uuid';
import { OrderType } from '@/const/enums';
import { t } from '@/i18n/translate';
import LocationHelper from '@/location/utils/LocationHelper';
import PaymentHelper from '@/payment/utils/PaymentHelper';
import { useStore } from '@/store/hooks/useStore';
import StoreHelper from '@/store/utils/StoreHelper';
import { getLang } from '@/theme/utils/getLangDirection';
import { Order, Tax } from '@/types';
import DateHelper from '@/utils/DateHelper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      backgroundColor: theme.palette.type === 'light' ? 'white' : theme.palette.primary.dark,
    },
    orders: {
      marginBottom: theme.spacing(3),
      height: '50vh',
      overflowY: 'scroll',
    },
    button: {
      fontWeight: 'bold',
      borderRadius: 0,
    },
    bottomContainer: {
      width: '100%',
      bottom: 0,
      justifyContent: 'stretch',
      flex: 1,
      display: 'flex',
      zIndex: 1,
    },
    stretch: {
      width: '50%',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    paymentIcon: {
      marginRight: theme.spacing(1),
    },
    paymentContainer: {
      alignItems: 'center',
      display: 'flex',
    },
    titleContainer: {
      marginTop: theme.spacing(9),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      flex: 1,
      display: 'flex',
      justifyContent: 'space-between',
    },
    listItem: {
      alignItems: 'flex-start',
    },
    clickable: {
      textDecoration: 'underline',
      color: theme.palette.secondary.main,
      fontSize: 12,
      cursor: 'pointer',
    },
    footerSpace: {
      height: 55,
    },
    column: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    checkoutButtonContainer: {
      backgroundColor: theme.palette.type === 'light' ? 'white' : theme.palette.primary.dark,
      position: 'fixed',
      width: '100%',
      zIndex: 1000,
      bottom: 0,
    },
  }),
);

interface Props {
  order: Order;
  onCallStoreClick: () => void;
  onStoreMapClick: () => void;
  onDeliveryMapClick: () => void;
  onContactCustomerClick: () => void;
  loading: boolean;
  rtl: boolean;
  trackingModalRef?: React.Ref<any>;
  tax: Opt<Tax>;
  arrivedAtStoreButtonLabel: string;
  disableReadyForPickupButton?: boolean;
  onActionClick: () => void;
  showDeliveryInProgressAction: boolean;
  showDeliveredAction: boolean;
}

export const OnlineCourierOrderPage = memo<Props>(
  ({
    order,
    onCallStoreClick,
    onStoreMapClick,
    onDeliveryMapClick,
    onContactCustomerClick,
    showDeliveryInProgressAction,
    showDeliveredAction,
    loading,
    rtl,
    tax,
    onActionClick,
  }) => {
    const classes = useStyles();
    const theme = useTheme();
    const iconColor = theme.palette.type === 'dark' ? '#fafafa' : '#161617';
    const { discount } = useStore();
    const lang = getLang();

    const renderDate = () => (
      <ListItem>
        <ListItemText className={classes.listItem}>
          <div>
            <div className={classes.row}>
              <Typography component="span" variant="h6" color="textPrimary">
                {t('order:orderDate')}
              </Typography>
            </div>
            {order.createdDate && (
              <Typography component="span" variant="subtitle1" color="textSecondary">
                {DateHelper.formatDateToReadableDateAndTime(order.createdDate, lang)}
              </Typography>
            )}
          </div>
        </ListItemText>
      </ListItem>
    );

    const renderPaymentMethod = () => {
      const { label, PaymentIcons } = PaymentHelper.getOrderViewPaymentMethodLabelAndIcon(
        order?.paymentTransaction,
      );
      return (
        <ListItem>
          <ListItemText className={classes.listItem}>
            <div>
              <div className={classes.row}>
                <Typography component="span" variant="h6" color="textPrimary">
                  {t('payment:paymentMethod')}
                </Typography>
              </div>
              <div className={classes.paymentContainer}>
                {PaymentIcons &&
                  PaymentIcons.map(PaymentIcon => (
                    <PaymentIcon
                      key={uuid()}
                      className={classes.paymentIcon}
                      fillColor={iconColor}
                    />
                  ))}
                <Typography component="span" variant="subtitle1" color="textSecondary">
                  {label}
                </Typography>
              </div>
            </div>
          </ListItemText>
        </ListItem>
      );
    };
    const renderOnlineStore = () => {
      return (
        <ListItem>
          <ListItemText className={classes.listItem}>
            <div>
              <div className={classes.row}>
                <Typography component="span" variant="h6" color="textPrimary">
                  {t('order:storeLocation')}
                </Typography>
                <div>
                  <Button variant="text" className={classes.clickable} onClick={onCallStoreClick}>
                    {t('order:contactStore')}
                  </Button>
                </div>
              </div>
              <div>
                <div>
                  <Typography component="span" variant="subtitle1" color="textSecondary">
                    {StoreHelper.getStoreTitle(order.store!!)}
                  </Typography>
                </div>
              </div>
            </div>
          </ListItemText>
        </ListItem>
      );
    };

    const renderStoreLocation = () => {
      return (
        <ListItem>
          <ListItemText className={classes.listItem}>
            <div>
              <div className={classes.row}>
                <Typography component="span" variant="h6" color="textPrimary">
                  {t('order:storeLocation')}
                </Typography>
                <div>
                  <Button variant="text" className={classes.clickable} onClick={onStoreMapClick}>
                    {t('order:map')}
                  </Button>
                  <Button variant="text" className={classes.clickable} onClick={onCallStoreClick}>
                    {t('order:contactStore')}
                  </Button>
                </div>
              </div>
              <div>
                <div>
                  <Typography component="span" variant="subtitle1" color="textSecondary">
                    {StoreHelper.getStoreTitle(order.store!!)}
                  </Typography>
                </div>
                <div>
                  <Typography component="span" variant="subtitle2" color="textSecondary">
                    {StoreHelper.getStoreAddress(order.store!!)}
                  </Typography>
                </div>
              </div>
            </div>
          </ListItemText>
        </ListItem>
      );
    };

    const renderDeliveryLocation = () => (
      <ListItem>
        <ListItemText className={classes.listItem}>
          <div>
            <div className={classes.row}>
              <Typography component="span" variant="h6" color="textPrimary">
                {t('order:deliveryLocationTitle')}
              </Typography>
              <div>
                <Button variant="text" className={classes.clickable} onClick={onDeliveryMapClick}>
                  {t('order:map')}
                </Button>
                <Button
                  variant="text"
                  className={classes.clickable}
                  onClick={onContactCustomerClick}>
                  {t('order:contactCustomer')}
                </Button>
              </div>
            </div>
            <div>
              <div>
                <Typography component="span" variant="subtitle1" color="textSecondary">
                  {order.guest?.name}
                </Typography>
              </div>
              <div>
                <Typography component="span" variant="subtitle2" color="textSecondary">
                  {LocationHelper.getReadableLocation(order.delivery?.location)}
                </Typography>
              </div>
            </div>
          </div>
        </ListItemText>
      </ListItem>
    );

    return (
      <div className={classes.root}>
        <HeaderContainer fixed hideSelection />
        <div className={classes.titleContainer}>
          <div className={classes.column}>
            <Typography component="span" variant="h6" color="textPrimary">
              {t('order:order')}
            </Typography>
            <Typography component="span" variant="subtitle2" color="textSecondary">
              {order.status?.split('_').join(' ')}
            </Typography>
          </div>

          <Typography component="span" variant="h6" color="textPrimary">
            {` #${order.orderNumber}`}
          </Typography>
        </div>
        <Divider />
        {renderDate()}
        {order.type === OrderType.Delivery && renderDeliveryLocation()}
        {renderPaymentMethod()}
        {order.store &&
          (StoreHelper.isOnlineStore(order.store) ? renderOnlineStore() : renderStoreLocation())}
        {order && (
          <CartMenuItemsList
            quantity={CartHelper.getQuantity(order.items!!)}
            orderTotal={order.total}
            rtl={rtl}
            totalPriceForItems={order.subtotal}
            menuItems={order.items!!}
            paidTax={CartHelper.getPaidTax(
              order.items!!,
              order.delivery?.deliveryFee,
              tax,
              discount,
            )}
            deliveryFee={CartHelper.getDeliveryFee(order.delivery?.deliveryFee)}
            serviceFee={CartHelper.calculateServiceFee(
              order.items!!,
              order.delivery?.deliveryFee,
              order.serviceFee,
            )}
            editable={false}
            showDelivery={order.type === OrderType.Delivery}
            tax={tax}
          />
        )}
        {(showDeliveryInProgressAction || showDeliveredAction) && (
          <Card square elevation={2} className={classes.checkoutButtonContainer}>
            <Button
              disableElevation
              disabled={loading}
              fullWidth
              style={{
                background: showDeliveryInProgressAction ? '#1dc698' : '#40c4ff',
              }}
              variant="contained"
              size="large"
              startIcon={showDeliveryInProgressAction ? <Update /> : <Done />}
              onClick={onActionClick}>
              {showDeliveryInProgressAction
                ? t('order:markDeliveryInProgress')
                : t('order:markDelivered')}
            </Button>
          </Card>
        )}
        <div className={classes.footerSpace} />
        <FooterContainer />
      </div>
    );
  },
);
