import React, { memo, useRef, useState } from 'react';

import { OnlineOrderGreetView } from '@/greet/mobile/components/OnlineOrderGreeView';
import { RequestLocationViewContainerMethods } from '@/location/mobile/containers/RequestLocationViewContainer';
import { useStore } from '@/store/hooks/useStore';
import { useRTL } from '@/theme/hooks/useRTL';

export const OnlineOrderGreetViewContainer = memo(() => {
  const [showGreeting, setShowGreeting] = useState(false);
  const { selectedStore } = useStore();
  const setShowRequestLocationRef = useRef<RequestLocationViewContainerMethods>();
  const rtl = useRTL();
  if (!selectedStore) {
    return <></>;
  }
  const { profilePic, name } = selectedStore!!;

  const handleBrowsingClick = () => setShowGreeting(false);
  const handlePickupClick = () => setShowGreeting(false);
  const handleDeliveryClick = () => {
    setShowGreeting(false);
    setShowRequestLocationRef?.current?.setOpen(true);
  };

  const handleClosePress = () => setShowGreeting(false);

  return (
    <OnlineOrderGreetView
      rtl={rtl}
      setShowRequestLocationRef={setShowRequestLocationRef}
      headerProfilePic={profilePic}
      headerTitle={rtl ? name.ar ?? '' : name.en}
      open={showGreeting}
      onClosePress={handleClosePress}
      onBrowsingClick={handleBrowsingClick}
      onDeliveryClick={handleDeliveryClick}
      onPickupClick={handlePickupClick}
    />
  );
});
