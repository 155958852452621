import { useConfirm } from 'material-ui-confirm';
import React, { memo, useEffect, useRef, useState } from 'react';

import Api from '@/api/Api';
import { useUpdateCart } from '@/cart/hooks/useUpdateCart';
import URLHelper from '@/common/utils/URLHelper';
import {
  DeliveryType,
  DriveThruStatus,
  OrderStatus,
  OrderType,
  PickupType,
  UpdateCartType,
} from '@/const/enums';
import { t } from '@/i18n/translate';
import MenuItemHelper from '@/menu/utils/MenuItemHelper';
import { useCancelOrder } from '@/orders/hooks/useCancelOrder';
import { useFetchOrderInterval } from '@/orders/hooks/useFetchOrderIntervals';
import { useOrder } from '@/orders/hooks/useOrder';
import { useTrackOrder } from '@/orders/hooks/useTrackOrder';
import ContactCourierDialog from '@/orders/mobile/components/ContactUserDialog';
import { OnlineOrderPage } from '@/orders/mobile/components/OnlineOrderPage';
import { OrderTrackingPageContainerMethods } from '@/orders/mobile/containers/IFrameOrderTrackingPageContainer';
import OrdersHelper from '@/orders/utils/OrdersHelper';
import PaymentHelper from '@/payment/utils/PaymentHelper';
import { useRTL } from '@/theme/hooks/useRTL';
import { getLang } from '@/theme/utils/getLangDirection';
import DateHelper from '@/utils/DateHelper';

export const OnlineOrderPageContainer = memo(() => {
  const urlParams = new URLSearchParams(window.location.search);
  const { updateCart } = useUpdateCart();
  const redirect = urlParams.get('redirect');
  const { cancelOrder, success, loading, error } = useCancelOrder();
  const [showContactCourier, setShowContactCourier] = useState(false);
  const [loadingReadyForPickup, setLoadingReadyForPickup] = useState(false);
  const rtl = useRTL();
  const lang = getLang();
  const { order, refetchOrder } = useOrder();
  const { store } = order || {};
  const tax = order?.tax;
  const trackOrder = useTrackOrder();
  console.log('online order page', rtl);
  const trackingOrderRef = useRef<OrderTrackingPageContainerMethods>();

  const { stopInterval, startInterval } = useFetchOrderInterval(
    DateHelper.convertMinutesToMilliseconds(1.5),
  );
  const confirm = useConfirm();

  useEffect(() => {
    if (redirect === 'true' && order) {
      // Log events
      trackOrder(order);
      updateCart([], UpdateCartType.Clear);
      // const newURL = URLHelper.removeURLParameter(
      //   `${window.location.pathname}${window.location.search}`,
      //   'redirect',
      // );
      // window.location.assign(newURL);
    }
  }, [order]);

  useEffect(() => {
    if (success && !loading) {
      window.location.reload();
    }
  }, [success, loading]);

  useEffect(() => {
    // alert(t('order:cannotCancelOrder'));
    if (error) {
      const async = async () => {
        try {
          await confirm({
            title: t('common:alert'),
            description: t('order:cannotCancelOrder'),
            cancellationText: '',
            confirmationText: t('common:cancel'),
            confirmationButtonProps: {
              color: 'default',
            },
          });
        } catch (e) {
          console.log(e);
        }
      };
      async();
    }
  }, [error]);

  const handleCancelClick = async () => {
    try {
      stopInterval();
      await confirm({
        title: t('common:youSure'),
        description: t('order:cancelOrderConfirm'),
        confirmationText: t('common:yes'),
        cancellationText: t('common:cancel'),
        confirmationButtonProps: {
          color: 'default',
        },
      });
      if (order) {
        cancelOrder(order.id!);
      }
    } catch (e) {
      startInterval();
    }
  };

  if (!order) {
    return <></>;
  }

  const getCurrentDeliveryStep = () => {
    if (!order.delivery?.secureCouriersBeforeAcceptingOrders) {
      return getCurrentStep();
    }
    switch (order.status) {
      case OrderStatus.SecuringCourier:
        return 0;
      case OrderStatus.New:
        return 1;
      case OrderStatus.Accepted:
        return 2;
      case OrderStatus.Rejected:
        return 1;
      case OrderStatus.DeliveryInProgress:
        return 3;
      case OrderStatus.Completed:
      case OrderStatus.Cancelled:
        return deliverySteps().titles.length - 1;
    }
    return 0;
  };

  const getCurrentStep = () => {
    switch (order.status) {
      case OrderStatus.New:
        // if (order.courier && order.delivery) return 2;
        return 0;
      case OrderStatus.Accepted:
      case OrderStatus.DriveThruReadyForPickup:
      case OrderStatus.InStoreReadyForPickup:
        return 1;
      case OrderStatus.Rejected:
        return 1;
      case OrderStatus.DeliveryInProgress:
        return 2;
      case OrderStatus.Completed:
      case OrderStatus.Cancelled:
        return steps().titles.length - 1;
    }
    return 0;
  };

  const deliverySteps = () => {
    const { delivery } = order || {};
    if (!delivery?.secureCouriersBeforeAcceptingOrders) {
      return steps();
    }
    // Secure Courier
    const titles = [t('order:orderReceivedTitle')];
    const contents = [t('order:orderReceivedBody')];

    if (order.status === OrderStatus.Cancelled) {
      titles.push(t('order:orderCancelledTitle'));
      let str = t('order:orderCancelledBody');
      if (PaymentHelper.isOnlinePayment(order.paymentTransaction?.method)) {
        str += `\n${t('order:refundedPayment', {
          payment: MenuItemHelper.getPriceWithCurrency(
            order.paymentTransaction?.amount ?? 0,
            store?.settings?.currency,
          ),
        })}`;
      }
      contents.push(str);
    } else if (order.status === OrderStatus.Rejected) {
      titles.push(t('order:orderRejectedTitle'));
      let str = t('order:orderRejectedBody');
      if (PaymentHelper.isOnlinePayment(order.paymentTransaction?.method)) {
        str += `\n${t('order:refundedPayment', {
          payment: MenuItemHelper.getPriceWithCurrency(
            order.paymentTransaction?.amount ?? 0,
            store?.settings?.currency,
          ),
        })}`;
      }
      contents.push(str);
    } else {
      titles.push(t('order:deliveryOrderSentToStoreTitle'));
      contents.push(t('order:deliveryOrderSentToStoreDescription', { store: store?.name[lang] }));

      titles.push(t('order:orderInProgressTitle'));
      contents.push(t('order:orderInProgressBody'));

      titles.push(t('order:orderInDeliveryTitle'));
      contents.push(t('order:orderInDeliveryBody'));

      titles.push(t('order:orderCompletedTitle'));
      contents.push(t('order:orderCompletedBody'));
    }
    return {
      titles,
      contents,
    };
  };

  const steps = () => {
    const titles = [t('order:orderReceivedTitle')];
    const contents = [t('order:orderReceivedBody')];

    if (order.status === OrderStatus.Cancelled) {
      titles.push(t('order:orderCancelledTitle'));
      contents.push(t('order:orderCancelledBody'));
    } else if (order.status === OrderStatus.Rejected) {
      titles.push(t('order:orderRejectedTitle'));
      contents.push(t('order:orderRejectedBody'));
    } else {
      // If it's not cancelled or rejected, add the necessary steps
      titles.push(t('order:orderInProgressTitle'));
      const eta = OrdersHelper.getETAStringAndIsETAPassed(order).etaStr;
      let str = `${t('order:orderInProgressBody')}${
        order.type === OrderType.Pickup && order.storeETA !== undefined ? `\nETA: ${eta}` : ''
      }`;
      if (order.pickup?.type === PickupType.DriveThru || order.pickup?.type === PickupType.Curbside) {
        str += `\n${t('order:orderReadyForPickupAtArrival')}`;
      }
      contents.push(str);

      if (
        order.delivery &&
        order.delivery.location !== null &&
        order.delivery.location !== undefined
      ) {
        titles.push(t('order:orderInDeliveryTitle'));
        contents.push(t('order:orderInDeliveryBody'));
      }

      titles.push(t('order:orderCompletedTitle'));
      contents.push(t('order:orderCompletedBody'));
    }
    return {
      titles,
      contents,
    };
  };

  const handlerCallback = () => window.open(`tel:${order.store!.phoneNumber}`, '_self');
  const handleStoreMapClick = () => {
    const { latitude, longitude } = order.store!.location.coordinates;
    window.open(`https://maps.google.com/maps?daddr=${latitude},${longitude}&amp;ll=`, '_blank');
  };

  const handleDeliveryMapClick = () => URLHelper.goToMap(order.delivery!.location!.coordinates);

  const handleTrackingClick = () => trackingOrderRef.current?.setOpen(true);

  const handleContactCourier = () => setShowContactCourier(true);

  const isPaidOnline = () => PaymentHelper.isOnlinePayment(order.paymentTransaction?.method);

  const handleReadyForPickupClick = async () => {
    setLoadingReadyForPickup(true);
    try {
      await Api.readyForPickup(order.id!);
      await refetchOrder();
    } catch (e) {
      console.error(e);
    }
    setLoadingReadyForPickup(false);
  };

  const showReadyForPickupButton = () => {
    return (
        (order.pickup?.type === PickupType.DriveThru || order.pickup?.type === PickupType.Curbside) &&
      getCurrentStep() === 1 &&
      (order.status === OrderStatus.Accepted ||
        order.status === OrderStatus.DriveThruReadyForPickup)
    );
  };

  const getReadyForPickupButtonLabel = () => {
    if (
      order.pickup?.driveThru?.status === DriveThruStatus.ReadyForPickup ||
      order.status === OrderStatus.DriveThruReadyForPickup
    ) {
      return t('order:notifiedReadyForPickup');
    }
    return t('order:readyForPickup');
  };

  const showCancel = () => {
    if (order.type === OrderType.Delivery && order.status === OrderStatus.SecuringCourier) {
      return true;
    } else if (
      order.status === OrderStatus.New &&
      !order.delivery?.secureCouriersBeforeAcceptingOrders
    ) {
      return true;
    }
    return false;
  };

  // const showTrackCourier =
  //   order.status !== OrderStatus.SecuringCourier &&
  //   order.status !== OrderStatus.Completed &&
  //   order.status !== OrderStatus.Cancelled &&
  //   order.status !== OrderStatus.Rejected &&
  //   !!(order.delivery && order.delivery.trackingUrl);

  const showCallCourierButton =
    order.status !== OrderStatus.SecuringCourier &&
    order.status !== OrderStatus.Completed &&
    order.status !== OrderStatus.Cancelled &&
    order.status !== OrderStatus.Rejected &&
    !!(order.delivery && order.delivery.courier?.phoneNumber);

  const stepsToShow = order.type === OrderType.Delivery ? deliverySteps() : steps();

  const handleFeedbackClick = () => window.open('https://forms.gle/ma8C8vjEG3UydUQM7', '_blank');

  return (
    <>
      <OnlineOrderPage
        onFeedbackClick={handleFeedbackClick}
        showFeedbackButton={order.merchantId === 'f5067470-0ca0-4a6b-8a27-2ed184abc47f'}
        disableReadyForPickupButton={
          order.pickup?.driveThru?.status === DriveThruStatus.ReadyForPickup
        }
        arrivedAtStoreButtonLabel={getReadyForPickupButtonLabel()}
        onReadyForPickupClick={handleReadyForPickupClick}
        loadingReadyForPickup={loadingReadyForPickup}
        onTrackingLinkClick={handleTrackingClick}
        onContactCourier={handleContactCourier}
        trackingModalRef={trackingOrderRef}
        loading={loading}
        showReadyForPickup={showReadyForPickupButton()}
        showCancel={showCancel()}
        order={order}
        showCallCourier={showCallCourierButton}
        rtl={rtl}
        activeStep={order.type === OrderType.Delivery ? getCurrentDeliveryStep() : getCurrentStep()}
        onCancelPress={handleCancelClick}
        steps={stepsToShow.titles}
        stepsContent={stepsToShow.contents}
        onStoreMapClick={handleStoreMapClick}
        onCallStoreClick={handlerCallback}
        onDeliveryMapClick={handleDeliveryMapClick}
        tax={tax}
        showRateCourier={
          !!(
            order.delivery &&
            order.delivery.courier &&
            order.delivery.type === DeliveryType.FleetManagement
          ) && order.status === OrderStatus.Completed
        }
        showTrackingLink={
          !!order.delivery?.trackingUrl &&
          order.status !== OrderStatus.SecuringCourier &&
          order.status !== OrderStatus.Completed &&
          order.status !== OrderStatus.Cancelled &&
          order.status !== OrderStatus.Rejected
        }
      />
      <ContactCourierDialog
        onClose={() => setShowContactCourier(false)}
        open={showContactCourier}
        onPhoneClick={() =>
          order.delivery?.courier?.phoneNumber &&
          URLHelper.goToPhone(order.delivery.courier?.phoneNumber)
        }
        onWhatsAppClick={() =>
          order.delivery?.courier?.phoneNumber &&
          URLHelper.goToWhatsApp(order.delivery.courier?.phoneNumber)
        }
        onMessageClick={() =>
          order.delivery?.courier?.phoneNumber &&
          URLHelper.goToMessage(order.delivery.courier?.phoneNumber)
        }
      />
    </>
  );
});
