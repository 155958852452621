import React, { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Geocode from 'react-geocode';

import { useLocation } from '@/location/hooks/useLocation';
import { RequestLocationPermission } from '@/location/mobile/components/RequestLocationPermission';
import { RequestLocationView } from '@/location/mobile/components/RequestLocationView';
import { LocationInputMapContainerMethods } from '@/location/mobile/containers/LocationInputMapContainer';
import { useStore } from '@/store/hooks/useStore';
import { useRTL } from '@/theme/hooks/useRTL';

Geocode.setApiKey('AIzaSyBgIg4fYEPiHbeC22F-5L7X16NOBDZmxbo');

interface Props {
  ref?: React.Ref<any>;
}
export interface RequestLocationViewContainerMethods {
  setOpen: (value: boolean) => void;
}

export const RequestLocationViewContainer = memo(
  // eslint-disable-next-line
  forwardRef<RequestLocationViewContainerMethods, Props>(({}, ref) => {
    const [open, setOpen] = useState(false);
    const [requestLocation, setRequestLocation] = useState(false);
    const [loading, setLoading] = useState(false);
    const rtl = useRTL();
    const { location, isSupported, setIsLocationPermitted } = useLocation();
    const locationInputRef = useRef<LocationInputMapContainerMethods>();
    useImperativeHandle(ref, () => ({ setOpen }), []);
    const { selectedStore } = useStore();

    useEffect(() => {
      setLoading(false);

      if (location && setIsLocationPermitted) {
        setIsLocationPermitted(true);
      }
    }, [location]);

    if (!selectedStore) {
      return <></>;
    }
    const { profilePic, name } = selectedStore!!;

    const handleChangeLocation = () => {
      locationInputRef.current?.setOpen(true);
    };

    const handleRequestLocation = () => {
      setLoading(true);
      setRequestLocation(true);
    };

    const handleCloseClick = () => setOpen(false);

    return (
      <>
        {requestLocation && <RequestLocationPermission />}
        <RequestLocationView
          showAddress={location !== undefined && !loading}
          showNotSupportedLocation={!isSupported && location !== undefined && !loading}
          showStartButton={location !== undefined && isSupported && !loading}
          rtl={rtl}
          loading={loading}
          headerProfilePic={profilePic}
          headerTitle={rtl ? name.ar ?? '' : name.en}
          open={open}
          onClosePress={handleCloseClick}
          onRequestLocationClick={handleRequestLocation}
          onChangeLocationClick={handleChangeLocation}
          disableMap={!location}
          location={location}
          locationInputRef={locationInputRef}
          onMapClick={handleChangeLocation}
          onStartClick={handleCloseClick}
        />
      </>
    );
  }),
);

export default RequestLocationViewContainer;
