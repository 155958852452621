import { useTheme } from '@material-ui/core';
import i18next from 'i18next';
import { useCallback, useContext } from 'react';

import { useGuest } from '@/guest/hooks/useGuest';
import { useUpdateGuest } from '@/guest/hooks/useUpdateGuest';
import { SetThemeContext } from '@/theme/providers/ThemeProvider';
import { createTheme } from '@/theme/theme';
import { getLangDirection } from '@/theme/utils/getLangDirection';
import FBStorage from '@/utils/FBStorage';

export const useSetLanguage = () => {
  const { setTheme } = useContext(SetThemeContext);
  const { guest } = useGuest();
  const { updateGuest } = useUpdateGuest();
  const theme = useTheme();
  return useCallback(
    (language: string, save = true) => {
      // const lang = getLang();
      const direction = getLangDirection(language);
      setTheme(createTheme({ direction }, theme.palette.type));
      i18next.changeLanguage(language);
      save && FBStorage.setLanguage(language);
      // cookies.set('primaryLanguage', language);
      // FBStorage.setLanguage(direction === 'rtl' ? Language.Arabic : Language.English);
      if (guest && language && language !== 'undefined') {
        guest.language = language;
        updateGuest(guest);
      }
    },
    [setTheme, theme, guest],
  );
};
