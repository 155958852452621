import { FormControl, MenuItem, Select } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { AllLanguages } from '@/const/consts';
import { useStore } from '@/store/hooks/useStore';
import { useSetLanguage } from '@/theme/hooks/useSetLanguage';
import { getLang, getLangDirection } from '@/theme/utils/getLangDirection';

export interface Item {
  value: string;
}

interface LanguageSelection {
  language: string;
  displayName: string;
  dir: 'rtl' | 'ltr';
}

/**
 * http://stackoverflow.com/a/10997390/11236
 */
const updateURLParameter = (url: string, param: string, paramVal: string) => {
  let newAdditionalURL = '';
  const tempArray = url.split('?');
  const baseURL = tempArray[0];
  const additionalURL = tempArray[1];
  let temp = '';
  if (additionalURL) {
    const tempArray = additionalURL.split('&');
    for (let i = 0; i < tempArray.length; i++) {
      if (tempArray[i].split('=')[0] != param) {
        newAdditionalURL = newAdditionalURL + temp + tempArray[i];
        temp = '&';
      }
    }
  }

  const rows_txt = temp + '' + param + '=' + paramVal;
  return baseURL + '?' + newAdditionalURL + rows_txt;
};

export const LanguageSelection = () => {
  const [selected, setSelected] = useState<LanguageSelection>();
  const [selections, setSelections] = useState<LanguageSelection[]>([]);

  const { selectedStore } = useStore();
  const setLanguage = useSetLanguage();

  useEffect(() => {
    const selections_: LanguageSelection[] = [];
    const { supported, disabled } = selectedStore?.menu?.languages || {};
    const availableLanguages = supported?.filter(
      supportedLang => disabled?.find(disabledLang => supportedLang === disabledLang) === undefined,
    );
    availableLanguages?.forEach(async lang => {
      // @ts-ignore
      const locale = AllLanguages[lang];
      selections_.push({
        language: lang,
        displayName: locale.nativeName ?? '',
        dir: getLangDirection(lang),
      });
      setSelections([...selections, ...selections_]);
    });
  }, [selectedStore]);

  useEffect(() => {
    const lang = getLang();
    console.log('LanguageSelection Lang: ', lang);
    if (lang) {
      // @ts-ignore
      const locale = AllLanguages[lang];
      setSelected({
        language: lang,
        displayName: locale.nativeName ?? '',
        dir: getLangDirection(lang),
      });
      handleSelection(locale, false);
    }
  }, []);

  const handleSelection = (lang: Opt<any>, save = true) => {
    if (lang) {
      const selection_ = selections.find(selection => selection.language === lang);
      selection_ && setSelected(selection_);

      // Set direction
      selection_?.dir && setLanguage(selection_?.language, save);

      // Update URL
      if (selection_?.language) {
        const url = window.location.pathname + window.location.search;
        const newUrl = updateURLParameter(url, 'lng', selection_?.language);
        window.history.replaceState(null, '', newUrl);
      }
    }
  };

  return (
    <FormControl>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selected?.language ?? 'en'}
        onChange={event => handleSelection(event.target.value)}>
        {selections &&
          selections.map(item => (
            <MenuItem key={item.language} value={item.language}>
              {item.displayName}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
