import { useTheme } from '@material-ui/core';
import React, { memo, useEffect, useState } from 'react';
import { AlertComponentProps, useAlert } from 'react-alert';

import { useBrowseType } from '@/browse/hooks/useBrowseType';
import { BrowseType } from '@/const/enums';
import { t } from '@/i18n/translate';
import MenuView from '@/menu/mobile/components/MenuView';
import { StartCheckView } from '@/orders/components/StartCheckView';
import { useDineIn } from '@/orders/hooks/useDineIn';
import { useStore } from '@/store/hooks/useStore';
import DiscountDialog from '@/store/mobile/components/DiscountDialog';
import FBStorage from '@/utils/FBStorage';

const MenuViewContainer = memo(() => {
  const { selectedStore, notAcceptingOrders, storeClosed, discount } = useStore();
  const alert = useAlert();
  const { checkLocation } = useDineIn();
  const [discountDialog, showDiscountDialog] = useState(false);
  const [showStartCheck, setShowStartCheck] = useState(false);

  const theme = useTheme();
  const isLight = theme.palette.type === 'light';
  const rtl = theme.direction === 'rtl';
  const { type: browseType } = useBrowseType();

  useEffect(() => {
    if (!selectedStore) {
      return;
    }
    const { location } = selectedStore!;
    // @ts-ignore
    alert.removeAll();
    if (
      (notAcceptingOrders || storeClosed) &&
      (browseType === BrowseType.online || browseType === BrowseType.dinein)
    ) {
      const message = notAcceptingOrders
        ? `${rtl ? location?.address?.neighborhood?.ar : location.address?.neighborhood?.en}:\n${t(
            'store:notReceivingOrders',
          )}`
        : `${t('store:storeClosed')}`;
      alertComponentProps = alert.error(message);
    }
  }, [selectedStore, storeClosed, notAcceptingOrders]);

  useEffect(() => {
    if (discount && discount.value > 0) {
      showDiscountDialog(true);
    }
  }, [discount]);

  if (!selectedStore) {
    return <></>;
  }

  const { menu, profilePic } = selectedStore;
  let alertComponentProps: Opt<AlertComponentProps>;

  if (!selectedStore) {
    return <></>;
  }
  if (!menu) {
    return <div>No Menu</div>;
  }

  console.log('FBStorage.getCheckLocation()', FBStorage.getCheckLocation());

  const handleShowStartCheck = (value: boolean) => {
    console.log('handleShowStartCheck', value, checkLocation);
    // TODO HERE
    if (browseType === BrowseType.dinein && value && checkLocation === undefined) {
      setShowStartCheck(true);
    }
  };

  return (
    <>
      <MenuView
        menu={menu}
        rtl={rtl}
        isLight={isLight}
        defaultPic={profilePic}
        setShowStartCheck={handleShowStartCheck}
      />
      <DiscountDialog open={discountDialog} onClosePress={() => showDiscountDialog(false)} />
      <StartCheckView open={showStartCheck} onClosePress={() => setShowStartCheck(false)} />
    </>
  );
});

export default MenuViewContainer;
