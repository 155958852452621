import { useTheme } from '@material-ui/core';
import { useCallback, useContext } from 'react';

import { URLThemeType } from '@/const/enums';
import { useRTL } from '@/theme/hooks/useRTL';
import { getDefaultTheme, SetThemeContext } from '@/theme/providers/ThemeProvider';
import { createTheme } from '@/theme/theme';
import FBStorage from '@/utils/FBStorage';

export const useSetThemeType = () => {
  const { setThemeType, setTheme } = useContext(SetThemeContext);
  const rtl = useRTL();
  const theme = useTheme();
  return useCallback(
    (themeType: URLThemeType) => {
      setThemeType && setThemeType(themeType);
      const newTheme = createTheme(
        {
          direction: rtl ? 'rtl' : 'ltr',
        },
        getDefaultTheme(themeType),
      );
      setTheme(newTheme);
      FBStorage.setTheme(themeType);
      // guest && updateGuest({ ...guest, theme: themeType });
    },
    [setTheme, theme],
  );
};
