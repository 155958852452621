import { useTheme } from '@material-ui/core';

import * as checkoutAnimation from '@/assets/images/checkoutAnimation.json';
import * as completeAnimation from '@/assets/images/completeAnimation.json';
import * as eatPizzaAnimationData from '@/assets/images/eatPizzaAnimation.json';
import * as pizzaAnimationData from '@/assets/images/pizzaAnimation.json';
import CartHelper from '@/cart/utils/CartHelper';
import { Selection } from '@/common/components/Header';
import URLHelper from '@/common/utils/URLHelper';
import { BrowseType, DineInStatus, OrderStatus, OrderType, PickupType } from '@/const/enums';
import { t } from '@/i18n/translate';
import { LocationField, Order, Store } from '@/types';
import DateHelper from '@/utils/DateHelper';
import FBStorage from '@/utils/FBStorage';

export default class OrdersHelper {
  /**
   * Get a human readable string for the order status
   *
   * @param order The order
   * @return The human readable string (i.e. Order Received)
   */
  public static formatOrderStatusToReadable(order: Order): string {
    if (order.status === OrderStatus.PendingReview) {
      return t('order:pendingReview');
    } else if (order.status === OrderStatus.New) {
      return t('order:newOrder');
    } else if (order.status === OrderStatus.Completed) {
      return t('order:complete');
    } else if (order.status === OrderStatus.Rejected) {
      return t('order:rejected');
    } else if (order.status === OrderStatus.Cancelled) {
      return t('order:cancelled');
    } else if (order.status === OrderStatus.DeliveryInProgress) {
      return t('order:deliveryInProgress');
    }
    return order.type === OrderType.DineIn ? t('order:preparing') : t('order:inProgress');
  }

  public static getDineInStatusTextAndAnimation(
    dineInStatus: Opt<DineInStatus>,
    orders: Order[],
  ): { text: string; animation: any; animationSpeed: number; loopAnimation: boolean } {
    let text = '';
    let animation;
    let animationSpeed = 1.0;
    let loopAnimation = true;
    if (!dineInStatus) {
      return { text, animation, animationSpeed, loopAnimation };
    }

    if (dineInStatus === DineInStatus.RequestCheckout) {
      text = t('order:checkRequested');
      animation = checkoutAnimation.default;
      return { text, animation, animationSpeed, loopAnimation };
    } else if (dineInStatus === DineInStatus.InProgress) {
      text = t('order:singleDineInOrderPendingDescription');
      animation = pizzaAnimationData.default;
      animationSpeed = 0.5;
    } else if (dineInStatus === DineInStatus.Serving) {
      text = t('order:dineInOrderPreparingDescription');
      animation = eatPizzaAnimationData.default;
    } else if (dineInStatus === DineInStatus.Completed) {
      text = t('order:dineInOrderCompleteDescription');
      animationSpeed = 0.7;
      loopAnimation = false;
      animation = completeAnimation.default;
    }
    return { text, animation, animationSpeed, loopAnimation };
  }

  public static getStatusColor(order: Order): string {
    const theme = useTheme();
    if (order.status === OrderStatus.New || order.status === OrderStatus.Accepted) {
      return '#FFAB40';
    } else if (order.status === OrderStatus.Completed) {
      return '#00C853';
    } else if (order.status === OrderStatus.Rejected) {
      return theme.palette.secondary.main;
    } else if (order.status === OrderStatus.Cancelled) {
      return theme.palette.secondary.main;
    }
    return theme.palette.type === 'light'
      ? theme.palette.primary.dark
      : theme.palette.primary.light;
  }

  public static getBrowseTypeFromOrderType(orderType: OrderType): BrowseType {
    switch (orderType) {
      case OrderType.DineIn:
        return BrowseType.dinein;
      case OrderType.Pickup:
      case OrderType.Delivery:
        return BrowseType.online;
      default:
        return BrowseType.basic;
    }
  }

  public static getLabelFromOrderType(
    orderTpe: Opt<OrderType>,
    selectedStore: Opt<Store>,
  ): Opt<Selection[]> {
    const deliveryFee = selectedStore?.settings?.deliveryFee;
    const deliveryRadius = selectedStore?.settings?.orders?.delivery?.radius;
    const fee = CartHelper.getDeliveryFee(deliveryFee, deliveryRadius, selectedStore);
    const isFree = !fee || fee! <= 0.0;
    const selections = [];
    switch (orderTpe) {
      case OrderType.Delivery:
        if (selectedStore?.merchantId === '2c0e8f16-f6c9-4ebb-accc-0dcd65a8df84') {
          selections.push({
            key: 1,
            value: OrderType.Delivery,
            label: t('order:roomDelivery'),
          });
        } else {
          selections.push({
            key: 1,
            value: OrderType.Delivery,
            label: !isFree ? `${t('order:delivery')} (${fee} SAR)` : t('order:freeDelivery'),
          });
        }
        break;
      case OrderType.DineIn:
        if (selectedStore?.merchantId === '69668a9b-fde3-463b-8ec5-005f1bc98133') {
          selections.push({ key: 2, value: OrderType.DineIn, label: t('order:nearbyDelivery') });
        } else {
          selections.push({ key: 2, value: OrderType.DineIn, label: t('order:dineIn') });
        }
        break;
      case OrderType.Pickup:
        if (
          selectedStore?.settings?.pickupTypes &&
          selectedStore?.settings?.pickupTypes.length > 0
        ) {
          selectedStore?.settings?.pickupTypes?.map(type => {
            switch (type) {
              case PickupType.Curbside:
              case PickupType.DriveThru:
                selections.push({
                  key: 3,
                  value: OrderType.Pickup,
                  pickupType: PickupType.DriveThru,
                  label: t('order:driveThruTitle'),
                });
                return;
              case PickupType.InStore:
              case PickupType.InPerson:
                selections.push({
                  key: 4,
                  value: OrderType.Pickup,
                  pickupType: PickupType.InStore,
                  label:
                    selectedStore.merchantId === 'f0181bef-77fc-41c1-9e76-fe312fb3ceb6' ||
                    selectedStore.merchantId === 'a08bef8b-4b7f-45db-b1fd-10a659bef86b'
                      ? t('order:windowPickupTitle')
                      : t('order:selfPickupTitle'),
                });
            }
          });
        } else {
          selections.push({ key: 3, value: OrderType.Pickup, label: t('order:pickup') });
        }
        break;
    }
    return selections;
  }

  public static getDeliveryCustomFieldsFromStorage(serverCustomFields: Opt<LocationField[]>) {
    const savedFields = FBStorage.getDeliveryLocationFields();
    serverCustomFields?.forEach(field => {
      savedFields?.forEach(savedField => {
        if (JSON.stringify(field.label) === JSON.stringify(savedField.label)) {
          field.value = savedField.value;
        }
      });
    });
    return serverCustomFields;
  }

  public static getDefaultOrderTypeFromBrowseType(
    browseType: BrowseType,
    orderTypes: Opt<OrderType[]>,
  ): Opt<OrderType> {
    const typeParam = URLHelper.getURLParams('type');
    if ((orderTypes && typeParam) || (orderTypes && orderTypes?.length >= 1 && !typeParam)) {
      const hasDelivery = orderTypes?.find(type => type === OrderType.Delivery);
      const hasDineIn = orderTypes?.find(type => type === OrderType.DineIn);
      const orderType = orderTypes[0];
      if (browseType === BrowseType.dinein) {
        if (hasDineIn) {
          return OrderType.DineIn;
        } else {
          return orderType;
        }
      } else if (browseType === BrowseType.online) {
        return hasDelivery ? OrderType.Delivery : orderTypes ? orderTypes[0] : OrderType.Pickup;
      }
    }
    return undefined;
  }

  /**
   * Sort the given orders from newer to older.
   *
   * @param orders The orders to sort
   * @return The sorted orders
   */
  public static sortOrders(orders: Order[]): Order[] {
    return orders.sort(
      (a, b) =>
        DateHelper.convertZFormatStringDateToDate(b.createdDate!).getTime() -
        DateHelper.convertZFormatStringDateToDate(a.createdDate!).getTime(),
    );
  }

  public static getETAStringAndIsETAPassed(order: Order): { etaStr: string; isETAPassed: boolean } {
    let etaStr = '';
    let isETAPassed = false;
    let isAboutToPass = false;

    if (order.lastUpdated && order.storeETA) {
      const orderDatePlusETA = DateHelper.getDateAfterApplyingETA(
        order.lastUpdated,
        order.storeETA * 60,
      );
      isAboutToPass = DateHelper.isDateNSecondsInThePast(orderDatePlusETA, -70);
      isETAPassed = DateHelper.isDateNSecondsInThePast(orderDatePlusETA, 0);
      if (isAboutToPass && !isETAPassed) {
        etaStr = 'in a minute';
      } else {
        etaStr = isETAPassed ? '0 mins' : DateHelper.elapsedTime(orderDatePlusETA);
      }
    }
    return { etaStr, isETAPassed };
  }

  public static getOrderWithPaymentId(paymentId: Opt<string>, orders: Order[]): Opt<Order> {
    const ordersWithPaymentId = orders.filter(order => order.paymentTransaction?.id === paymentId);
    if (ordersWithPaymentId.length > 0) {
      return ordersWithPaymentId[0];
    }
    return undefined;
  }
}
