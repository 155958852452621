import React, { useEffect, useState } from 'react';

import { OrderStatus } from '@/const/enums';
import { useFetchOrder } from '@/orders/hooks/useFetchOrder';
import { useOrder } from '@/orders/hooks/useOrder';
import { Order } from '@/types';

export const useFetchOrderInterval = (delay: number) => {
  const [intervalId, setIntervalId] = useState<Opt<NodeJS.Timeout>>(undefined);
  const { fetchOrder } = useFetchOrder();
  const { setOrder, order } = useOrder();

  const startInterval = () => {
    if (!order) {
      return;
    }
    const tempInterval = setInterval(async () => {
      const updatedOrder = await fetchOrder(order!.id!);
      if (updatedOrder) {
        if (!shouldAutoUpdate(updatedOrder)) {
          stopIntervalForId(tempInterval);
        }
        setOrder(updatedOrder!);
      }
    }, delay);
    setIntervalId(tempInterval);
  };

  const stopInterval = () => stopIntervalForId(intervalId);

  const stopIntervalForId = (intervalId: Opt<NodeJS.Timeout>) => {
    if (intervalId) {
      clearInterval(intervalId);
      const temp = setIntervalId(undefined);
    }
  };

  const shouldAutoUpdate = (order: Order) =>
    order?.status !== OrderStatus.Completed &&
    order?.status !== OrderStatus.Rejected &&
    order?.status !== OrderStatus.Cancelled;

  useEffect(() => {
    if (!intervalId && order && shouldAutoUpdate(order)) {
      startInterval();
    }
  }, [order]);

  useEffect(() => {
    return () => stopInterval();
  }, []);

  return { stopInterval, startInterval };
};
