import RedirectPage from '@/common/components/Default';
import HealthPage from '@/common/components/HealthPage';
import NotFound from '@/common/components/NotFound';
import CourierOrderPageRouter from '@/orders/components/CourierOrderPageRouter';
import DineInPageRouter from '@/orders/components/DineInPageRouter';
import OrderPageRouter from '@/orders/components/OrderPageRouter';
import OrdersPageRouter from '@/orders/components/OrdersPageRouter';
import FailedPaymentRedirectPageRouter from '@/payment/components/FailedPaymentRedirectPageRouter';
import ReservationPaymentPageStatusRouter from '@/payment/components/ReservationPaymentPageStatusRouter';
import ShortLinkPageRouter from '@/shortlinks/components/ShortLinkPageRouter';
import StorePageRouter from '@/store/components/StorePageRouter';

export default [
  {
    path: '/payment-status',
    component: ReservationPaymentPageStatusRouter,
    exact: true,
  },
  {
    path: '/:handle',
    component: StorePageRouter,
    exact: true,
  },
  {
    path: '/:handle/orders',
    component: OrdersPageRouter,
    exact: true,
  },
  {
    path: '/:handle/dine-in/:dineInId',
    component: DineInPageRouter,
    exact: true,
  },
  {
    path: '/:handle/order/:orderId',
    component: OrderPageRouter,
    exact: true,
  },
  {
    path: '/:handle/courier-order/:orderId',
    component: CourierOrderPageRouter,
    exact: true,
  },
  {
    path: '/:handle/:shortLinkCode',
    component: ShortLinkPageRouter,
    exact: true,
  },
  {
    path: '/:handle/payment/fail',
    component: FailedPaymentRedirectPageRouter,
    exact: true,
  },
  {
    path: '/',
    component: RedirectPage,
  },
  {
    path: '/health',
    component: HealthPage,
  },
  {
    component: NotFound,
  },
];
