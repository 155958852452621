import useTheme from '@material-ui/core/styles/useTheme';
import React, { memo } from 'react';

interface Props {
  height?: number;
  width?: number;
}
export const Space = memo<Props>(({ height, width }) => {
  const theme = useTheme();
  return (
    <div
      style={{
        height: height ? theme.spacing(height) : 0,
        width: width ? theme.spacing(width) : 0,
      }}
    />
  );
});
