import { useCallback, useContext, useState } from 'react';

import { Language } from '@/const/enums';
import { useGuest } from '@/guest/hooks/useGuest';
import { useRTL } from '@/theme/hooks/useRTL';
import { SetThemeContext } from '@/theme/providers/ThemeProvider';
import { Guest } from '@/types';
import FBStorage from '@/utils/FBStorage';

import Api from '../../api/Api';

export const useUpdateGuest = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const { setGuest } = useGuest();
  const { themeType } = useContext(SetThemeContext);

  const updateGuest = useCallback(async (guest: Guest) => {
    setLoading(true);
    try {
      guest.theme = themeType;
      // guest.language = rtl ? Language.Arabic : Language.English;
      const savedGuest = await Api.updateGuest(guest);
      FBStorage.setGuest(savedGuest);
      setGuest && setGuest(savedGuest);
      setLoading(false);
      setSuccess(true);
    } catch (e) {
      setError(e.message);
      setSuccess(false);
    }
  }, []);

  return {
    updateGuest,
    loading,
    error,
    success,
  };
};
