export const en = {
  paymentMethod: 'Payment Method',
  applePay: 'Apple Pay',
  cash: 'Cash',
  other: 'Other (i.e. Mastercard, VISA, MADA)',
  payAtPickup: 'Pay at pickup',
  payAtStore: 'Pay at location',
  submittingOrder: 'Submitting Order...',
  failedPayment: 'Failed Payment',
  goToPayment: 'Go to Payment',
  applePayUnavailable: 'Sorry, Apple Pay is unavailable on your device!',
  applePayError: 'Sorry, error in processing your Apple Pay payment',
  reservationSuccessTitle: 'Thank You',
  reservationFailTitle: 'Sorry',
  reservationSuccessMessage:
      'Your payment is received and your reservation is confirmed',
  reservationFailMessage:
      'Your payment was not successful and your reservation was cancelled',
  backToReservation: 'backToReservation',
};

export const ar: typeof en = {
  paymentMethod: 'طريقة الدفع',
  applePay: 'ابل باي',
  cash: 'كاش',
  other: 'اخر (ماستركارد، فيزا، مدى)',
  payAtPickup: 'الدفع عند الاستلام',
  payAtStore: 'الدفع داخل الفرع',
  submittingOrder: 'قيد إرسال الطلب ...',
  failedPayment: 'خطأ في عملية الدفع',
  goToPayment: 'اذهب الى صفحة الدفع',
  applePayUnavailable: 'عفوا، ابل باي غير متوفر على جهازك',
  applePayError: 'عفوا، حصل خطأ في تنفيذ العملية',
  reservationSuccessTitle: 'شكرا',
  reservationFailTitle: 'عفواً',
  reservationSuccessMessage: 'تم تأكيد حجزك',
  reservationFailMessage: 'لم يتم تنفيذ عميلة الدفع بنجاح. لقد تم الغاء الحجز',
  backToReservation: 'العودة الى الحجوزات',
};
