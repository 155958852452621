import React from 'react';

import {Discount, Merchant, Store} from '@/types';

interface StoreProps {
  stores: Store[];
  selectedStore: Opt<Store>;
  nearestStore?: Store;
  discount: Opt<Discount>;
  setSelectedStore?: (store: Store) => void;
  setNearestStore: (store: Store) => void;
  setStores: (store: Store[]) => void;
  setMerchant: (merchant: string) => void;
  notAcceptingOrders: boolean;
  storeClosed: boolean;
  opensAt: Opt<string>;
  isMamaNoura: boolean;
  merchant: Opt<string>;
}
export const StoreContext = React.createContext<StoreProps>({
  selectedStore: undefined,
  nearestStore: undefined,
  discount: undefined,
  setNearestStore: () => undefined,
  setStores: () => undefined,
  setMerchant: () => undefined,
  stores: [],
  notAcceptingOrders: false,
  storeClosed: false,
  opensAt: undefined,
  isMamaNoura: false,
  merchant: undefined,
});
