import { Box, Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { memo } from 'react';

import { Images } from '@/assets/images';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flex: 1,
      backgroundColor: theme.palette.type === 'light' ? 'white' : theme.palette.primary.dark,
      paddingTop: theme.spacing(1.3),
      paddingBottom: theme.spacing(1.8),
      bottom: 0,
      position: 'fixed',
      alignSelf: 'center',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    img: {
      height: 27,
      cursor: 'pointer',
    },
  }),
);

interface Props {
  onClick: () => void;
  isDark: boolean;
}
export const Footer = memo<Props>(({ onClick, isDark }) => {
  const classes = useStyles();
  return (
    <div className={classes.container} onClick={onClick}>
      <img className={classes.img} src={isDark ? Images.poweredByDark : Images.poweredByLight} />
    </div>
  );
});
