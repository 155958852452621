import {
  createStyles,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import React, { memo, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';

import { labelOrDefault } from '@/common/utils/tools';
import { ThemeType } from '@/const/enums';
import { t } from '@/i18n/translate';
import MenuItemHelper from '@/menu/utils/MenuItemHelper';
import { useStore } from '@/store/hooks/useStore';
import { getLang, getLangDirection } from '@/theme/utils/getLangDirection';
import { Menu } from '@/types';
import DateHelper from '@/utils/DateHelper';

import MenuItemsList from './MenuItemsList';

const SWIPE_HEIGHT = '72vh';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      height: 'auto',
    },
    shadow: {
      boxShadow: theme.palette.type === 'light' ? '1px 2px 1px #EDEDED' : '1px 2px 1px #DEDED',
    },
    tabs: {
      position: 'fixed',
      margin: '0 auto',
      top: 65,
      width: '100%',
      color: theme.palette.type === 'light' ? theme.palette.primary.dark : 'white',
      backgroundColor: theme.palette.type === 'light' ? 'white' : theme.palette.primary.dark,
      paddingBottom: theme.spacing(0.5),
      zIndex: 1,
    },
    tab: {
      minHeight: SWIPE_HEIGHT,
    },
    button: {
      fontWeight: 'bold',
      height: '7%',
      justifyContent: 'center',
      bottom: 1,
      alignSelf: 'center',
    },
    space: {
      height: 55,
    },
  }),
);

interface Props {
  menu: Menu;
  defaultPic: string;
  isLight: boolean;
  rtl: boolean;
  setShowStartCheck?: (value: boolean) => void;
}
const MenuView = memo<Props>(({ menu, isLight, rtl, defaultPic, setShowStartCheck }) => {
  const theme = useTheme();
  const classes = useStyles();
  const { selectedStore } = useStore() || {};
  const { settings } = selectedStore || {};
  const [tabIndex, setTabIndex] = useState(0);
  // const { themeType } = useContext(SetThemeContext);
  const { theme: customTheme } = settings || {};
  const { basic: basicTheme } = customTheme || {};
  const lang = getLang();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleTabChange = (index: number) => {
    setTabIndex(index);
  };

  if (!menu) {
    return <div>No Menu</div>;
  }

  const categories = menu.categories.filter(
    category => category.id !== '19224a0b-6916-489c-9114-461966928478',
  );

  // TODO: HACK for LDP to hide disabled categories
  // if (
  //   selectedStore?.id === '0bfb3b62-94a4-41c0-9c72-67fb7153bd36' ||
  //   selectedStore?.id === 'ef6adfa8-70e7-4153-9202-0a65504f061f'
  // ) {
  //   categories = categories.filter(category => {
  //     if (category.availability && !category.availability.isAvailable) {
  //       return false;
  //     }
  //     return true;
  //   });
  // }

  console.log('render menu');
  // @ts-ignore
  return (
    <>
      <div>
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          style={{
            background:
              basicTheme?.type === ThemeType.Light
                ? 'linear-gradient(to top, #ffff, #F9F9F9)'
                : undefined,
          }}
          variant={categories && categories.length > 3 ? 'scrollable' : 'standard'}
          scrollButtons={categories && categories.length > 3 ? 'on' : 'auto'}
          TabIndicatorProps={{
            style: { background: basicTheme?.color ?? 'primary' },
          }}
          className={classes.tabs}>
          {categories &&
            categories.map((category, index) => {
              const isAvailable =
                category?.availability === undefined || category?.availability?.isAvailableNow;
              const showCategoryTempUnavailable = MenuItemHelper.isCategoryUnavailable(
                category,
                selectedStore?.id,
              );
              const enableCategoryMarginTimes =
                categories &&
                categories.filter(
                  each =>
                    each.availability !== undefined &&
                    (each.availability?.isTimeAvailable === true ||
                      each.availability?.isEnabled === true) &&
                    each.availability.start !== undefined &&
                    each.availability.start !== null,
                ).length > 0;
              return (
                <Tab
                  key={category.id}
                  style={{
                    fontSize: rtl ? 18 : 14,
                  }}
                  label={
                    <>
                      <Typography
                        style={{
                          color:
                            index === tabIndex
                              ? basicTheme?.color ?? theme.palette.secondary.main
                              : theme.palette.text.secondary,
                          fontWeight: tabIndex === index ? 'bold' : 500,
                        }}>
                        {labelOrDefault(category.name, lang)}
                      </Typography>
                      {showCategoryTempUnavailable && (
                        <Typography
                          variant="caption"
                          dir="ltr"
                          align={'inherit'}
                          style={{
                            color: showCategoryTempUnavailable
                              ? 'red'
                              : index === tabIndex
                              ? basicTheme?.color ?? 'undefined'
                              : theme.palette.text.secondary,
                            fontWeight: tabIndex === index ? 'bold' : 500,
                          }}>
                          {t('menu:tempUnavailable')}
                        </Typography>
                      )}
                      {(category?.availability?.isTimeAvailable === true ||
                        category.availability?.isEnabled === true) &&
                      !showCategoryTempUnavailable ? (
                        <Typography
                          variant="caption"
                          dir="ltr"
                          align={'inherit'}
                          style={{
                            color: !isAvailable
                              ? 'red'
                              : index === tabIndex
                              ? basicTheme?.color ?? 'undefined'
                              : theme.palette.text.secondary,
                            fontWeight: tabIndex === index ? 'bold' : 500,
                          }}>
                          {`${DateHelper.convertHourToDateAndFormatIt(
                            category?.availability?.start,
                          )} - ${DateHelper.convertHourToDateAndFormatIt(
                            category?.availability?.end,
                          )}`}
                        </Typography>
                      ) : (
                        enableCategoryMarginTimes && (
                          <Typography
                            variant="caption"
                            dir="ltr"
                            style={{ whiteSpace: 'pre-line' }}>
                            {'\n'}
                          </Typography>
                        )
                      )}
                    </>
                  }
                />
              );
            })}
        </Tabs>
      </div>

      <SwipeableViews
        animateHeight
        axis={rtl ? 'x-reverse' : 'x'}
        index={tabIndex}
        containerStyle={{
          marginTop: theme.spacing(13),
        }}
        onChangeIndex={handleTabChange}>
        {categories &&
          categories.map(category => (
            <div key={category.id} dir={getLangDirection()}>
              <MenuItemsList
                key={category.id}
                setShowStartCheck={setShowStartCheck}
                showCategoryTempUnavailable={MenuItemHelper.isCategoryUnavailable(
                  category,
                  selectedStore?.id,
                )}
                category={category}
                defaultPic={defaultPic}
                isCategoryAvailable={
                  category?.availability
                    ? !MenuItemHelper.isCategoryUnavailable(category, selectedStore?.id) &&
                      category?.availability?.isAvailableNow === true
                    : true
                }
                categoryAvailability={
                  category?.availability?.isTimeAvailable === true ||
                  category?.availability?.isEnabled === true
                    ? {
                        start: category?.availability?.start,
                        end: category?.availability?.end,
                      }
                    : undefined
                }
              />
            </div>
          ))}
      </SwipeableViews>
      <div className={classes.space} />
    </>
  );
});

export default MenuView;
