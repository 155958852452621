import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import TextTruncate from 'react-text-truncate'; // recommend

import MenuItemCard from '@/menu/desktop/components/MenuItemCard';
import MenuItemHelper from '@/menu/utils/MenuItemHelper';
import { useStore } from '@/store/hooks/useStore';
import { useRTL } from '@/theme/hooks/useRTL';
import { MenuItem } from '@/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      alignItems: 'flex-start',
    },
    img: {
      height: 150,
      width: 150,
      marginRight: 15,
      borderRadius: 15,
      marginTop: 10,
      objectFit: 'contain',
    },
    nameContainer: {
      marginBottom: 5,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    caloriesText: {
      margin: '15px 10px',
    },
    card: {
      maxWidth: 450,
      borderRadius: 8,
    },
    media: {
      height: 0,
      paddingTop: '80.25%', // 16:9
    },
    subtitle: {
      color: theme.palette.primary.light,
      fontSize: 15,
    },
  }),
);

interface Props {
  menuItem: MenuItem;
  className?: string;
}

const MenuItemCardContainer = ({ menuItem, className }: Props) => {
  const { selectedStore } = useStore();

  return (
    <MenuItemCard
      className={className}
      menuItem={menuItem}
      defaultPic={selectedStore?.profilePic ?? ''}
    />
  );
};

export default MenuItemCardContainer;
