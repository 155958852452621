import {
  Button,
  Chip,
  createStyles,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import React, { useState } from 'react';

import CartMenuItemsList from '@/cart/mobile/components/CartMenuItemsList';
import CartHelper from '@/cart/utils/CartHelper';
import { OrderStatus } from '@/const/enums';
import { t } from '@/i18n/translate';
import OrdersHelper from '@/orders/utils/OrdersHelper';
import { Order } from '@/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    listItem: {
      alignItems: 'flex-start',
    },
    card: {
      backgroundColor: theme.palette.type === 'light' ? '#e8e8e8' : 'default',
    },
    statusContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'center',
    },
    expandIcon: {
      fontSize: 25,
    },
    fullWidth: {
      width: '100%',
    },
    spaceBetweenRow: {
      display: 'flex',
      flex: 1,
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    chip: {
      marginLeft: theme.spacing(-0.5),
    },
    items: {
      marginLeft: theme.spacing(1),
    },
    statusIcon: {
      fontSize: 16,
      marginRight: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
    },
    clickable: {
      textDecoration: 'underline',
      color: '#0091EA',
      cursor: 'pointer',
    },
  }),
);

interface Props {
  order: Order;
  showETA: boolean;
  eta: Opt<string>;
  onMoreInfoClick?: () => void;
  hideTotal: boolean;
  showMoreInfo: boolean;
  rtl: boolean;
}

const OrderCard = ({
  order,
  showETA,
  hideTotal,
  showMoreInfo,
  onMoreInfoClick,
  eta,
  rtl,
}: Props) => {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);

  const statusIcon = () => {
    if (order.status === OrderStatus.Accepted) {
      return (
        <HourglassFullIcon
          className={classes.statusIcon}
          style={{
            color: OrdersHelper.getStatusColor(order),
          }}
        />
      );
    } else if (order.status === OrderStatus.Completed) {
      return (
        <CheckCircleIcon
          className={classes.statusIcon}
          style={{
            color: OrdersHelper.getStatusColor(order),
          }}
        />
      );
    }
    return <></>;
  };
  return (
    <div className={classes.root}>
      <ExpansionPanel expanded={isExpanded} elevation={0} className={classes.card}>
        <ExpansionPanelSummary
          expandIcon={
            <ExpandMoreIcon
              onClick={() => {
                setIsExpanded(!isExpanded);
              }}
              className={classes.expandIcon}
            />
          }>
          <div className={classes.fullWidth}>
            <div className={classes.spaceBetweenRow}>
              <div>
                <Typography variant="subtitle1">{`#${order.orderNumber}`}</Typography>
                <Chip
                  size="small"
                  className={classes.chip}
                  label={`x${CartHelper.getQuantity(order?.items!!)} ${t('menu:items')}`}
                />
              </div>
              <div className={classes.statusContainer}>
                <div className={classes.row}>
                  {statusIcon()}
                  <Typography
                    color="textSecondary"
                    variant="subtitle1"
                    align="center"
                    style={{
                      color: OrdersHelper.getStatusColor(order),
                    }}>
                    {OrdersHelper.formatOrderStatusToReadable(order)}
                  </Typography>
                </div>

                {showMoreInfo && (
                  <Button
                    variant="text"
                    size="small"
                    className={classes.clickable}
                    onClick={onMoreInfoClick}>
                    {t('common:moreInfo')}
                  </Button>
                )}

                {showETA && (
                  <Typography color="textSecondary" variant="caption">
                    {`${t('order:eta')}: ${eta}`}
                  </Typography>
                )}
              </div>
            </div>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <CartMenuItemsList
            hideTitle
            hideTotal={hideTotal}
            hideBottomSpace
            orderTotal={CartHelper.getTotalPrice(
              order.items!!,
              order.delivery?.deliveryFee,
              order.serviceFee,
              order.tax,
            )}
            quantity={CartHelper.getQuantity(order.items!!)}
            menuItems={order.items!!}
            editable={false}
            showMinimumDeliveryOrder={false}
            showDelivery={false}
            rtl={rtl}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

export default OrderCard;
