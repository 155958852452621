import { createStyles, withStyles } from '@material-ui/core';
import React from 'react';
import Favicon from 'react-favicon';

import { Event } from '@/analytics/Event';
import SafaryAnalytics from '@/analytics/SafaryAnalytics';
import { FailedPaymentRedirectPageContainer } from '@/payment/mobile/container/FailedPaymentRedirectPageContainer';
import { PaymentProvider } from '@/payment/providers/PaymentProvider';
import { getInitialProps, Props } from '@/store/components/StorePageRouter';
import { StoreProvider } from '@/store/providers/StoreProvider';
import StoreHelper from '@/store/utils/StoreHelper';

const styles = createStyles({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});

interface State {
  openDialog: boolean;
}

class FailedPaymentRedirectPageRouter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openDialog: false,
    };
  }

  public static getInitialProps = ({ _, __, match, req }: any) => {
    return getInitialProps({ _, __, match, req });
  };

  componentDidMount(): void {
    SafaryAnalytics.logEvent(Event.ViewOrderPage);
  }

  public render() {
    const isSSR = typeof window === 'undefined';
    if (isSSR) {
      return <></>;
    }
    const { stores, error, classes, paymentTransactionId } = this.props;
    if (error || !stores || stores.length === 0) {
      return <></>;
    }

    const defaultStore = StoreHelper.getDefaultStore(stores);
    const { profilePic } = defaultStore;

    return (
      <div className={classes.root}>
        <Favicon url={profilePic} />
        <StoreProvider defaultStore={defaultStore} defaultStores={stores}>
          <PaymentProvider paymentTransactionId={paymentTransactionId}>
            <FailedPaymentRedirectPageContainer />
          </PaymentProvider>
        </StoreProvider>
      </div>
    );
  }
}

export default withStyles(styles)(FailedPaymentRedirectPageRouter);
