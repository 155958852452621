import { createMuiTheme, responsiveFontSizes, ThemeOptions } from '@material-ui/core';

export const createTheme = (options: ThemeOptions = {}, type: 'light' | 'dark') => {
  const { direction = 'rtl' } = options;
  return createMuiTheme({
    direction,
    typography: {
      fontSize: 13,
      fontFamily: ['DroidNaskh-Regular', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(
        ',',
      ),
    },
    palette: {
      type,
      primary: {
        main: '#0f0e0f',
        dark: '#0f0e0f',
        light: '#a49b9b',
      },
      secondary: {
        main: '#FA3532',
        dark: '#a49b9b',
        light: '#0c0b0b',
      },
    },
    ...options,
  });
};
