import {
  Checkbox,
  createStyles,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
  Typography,
} from '@material-ui/core';
import { useConfirm } from 'material-ui-confirm';
import React, { forwardRef, memo, useEffect, useImperativeHandle, useState } from 'react';

import Button from '@/common/components/CustomButton';
import { MenuItemOptionsType } from '@/const/enums';
import { t } from '@/i18n/translate';
import MenuItemHelper from '@/menu/utils/MenuItemHelper';
import { useStore } from '@/store/hooks/useStore';
import { useRTL } from '@/theme/hooks/useRTL';
import { MenuItemOption, MenuItemOptions } from '@/types';
import DateHelper from '@/utils/DateHelper';
import ObjectHelper from '@/utils/ObjectHelper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      padding: theme.spacing(2),
    },
    formLabel: {
      marginBottom: theme.spacing(2),
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      alignItems: 'center',
    },
    optionContainer: {
      display: 'flex',
      flex: 1,
      minWidth: '50vw',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'start',
    },
    button: {
      fontWeight: 'bold',
      height: '7%',
      marginTop: theme.spacing(2),
      width: '100%',
      bottom: 1,
    },
    disabled: {
      opacity: 0.6,
    },
  }),
);

interface Props {
  open: boolean;
  defaultOptions: MenuItemOptions;
  onClosePress: () => void;
  onAddOptionClick: () => void;
  onCancelClick: () => void;
  loading: boolean;
  ref?: React.Ref<any>;
  type: MenuItemOptionsType;
  itemPrice: number;
}

export interface MenuItemOptionsViewMethods {
  resetOptionsToDefault: () => void;
  setOptions: (options: MenuItemOptions) => void;
}

const MenuItemOptionsView = memo<Props>(
  forwardRef<MenuItemOptionsViewMethods, Props>(
    (
      {
        open,
        defaultOptions,
        onClosePress,
        onAddOptionClick,
        loading,
        onCancelClick,
        type,
        itemPrice,
      },
      ref,
    ) => {
      const confirm = useConfirm();
      const { selectedStore } = useStore() || {};
      const { currency } = selectedStore?.settings || {};

      const initDefaultOptions = (options: MenuItemOptions) => ({
        ...options,
        items: Object.assign([], options.items),
      });
      const [internalOptions, setInternalOptions] = useState<MenuItemOptions>(
        initDefaultOptions(defaultOptions),
      );

      const setOptions = (options: MenuItemOptions) => {
        setInternalOptions(initDefaultOptions(options));
      };

      useImperativeHandle(ref, () => ({ resetOptionsToDefault, setOptions }), []);

      const [initialOptions, setInitialOptions] = useState<MenuItemOptions>();

      const [highlightedGroups, setHighlightedGroups] = useState<MenuItemOption[]>([]);

      const classes = useStyles();
      const rtl = useRTL();

      useEffect(() => {
        setInitialOptions(ObjectHelper.deepCopy(defaultOptions));
      }, [open]);

      const handleCancelClick = () => {
        onCancelClick();
        resetOptions();
      };

      const resetOptionsToDefault = () => {
        if (!internalOptions) {
          return;
        }
        internalOptions.items?.forEach(eachOption => {
          eachOption.selected = false;
        });
        setInternalOptions(internalOptions);
      };

      const resetOptions = () => {
        internalOptions.items?.forEach(eachOption => {
          initialOptions!.items?.forEach(initialOption => {
            if (initialOption.name === eachOption.name) {
              eachOption.selected = initialOption.selected;
            }
          });
        });
        setInternalOptions(internalOptions);
      };

      const handleRadioButtonPress = (option: MenuItemOption) => {
        if (internalOptions && internalOptions.items && internalOptions.items.length > 0) {
          // internalOptions.groups.forEach((eachGroup: MenuItemOptionsGroup) => {
          internalOptions.items?.forEach((eachOption: MenuItemOption) => {
            eachOption.selected = false;
            if (eachOption.name === option.name) {
              resetOtherGroups(eachOption, internalOptions.items!);
              eachOption.selected = !eachOption.selected;
              highlightedGroups.push(eachOption);
              setHighlightedGroups(highlightedGroups);
            }
          });
          // });
        }
        const newOptions = { ...internalOptions };
        setInternalOptions(newOptions);
      };

      const renderMultipleOptions = () => {
        return (
          <FormControl>
            <FormLabel className={classes.formLabel} disabled>
              {MenuItemHelper.getOptionsTitle(defaultOptions)}
            </FormLabel>
            <FormGroup>
              {internalOptions.items &&
                internalOptions.items
                  ?.filter(item => !MenuItemHelper.isOptionHidden(item, selectedStore?.id))
                  ?.map(option => {
                    const isAvailable = !MenuItemHelper.isOptionUnavailable(
                      option,
                      selectedStore?.id,
                    );
                    // option.availability === undefined || option.availability?.isAvailableNow;
                    return (
                      <FormControlLabel
                        key={option.id}
                        control={
                          <Checkbox
                            checked={option.selected ? option.selected : false}
                            disabled={!isAvailable}
                            onChange={() => handleCheckBoxPress(option)}
                          />
                        }
                        label={
                          <div className={classes.optionContainer}>
                            <div className={classes.row}>
                              <Typography
                                variant="subtitle2"
                                className={!isAvailable ? classes.disabled : undefined}
                                color="textPrimary">
                                {`${MenuItemHelper.getOptionName(option)} (${
                                  option.price > 0 ? '+' : ''
                                }${MenuItemHelper.getPriceWithCurrency(option.price, currency)})`}
                              </Typography>
                            </div>

                            {option.availability?.start &&
                              (option.availability.isTimeAvailable === true ||
                                option.availability?.isEnabled === true) && (
                                <Typography
                                  dir="ltr"
                                  variant="caption"
                                  color={isAvailable ? 'textSecondary' : 'error'}
                                  className={!isAvailable ? classes.disabled : undefined}>
                                  {`${DateHelper.convertHourToDateAndFormatIt(
                                    option?.availability?.start,
                                  )} - ${DateHelper.convertHourToDateAndFormatIt(
                                    option?.availability?.end,
                                  )}`}
                                </Typography>
                              )}
                          </div>
                        }
                      />
                    );
                  })}
            </FormGroup>
          </FormControl>
        );
      };

      const renderSingleOptions = () => {
        return (
          <FormControl>
            <FormLabel className={classes.formLabel} disabled>
              {MenuItemHelper.getOptionsTitle(internalOptions)}
            </FormLabel>
            <RadioGroup
              name={MenuItemHelper.getOptionsTitle(internalOptions)}
              value={MenuItemHelper.getSelectedSingleOption(internalOptions)?.id}
              onChange={(event, value) => {
                handleRadioButtonPress(internalOptions?.items?.find(opt => opt.id === value)!);
              }}>
              {internalOptions.items &&
                internalOptions.items
                  ?.filter(item => !MenuItemHelper.isOptionHidden(item, selectedStore?.id))
                  ?.map(option => {
                    const isAvailable = !MenuItemHelper.isOptionUnavailable(
                      option,
                      selectedStore?.id,
                    );
                    // option.availability === undefined || option.availability?.isAvailableNow;
                    return (
                      <FormControlLabel
                        key={option.id}
                        value={option.id}
                        disabled={!isAvailable}
                        control={<Radio />}
                        label={
                          <div className={classes.optionContainer}>
                            <div className={classes.row}>
                              <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                className={!isAvailable ? classes.disabled : undefined}>
                                {`${MenuItemHelper.getOptionName(option)} (${
                                  option.price > 0 ? '+' : ''
                                }${MenuItemHelper.getPriceWithCurrency(option.price, currency)})`}
                              </Typography>
                            </div>
                            {option.price > 0 && (
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                className={!isAvailable ? classes.disabled : undefined}>
                                {`${t('cart:total')} = ${MenuItemHelper.getPriceWithCurrency(
                                  itemPrice + option.price,
                                  currency,
                                )}`}
                              </Typography>
                            )}
                            {!isAvailable &&
                              option &&
                              option?.availability &&
                              option?.availability?.start && (
                                <Typography
                                  dir="ltr"
                                  variant="caption"
                                  color="error"
                                  className={!isAvailable ? classes.disabled : undefined}>
                                  {`${DateHelper.convertHourToDateAndFormatIt(
                                    option?.availability?.start,
                                  )} - ${DateHelper.convertHourToDateAndFormatIt(
                                    option?.availability?.end,
                                  )}`}
                                </Typography>
                              )}
                          </div>
                        }
                      />
                    );
                  })}
            </RadioGroup>
          </FormControl>
        );
      };

      const handleCheckBoxPress = (option: MenuItemOption) => {
        // If we have groups, iterate through these groups
        internalOptions.items?.forEach(async (eachOption: MenuItemOption) => {
          if (eachOption.name === option.name) {
            const selectedItems = internalOptions.items?.filter(item => item.selected).length;
            const isSelecting = !eachOption.selected;
            // Check if use reached maximum selection
            if (
              isSelecting &&
              internalOptions.maximumNumberOfSelections &&
              selectedItems &&
              selectedItems! >= internalOptions.maximumNumberOfSelections
            ) {
              try {
                await confirm({
                  title: t('common:alert'),
                  description: `${t('menu:reachedMaximumSelections')} ${
                    internalOptions.maximumNumberOfSelections
                  }`,
                  confirmationText: t('common:ok'),
                  cancellationText: '',
                  confirmationButtonProps: {
                    color: 'default',
                  },
                });
              } catch (e) {
                console.log(e);
              }
            } else {
              eachOption.selected = !eachOption.selected;
              highlightedGroups.push(eachOption);
              setHighlightedGroups(highlightedGroups);
            }
          }
        });

        const newOptions = { ...internalOptions };
        setInternalOptions(newOptions);
      };

      const resetOtherGroups = (selectedOption: MenuItemOption, allOptions: MenuItemOption[]) => {
        // highlightedGroups.splice(highlightedGroups.indexOf(selectedGroup));
        highlightedGroups.pop();
        setHighlightedGroups([]);
        allOptions.forEach(eachOption => {
          if (eachOption.id !== selectedOption.id) {
            // eachGroup.items.forEach(eachItem => {
            eachOption.selected = false;
            // });
          }
        });
      };
      return (
        <Dialog open={open} onClose={onClosePress} onEscapeKeyDown={onClosePress}>
          <div className={classes.dialog} dir={rtl ? 'rtl' : 'ltr'}>
            {type === MenuItemOptionsType.Multiple
              ? renderMultipleOptions()
              : renderSingleOptions()}

            {/* Add Item Button */}
            <div>
              <Button
                loading={loading}
                variant="contained"
                onClick={onAddOptionClick}
                className={classes.button}>
                {t('common:save')}
              </Button>

              <Button
                variant="text"
                color="default"
                onClick={handleCancelClick}
                className={classes.button}>
                {t('common:cancel')}
              </Button>
            </div>
          </div>
        </Dialog>
      );
    },
  ),
);

export default MenuItemOptionsView;
