import { createStyles, withStyles } from '@material-ui/core';
import React from 'react';
import Favicon from 'react-favicon';
import { Helmet } from 'react-helmet';

import { Event } from '@/analytics/Event';
import { AnalyticsProvider } from '@/analytics/providers/AnalyticsProvider';
import SafaryAnalytics from '@/analytics/SafaryAnalytics';
import { BrowseTypeProvider } from '@/browse/providers/BrowseTypeProvider';
import NotFound from '@/common/components/NotFound';
import { GuestProvider } from '@/guest/provider/GuestProvider';
import { OnlineOrderPageContainer } from '@/orders/mobile/containers/OnlineOrderPageContainer';
import { DineInProvider } from '@/orders/providers/DineInProvider';
import { OrderProvider } from '@/orders/providers/OrderProvider';
import { getInitialProps, Props } from '@/store/components/StorePageRouter';
import { StoreProvider } from '@/store/providers/StoreProvider';
import StoreHelper from '@/store/utils/StoreHelper';
import MetaHelper from '@/utils/MetaHelper';

const styles = createStyles({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});

interface State {
  openDialog: boolean;
}

class OrderPageRouter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openDialog: false,
    };
  }

  public static getInitialProps = ({ _, __, match, req }: any) => {
    return getInitialProps({ _, __, match, req });
  };

  componentDidMount(): void {
    SafaryAnalytics.logEvent(Event.ViewOrderPage);
  }

  public render() {
    const isSSR = typeof window === 'undefined';
    if (isSSR) {
      return <></>;
    }
    const { stores, error, classes, siteUrl, handle, lng, orderId, browseType } = this.props;
    if (error || !stores || stores.length === 0) {
      return <></>;
    }

    const defaultStore = StoreHelper.getDefaultStore(stores);
    const { profilePic } = defaultStore;

    if (!orderId) {
      return <NotFound />;
    }

    return (
      <div className={classes.root}>
        <Favicon url={profilePic} />
        <Helmet>
          <meta charSet="utf-8" />
          <title>{MetaHelper.getTitle(defaultStore)}</title>
          <meta
            name="description"
            content={MetaHelper.getDescription(defaultStore, lng, browseType)}
          />
          <link rel="canonical" href={MetaHelper.getCanonicalURL(siteUrl, handle)} />
        </Helmet>
        <StoreProvider defaultStore={defaultStore} defaultStores={stores}>
          <GuestProvider shouldCreateGuest={false}>
            <BrowseTypeProvider browseType={browseType}>
              <AnalyticsProvider>
                <DineInProvider>
                  <OrderProvider orderId={orderId}>
                    <OnlineOrderPageContainer />
                  </OrderProvider>
                </DineInProvider>
              </AnalyticsProvider>
            </BrowseTypeProvider>
          </GuestProvider>
        </StoreProvider>
      </div>
    );
  }
}

export default withStyles(styles)(OrderPageRouter);
