import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import * as cartStrings from '@/cart/assets/strings';
import * as commonStrings from '@/common/assets/strings';
import * as greetStrings from '@/greet/assets/strings';
import { formatResources } from '@/i18n/formatResources';
import { languageDetector } from '@/i18n/languageDetector';
import * as locationStrings from '@/location/assets/strings';
import * as menuStrings from '@/menu/assets/strings';
import * as orderStrings from '@/orders/assets/strings';
import * as paymentStrings from '@/payment/assets/strings';
import * as signupStrings from '@/signup/assets/strings';
import * as storeStrings from '@/store/assets/strings';

const resources = [
  { namespace: 'cart', translations: cartStrings },
  { namespace: 'menu', translations: menuStrings },
  { namespace: 'common', translations: commonStrings },
  { namespace: 'greet', translations: greetStrings },
  { namespace: 'order', translations: orderStrings },
  { namespace: 'signup', translations: signupStrings },
  { namespace: 'location', translations: locationStrings },
  { namespace: 'store', translations: storeStrings },
  { namespace: 'payment', translations: paymentStrings },
];

export const init = () =>
  i18next
    .use(languageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: 'en',
      defaultNS: 'common',
      resources: formatResources(resources),
    });
