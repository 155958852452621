import React, { memo, useEffect } from 'react';

import URLHelper from '@/common/utils/URLHelper';
import { FailedPaymentRedirectPage } from '@/payment/mobile/components/FailedPaymentRedirectPage';
import { usePayment } from '@/payment/mobile/hooks/usePayment';
import FBStorage from '@/utils/FBStorage';

export const FailedPaymentRedirectPageContainer = memo(() => {
  const handleGoHomeClick = () => URLHelper.goHome();
  // const { paymentTransaction } = usePayment();
  // useEffect(() => {
  // if (paymentTransaction) {
  // paymentTransaction.id && FBStorage.clearOrder(paymentTransaction.id);
  // }
  // }, []);
  return <FailedPaymentRedirectPage onHomeClick={handleGoHomeClick} />;
});
