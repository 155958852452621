// @ts-ignore
import React, { memo } from 'react';

interface Props {
  redirectUrl: string;
  requestParams: any;
}

export const PaymentPageCards = memo<Props>(({ redirectUrl, requestParams }) => {
  // const merchantIdentifier = 'c962cf8f';

  const renderInputForm = (name: string, value: string) => (
    <input key={name} type="hidden" name={name} value={value} />
  );

  const renderScript = () => {
    // @ts-ignore
    return document?.frm && <script type="text/javascript">{document.frm.submit()}</script>;
  };

  return (
    <div>
      <body>
        <form action={redirectUrl} method="post" name="frm">
          {Object.keys(requestParams).map((name: string) =>
            renderInputForm(name, requestParams[name]),
          )}
          {renderScript()}
        </form>
        {/*<Loader />*/}
      </body>
    </div>
  );
});
