import { List } from '@material-ui/core';
import React from 'react';

import { Space } from '@/common/components/Space';
import MenuItemHelper from '@/menu/utils/MenuItemHelper';
import { useStore } from '@/store/hooks/useStore';
import { MenuCategory, MenuItem, TimeRange } from '@/types';

import MenuItemRow from './MenuItemRow';

interface Props {
  defaultPic: string;
  isCategoryAvailable: boolean;
  showCategoryTempUnavailable: boolean;
  categoryAvailability: Opt<TimeRange>;
  setShowStartCheck?: (value: boolean) => void;
  category: MenuCategory;
}

const MenuItemsList = ({
  defaultPic,
  categoryAvailability,
  showCategoryTempUnavailable,
  isCategoryAvailable,
  setShowStartCheck,
  category,
}: Props) => {
  let countImages = 0;
  const { selectedStore } = useStore();
  const menuItems =
    category.items.filter(item => !MenuItemHelper.isItemHidden(item, selectedStore?.id)) ?? [];
  menuItems.forEach(item => {
    if (item.profilePic) {
      countImages = countImages + 1;
    }
  });

  const showDefaultPic = MenuItemHelper.shouldShowDefaultPic(menuItems);

  return (
    <List component="div">
      {menuItems.map((item, index) => (
        <MenuItemRow
          key={item.id}
          category={category}
          menuItem={item}
          divider={index < menuItems.length - 1}
          defaultPic={defaultPic}
          setShowStartCheck={setShowStartCheck}
          showCategoryTempUnavailable={showCategoryTempUnavailable}
          isCategoryAvailable={isCategoryAvailable}
          categoryAvailability={categoryAvailability}
          showDefaultPic={showDefaultPic}
        />
      ))}

      <Space height={5} />
    </List>
  );
};

export default MenuItemsList;
