import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import TextTruncate from 'react-text-truncate'; // recommend

import MenuItemHelper from '@/menu/utils/MenuItemHelper';
import { useRTL } from '@/theme/hooks/useRTL';
import { MenuItem } from '@/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      alignItems: 'flex-start',
    },
    img: {
      height: 150,
      width: 150,
      marginRight: 15,
      borderRadius: 15,
      marginTop: 10,
      objectFit: 'contain',
    },
    nameContainer: {
      marginBottom: 5,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    caloriesText: {
      margin: '15px 10px',
    },
    card: {
      maxWidth: 450,
      borderRadius: 8,
    },
    media: {
      height: 0,
      paddingTop: '80.25%', // 16:9
    },
    subtitle: {
      color: theme.palette.primary.light,
      fontSize: 15,
    },
    greyImg: {
      filter: 'grayscale(100%)',
      paddingTop: '80.25%', // 16:9
      opacity: 0.5,
    },
  }),
);

interface Props {
  menuItem: MenuItem;
  className?: string;
  defaultPic: string;
}

const MenuItemCard = ({ menuItem, className, defaultPic }: Props) => {
  const classes = useStyles();
  const rtl = useRTL();

  const renderCards = () => {
    const { name, description, price, backgroundPic } = menuItem;
    const itemName = rtl ? name.ar : name.en;
    const itemDescription = rtl ? description?.ar : description?.en;
    return (
      <Card className={classNames(className, classes.card)}>
        <CardMedia
          className={backgroundPic ? classes.media : classes.greyImg}
          image={backgroundPic ?? defaultPic}
          title="Paella dish"
        />
        <CardHeader
          title={itemName}
          subheader={
            <TextTruncate
              containerClassName={classes.subtitle}
              line={7}
              element="span"
              truncateText="..."
              text={itemDescription}
            />
          }
        />
        <CardContent>
          {/* Title */}
          <Typography variant="h6">{`${price} SAR`}</Typography>
        </CardContent>
      </Card>
    );
  };

  return renderCards();
};

export default MenuItemCard;
