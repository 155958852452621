import { useCallback, useState } from 'react';

import Api from '../../api/Api';

export const useCancelOrder = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);

  const cancelOrder = useCallback(async (orderId: string) => {
    setLoading(true);
    try {
      await Api.cancelOrder(orderId);
      setLoading(false);
      setSuccess(true);
    } catch (e) {
      setLoading(false);
      setError(e.message);
      setSuccess(false);
    }
  }, []);

  return {
    cancelOrder,
    loading,
    error,
    success,
  };
};
