import React, { memo, useRef, useState } from 'react';

import MenuView from '@/menu/desktop/components/MenuView';
import StorePageHeader, { StorePageMethods } from '@/store/desktop/components/StorePageHeader';

interface Props {
  showAppBar: boolean;
}

export const StorePage = memo<Props>(({ showAppBar = true }) => {
  const storePageMethods = useRef<StorePageMethods>();
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(true);

  const toggleDrawer = () => {
    if (storePageMethods.current) {
      storePageMethods.current!.toggleDrawer();
    }
  };

  return (
    <>
      {showAppBar && (
        <StorePageHeader
          ref={storePageMethods}
          isSideMenuOpen={isSideMenuOpen}
          setIsSideMenuOpen={setIsSideMenuOpen}
        />
      )}
      <MenuView
        showAppBar={showAppBar}
        isSideMenuOpen={isSideMenuOpen}
        toggleDrawer={toggleDrawer}
      />
    </>
  );
});
