import { Button, ButtonProps, createStyles, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React, { memo } from 'react';

import { Loader } from '@/common/components/Loader';

interface Props extends ButtonProps {
  onClick: () => void;
  loading?: boolean;
  containerStyle?: string;
  buttonStyle?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
    },
    button: {
      fontWeight: 'bold',
      bottom: 1,
    },
  }),
);

const CustomButton = memo<Props>(({ onClick, loading, containerStyle, buttonStyle, ...rest }) => {
  const classes = useStyles();
  return (
    <div className={classNames(classes.container, containerStyle)}>
      {loading ? (
        <Loader />
      ) : (
        <Button
          fullWidth
          onClick={onClick}
          className={classNames(classes.button, buttonStyle)}
          {...rest}
        />
      )}
    </div>
  );
});

export default CustomButton;
