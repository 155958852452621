// @ts-nocheck
import './styles.css';
declare const google: any;
import { Fade, Modal } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import React, { Component } from 'react';
import { isAndroid } from 'react-device-detect';
import Geocode from 'react-geocode';
import Autocomplete from 'react-google-autocomplete';
import { GoogleMap, InfoWindow, Marker, withGoogleMap, withScriptjs } from 'momos-react-google-maps';

import CustomButton from '@/common/components/CustomButton';
import HeaderContainer from '@/common/containers/HeaderContainer';

Geocode.setApiKey('AIzaSyBgIg4fYEPiHbeC22F-5L7X16NOBDZmxbo');
// Geocode.enableDebug();

class LocationInputMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      center: null,
      streetNumber: '',
      zoom: props.zoom,
      city: '',
      area: '',
      state: '',
      street: '',
      neighborhood: '',
      zipCode: '',
      country: '',
      mapPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng,
      },
      markerPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng,
      },
    };
  }

  refMap = React.createRef();

  /**
   * Get the current address from the default map position and set those values in the state
   */
  componentDidMount() {
    this.props.onRef(this);
    this.updateMap(this.state.mapPosition.lat, this.state.mapPosition.lng);
    // document.body.style.overflow = 'hidden';
    // document.body.style.overflowY = 'hidden';
    // document.body.classList.add('no-sroll');
  }

  componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<{}>, snapshot?: any) {
    this.props.onRef(this);
  }

  updateMap(lat, lng) {
    Geocode.fromLatLng(lat, lng).then(
      response => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          zipCode = this.getZipCode(addressArray),
          street = this.getStreet(addressArray),
          neighborhood = this.getNeighborhood(addressArray),
          streetNumber = this.getStreetNumber(addressArray),
          country = this.getCountry(addressArray),
          state = this.getState(addressArray);

        this.setState({
          address: address ? address : '',
          area: area ? area : '',
          city: city ? city : '',
          state: state ? state : '',
          neighborhood: neighborhood ? neighborhood : '',
          street: street ? street : '',
          streetNumber: streetNumber ? streetNumber : '',
          zipCode: zipCode ? zipCode : '',
          country: country ? country : '',
          markerPosition: {
            lat,
            lng,
          },
          mapPosition: {
            lat,
            lng,
          },
        });
      },
      error => {
        console.error(error);
      },
    );
  }
  /**
   * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
   *
   * @param nextProps
   * @param nextState
   * @return {boolean}
   */
  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.markerPosition.lat !== this.props.center.lat ||
      this.state.address !== nextState.address ||
      this.state.city !== nextState.city ||
      this.state.area !== nextState.area ||
      this.state.state !== nextState.state
    ) {
      return true;
    } else if (this.props.center.lat === nextProps.center.lat) {
      return false;
    }
  }
  /**
   * Get the city and set the city input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getCity = addressArray => {
    let city = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        addressArray[i].types[1] &&
        'locality' === addressArray[i].types[0] &&
        'political' === addressArray[i].types[1]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };
  /**
   * Get the area and set the area input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getArea = addressArray => {
    let area = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            'sublocality_level_1' === addressArray[i].types[j] ||
            'locality' === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };

  getNeighborhood = addressArray => {
    let nei = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        addressArray[i].types[1] &&
        'sublocality_level_1' === addressArray[i].types[0] &&
        'sublocality' === addressArray[i].types[1]
      ) {
        nei = addressArray[i].long_name;
        return nei;
      }
    }
  };

  getStreetNumber = addressArray => {
    let streetNumber = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && 'street_number' === addressArray[i].types[0]) {
        streetNumber = addressArray[i].long_name;
        return streetNumber;
      }
    }
  };

  getStreet = addressArray => {
    let street = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && 'route' === addressArray[i].types[0]) {
        street = addressArray[i].long_name;
        return street;
      }
    }
  };

  getCountry = addressArray => {
    let country = '';
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0] && 'country' === addressArray[i].types[0]) {
          country = addressArray[i].long_name;
          return country;
        }
      }
    }
  };

  getZipCode = addressArray => {
    let zipCode = '';
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0] && 'postal_code' === addressArray[i].types[0]) {
          zipCode = addressArray[i].long_name;
          return zipCode;
        }
      }
    }
  };

  /**
   * Get the address and set the address input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getState = addressArray => {
    let state = '';
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          'administrative_area_level_1' === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };
  /**
   * And function for city,state and address input
   * @param event
   */
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  /**
   * This Event triggers when the marker window is closed
   *
   * @param event
   */
  onInfoWindowClose = event => {};

  /**
   * When the marker is dragged you get the lat and long using the functions available from event object.
   * Use geocode to get the address, city, area and state from the lat and lng positions.
   * And then set those values in the state.
   *
   * @param event
   */
  handleOnDragEnd = () => {
    const newLat = this.refMap.getCenter().lat(),
      newLng = this.refMap.getCenter().lng();

    Geocode.fromLatLng(newLat, newLng).then(
      response => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          zipCode = this.getZipCode(addressArray),
          street = this.getStreet(addressArray),
          neighborhood = this.getNeighborhood(addressArray),
          streetNumber = this.getStreetNumber(addressArray),
          country = this.getCountry(addressArray),
          state = this.getState(addressArray);

        this.setState({
          address: address ? address : '',
          area: area ? area : '',
          city: city ? city : '',
          state: state ? state : '',
          neighborhood: neighborhood ? neighborhood : '',
          street: street ? street : '',
          streetNumber: streetNumber ? streetNumber : '',
          zipCode: zipCode ? zipCode : '',
          country: country ? country : '',
          markerPosition: {
            lat: newLat,
            lng: newLng,
          },
          mapPosition: {
            lat: newLat,
            lng: newLng,
          },
        });
      },
      error => {
        console.error(error);
      },
    );
  };

  onPlaceSelected = (place, refMap) => {
    const address = place.formatted_address,
      addressArray = place.address_components,
      city = this.getCity(addressArray),
      area = this.getArea(addressArray),
      state = this.getState(addressArray),
      zipCode = this.getZipCode(addressArray),
      street = this.getStreet(addressArray),
      country = this.getCountry(addressArray),
      neighborhood = this.getNeighborhood(addressArray),
      streetNumber = this.getStreetNumber(addressArray),
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();
    // this.refMap.center = {
    //   lat: latValue,
    //   lng: lngValue,
    // };
    this.refMap.panTo(new window.google.maps.LatLng(latValue, lngValue));

    // Set these values in the state.
    this.setState({
      input: street,
      address: address ? address : '',
      area: area ? area : '',
      city: city ? city : '',
      state: state ? state : '',
      neighborhood: neighborhood ? neighborhood : '',
      street: street ? street : '',
      streetNumber: streetNumber ? streetNumber : '',
      zipCode: zipCode ? zipCode : '',
      country: country ? country : '',
      markerPosition: {
        lat: latValue,
        lng: lngValue,
      },
      mapPosition: {
        lat: latValue,
        lng: lngValue,
      },
    });
  };

  handleCenterChanged = () => {
    // const center = this.refMap.current.getCenter(); //get map center
    // this.setState({
    //   ...this.state,
    //   center: { lat: this.refMap.getCenter().lat(), lng: this.refMap.getCenter().lng() },
    // });
    // console.log('new center', this.state.center);
  };

  // handleLoad(map) {
  //   this.refMap.current = map;
  // }

  // handleOnDragEnd = () => {
  //   console.log(
  //     'this.refMap.current handleCenterChanged',
  //     this.refMap.getCenter().lat(),
  //     this.refMap.getCenter().lng(),
  //   );
  //   this.setState({
  //     markerPosition: {
  //       lat: this.refMap.getCenter().lat(),
  //       lng: this.refMap.getCenter().lng(),
  //     },
  //     mapPosition: {
  //       lat: this.refMap.getCenter().lat(),
  //       lng: this.refMap.getCenter().lng(),
  //     },
  //   });
  // };

  handleZoomChanged = () => {
    this.setState(
      {
        zoom: this.refMap.getZoom(),
      },
      () => {},
    );
    this.handleOnDragEnd();
  };

  handleMapMounted(map) {
    this._map = map;
  }

  getMapHeight() {
    if (screen.height <= 667) {
      return '83vh';
    } else if (screen.height <= 736) {
      return '83vh';
    } else if (screen.height <= 740) {
      return '83vh';
    } else if (screen.height <= 896) {
      return '83vh';
    }
    return '83vh';
  }

  getSearchTop() {
    if (screen.height <= 667) {
      return '15.5%';
    } else if (screen.height <= 736) {
      return '14%';
    } else if (isAndroid && screen.height <= 740) {
      return '19.5%';
    } else if (isAndroid && screen.height <= 810) {
      return '18.0%';
    } else if (isAndroid && screen.height <= 847) {
      return '17%';
    } else if (screen.height <= 896) {
      return '14%';
    }
    return '14%';
  }

  getCenterMarkerTop() {
    if (screen.height <= 667) {
      return '46.0%';
    } else if (screen.height <= 736) {
      return '46.5%';
    } else if (isAndroid && screen.height <= 740) {
      return '46.5%';
    } else if (isAndroid && screen.height <= 810) {
      return '46.5%';
    } else if (isAndroid && screen.height <= 847) {
      return '47.0%';
    } else if (screen.height <= 896) {
      return '47%';
    }
    return '47.0%';
  }

  render() {
    const AsyncMap = withScriptjs(
      withGoogleMap(props => (
        <GoogleMap
          className="google-map"
          ref={googleMap => {
            if (!googleMap) {
              return;
            }
            this.refMap = googleMap;
          }}
          google={this.props.google}
          onDragEnd={this.handleOnDragEnd}
          defaultZoom={this.state.zoom}
          onZoomChanged={this.handleZoomChanged}
          options={{
            gestureHandling: 'greedy',
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            zoomControlOptions: { position: 4 },
          }}
          defaultCenter={{
            lat: this.state.mapPosition.lat,
            lng: this.state.mapPosition.lng,
          }}>
          <Marker
            google={this.props.google}
            name={'Dolores park'}
            draggable={false}
            clickable={true}
            onDragEnd={this.onMarkerDragEnd}
            position={{ lat: this.state.markerPosition.lat, lng: this.state.markerPosition.lng }}>
            <InfoWindow
              onClose={this.onInfoWindowClose}
              position={{
                lat: this.state.markerPosition.lat + 0.0018,
                lng: this.state.markerPosition.lng,
              }}>
              <div>
                <span style={{ padding: 0, margin: 0 }}>{this.state.address}</span>
              </div>
            </InfoWindow>
          </Marker>
          <LocationOnIcon
            color="secondary"
            style={{
              fontSize: 37,
              position: 'fixed',
              left: '50%',
              top: this.getCenterMarkerTop(),
              transform: 'translate(-50%, -50%)',
            }}
          />
          <Autocomplete
            autoCorrect={false}
            className="pac-container"
            style={{
              width: '100vw',
              height: '50px',
              alignSelf: 'center',
              paddingLeft: 10,
              paddingRight: 10,
              marginTop: '2px',
              elevation: 0,
              shadowColor: 'transparent',
              top: this.getSearchTop(),
              position: 'fixed',
              boxShadow: 'rgba(0, 0, 0, 0.0) 0px 0px',
            }}
            placeholder={this.props.searchTextPlaceHolder}
            onPlaceSelected={this.onPlaceSelected}
            types={['geocode']}
          />
        </GoogleMap>
      )),
    );
    let map;
    if (this.props.center.lat !== undefined) {
      map = (
        <div>
          <AsyncMap
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBgIg4fYEPiHbeC22F-5L7X16NOBDZmxbo&libraries=places"
            loadingElement={<div style={{ width: '100vw' }} />}
            containerElement={
              <div
                style={{
                  height: '100vh',
                  width: '100%',
                  position: 'fixed',
                  left: '50%',
                  top: '50%',
                  zIndex: 1,
                  transform: 'translate(-50%, -50%)',
                }}
              />
            }
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
      );
    } else {
      map = <div style={{ height: this.props.height }} />;
    }

    return (
      <Modal
        style={{
          height: '100%',
        }}
        closeAfterTransition
        fullScreen
        fullWidth
        open={true}>
        <Fade in={true}>
          <div
            dir={this.props.rtl ? 'rtl' : 'ltr'}
            style={{
              backgroundColor: this.props.backgroundColor ? this.props.backgroundColor : 'black',
              height: '100%',
            }}>
            <HeaderContainer
              disableSelection
              hideSelection
              fixed
              onCloseClick={this.props.onCloseClick}
            />
            <div>{map}</div>
            <div
              style={{
                bottom: -1,
                flexDirection: 'row',
                display: 'flex',
                position: 'absolute',
                zIndex: 20,
              }}>
              <div style={{ width: '50vw' }}>
                <CustomButton
                  loading={this.props.loadingMyPosition}
                  style={{ borderRadius: 0, width: '50vw' }}
                  size="large"
                  color="primary"
                  variant="contained"
                  startIcon={<MyLocationIcon />}
                  onClick={this.props.locateMe}>
                  {this.props.locateButtonTitle}
                </CustomButton>
              </div>
              <CustomButton
                size="large"
                style={{ borderRadius: 0, width: '50vw' }}
                color="secondary"
                variant="contained"
                onClick={() => this.props.onClick(this.state)}>
                {this.props.buttonTitle}
              </CustomButton>
            </div>
          </div>
        </Fade>
      </Modal>
    );
  }
}
export default LocationInputMap;
