import React, { memo, useEffect, useState } from 'react';

import { useFetchOrder } from '@/orders/hooks/useFetchOrder';
import { Order } from '@/types';

import { OrderContext } from './OrderContext';

interface Props {
  children: React.ReactChild;
  orderId: string;
}

export const OrderProvider = memo<Props>(({ children, orderId }) => {
  const { fetchOrder } = useFetchOrder();
  const [order, setOrder] = useState<Order>();

  const refetchOrder = async () => {
    setOrder(await fetchOrder(orderId));
  };

  useEffect(() => {
    refetchOrder();
  }, []);

  return <OrderContext.Provider value={{ order, setOrder, refetchOrder }}>{children}</OrderContext.Provider>;
});
