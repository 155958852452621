import React from 'react';

import { PayfortSettings, PaymentTransaction } from '@/types';
import { isBrowser } from '@/utils/isBrowser';

export interface Payfort {
  merchantId: string;
  accessCode: string;
  passPhrase: string;
}
interface PaymentProps {
  merchantIdentifier?: string;
  accessCode?: string;
  paymentTransaction: Opt<PaymentTransaction>;
  error: Opt<string>;
  payfort: PayfortSettings;
}
export const PaymentContext = React.createContext<PaymentProps>({
  paymentTransaction: undefined,
  error: undefined,
  payfort: {
    merchantId: '',
    applePayMerchantAccessCode: '',
    applePayMerchantId: '',
    accessCode: '',
    passPhrase: '',
  },
});
