import { Buffer } from 'buffer';

export default class StringsHelper {
  /**
   * Generate a random id.
   *
   * @returns {string}
   */
  public static generateGuid(): string {
    const s4 = () =>
      Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);

    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
  }

  /** Function that count occurrences of a substring in a string;
   * @param {String} string               The string
   * @param {String} subString            The sub string to search for
   * @param {Boolean} [allowOverlapping]  Optional. (Default:false)
   *
   * @author Vitim.us https://gist.github.com/victornpb/7736865
   * @see Unit Test https://jsfiddle.net/Victornpb/5axuh96u/
   * @see http://stackoverflow.com/questions/4009756/how-to-count-string-occurrence-in-string/7924240#7924240
   */
  public static occurrences(string: string, subString: string, allowOverlapping: boolean): number {
    string += '';
    subString += '';
    if (subString.length <= 0) {
      return string.length + 1;
    }

    let n = 0,
      pos = 0,
      step = allowOverlapping ? 1 : subString.length;

    while (true) {
      pos = string.indexOf(subString, pos);
      if (pos >= 0) {
        ++n;
        pos += step;
      } else {
        break;
      }
    }
    return n;
  }

  /**
   * Encode a given string to base64.
   *
   * @param target The string to convert
   * @returns {string}
   */
  public static encodeToB64(target: string) {
    return Buffer.from(target).toString('base64');
  }

  /**
   * Return a string with a truncated text (i.e. 'Long String...').
   * @param text
   * @param maxLength
   */
  public static truncateTextWithLength(text: string | undefined, maxLength: number): string {
    if (text === undefined) {
      return '';
    }
    if (text.length > maxLength) {
      return text.substr(0, maxLength).concat('...');
    }
    return text;
  }

  /**
   * Format the given string from something like '$id' or 'id_ to 'id'.
   *
   * @param property The property name
   * @returns {string} The formatted property
   */
  public static formatDealbreakerName(dbName: string): string {
    return dbName.replace('_', ' ');
  }

  /**
   * Is the given string null/undefined or empty.
   *
   * @param value The string value
   * @return True if null/undefined/empty
   */
  public static isNullOrEmpty(value?: string): value is undefined {
    return !value || value.trim() === '';
  }

  public static replaceAll(str: string, search: string, replace: string) {
    return str.split(search).join(replace);
  }
}
