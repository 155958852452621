import { CircularProgress, createStyles, makeStyles } from '@material-ui/core';
import { CircularProgressProps } from '@material-ui/core/CircularProgress/CircularProgress';
import React, { memo } from 'react';

interface Props extends CircularProgressProps {}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

export const Loader = memo<Props>(({ ...props }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <CircularProgress {...props} color="secondary" />
    </div>
  );
});
