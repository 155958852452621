import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { memo } from 'react';

import { EmptyView } from '@/common/components/EmptyView';
import { Loader } from '@/common/components/Loader';
import { FooterContainer } from '@/common/containers/FooterContainer';
import HeaderContainer from '@/common/containers/HeaderContainer';
import { t } from '@/i18n/translate';
import { OrdersList } from '@/orders/mobile/components/OrdersList';
import { Order } from '@/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      backgroundColor: theme.palette.type === 'light' ? 'white' : theme.palette.primary.dark,
    },
    space: {
      marginTop: theme.spacing(9),
    },
  }),
);

interface Props {
  orders: Order[];
  loading: boolean;
}

export const OnlineOrdersPage = memo<Props>(({ loading, orders }) => {
  const classes = useStyles();

  const renderContent = () => {
    if (loading && !orders) {
      return <Loader />;
    }

    if (!loading && orders.length === 0) {
      return <EmptyView text={t('order:empty')} />;
    }
    console.log('OnlineOrdersPage', orders);
    return (
      <>
        <div className={classes.space} />
        <OrdersList fullScreen orders={orders} />
      </>
    );
  };

  return (
    <div className={classes.root}>
      <HeaderContainer fixed hideSelection />
      {renderContent()}
      <FooterContainer />
    </div>
  );
});
