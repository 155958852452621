import React, { memo, useState } from 'react';

import { useShowGreeting } from '@/greet/hooks/useShowGreeting';
import { DineInGreetView } from '@/greet/mobile/components/DineInGreetView';
import { useStore } from '@/store/hooks/useStore';
import { useRTL } from '@/theme/hooks/useRTL';

export const DineInGreetViewContainer = memo(() => {
  const { showGreeting, setShowGreeting } = useShowGreeting();
  const [openHowItWorks, setOpenHowItWorks] = useState(false);
  const rtl = useRTL();
  const { selectedStore } = useStore();
  if (!selectedStore) {
    return <></>;
  }
  const { profilePic, name } = selectedStore!!;

  const handleHowItWorksClick = () => setOpenHowItWorks(true);
  const handleCloseHowItWorksClick = () => setOpenHowItWorks(false);
  const handleClosePress = () => setShowGreeting(false);

  return (
    <DineInGreetView
      rtl={rtl}
      headerProfilePic={profilePic}
      headerTitle={rtl ? name.ar ?? '' : name.en}
      onCloseHowItWorks={handleCloseHowItWorksClick}
      openHowItWorks={openHowItWorks}
      open={showGreeting}
      onClosePress={handleClosePress}
      onHowItWorksClick={handleHowItWorksClick}
    />
  );
});
