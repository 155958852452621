import React, { memo } from 'react';

import { useBrowseType } from '@/browse/hooks/useBrowseType';
import { BrowseType } from '@/const/enums';
import { DineInGreetViewContainer } from '@/greet/mobile/containers/DineInGreetViewContainer';
import { OnlineOrderGreetViewContainer } from '@/greet/mobile/containers/OnlineOrderGreetViewContainer';
import { useStore } from '@/store/hooks/useStore';

export const GreetViewContainer = memo(() => {
  const { type: browseType } = useBrowseType();
  const { selectedStore } = useStore();
  if (!selectedStore) {
    return <></>;
  }
  const { isReceivingOrders } = selectedStore!!;
  if ((!isReceivingOrders && browseType === BrowseType.online) || browseType === BrowseType.basic) {
    return <></>;
  }
  return browseType === BrowseType.online ? (
    <OnlineOrderGreetViewContainer />
  ) : (
    <DineInGreetViewContainer />
  );
});
