import { createStyles, Dialog, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { memo } from 'react';
import Lottie from 'react-lottie';

import * as animationData from '@/assets/images/discount.json';
import Button from '@/common/components/CustomButton';
import { t } from '@/i18n/translate';
import { useStore } from '@/store/hooks/useStore';
import { useRTL } from '@/theme/hooks/useRTL';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      padding: theme.spacing(4),
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    formsContainer: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    form: {
      marginBottom: theme.spacing(2),
    },
    button: {
      fontWeight: 'bold',
      height: '7%',
      marginTop: theme.spacing(2),
      width: '100%',
      bottom: 1,
    },
    formControl: {
      margin: theme.spacing(1),
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    img: {
      maxWidth: '25%',
      borderRadius: 5,
      marginBottom: 40,
    },
    title: {
      marginTop: theme.spacing(2),
    },
    text: {
      marginTop: theme.spacing(1),
    },
  }),
);

interface Props {
  open: boolean;
  onClosePress: () => void;
}

export interface SignupViewMethods {}

export const DiscountDialog = memo<Props>(({ open, onClosePress }) => {
  const classes = useStyles();
  const rtl = useRTL();
  const { selectedStore: store } = useStore();
  const { theme } = store?.settings || {};
  const { basic } = theme || {};

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const renderHeader = () => (
    <div className={classes.header}>
      <img alt="Safary" src={store?.profilePic} className={classes.img} />
    </div>
  );
  return (
    <Dialog open={open} onClose={onClosePress} onEscapeKeyDown={onClosePress}>
      <div className={classes.dialog} dir={rtl ? 'rtl' : 'ltr'}>
        {renderHeader()}
        <Lottie options={defaultOptions} height={150} width={150} />
        <Typography align={'center'} className={classes.text} variant="h6" color="textPrimary">
          {t('store:discount')}
        </Typography>
        <Button
          variant="contained"
          size="large"
          onClick={onClosePress}
          style={
            basic?.color
              ? { backgroundColor: basic?.color, color: 'white', marginTop: 30 }
              : { marginTop: 30 }
          }>
          {t('common:ok')}
        </Button>
      </div>
    </Dialog>
  );
});

export default DiscountDialog;
