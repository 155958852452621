import { useCallback, useEffect, useState } from 'react';

import { Event } from '@/analytics/Event';
import EventProperties from '@/analytics/EventProperties';
import { useAnalytics } from '@/analytics/hooks/useAnalytics';
import { useCart } from '@/cart/hooks/useCart';
import CartHelper from '@/cart/utils/CartHelper';
import { UpdateCartType } from '@/const/enums';
import { useGuest } from '@/guest/hooks/useGuest';
import { useStore } from '@/store/hooks/useStore';
import StoreHelper from '@/store/utils/StoreHelper';
import { Cart, MenuItem, SimilarItemInCart } from '@/types';
import FBStorage from '@/utils/FBStorage';
import ObjectHelper from '@/utils/ObjectHelper';

export const useUpdateCart = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const { cart, setCart } = useCart();
  const { logEvent } = useAnalytics();
  const { selectedStore } = useStore();

  const updateCart = useCallback(
    async (items: MenuItem[], type: UpdateCartType) => {
      if (!selectedStore) {
        return;
      }
      try {
        setLoading(true);

        // Copy the items
        let currentItems: MenuItem[] = cart ? Object.assign([], cart!!.items) : [];

        switch (type) {
          case UpdateCartType.Add:
            // If we have one item in the cart,
            // check to see if we have a similar item in that cart.
            let similarItem: SimilarItemInCart = { isSimilar: false, index: 0 };
            const similarItems = items!!.map(item => CartHelper.doesItemExistInCart(cart, item));
            if (similarItems && similarItems.length > 0) {
              similarItem = similarItems[0];
            }
            if (similarItem && similarItem.isSimilar) {
              currentItems[similarItem.index].quantity =
                currentItems[similarItem.index].quantity + 1;
            } else {
              currentItems = currentItems.concat(items!!);
            }
            items.forEach(item =>
              logEvent(Event.AddItemToCart, EventProperties.getItemProps(item, selectedStore)),
            );
            break;
          case UpdateCartType.Remove:
            for (const currentItem of currentItems) {
              for (const newItem of items!!) {
                if (currentItem.secondaryId === newItem!!.secondaryId) {
                  const index = currentItems.indexOf(currentItem, 0);
                  currentItems.splice(index, 1);
                  break;
                }
              }
            }
            logEvent(Event.RemoveItemToCart);
            break;
          case UpdateCartType.Update:
            currentItems.forEach((eachItem: MenuItem, index: number) => {
              for (const newItem of items!!) {
                if (newItem && newItem!!.secondaryId === eachItem.secondaryId) {
                  currentItems[index] = newItem;
                }
              }
            });
            break;
          case UpdateCartType.ClearAndAdd:
            currentItems = [];
            currentItems = currentItems.concat(items!!);
            break;
          case UpdateCartType.Clear:
            currentItems = [];
            break;
        }
        // Update the cart
        const cartTemp: Cart = {
          items: currentItems,
          store: selectedStore,
        };

        FBStorage.setCart(cartTemp);
        const newCart = ObjectHelper.deepCopy(cartTemp);
        if (setCart) {
          setCart({ ...newCart!! });
        }
        setTimeout(() => {
          setSuccess(true);
          setLoading(false);
        }, 500);
      } catch (e) {
        setError(e.message);
        setSuccess(false);
      }
    },
    [cart, setCart, selectedStore],
  );

  return {
    updateCart,
    loading,
    error,
    success,
  };
};
