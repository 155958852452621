import React, { memo, useEffect, useState } from 'react';

import { useBrowseType } from '@/browse/hooks/useBrowseType';
import { BrowseType, OrderStatus, OrderType } from '@/const/enums';
import OrderCard from '@/orders/mobile/components/OrderCard';
import { OrderView } from '@/orders/mobile/components/OrderView';
import OrdersHelper from '@/orders/utils/OrdersHelper';
import { useRTL } from '@/theme/hooks/useRTL';
import { Order } from '@/types';

interface Props {
  order: Order;
  showMoreInfo?: boolean;
}

export const OrderItemContainer = memo<Props>(({ order, showMoreInfo = true }) => {
  const [showOrderView, setShowOrderView] = useState(false);
  const { type: browseType } = useBrowseType();
  const rtl = useRTL();

  const handleMoreInfoClick = () => {
    goToOrderPage();
    // if (browseType === BrowseType.online) {
    //   goToOrderPage();
    // } else {
    //   setShowOrderView(true);
    // }
  };

  useEffect(() => {}, [browseType]);

  const goToOrderPage = () => {
    const orderPage = `${window.location.pathname.slice(0, -'orders/'.length)}/order/${order?.id}${
      window.location.search
    }`;
    window.location.assign(orderPage);
  };

  return (
    <>
      <OrderCard
        rtl={rtl}
        hideTotal={browseType === BrowseType.dinein}
        showMoreInfo={showMoreInfo}
        onMoreInfoClick={handleMoreInfoClick}
        key={order.id}
        eta={OrdersHelper.getETAStringAndIsETAPassed(order).etaStr}
        order={order}
        showETA={
          order.storeETA !== undefined &&
          (order.type === OrderType.Pickup || order.type === OrderType.DineIn) &&
          order.status === OrderStatus.Accepted
        }
      />
      <OrderView open={showOrderView} onClosePress={() => setShowOrderView(false)} order={order} />
    </>
  );
});
