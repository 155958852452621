import {
  Button,
  createMuiTheme,
  createStyles,
  Divider,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { memo } from 'react';

import CartMenuItemsList from '@/cart/mobile/components/CartMenuItemsList';
import CartHelper from '@/cart/utils/CartHelper';
import CustomButton from '@/common/components/CustomButton';
import { FooterContainer } from '@/common/containers/FooterContainer';
import HeaderContainer from '@/common/containers/HeaderContainer';
import { uuid } from '@/common/utils/uuid';
import { Language, OrderStatus, OrderType, PickupType } from '@/const/enums';
import { t } from '@/i18n/translate';
import LocationHelper from '@/location/utils/LocationHelper';
import { OrderStepper } from '@/orders/mobile/components/OrderStepper';
import { IFrameOrderTrackingPageContainer } from '@/orders/mobile/containers/IFrameOrderTrackingPageContainer';
import PaymentHelper from '@/payment/utils/PaymentHelper';
import { useStore } from '@/store/hooks/useStore';
import StoreHelper from '@/store/utils/StoreHelper';
import { getLang } from '@/theme/utils/getLangDirection';
import { Order, Tax } from '@/types';
import DateHelper from '@/utils/DateHelper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      backgroundColor: theme.palette.type === 'light' ? 'white' : theme.palette.primary.dark,
    },
    orders: {
      marginBottom: theme.spacing(3),
      height: '50vh',
      overflowY: 'scroll',
    },
    button: {
      fontWeight: 'bold',
      borderRadius: 0,
    },
    bottomContainer: {
      width: '100%',
      bottom: 0,
      justifyContent: 'stretch',
      flex: 1,
      display: 'flex',
      zIndex: 1,
    },
    stretch: {
      width: '50%',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    paymentIcon: {
      marginRight: theme.spacing(1),
    },
    paymentContainer: {
      alignItems: 'center',
      display: 'flex',
    },
    titleContainer: {
      marginTop: theme.spacing(9),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      flex: 1,
      display: 'flex',
      justifyContent: 'space-between',
    },
    listItem: {
      alignItems: 'flex-start',
    },
    clickable: {
      textDecoration: 'underline',
      color: theme.palette.secondary.main,
      cursor: 'pointer',
    },
    footerSpace: {
      height: 55,
    },
    feedbackButton: {
      color: 'white',
    },
  }),
);

interface Props {
  order: Order;
  activeStep: number;
  onCancelPress: () => void;
  showCancel: boolean;
  steps: string[];
  stepsContent: string[];
  onCallStoreClick: () => void;
  onStoreMapClick: () => void;
  onDeliveryMapClick: () => void;
  onContactCourier: () => void;
  loading: boolean;
  showTrackingLink: boolean;
  rtl: boolean;
  trackingModalRef?: React.Ref<any>;
  onTrackingLinkClick: () => void;
  tax: Opt<Tax>;
  showRateCourier?: boolean;
  showCallCourier?: boolean;
  showReadyForPickup?: boolean;
  onReadyForPickupClick: () => void;
  loadingReadyForPickup: boolean;
  arrivedAtStoreButtonLabel: string;
  disableReadyForPickupButton?: boolean;
  showFeedbackButton: boolean;
  onFeedbackClick: () => void;
}

export const OnlineOrderPage = memo<Props>(
  ({
    showFeedbackButton,
    order,
    activeStep,
    onCancelPress,
    showCancel,
    steps,
    stepsContent,
    onCallStoreClick,
    onStoreMapClick,
    onDeliveryMapClick,
    loading,
    showTrackingLink,
    trackingModalRef,
    onContactCourier,
    rtl,
    onTrackingLinkClick,
    tax,
    showRateCourier,
    showCallCourier,
    showReadyForPickup,
    onReadyForPickupClick,
    loadingReadyForPickup,
    arrivedAtStoreButtonLabel,
    disableReadyForPickupButton,
    onFeedbackClick,
  }) => {
    const classes = useStyles();
    const theme = useTheme();
    const iconColor = theme.palette.type === 'dark' ? '#fafafa' : '#161617';
    const { discount } = useStore();
    const lang = getLang();

    const renderDate = () => (
      <ListItem>
        <ListItemText className={classes.listItem}>
          <div>
            <div className={classes.row}>
              <Typography component="span" variant="h6" color="textPrimary">
                {t('order:orderDate')}
              </Typography>
            </div>
            {order.createdDate && (
              <Typography component="span" variant="subtitle1" color="textSecondary">
                {DateHelper.formatDateToReadableDateAndTime(order.createdDate, lang)}
              </Typography>
            )}
          </div>
        </ListItemText>
      </ListItem>
    );

    const renderPaymentMethod = () => {
      const { label, PaymentIcons } = PaymentHelper.getOrderViewPaymentMethodLabelAndIcon(
        order?.paymentTransaction,
      );
      return (
        <ListItem>
          <ListItemText className={classes.listItem}>
            <div>
              <div className={classes.row}>
                <Typography component="span" variant="h6" color="textPrimary">
                  {t('payment:paymentMethod')}
                </Typography>
              </div>
              <div className={classes.paymentContainer}>
                {PaymentIcons &&
                  PaymentIcons.map(PaymentIcon => (
                    <PaymentIcon
                      key={uuid()}
                      className={classes.paymentIcon}
                      fillColor={iconColor}
                    />
                  ))}
                <Typography component="span" variant="subtitle1" color="textSecondary">
                  {label}
                </Typography>
              </div>
            </div>
          </ListItemText>
        </ListItem>
      );
    };
    const renderOnlineStore = () => {
      return (
        <ListItem>
          <ListItemText className={classes.listItem}>
            <div>
              <div className={classes.row}>
                <Typography component="span" variant="h6" color="textPrimary">
                  {t('order:storeLocation')}
                </Typography>
                <div>
                  <Button variant="text" className={classes.clickable} onClick={onCallStoreClick}>
                    {t('order:contactStore')}
                  </Button>
                </div>
              </div>
              <div>
                <div>
                  <Typography component="span" variant="subtitle1" color="textSecondary">
                    {StoreHelper.getStoreTitle(order.store!)}
                  </Typography>
                </div>
              </div>
            </div>
          </ListItemText>
        </ListItem>
      );
    };

    const renderStoreLocation = () => {
      return (
        <ListItem>
          <ListItemText className={classes.listItem}>
            <div>
              <div className={classes.row}>
                <Typography component="span" variant="h6" color="textPrimary">
                  {t('order:storeLocation')}
                </Typography>
                <div>
                  <Button variant="text" className={classes.clickable} onClick={onCallStoreClick}>
                    {t('order:contactStore')}
                  </Button>
                  <Button variant="text" className={classes.clickable} onClick={onStoreMapClick}>
                    {t('order:map')}
                  </Button>
                </div>
              </div>
              <div>
                <div>
                  <Typography component="span" variant="subtitle1" color="textSecondary">
                    {StoreHelper.getStoreTitle(order.store!)}
                  </Typography>
                </div>
                <div>
                  <Typography component="span" variant="subtitle2" color="textSecondary">
                    {StoreHelper.getStoreAddress(order.store!)}
                  </Typography>
                </div>
              </div>
            </div>
          </ListItemText>
        </ListItem>
      );
    };

    const orderType = () => {
      const lang = rtl ? Language.Arabic : Language.English;
      if (order.type === OrderType.DineIn) {
        return `${t('order:dineIn')} (${order.dineIn?.tableNumber})`;
      } else if (order.type === OrderType.Delivery) {
        let str =
          order.merchantId === '2c0e8f16-f6c9-4ebb-accc-0dcd65a8df84'
            ? t('order:roomDelivery')
            : t('order:delivery');
        if (order.delivery?.location?.customFields) {
          str += '\n';
          order.delivery?.location?.customFields.forEach(field => {
            str += `${field?.label[lang]} ${field?.value}\n`;
          });
        }
        return str;
      } else if (order.type === OrderType.Pickup) {
        if (
          order.pickup?.type === PickupType.DriveThru ||
          order.pickup?.type === PickupType.Curbside
        ) {
          const carInfo = order.pickup.driveThru?.carInfo;
          let str = t('order:driveThruTitle');
          str = str + `\n${carInfo?.make} - ${carInfo?.color}`;
          return str;
        } else if (
          order.pickup?.type === PickupType.InPerson ||
          order.pickup?.type === PickupType.InStore
        ) {
          if (
            order.merchantId === 'f0181bef-77fc-41c1-9e76-fe312fb3ceb6' ||
            order.merchantId === 'a08bef8b-4b7f-45db-b1fd-10a659bef86b'
          ) {
            return t('order:windowPickupTitle');
          }
          return t('order:selfPickupTitle');
        }
        return t('order:pickup');
      }
      return '';
    };

    const renderDeliveryOrPickup = () => (
      <ListItem>
        <ListItemText className={classes.listItem}>
          <div>
            <div>
              <Typography component="span" variant="h6" color="textPrimary">
                {t('order:type')}
              </Typography>
            </div>
            <div>
              <Typography
                style={{ whiteSpace: 'pre-line' }}
                component="span"
                variant="subtitle1"
                color="textSecondary">
                {orderType()}
              </Typography>
            </div>
          </div>
        </ListItemText>
      </ListItem>
    );

    const renderDeliveryLocation = () => (
      <ListItem>
        <ListItemText className={classes.listItem}>
          <div>
            <div className={classes.row}>
              <Typography component="span" variant="h6" color="textPrimary">
                {t('order:deliveryLocationTitle')}
              </Typography>
              <Button variant="text" className={classes.clickable} onClick={onDeliveryMapClick}>
                {t('order:map')}
              </Button>
            </div>
            <div>
              <div>
                <Typography component="span" variant="subtitle1" color="textSecondary">
                  {order.guest?.name}
                </Typography>
              </div>
              <div>
                <Typography component="span" variant="subtitle2" color="textSecondary">
                  {LocationHelper.getReadableLocation(order.delivery?.location)}
                </Typography>
              </div>
            </div>
          </div>
        </ListItemText>
      </ListItem>
    );

    const feedbackTheme = createMuiTheme({
      palette: {
        primary: {
          main: '#4CAF50',
        },
        secondary: {
          main: '#4CAF50',
        },
      },
    });

    return (
      <div className={classes.root}>
        <HeaderContainer fixed hideSelection />
        <div className={classes.titleContainer}>
          <Typography component="span" variant="h6" color="textPrimary">
            {t('order:status')}
          </Typography>
          <Typography component="span" variant="h6" color="textPrimary">
            {` #${order.orderNumber}`}
          </Typography>
        </div>
        <OrderStepper
          steps={steps}
          disableReadyForPickupButton={disableReadyForPickupButton}
          arrivedAtStoreButtonLabel={arrivedAtStoreButtonLabel}
          loadingReadyForPickup={loadingReadyForPickup}
          onReadyForPickupClick={onReadyForPickupClick}
          showReadyForPickup={showReadyForPickup}
          stepsContent={stepsContent}
          activeStep={activeStep}
          onTrackingLinkClick={onTrackingLinkClick}
          showTrackingLink={showTrackingLink}
          showCallCourier={showCallCourier}
          onContactCourier={onContactCourier}
          showRateCourier={showRateCourier}
        />
        <Divider />
        {renderDate()}
        {order.store &&
          (StoreHelper.isOnlineStore(order.store) ? renderOnlineStore() : renderStoreLocation())}
        {renderDeliveryOrPickup()}
        {renderPaymentMethod()}
        {order.type === OrderType.Delivery && renderDeliveryLocation()}
        {order && (
          <CartMenuItemsList
            quantity={CartHelper.getQuantity(order.items!)}
            orderTotal={order.total}
            rtl={rtl}
            totalPriceForItems={order.subtotal}
            menuItems={order.items!}
            paidTax={CartHelper.getPaidTax(
              order.items!,
              order.delivery?.deliveryFee,
              tax,
              discount,
            )}
            deliveryFee={CartHelper.getDeliveryFee(order.delivery?.deliveryFee)}
            serviceFee={CartHelper.calculateServiceFee(
              order.items!,
              order.delivery?.deliveryFee,
              order.serviceFee,
            )}
            editable={false}
            showDelivery={order.type === OrderType.Delivery}
            tax={tax}
          />
        )}
        {showFeedbackButton && (
          <MuiThemeProvider theme={feedbackTheme}>
            <CustomButton
              size="large"
              color={'primary'}
              variant="contained"
              buttonStyle={classNames(classes.button, classes.feedbackButton)}
              onClick={onFeedbackClick}>
              {t('order:experienceFeedbackButton')}
            </CustomButton>
          </MuiThemeProvider>
        )}
        {showCancel && (
          <CustomButton
            loading={loading}
            containerStyle={classes.bottomContainer}
            buttonStyle={classes.button}
            fullWidth
            variant="contained"
            size="large"
            onClick={onCancelPress}>
            {t('order:cancelOrder')}
          </CustomButton>
        )}
        <div className={classes.footerSpace} />
        <FooterContainer />

        {order.delivery?.trackingUrl && (
          <IFrameOrderTrackingPageContainer
            showContactCourier={!!order.delivery.courier && order.status !== OrderStatus.Completed}
            ref={trackingModalRef}
            courier={order.delivery?.courier}
            url={order.delivery?.trackingUrl}
          />
        )}
      </div>
    );
  },
);
