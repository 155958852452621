import { useTheme } from '@material-ui/core';
import React, { forwardRef, memo, useEffect, useImperativeHandle, useState } from 'react';

import { t } from '@/i18n/translate';
import { useLocation } from '@/location/hooks/useLocation';
import { isLocationSupported } from '@/location/providers/LocationProvider';
import { useStore } from '@/store/hooks/useStore';
import { useRTL } from '@/theme/hooks/useRTL';
import { DeliveryLocation, Location } from '@/types';
import FBStorage from '@/utils/FBStorage';

import LocationInputMap from '../components/LocationInputMap';

interface Props {
  ref?: React.Ref<any>;
}

export interface LocationInputMapContainerMethods {
  setOpen: (value: boolean) => void;
}

export const LocationInputMapContainer = memo<Props>(
  forwardRef<LocationInputMapContainerMethods, Props>(({}, ref) => {
    const [open, setOpen] = useState(false);
    const { location, setLocation, setIsSupported } = useLocation();
    const handleClosePress = () => setOpen(false);
    const {  selectedStore } = useStore();
    let locationInputRef: any = undefined;
    const [loadingMyPosition, setLoadingMyPosition] = useState(false);

    useEffect(() => {
      // document.body.style.overflow = 'hidden';
      // document.body.classList.add('no-sroll');
    }, []);

    useEffect(() => {

    }, [location]);

    const handleSaveClick = (mapLocation: any) => {
      const { city, state, streetNumber, zipCode, neighborhood, country, address } = mapLocation;
      let { street } = mapLocation;

      let unknownStreet = false;
      if (!street || street.trim() === '') {
        street = `${neighborhood}, ${city}`;
        unknownStreet = true;
      }
      const { lat, lng } = mapLocation.markerPosition;
      const location: DeliveryLocation = {
        display: address,
        address: {
          city,
          state,
          street: `${streetNumber} ${street}`,
          zipCode,
          neighborhood,
          country,
          unknownStreet,
        },
        coordinates: {
          latitude: lat,
          longitude: lng,
        },
      };
      if (setLocation && setIsSupported) {
        const isSupported = isLocationSupported(selectedStore, location);
        setIsSupported(isSupported);
        setLocation(location);
        FBStorage.setLocation(location);
      }
      setOpen(false);
    };
    const theme = useTheme();
    const rtl = useRTL();
    useImperativeHandle(ref, () => ({ setOpen }), []);
    if (!open) {
      return <></>;
    }
    const locateMe = () => {
      const location = window.navigator && window.navigator.geolocation;

      if (location) {
        setLoadingMyPosition(true);
        location.getCurrentPosition(
          position => {
            if (position && position.coords) {
              const { latitude, longitude } = position.coords;
              const newLocation = {
                ...location,
                coordinates: { latitude, longitude },
              };
              if (locationInputRef) {
                locationInputRef.updateMap(latitude, longitude);
              }
              if (setLocation) {
                setLocation(newLocation);
              }
            }
            setLoadingMyPosition(false);
          },
          error => {
            setLoadingMyPosition(false);
            alert(error.message);
          },
        );
      }
    };

    return (
      <LocationInputMap
        // @ts-ignore
        onRef={ref => (locationInputRef = ref)}
        theme={theme}
        open={open}
        rtl={rtl}
        loadingMyPosition={loadingMyPosition}
        locateButtonTitle={t('location:locateMe')}
        locateMe={locateMe}
        searchTextPlaceHolder={t('location:searchLocation')}
        backgroundColor={theme.palette.type === 'dark' ? 'black' : 'white'}
        buttonTitle={t('common:save')}
        onCloseClick={handleClosePress}
        center={
          location && location.coordinates
            ? { lat: location?.coordinates.latitude, lng: location?.coordinates.longitude }
            : { lat: 24.7136, lng: 46.6753 }
        }
        height="300px"
        onClick={handleSaveClick}
        zoom={15}
      />
    );
  }),
);
