import React from 'react';

import { Order } from '@/types';

interface OrderContext {
  order: Opt<Order>;
  setOrder: (order: Order) => void;
  refetchOrder: () => void;
}
export const OrderContext = React.createContext<OrderContext>({
  order: undefined,
  setOrder: order => {},
  refetchOrder: () => {},
});
