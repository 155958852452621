import { Button, createStyles, makeStyles, Modal, Theme, Typography } from '@material-ui/core';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GoogleMapReact from 'google-map-react';
import { disable } from 'mixpanel-browser';
import React, { memo, useEffect } from 'react';

import { Images } from '@/assets/images';
import LocationAnimation from '@/location/mobile/components/LocationAnimation';
import { isBrowser } from '@/utils/isBrowser';

const left = 50;
const top = 50;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      height: '30vh',
      top: 0,
      borderRadius: 20,
    },
    marker: {
      width: 40,
      height: 40,
    },
    map: {
      opacity: 0.8,
    },
    opaque: {
      backgroundColor:
        theme.palette.type === 'light' ? 'rgba(255,255,255,0.7)' : 'rgba(92,92,92,0.7)',
      width: '100%',
      height: '30vh',
      position: 'absolute',
      marginTop: theme.spacing(3),
      top: 0,
      left: 0,
    },
    animation: {
      marginTop: theme.spacing(8),
    },
  }),
);

interface Props {
  center: Opt<{ lat: number; lng: number }>;
  zoom: number;
  disabled: boolean;
  onClick: () => void;
}
const GOOGLE_API_KEY = isBrowser() ? window.env.GOOGLE_API_KEY : process.env.GOOGLE_API_KEY!;

export const StaticMapView = memo<Props>(({ center, zoom, disabled, onClick }) => {
  const classes = useStyles();
  const Marker = ({ lng, lat }: { lat: number; lng: number }) => (
    <img className={classes.marker} src={Images.marker} />
  );

  useEffect(() => {}, [center]);

  return (
    <div className={classes.container} onClick={onClick}>
      <GoogleMapReact
        options={{
          disableDefaultUI: true,
          disableDoubleClickZoom: true,
          draggable: false,
        }}
        bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
        center={center ? center : { lat: 24.7136, lng: 46.6753 }}
        defaultZoom={zoom}>
        {!disabled && center && <Marker lat={center.lat} lng={center.lng} />}
      </GoogleMapReact>
      {disabled && (
        <div className={classes.opaque}>
          <div className={classes.animation}>
            <LocationAnimation />
          </div>
        </div>
      )}
    </div>
  );
});
