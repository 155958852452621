import React, { useEffect, useState } from 'react';

import { useGuest } from '@/guest/hooks/useGuest';
import { useFetchOrders } from '@/orders/hooks/useFetchOrders';
import { useOrders } from '@/orders/hooks/useOrders';

export const useFetchOrdersInterval = (delay: number) => {
  const [intervalId, setIntervalId] = useState<Opt<NodeJS.Timeout>>(undefined);
  const [loading, setLoading] = useState(false);
  const { fetchOrders } = useFetchOrders();
  const { setOrders } = useOrders();
  const { guest } = useGuest();

  const startInterval = () => {
    const tempInterval = setInterval(async () => {
      setLoading(true);
      const updatedOrders = guest ? await fetchOrders(guest.id!!) : [];
      if (setOrders) {
        setOrders(updatedOrders);
      }
      setLoading(false);
    }, delay);
    setIntervalId(tempInterval);
  };

  const stopInterval = () => {
    if (intervalId) {
      clearInterval(intervalId);
      const temp = setIntervalId(undefined);
    }
  };

  useEffect(() => {
    if (guest) {
      startInterval();
    }
    return () => stopInterval();
  }, [guest]);

  return { stopInterval, startInterval, loading };
};
