import { Button, createStyles, makeStyles, Modal, Theme, Typography } from '@material-ui/core';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React, { memo } from 'react';

import { t } from '@/i18n/translate';
import RequestLocationViewContainer from '@/location/mobile/containers/RequestLocationViewContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      position: 'absolute',
      width: '70%',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      display: 'flex',
      outline: 'none',
      top: '50%',
      left: '50%',
      maxWidth: '350px',
      padding: theme.spacing(4),
      transform: `translate(-50%, -50%)`,
    },
    img: {
      width: 80,
      height: 80,
      borderRadius: 10,
    },
    name: {
      marginTop: theme.spacing(1),
    },
    centerText: {
      alignText: 'center',
    },
    welcome: {
      marginTop: theme.spacing(2),
    },
    welcomeMessage: {
      marginTop: theme.spacing(1),
    },
    buttons: {
      marginTop: theme.spacing(4),
    },
    howItWorks: {
      marginTop: theme.spacing(1),
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(2),
      width: '100%',
      bottom: 5,
    },
    button: {
      borderRadius: 0,
    },
  }),
);

interface Props {
  open: boolean;
  onClosePress: () => void;
  headerTitle: string;
  headerProfilePic: string;
  rtl: boolean;
  onPickupClick: () => void;
  onDeliveryClick: () => void;
  onBrowsingClick: () => void;
  setShowRequestLocationRef?: React.Ref<any>;
}

export const OnlineOrderGreetView = memo<Props>(
  ({
    open,
    onClosePress,
    headerTitle,
    headerProfilePic,
    rtl,
    onPickupClick,
    onBrowsingClick,
    onDeliveryClick,
    setShowRequestLocationRef,
  }) => {
    const classes = useStyles();

    return (
      <>
        <Modal open={open} onClose={onClosePress}>
          <div className={classes.dialog} dir={rtl ? 'rtl' : 'ltr'}>
            <img alt="Safary" src={headerProfilePic} className={classes.img} />
            <Typography variant="subtitle1" color="textSecondary" className={classes.name}>
              {headerTitle}
            </Typography>

            <Typography
              align={'center'}
              variant="h5"
              color="textPrimary"
              className={classes.welcome}>
              {t('greet:welcome')}
            </Typography>

            <Typography
              align={'center'}
              variant="body1"
              color="textPrimary"
              className={classes.welcomeMessage}
              style={{ whiteSpace: 'pre-line' }}>
              {t('greet:onlineOrderGreetTitle')}
            </Typography>

            <Typography
              align={'center'}
              variant="body2"
              color="secondary"
              className={classes.welcomeMessage}
              style={{ whiteSpace: 'pre-line' }}>
              {t('greet:deliveryWarning')}
            </Typography>

            <div className={classes.buttonContainer}>
              <div className={classes.row}>
                <Button
                  className={classes.button}
                  fullWidth
                  color="secondary"
                  variant="contained"
                  size="large"
                  startIcon={<LocalMallIcon />}
                  onClick={onPickupClick}>
                  {t('order:pickup')}
                </Button>
                <Button
                  className={classes.button}
                  fullWidth
                  color="primary"
                  variant="contained"
                  size="large"
                  startIcon={<DirectionsCarIcon fontSize="small" />}
                  onClick={onDeliveryClick}>
                  {t('order:delivery')}
                </Button>
              </div>

              <Button
                variant="text"
                size="large"
                onClick={onBrowsingClick}
                startIcon={<VisibilityIcon fontSize="small" />}>
                {t('location:justBrowsing')}
              </Button>
            </div>
          </div>
        </Modal>
        <RequestLocationViewContainer ref={setShowRequestLocationRef} />
      </>
    );
  },
);
