import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import classNames from 'classnames';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    greyImg: {
      filter: 'grayscale(100%)',
      opacity: 0.5,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    text: {
      marginLeft: theme.spacing(0.5),
    },
    errorIcon: {
      fontSize: 14,
    },
    errorContainer: {
      marginBottom: theme.spacing(1),
    },
  }),
);

interface Props {
  text: string;
  color?: 'inherit' | 'primary' | 'grey' | 'error';
  hideIcon?: boolean;
  textDir?: string;
}

const Warning = ({ text, color, hideIcon, textDir }: Props) => {
  const classes = useStyles();
  const getIconColor = () => {
    if (color == 'grey') {
      return 'disabled';
    }
    return color;
  };
  const getTextColor = () => {
    if (color == 'grey') {
      return 'textSecondary';
    }
    return color;
  };
  return (
    <div className={classNames(classes.row, classes.errorContainer)}>
      {!hideIcon && <WarningIcon className={classes.errorIcon} color={getIconColor() ?? 'error'} />}
      <Typography
        dir={textDir}
        className={classes.text}
        color={getTextColor() ?? 'error'}
        variant="caption">
        {text}
      </Typography>
    </div>
  );
};

export default Warning;
