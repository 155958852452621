import {
  AppBar,
  Button,
  createStyles,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
  useTheme,
} from '@material-ui/core';
import { ChevronLeft, ChevronRight, Language } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import React, { memo, useEffect, useState } from 'react';
import Select from 'react-select';

import { Images } from '@/assets/images';
import { LanguageSelection } from '@/common/components/LanguageSelection';
import { MenuLinksContainer } from '@/common/containers/MenuLinksContainer';
import { OrderType, PickupType, ThemeType } from '@/const/enums';
import StoreHelper from '@/store/utils/StoreHelper';
import { Store } from '@/types';

const drawerWidth = 260;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    div: {
      paddingTop: 5,
      paddingBottom: 5,
      backgroundColor: theme.palette.primary.dark,
      position: 'relative',
    },
    appBar: {
      paddingTop: 4,
      paddingBottom: 5,
      backgroundColor: theme.palette.primary.dark,
      maxHeight: 65,
    },
    grow: {
      flex: 1,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    img: {
      // maxWidth: '12%',
      marginRight: 10,
      borderRadius: 3,
      maxHeight: theme.spacing(6),
    },
    mamaNouraImg: {
      width: theme.spacing(23),
    },
    button: {
      fontWeight: 'bold',
      width: 80,
      color: 'white',
      borderColor: 'white',
    },
    closeIcon: {
      color: 'white',
      right: 10,
      position: 'absolute',
    },
    formControl: {
      marginRight: theme.spacing(2),
    },
    homeIcon: {
      color: 'white',
      width: 25,
    },
    selection: {
      fontSize: 14,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
  }),
);

export interface Selection {
  key: number;
  label: any;
  value: OrderType;
  pickupType?: PickupType;
}

export interface PickupSelection {
  label: any;
  value: PickupType;
}

interface Props {
  disableSelection?: boolean;
  onCloseClick?: () => void;
  title?: Opt<string>;
  fixed?: boolean;
  showLanguageButton: boolean;
  allStores: Store[];
  selectedStore: Store;
  onSelectionChange: (selection: any) => void;
  logo: string;
  rtl: boolean;
  hideElevation?: boolean;
  hideSelection?: boolean;
  showMenu: boolean;
  isMamaNoura: boolean;
  isQuickMarket: boolean;
}

const Header = memo<Props>(
  ({
    showMenu,
    disableSelection = true,
    onCloseClick,
    title,
    fixed,
    selectedStore,
    allStores,
    onSelectionChange,
    logo,
    rtl,
    hideSelection,
    isMamaNoura,
    isQuickMarket,
  }) => {
    const theme = useTheme();
    const { settings } = selectedStore || {};
    const { theme: customTheme } = settings || {};
    const { basic: basicTheme } = customTheme || {};
    const classes = useStyles();
    const [openDrawer, setOpenDrawer] = useState(false);
    const customStyles = {
      valueContainer: (provided: any, state: any) => ({}),
      dropdownIndicator: (provided: any, state: any) => ({
        color: disableSelection
          ? 'transparent'
          : basicTheme?.type === ThemeType.Light
          ? '#808080'
          : '#ffffff',
      }),
      option: (provided: any, state: any) => ({
        ...provided,
        color: state.isSelected
          ? basicTheme?.type === ThemeType.Light
            ? 'black'
            : '#fff'
          : theme.palette.type === 'dark'
          ? '#aaaaaa'
          : '#666666',
        backgroundColor: state.isSelected ? '#575757' : state.selectProps.menuColor,
      }),
      control: (provided: any, state: any) => ({
        ...provided,
        borderWidth: 0,
        backgroundColor: basicTheme?.type === ThemeType.Light ? 'white' : 'primary',
        boxShadow: '',
        minHeight: 0,
        width: 'auto',
      }),
      indicatorSeparator: () => ({}),
      menu: (provided: any, state: any) => ({
        ...provided,
        width: state.selectProps.width,
        color: state.selectProps.menuColor,
        backgroundColor: state.selectProps.menuColor,
      }),

      singleValue: (provided: any) => ({
        ...provided,
        paddingLeft: rtl ? 10 : 0,
        width: 'auto',
        fontSize: 14.5,
        color: basicTheme?.type === ThemeType.Light ? '#5f5f5f' : '#d6d6d6',
      }),
    };

    const allSelections = (): any[] =>
      StoreHelper.sortStores(allStores).map(store => {
        const { id, branchName } = store;
        const subTitle = rtl ? branchName?.ar : branchName?.en;
        const value = id;
        return {
          value,
          label: subTitle,
        };
      });

    const defaultSelection = () =>
      allSelections().find(each => {
        const { id } = selectedStore!;
        return id === each.value;
      });

    const handleDrawerOpen = () => {
      setOpenDrawer(true);
    };

    const renderContent = () => (
      <Toolbar>
        {showMenu && (
          <IconButton
            color="inherit"
            style={{
              color: basicTheme?.type === ThemeType.Light ? 'black' : 'primary',
            }}
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start">
            <MenuIcon />
          </IconButton>
        )}

        {!isMamaNoura && !isQuickMarket && <img alt="Safary" src={logo} className={classes.img} />}

        <div dir={rtl ? 'rtl' : 'ltr'} className={classes.grow}>
          {isMamaNoura && <img className={classes.mamaNouraImg} src={Images.mamaNoura} />}
          {isQuickMarket && <img className={classes.mamaNouraImg} src={Images.quickMarket} />}
          {!isMamaNoura && !isQuickMarket && (
            <Typography
              style={{
                color: basicTheme?.type === ThemeType.Light ? 'black' : 'white',
                fontFamily: 'sans-serif',
                fontWeight: 'bold',
                fontSize: 17,
              }}>
              {title}
            </Typography>
          )}
          {!hideSelection && (
            <Select
              disableUnderline
              isDisabled={disableSelection}
              className={classes.formControl}
              isSearchable={false}
              styles={customStyles}
              multiline
              value={defaultSelection()}
              menuColor={theme.palette.background.paper}
              onChange={onSelectionChange}
              options={allSelections()}
            />
          )}
        </div>

        {onCloseClick && (
          <IconButton className={classes.closeIcon} onClick={onCloseClick} size="small">
            <CloseIcon
              style={{
                color: basicTheme?.type === ThemeType.Light ? 'black' : 'primary',
              }}
            />
          </IconButton>
        )}
      </Toolbar>
    );

    const handleDrawerClose = () => setOpenDrawer(false);

    const renderDrawer = () => (
      <Drawer
        onBackdropClick={handleDrawerClose}
        className={classes.drawer}
        variant="temporary"
        anchor="left"
        open={openDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}>
        <div className={classes.drawerHeader}>
          {rtl && <IconButton onClick={handleDrawerClose}>{<CloseIcon />}</IconButton>}
          <ListItem dir={rtl ? 'rtl' : 'ltr'}>
            <ListItemIcon>
              <Language />
            </ListItemIcon>
            <LanguageSelection />
          </ListItem>
          {!rtl && <IconButton onClick={handleDrawerClose}>{<CloseIcon />}</IconButton>}
        </div>
        <MenuLinksContainer />
      </Drawer>
    );

    const appBarBackground = () => {
      if (basicTheme?.type === ThemeType.Light) {
        return 'white';
      } else if (basicTheme?.type === ThemeType.Original) {
        return basicTheme?.color ?? 'white';
      }
      return 'primary';
    };
    const renderAppBar = () => (
      <AppBar
        elevation={0}
        className={classes.appBar}
        style={{
          backgroundColor: appBarBackground(),
        }}
        dir={rtl ? 'rtl' : 'ltr'}>
        {renderContent()}
        {renderDrawer()}
      </AppBar>
    );

    const renderDiv = () => (
      <div className={classes.div} dir={rtl ? 'rtl' : 'ltr'}>
        {renderContent()}
      </div>
    );

    return fixed ? renderAppBar() : renderDiv();
  },
);

export default Header;
