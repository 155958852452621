export const en = {
  viewCart: 'View Cart',
  notesToKitchen: 'Notes',
  emptyCart: 'Empty Cart',
  total: 'Total',
  items: 'Items',
  clearCart: 'Empty Cart',
  checkout: 'Checkout',
  clearCartConfirmation: 'You want to delete all items in cart?',
  deleteItemConfirm: 'You sure you want to remove the entire item with all quantities?',
  taxIncluded: `Tax is included in total`,
  taxNotIncluded: `Tax is not included in total`,
  deliveryFee: 'Delivery Fee',
  serviceFee: 'Service Fee',
};

export const ar: typeof en = {
  viewCart: 'عرض السلّة',
  notesToKitchen: 'ملاحظات',
  emptyCart: 'السلّه فارغة',
  total: 'المجموع',
  items: 'المحتويات',
  clearCart: 'حذف محتويات السلّه',
  checkout: 'تنفيذ الطلب',
  clearCartConfirmation: 'هل تريد حذف جميع محتويات السلًه؟',
  deleteItemConfirm: 'هل تريد حذف الطبق بالكامل؟',
  taxIncluded: `المجموع يشمل قيمة الضريبة المضافة`,
  taxNotIncluded: `المجموع لا يشمل قيمة الضريبة المضافة`,
  deliveryFee: 'رسوم التوصيل',
  serviceFee: 'رسوم ادارية',
};
