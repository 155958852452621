import { useTheme } from '@material-ui/core';
import React, { memo, useContext } from 'react';

import { Event } from '@/analytics/Event';
import { useAnalytics } from '@/analytics/hooks/useAnalytics';
import { Footer } from '@/common/components/Footer';
import { useStore } from '@/store/hooks/useStore';
import { SetThemeContext } from '@/theme/providers/ThemeProvider';
import { isBrowser } from '@/utils/isBrowser';

export const FooterContainer = memo(() => {
  const poweredByURL = isBrowser() ? window.env.POWERED_BY_URL : process.env.POWERED_BY_URL!;
  const { logEvent } = useAnalytics();
  const { themeType } = useContext(SetThemeContext);
  const { selectedStore } = useStore();
  const handleClick = () => {
    window.location.assign(poweredByURL);
    logEvent(Event.ClickFooter);
  };
  const { palette } = useTheme();

  if (
    selectedStore?.merchantId === 'f0181bef-77fc-41c1-9e76-fe312fb3ceb6' ||
    selectedStore?.merchantId === 'b6fdb8b6-ae50-4916-a202-b207934d13ee' ||
    selectedStore?.merchantId === 'f0181bef-77fc-41c1-9e76-fe312fb3ceb6' ||
    selectedStore?.merchantId === 'a08bef8b-4b7f-45db-b1fd-10a659bef86b'
  ) {
    return <></>;
  }
  return <Footer onClick={handleClick} isDark={palette.type === 'dark'} />;
});
