export interface Language {
  [index: string]: {
    name: string;
    nativeName: string;
    dir: 'ltr' | 'rtl';
  };
}
export const AllLanguages: Language = {
  ab: {
    name: 'Abkhaz',
    nativeName: 'аҧсуа',
    dir: 'ltr',
  },
  aa: {
    name: 'Afar',
    nativeName: 'Afaraf',
    dir: 'ltr',
  },
  af: {
    name: 'Afrikaans',
    nativeName: 'Afrikaans',
    dir: 'ltr',
  },
  ak: {
    name: 'Akan',
    nativeName: 'Akan',
    dir: 'ltr',
  },
  sq: {
    name: 'Albanian',
    nativeName: 'Shqip',
    dir: 'ltr',
  },
  am: {
    name: 'Amharic',
    nativeName: 'አማርኛ',
    dir: 'ltr',
  },
  ar: {
    name: 'Arabic',
    nativeName: 'العربية',
    dir: 'rtl',
  },
  an: {
    name: 'Aragonese',
    nativeName: 'Aragonés',
    dir: 'ltr',
  },
  hy: {
    name: 'Armenian',
    nativeName: 'Հայերեն',
    dir: 'ltr',
  },
  as: {
    name: 'Assamese',
    nativeName: 'অসমীয়া',
    dir: 'ltr',
  },
  av: {
    name: 'Avaric',
    nativeName: 'авар мацӀ, магӀарул мацӀ',
    dir: 'ltr',
  },
  ae: {
    name: 'Avestan',
    nativeName: 'avesta',
    dir: 'ltr',
  },
  ay: {
    name: 'Aymara',
    nativeName: 'aymar aru',
    dir: 'ltr',
  },
  az: {
    name: 'Azerbaijani',
    nativeName: 'azərbaycan dili',
    dir: 'ltr',
  },
  bm: {
    name: 'Bambara',
    nativeName: 'bamanankan',
    dir: 'ltr',
  },
  ba: {
    name: 'Bashkir',
    nativeName: 'башҡорт теле',
    dir: 'ltr',
  },
  eu: {
    name: 'Basque',
    nativeName: 'euskara, euskera',
    dir: 'ltr',
  },
  be: {
    name: 'Belarusian',
    nativeName: 'Беларуская',
    dir: 'ltr',
  },
  bn: {
    name: 'Bengali',
    nativeName: 'বাংলা',
    dir: 'ltr',
  },
  bh: {
    name: 'Bihari',
    nativeName: 'भोजपुरी',
    dir: 'ltr',
  },
  bi: {
    name: 'Bislama',
    nativeName: 'Bislama',
    dir: 'ltr',
  },
  bs: {
    name: 'Bosnian',
    nativeName: 'bosanski jezik',
    dir: 'ltr',
  },
  br: {
    name: 'Breton',
    nativeName: 'brezhoneg',
    dir: 'ltr',
  },
  bg: {
    name: 'Bulgarian',
    nativeName: 'български език',
    dir: 'ltr',
  },
  my: {
    name: 'Burmese',
    nativeName: 'ဗမာစာ',
    dir: 'ltr',
  },
  ca: {
    name: 'Catalan; Valencian',
    nativeName: 'Català',
    dir: 'ltr',
  },
  ch: {
    name: 'Chamorro',
    nativeName: 'Chamoru',
    dir: 'ltr',
  },
  ce: {
    name: 'Chechen',
    nativeName: 'нохчийн мотт',
    dir: 'ltr',
  },
  ny: {
    name: 'Chichewa; Chewa; Nyanja',
    nativeName: 'chiCheŵa, chinyanja',
    dir: 'ltr',
  },
  zh: {
    name: 'Chinese',
    nativeName: '中文 (Zhōngwén), 汉语, 漢語',
    dir: 'ltr',
  },
  cv: {
    name: 'Chuvash',
    nativeName: 'чӑваш чӗлхи',
    dir: 'ltr',
  },
  kw: {
    name: 'Cornish',
    nativeName: 'Kernewek',
    dir: 'ltr',
  },
  co: {
    name: 'Corsican',
    nativeName: 'corsu, lingua corsa',
    dir: 'ltr',
  },
  cr: {
    name: 'Cree',
    nativeName: 'ᓀᐦᐃᔭᐍᐏᐣ',
    dir: 'ltr',
  },
  hr: {
    name: 'Croatian',
    nativeName: 'hrvatski',
    dir: 'ltr',
  },
  cs: {
    name: 'Czech',
    nativeName: 'česky, čeština',
    dir: 'ltr',
  },
  da: {
    name: 'Danish',
    nativeName: 'dansk',
    dir: 'ltr',
  },
  dv: {
    name: 'Divehi; Dhivehi; Maldivian;',
    nativeName: 'ދިވެހި',
    dir: 'ltr',
  },
  nl: {
    name: 'Dutch',
    nativeName: 'Nederlands, Vlaams',
    dir: 'ltr',
  },
  en: {
    name: 'English',
    nativeName: 'English',
    dir: 'ltr',
  },
  eo: {
    name: 'Esperanto',
    nativeName: 'Esperanto',
    dir: 'ltr',
  },
  et: {
    name: 'Estonian',
    nativeName: 'eesti, eesti keel',
    dir: 'ltr',
  },
  ee: {
    name: 'Ewe',
    nativeName: 'Eʋegbe',
    dir: 'ltr',
  },
  fo: {
    name: 'Faroese',
    nativeName: 'føroyskt',
    dir: 'ltr',
  },
  fj: {
    name: 'Fijian',
    nativeName: 'vosa Vakaviti',
    dir: 'ltr',
  },
  fi: {
    name: 'Finnish',
    nativeName: 'suomi, suomen kieli',
    dir: 'ltr',
  },
  fr: {
    name: 'French',
    nativeName: 'français, langue française',
    dir: 'ltr',
  },
  ff: {
    name: 'Fula; Fulah; Pulaar; Pular',
    nativeName: 'Fulfulde, Pulaar, Pular',
    dir: 'ltr',
  },
  gl: {
    name: 'Galician',
    nativeName: 'Galego',
    dir: 'ltr',
  },
  ka: {
    name: 'Georgian',
    nativeName: 'ქართული',
    dir: 'ltr',
  },
  de: {
    name: 'German',
    nativeName: 'Deutsch',
    dir: 'ltr',
  },
  el: {
    name: 'Greek, Modern',
    nativeName: 'Ελληνικά',
    dir: 'ltr',
  },
  gn: {
    name: 'Guaraní',
    nativeName: 'Avañeẽ',
    dir: 'ltr',
  },
  gu: {
    name: 'Gujarati',
    nativeName: 'ગુજરાતી',
    dir: 'ltr',
  },
  ht: {
    name: 'Haitian; Haitian Creole',
    nativeName: 'Kreyòl ayisyen',
    dir: 'ltr',
  },
  ha: {
    name: 'Hausa',
    nativeName: 'Hausa, هَوُسَ',
    dir: 'ltr',
  },
  he: {
    name: 'Hebrew (modern)',
    nativeName: 'עברית',
    dir: 'rtl',
  },
  hz: {
    name: 'Herero',
    nativeName: 'Otjiherero',
    dir: 'ltr',
  },
  hi: {
    name: 'Hindi',
    nativeName: 'हिन्दी, हिंदी',
    dir: 'ltr',
  },
  ho: {
    name: 'Hiri Motu',
    nativeName: 'Hiri Motu',
    dir: 'ltr',
  },
  hu: {
    name: 'Hungarian',
    nativeName: 'Magyar',
    dir: 'ltr',
  },
  ia: {
    name: 'Interlingua',
    nativeName: 'Interlingua',
    dir: 'ltr',
  },
  id: {
    name: 'Indonesian',
    nativeName: 'Bahasa Indonesia',
    dir: 'ltr',
  },
  ie: {
    name: 'Interlingue',
    nativeName: 'Originally called Occidental; then Interlingue after WWII',
    dir: 'ltr',
  },
  ga: {
    name: 'Irish',
    nativeName: 'Gaeilge',
    dir: 'ltr',
  },
  ig: {
    name: 'Igbo',
    nativeName: 'Asụsụ Igbo',
    dir: 'ltr',
  },
  ik: {
    name: 'Inupiaq',
    nativeName: 'Iñupiaq, Iñupiatun',
    dir: 'ltr',
  },
  io: {
    name: 'Ido',
    nativeName: 'Ido',
    dir: 'ltr',
  },
  is: {
    name: 'Icelandic',
    nativeName: 'Íslenska',
    dir: 'ltr',
  },
  it: {
    name: 'Italian',
    nativeName: 'Italiano',
    dir: 'ltr',
  },
  iu: {
    name: 'Inuktitut',
    nativeName: 'ᐃᓄᒃᑎᑐᑦ',
    dir: 'ltr',
  },
  ja: {
    name: 'Japanese',
    nativeName: '日本語 (にほんご／にっぽんご)',
    dir: 'ltr',
  },
  jv: {
    name: 'Javanese',
    nativeName: 'basa Jawa',
    dir: 'ltr',
  },
  kl: {
    name: 'Kalaallisut, Greenlandic',
    nativeName: 'kalaallisut, kalaallit oqaasii',
    dir: 'ltr',
  },
  kn: {
    name: 'Kannada',
    nativeName: 'ಕನ್ನಡ',
    dir: 'ltr',
  },
  kr: {
    name: 'Kanuri',
    nativeName: 'Kanuri',
    dir: 'ltr',
  },
  ks: {
    name: 'Kashmiri',
    nativeName: 'कश्मीरी, كشميري‎',
    dir: 'ltr',
  },
  kk: {
    name: 'Kazakh',
    nativeName: 'Қазақ тілі',
    dir: 'ltr',
  },
  km: {
    name: 'Khmer',
    nativeName: 'ភាសាខ្មែរ',
    dir: 'ltr',
  },
  ki: {
    name: 'Kikuyu, Gikuyu',
    nativeName: 'Gĩkũyũ',
    dir: 'ltr',
  },
  rw: {
    name: 'Kinyarwanda',
    nativeName: 'Ikinyarwanda',
    dir: 'ltr',
  },
  ky: {
    name: 'Kirghiz, Kyrgyz',
    nativeName: 'кыргыз тили',
    dir: 'ltr',
  },
  kv: {
    name: 'Komi',
    nativeName: 'коми кыв',
    dir: 'ltr',
  },
  kg: {
    name: 'Kongo',
    nativeName: 'KiKongo',
    dir: 'ltr',
  },
  ko: {
    name: 'Korean',
    nativeName: '한국어 (韓國語), 조선말 (朝鮮語)',
    dir: 'ltr',
  },
  ku: {
    name: 'Kurdish',
    nativeName: 'Kurdî, كوردی‎',
    dir: 'rtl',
  },
  kj: {
    name: 'Kwanyama, Kuanyama',
    nativeName: 'Kuanyama',
    dir: 'ltr',
  },
  la: {
    name: 'Latin',
    nativeName: 'latine, lingua latina',
    dir: 'ltr',
  },
  lb: {
    name: 'Luxembourgish, Letzeburgesch',
    nativeName: 'Lëtzebuergesch',
    dir: 'ltr',
  },
  lg: {
    name: 'Luganda',
    nativeName: 'Luganda',
    dir: 'ltr',
  },
  li: {
    name: 'Limburgish, Limburgan, Limburger',
    nativeName: 'Limburgs',
    dir: 'ltr',
  },
  ln: {
    name: 'Lingala',
    nativeName: 'Lingála',
    dir: 'ltr',
  },
  lo: {
    name: 'Lao',
    nativeName: 'ພາສາລາວ',
    dir: 'ltr',
  },
  lt: {
    name: 'Lithuanian',
    nativeName: 'lietuvių kalba',
    dir: 'ltr',
  },
  lu: {
    name: 'Luba-Katanga',
    nativeName: '',
    dir: 'ltr',
  },
  lv: {
    name: 'Latvian',
    nativeName: 'latviešu valoda',
    dir: 'ltr',
  },
  gv: {
    name: 'Manx',
    nativeName: 'Gaelg, Gailck',
    dir: 'ltr',
  },
  mk: {
    name: 'Macedonian',
    nativeName: 'македонски јазик',
    dir: 'ltr',
  },
  mg: {
    name: 'Malagasy',
    nativeName: 'Malagasy fiteny',
    dir: 'ltr',
  },
  ms: {
    name: 'Malay',
    nativeName: 'bahasa Melayu, بهاس ملايو‎',
    dir: 'ltr',
  },
  ml: {
    name: 'Malayalam',
    nativeName: 'മലയാളം',
    dir: 'ltr',
  },
  mt: {
    name: 'Maltese',
    nativeName: 'Malti',
    dir: 'ltr',
  },
  mi: {
    name: 'Māori',
    nativeName: 'te reo Māori',
    dir: 'ltr',
  },
  mr: {
    name: 'Marathi (Marāṭhī)',
    nativeName: 'मराठी',
    dir: 'ltr',
  },
  mh: {
    name: 'Marshallese',
    nativeName: 'Kajin M̧ajeļ',
    dir: 'ltr',
  },
  mn: {
    name: 'Mongolian',
    nativeName: 'монгол',
    dir: 'ltr',
  },
  na: {
    name: 'Nauru',
    nativeName: 'Ekakairũ Naoero',
    dir: 'ltr',
  },
  nv: {
    name: 'Navajo, Navaho',
    nativeName: 'Diné bizaad, Dinékʼehǰí',
    dir: 'ltr',
  },
  nb: {
    name: 'Norwegian Bokmål',
    nativeName: 'Norsk bokmål',
    dir: 'ltr',
  },
  nd: {
    name: 'North Ndebele',
    nativeName: 'isiNdebele',
    dir: 'ltr',
  },
  ne: {
    name: 'Nepali',
    nativeName: 'नेपाली',
    dir: 'ltr',
  },
  ng: {
    name: 'Ndonga',
    nativeName: 'Owambo',
    dir: 'ltr',
  },
  nn: {
    name: 'Norwegian Nynorsk',
    nativeName: 'Norsk nynorsk',
    dir: 'ltr',
  },
  no: {
    name: 'Norwegian',
    nativeName: 'Norsk',
    dir: 'ltr',
  },
  ii: {
    name: 'Nuosu',
    nativeName: 'ꆈꌠ꒿ Nuosuhxop',
    dir: 'ltr',
  },
  nr: {
    name: 'South Ndebele',
    nativeName: 'isiNdebele',
    dir: 'ltr',
  },
  oc: {
    name: 'Occitan',
    nativeName: 'Occitan',
    dir: 'ltr',
  },
  oj: {
    name: 'Ojibwe, Ojibwa',
    nativeName: 'ᐊᓂᔑᓈᐯᒧᐎᓐ',
    dir: 'ltr',
  },
  cu: {
    name: 'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
    nativeName: 'ѩзыкъ словѣньскъ',
    dir: 'ltr',
  },
  om: {
    name: 'Oromo',
    nativeName: 'Afaan Oromoo',
    dir: 'ltr',
  },
  or: {
    name: 'Oriya',
    nativeName: 'ଓଡ଼ିଆ',
    dir: 'ltr',
  },
  os: {
    name: 'Ossetian, Ossetic',
    nativeName: 'ирон æвзаг',
    dir: 'ltr',
  },
  pa: {
    name: 'Panjabi, Punjabi',
    nativeName: 'ਪੰਜਾਬੀ, پنجابی‎',
    dir: 'ltr',
  },
  pi: {
    name: 'Pāli',
    nativeName: 'पाऴि',
    dir: 'ltr',
  },
  fa: {
    name: 'Persian',
    nativeName: 'فارسی',
    dir: 'rtl',
  },
  pl: {
    name: 'Polish',
    nativeName: 'polski',
    dir: 'ltr',
  },
  ps: {
    name: 'Pashto, Pushto',
    nativeName: 'پښتو',
    dir: 'ltr',
  },
  pt: {
    name: 'Portuguese',
    nativeName: 'Português',
    dir: 'ltr',
  },
  qu: {
    name: 'Quechua',
    nativeName: 'Runa Simi, Kichwa',
    dir: 'ltr',
  },
  rm: {
    name: 'Romansh',
    nativeName: 'rumantsch grischun',
    dir: 'ltr',
  },
  rn: {
    name: 'Kirundi',
    nativeName: 'kiRundi',
    dir: 'ltr',
  },
  ro: {
    name: 'Romanian, Moldavian, Moldovan',
    nativeName: 'română',
    dir: 'ltr',
  },
  ru: {
    name: 'Russian',
    nativeName: 'русский язык',
    dir: 'ltr',
  },
  sa: {
    name: 'Sanskrit (Saṁskṛta)',
    nativeName: 'संस्कृतम्',
    dir: 'ltr',
  },
  sc: {
    name: 'Sardinian',
    nativeName: 'sardu',
    dir: 'ltr',
  },
  sd: {
    name: 'Sindhi',
    nativeName: 'सिन्धी, سنڌي، سندھی‎',
    dir: 'ltr',
  },
  se: {
    name: 'Northern Sami',
    nativeName: 'Davvisámegiella',
    dir: 'ltr',
  },
  sm: {
    name: 'Samoan',
    nativeName: 'gagana faa Samoa',
    dir: 'ltr',
  },
  sg: {
    name: 'Sango',
    nativeName: 'yângâ tî sängö',
    dir: 'ltr',
  },
  sr: {
    name: 'Serbian',
    nativeName: 'српски језик',
    dir: 'ltr',
  },
  gd: {
    name: 'Scottish Gaelic; Gaelic',
    nativeName: 'Gàidhlig',
    dir: 'ltr',
  },
  sn: {
    name: 'Shona',
    nativeName: 'chiShona',
    dir: 'ltr',
  },
  si: {
    name: 'Sinhala, Sinhalese',
    nativeName: 'සිංහල',
    dir: 'ltr',
  },
  sk: {
    name: 'Slovak',
    nativeName: 'slovenčina',
    dir: 'ltr',
  },
  sl: {
    name: 'Slovene',
    nativeName: 'slovenščina',
    dir: 'ltr',
  },
  so: {
    name: 'Somali',
    nativeName: 'Soomaaliga, af Soomaali',
    dir: 'ltr',
  },
  st: {
    name: 'Southern Sotho',
    nativeName: 'Sesotho',
    dir: 'ltr',
  },
  es: {
    name: 'Spanish; Castilian',
    nativeName: 'español, castellano',
    dir: 'ltr',
  },
  su: {
    name: 'Sundanese',
    nativeName: 'Basa Sunda',
    dir: 'ltr',
  },
  sw: {
    name: 'Swahili',
    nativeName: 'Kiswahili',
    dir: 'ltr',
  },
  ss: {
    name: 'Swati',
    nativeName: 'SiSwati',
    dir: 'ltr',
  },
  sv: {
    name: 'Swedish',
    nativeName: 'svenska',
    dir: 'ltr',
  },
  ta: {
    name: 'Tamil',
    nativeName: 'தமிழ்',
    dir: 'ltr',
  },
  te: {
    name: 'Telugu',
    nativeName: 'తెలుగు',
    dir: 'ltr',
  },
  tg: {
    name: 'Tajik',
    nativeName: 'тоҷикӣ, toğikī, تاجیکی‎',
    dir: 'ltr',
  },
  th: {
    name: 'Thai',
    nativeName: 'ไทย',
    dir: 'ltr',
  },
  ti: {
    name: 'Tigrinya',
    nativeName: 'ትግርኛ',
    dir: 'ltr',
  },
  bo: {
    name: 'Tibetan Standard, Tibetan, Central',
    nativeName: 'བོད་ཡིག',
    dir: 'ltr',
  },
  tk: {
    name: 'Turkmen',
    nativeName: 'Türkmen, Түркмен',
    dir: 'ltr',
  },
  tl: {
    name: 'Tagalog',
    nativeName: 'Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔',
    dir: 'ltr',
  },
  tn: {
    name: 'Tswana',
    nativeName: 'Setswana',
    dir: 'ltr',
  },
  to: {
    name: 'Tonga (Tonga Islands)',
    nativeName: 'faka Tonga',
    dir: 'ltr',
  },
  tr: {
    name: 'Turkish',
    nativeName: 'Türkçe',
    dir: 'ltr',
  },
  ts: {
    name: 'Tsonga',
    nativeName: 'Xitsonga',
    dir: 'ltr',
  },
  tt: {
    name: 'Tatar',
    nativeName: 'татарча, tatarça, تاتارچا‎',
    dir: 'ltr',
  },
  tw: {
    name: 'Twi',
    nativeName: 'Twi',
    dir: 'ltr',
  },
  ty: {
    name: 'Tahitian',
    nativeName: 'Reo Tahiti',
    dir: 'ltr',
  },
  ug: {
    name: 'Uighur, Uyghur',
    nativeName: 'Uyƣurqə, ئۇيغۇرچە‎',
    dir: 'ltr',
  },
  uk: {
    name: 'Ukrainian',
    nativeName: 'українська',
    dir: 'ltr',
  },
  ur: {
    name: 'Urdu',
    nativeName: 'اردو',
    dir: 'rtl',
  },
  uz: {
    name: 'Uzbek',
    nativeName: 'zbek, Ўзбек, أۇزبېك‎',
    dir: 'ltr',
  },
  ve: {
    name: 'Venda',
    nativeName: 'Tshivenḓa',
    dir: 'ltr',
  },
  vi: {
    name: 'Vietnamese',
    nativeName: 'Tiếng Việt',
    dir: 'ltr',
  },
  vo: {
    name: 'Volapük',
    nativeName: 'Volapük',
    dir: 'ltr',
  },
  wa: {
    name: 'Walloon',
    nativeName: 'Walon',
    dir: 'ltr',
  },
  cy: {
    name: 'Welsh',
    nativeName: 'Cymraeg',
    dir: 'ltr',
  },
  wo: {
    name: 'Wolof',
    nativeName: 'Wollof',
    dir: 'ltr',
  },
  fy: {
    name: 'Western Frisian',
    nativeName: 'Frysk',
    dir: 'ltr',
  },
  xh: {
    name: 'Xhosa',
    nativeName: 'isiXhosa',
    dir: 'ltr',
  },
  yi: {
    name: 'Yiddish',
    nativeName: 'ייִדיש',
    dir: 'ltr',
  },
  yo: {
    name: 'Yoruba',
    nativeName: 'Yorùbá',
    dir: 'ltr',
  },
  za: {
    name: 'Zhuang, Chuang',
    nativeName: 'Saɯ cueŋƅ, Saw cuengh',
    dir: 'ltr',
  },
};
export const SupportedUILanguages = [AllLanguages.ar, AllLanguages.en];
