import { createStyles, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import { useRTL } from '@/theme/hooks/useRTL';

const useStyles = makeStyles(() =>
  createStyles({
    link: {
      color: 'inherit',
      textDecoration: 'none',
    },
  }),
);

interface Props {
  icon: React.ReactElement;
  text: string;
  onClick: () => void;
}

export const MenuLink = memo<Props>(({ icon, text, onClick }) => {
  const rtl = useRTL();
  return (
    <ListItem dir={rtl ? 'rtl' : 'ltr'} button onClick={onClick}>
      <ListItemIcon>{icon}</ListItemIcon> <ListItemText primary={text} />
    </ListItem>
  );
});
