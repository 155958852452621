import { OrderType } from '@/const/enums';
import { Guest, MenuItem, Order, Store } from '@/types';

export default class EventProperties {
  public static getOrderProps(order: Order, store: Opt<Store>, guest: Opt<Guest>): Dict {
    const deliveryFee = store?.settings?.deliveryFee;
    return order.items
      ? {
          $order_type: order.type === OrderType.Delivery,
          $order_notes: order.notes,
          $delivery_fee: deliveryFee?.base,
          $guest_id: guest?.id,
          $total_price: order.total,
        }
      : {};
  }

  public static getSignupForRestaurantProps(
    store: Opt<Store>,
    guest: Opt<Guest>,
    experiment: string,
  ): Dict {
    return {
      $experiment: experiment,
      $guest_name: guest?.name,
      $user_phone_number: guest?.phoneNumber,
    };
  }

  public static getItemProps(item: MenuItem, store: Opt<Store>): Dict {
    if (!store) {
      return {};
    }

    return {
      $item_name: item.name.en ?? item.name.ar,
    };
  }
}
