import { createStyles, withStyles } from '@material-ui/core';
import React from 'react';
import Favicon from 'react-favicon';

import { ReservationPaymentStatusPage } from '@/payment/components/ReservationPaymentStatusPage';
import { getInitialProps, Props } from '@/store/components/StorePageRouter';
import { StoreProvider } from '@/store/providers/StoreProvider';
import StoreHelper from '@/store/utils/StoreHelper';

const styles = createStyles({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});

interface State {
  openDialog: boolean;
}

class ReservationPaymentPageStatusRouter extends React.Component<Props, State> {
  constructor(props: Props) {
    console.log('ReservationPaymentPageStatusRouter');
    super(props);
    this.state = {
      openDialog: false,
    };
  }

  public static getInitialProps = ({ _, __, match, req }: any) => {
    return getInitialProps({ _, __, match, req });
  };

  public render() {
    const isSSR = typeof window === 'undefined';
    if (isSSR) {
      return <></>;
    }
    const { stores, error, classes, paymentTransactionId } = this.props;
    // if (error || !stores || stores.length === 0) {
    //   return <></>;
    // }
    //
    // const defaultStore = StoreHelper.getDefaultStore(stores);
    // const { profilePic } = defaultStore;

    return (
      <div className={classes.root}>
        {/*<Favicon url={profilePic} />*/}
        <ReservationPaymentStatusPage />
      </div>
    );
  }
}

export default withStyles(styles)(ReservationPaymentPageStatusRouter);
