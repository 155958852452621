import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import classNames from 'classnames';
import React from 'react';

import { FishIcon } from '@/assets/images/tsxSVGIcons';
import { useStore } from '@/store/hooks/useStore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    greyImg: {
      filter: 'grayscale(100%)',
      opacity: 0.5,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    text: {
      fontSize: 10,
      fontWeight: 'bold',
      color: 'white',
      marginLeft: 5,
      marginTop: 2,
      alignText: 'center',
    },
    icon: {
      fontSize: 8,
    },
    container: {
      display: 'flex',
      padding: 3,
      paddingLeft: 5,
      paddingRight: 5,
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      flexDirection: 'row',
      borderRadius: 5,
    },
  }),
);

interface Props {
  text: string;
  color?: 'inherit' | 'primary' | 'grey' | 'error';
  hideIcon?: boolean;
  textDir?: string;
}

const SushiWarning = ({ text, color, hideIcon, textDir }: Props) => {
  const classes = useStyles();
  const { selectedStore } = useStore();
  const { theme } = selectedStore?.settings || {};
  const getTextColor = () => {
    if (color === 'grey') {
      return 'textSecondary';
    }
    return color;
  };
  return (
    <div
      className={classNames(classes.container)}
      style={{
        background: theme?.basic.color || 'yellow',
      }}>
      {!hideIcon && <FishIcon className={classes.icon} />}
      <Typography dir={textDir} className={classes.text}>
        {text}
      </Typography>
    </div>
  );
};

export default SushiWarning;
