import { StyledProps } from '@material-ui/core';
import React, { memo } from 'react';

import Tax from '@/common/components/Tax';
import { useStore } from '@/store/hooks/useStore';

interface Props extends StyledProps {}
export const TaxContainer = memo<Props>(({ ...props }) => {
  const { selectedStore } = useStore();
  const { tax } = selectedStore?.settings || {};
  const { isTaxIncludedInPrices } = tax || {};
  return (
    <Tax taxValue={tax?.percentage} isTaxIncluded={isTaxIncludedInPrices} className {...props} />
  );
});
