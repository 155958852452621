import TwitterConvTrkr from 'fb-react-twitter-conversion-tracker';
import { useCallback, useState } from 'react';
import { useAlert } from 'react-alert';

import { OrderStatus } from '@/const/enums';
import { useGuest } from '@/guest/hooks/useGuest';
import { t } from '@/i18n/translate';
import { useTrackOrder } from '@/orders/hooks/useTrackOrder';
import { Order } from '@/types';
import FBStorage from '@/utils/FBStorage';
import { isBrowser } from '@/utils/isBrowser';

import Api from '../../api/Api';

export const useCreateOrder = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const [createdOrder, setCreatedOrder] = useState<Opt<Order>>();
  const trackOrder = useTrackOrder();
  const { setGuest } = useGuest();

  const createOrder = async (order: Order, isPending = false): Promise<Opt<Order>> => {
    setLoading(true);
    try {
      if (order.store?.id) {
        const { isReceivingOrders } = await Api.isStoreReceivingOrders(order.store?.id);

        if (!isReceivingOrders) {
          setError(t('store:notReceivingOrders'));
          setLoading(false);
          setSuccess(false);
          return;
          // alert(t('store:notReceivingOrders'));
        }

        const newOrder = await Api.saveOrder(order, isPending);

        // Log events
        if (order.status !== OrderStatus.PendingPayment) {
          trackOrder(newOrder);

          if (isBrowser() && order.merchantId === '1a410b4e-f118-4fe4-b577-c4d1da0bbca7') {
            import('react-facebook-pixel')
              .then(module => module.default)
              .then(ReactPixel => {
                console.log('React Pixel Init');
                ReactPixel.init('3173764769535113');
                ReactPixel.track('Purchase', {
                  currency: order.currency?.value,
                  value: order.subtotal,
                });
              });
            TwitterConvTrkr.init('o8fsf');
            TwitterConvTrkr.track('Purchase', {
              currency: order.currency?.value,
              value: order.subtotal,
            });
          }
        }

        if (setGuest && newOrder.guest) {
          setGuest(newOrder.guest);
        }

        // FBStorage.addOrderId(newOrder.id!!);
        setCreatedOrder(newOrder);
        setError(undefined);
        setLoading(false);
        setSuccess(true);
        return newOrder;
      }
    } catch (e) {
      setError(e.message);
      setLoading(false);
      setSuccess(false);
      console.error(e.message);
    }
    return undefined;
  };

  return {
    createOrder,
    loading,
    error,
    setError,
    success,
    createdOrder,
    setLoading,
  };
};
