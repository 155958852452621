import React from 'react';

import { Order } from '@/types';

interface OrdersContext {
  orders: Order[];
  setOrders?: (orders: Order[]) => void;
  loading: boolean;
}
export const OrdersContext = React.createContext<OrdersContext>({
  orders: [],
  loading: false,
});
