import { createStyles, makeStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';

import { drawerWidth, SideMenu } from '@/common/components/SideMenu';
import { useStore } from '@/store/hooks/useStore';
import { useRTL } from '@/theme/hooks/useRTL';
import { MenuCategory } from '@/types';

import MenuItemsGrid from './MenuItemsGrid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      padding: theme.spacing(1),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    contentShift: {
      padding: theme.spacing(1),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    logo: {
      marginRight: 10,
    },
  }),
);

interface StoreCompProps {
  isSideMenuOpen: boolean;
  toggleDrawer: () => void;
  showAppBar?: boolean;
}

const MenuView = ({ isSideMenuOpen, toggleDrawer, showAppBar = true }: StoreCompProps) => {
  const [showLanguageButton, setShowLanguageButton] = useState(false);
  const [rtl, setRTL] = useState(useRTL());
  const { selectedStore } = useStore();
  const classes = useStyles();

  const [currentCategory, setCurrentCategory] = useState<Opt<MenuCategory>>(
    selectedStore?.menu && selectedStore.menu.categories[0],
  );


  const showOrHidLanguageButton = useCallback(() => {
    if (selectedStore && selectedStore.menu && selectedStore!.menu!.supportedLanguages) {
      if (selectedStore.menu.supportedLanguages.length === 1) {
        setRTL(selectedStore.menu.supportedLanguages[0] === 'ar');
      } else {
        setShowLanguageButton(true);
      }
    } else {
      setShowLanguageButton(true);
    }
  }, [selectedStore]);

  useEffect(() => {
    showOrHidLanguageButton();
  }, [showOrHidLanguageButton, rtl]);

  if (!selectedStore) {
    return <></>;
  }

  const { menu } = selectedStore!;

  if (!menu) {
    return <div>No Menu</div>;
  }

  const contentMargin = () => {
    if (!isSideMenuOpen) {
      return {};
    }
    return rtl
      ? { marginRight: drawerWidth }
      : {
          marginLeft: drawerWidth,
        };
  };

  const handleSelectedCategory = (category: MenuCategory) => setCurrentCategory(category);

  const renderSideBar = () =>
    currentCategory && (
      <>
        <SideMenu
          showLanguageButton={showLanguageButton}
          open={isSideMenuOpen}
          categories={selectedStore.menu && selectedStore.menu.categories}
          onCloseDrawer={toggleDrawer}
          selectedCategory={currentCategory}
          showCollapseMenu={showAppBar}
          onCategorySelect={handleSelectedCategory}
          onLanguageButtonClick={() => {}}
        />
        {showAppBar && <div className={classes.drawerHeader} />}
      </>
    );

  const renderContent = () =>
    currentCategory && (
      <>
        <div
          className={classNames(isSideMenuOpen ? classes.contentShift : classes.content)}
          style={contentMargin()}>
          <MenuItemsGrid category={currentCategory} />
        </div>
      </>
    );

  return (
    <>
      {renderSideBar()}
      {renderContent()}
    </>
  );
};

export default MenuView;
