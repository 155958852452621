import { useConfirm } from 'material-ui-confirm';
import React, { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { Event } from '@/analytics/Event';
import SafaryAnalytics from '@/analytics/SafaryAnalytics';
import { useBrowseType } from '@/browse/hooks/useBrowseType';
import { useUpdateCart } from '@/cart/hooks/useUpdateCart';
import CartHelper from '@/cart/utils/CartHelper';
import { Selection } from '@/common/components/Header';
import URLHelper from '@/common/utils/URLHelper';
import { uuid } from '@/common/utils/uuid';
import {
  CheckLocationType,
  DeliveryType,
  Language,
  LocationFieldType,
  OrderStatus,
  OrderType,
  PaymentMethod,
  PaymentStatus,
  PickupType,
  UpdateCartType,
  UserFieldType,
} from '@/const/enums';
import { useGuest } from '@/guest/hooks/useGuest';
import { t } from '@/i18n/translate';
import { useLocation } from '@/location/hooks/useLocation';
import { LocationInputMapContainerMethods } from '@/location/mobile/containers/LocationInputMapContainer';
import { isLocationSupported } from '@/location/providers/LocationProvider';
import LocationHelper from '@/location/utils/LocationHelper';
import { StartCheckView } from '@/orders/components/StartCheckView';
import { useCreateOrder } from '@/orders/hooks/useCreateOrder';
import { useDineIn } from '@/orders/hooks/useDineIn';
import { FinalizeOnlineOrderForm } from '@/orders/mobile/components/FinalizeOnlineOrderForm';
import OrdersHelper from '@/orders/utils/OrdersHelper';
import { useApplePay } from '@/payment/hooks/useApplePay';
import PaymentHelper from '@/payment/utils/PaymentHelper';
import { useStore } from '@/store/hooks/useStore';
import { useRTL } from '@/theme/hooks/useRTL';
import { CarInfo, Cart, Check, Label, LocationField, Order } from '@/types';
import FBStorage from '@/utils/FBStorage';
import PhoneHelper from '@/utils/PhoneHelper';

interface Props {
  ref?: React.Ref<any>;
  setTransitionToOrderPage: (value: boolean) => void;
  cart: Cart;
}

export interface FinalizeOrderContainerMethods {
  setOrderTypeValue?: (value: string) => void;
  setOpen: (open: boolean) => void;
}

export const FinalizeOnlineOrderContainer = memo<Props>(
  forwardRef<FinalizeOrderContainerMethods, Props>(({ cart, setTransitionToOrderPage }, ref) => {
    useImperativeHandle(ref, () => ({ setOpen }), []);
    const { guest, setGuest } = useGuest();

    const [showStartCheck, setShowStartCheck] = useState(false);

    // Modal
    const [open, setOpen] = useState(false);

    // Check
    const { check, checkLocation, setCheckLocation, checkIsCheckOpened, startCheck, setCheck } =
      useDineIn();

    // Browse
    const { type: browseType } = useBrowseType();

    // Dine In
    const [tableNoError, setNameTableNoError] = useState(false);

    // Location
    const { location, setIsSupported, isSupported } = useLocation();
    const locationInputRef = useRef<LocationInputMapContainerMethods>();

    // Store
    const { selectedStore, stores, setSelectedStore, nearestStore, discount } = useStore();
    const { updateCart } = useUpdateCart();
    const storeContext = useStore();
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);
    const tax = selectedStore?.settings?.tax;
    let deliveryTypes = selectedStore?.settings?.deliveryTypes;
    const deliveryLocationCustomFields = selectedStore?.settings?.orders?.delivery?.locationFields;

    // Payments
    const { showApplePay, isApplePayAvailable } = useApplePay();
    const [goToPayment, setGoToPayment] = useState(false);
    const deliveryPaymentMethods = selectedStore?.settings?.deliveryPaymentMethods;
    const pickupPaymentMethods = selectedStore?.settings?.pickupPaymentMethods;
    const dineInPaymentMethods = selectedStore?.settings?.dineInPaymentMethods;
    const enablePaymentMethods = selectedStore?.settings?.enablePaymentMethods;
    const serviceFee = selectedStore?.settings?.serviceFee;

    // RTL
    const rtl = useRTL();

    // PaymentTransaction
    const [loadingPayment, setLoadingPayment] = useState(false);
    const getPaymentMethodLabels = () => {
      switch (orderTypeValue?.value) {
        case OrderType.Delivery:
          return PaymentHelper.getFinalizeOrderPaymentMethodLabelsAndIcons(
            deliveryPaymentMethods,
            orderTypeValue?.pickupType,
            isApplePayAvailable,
          );
        case OrderType.Pickup:
          return PaymentHelper.getFinalizeOrderPaymentMethodLabelsAndIcons(
            pickupPaymentMethods,
            orderTypeValue?.pickupType,
            isApplePayAvailable,
          );
        default:
          return PaymentHelper.getFinalizeOrderPaymentMethodLabelsAndIcons(
            dineInPaymentMethods,
            orderTypeValue?.pickupType,
            isApplePayAvailable,
          );
      }
    };

    const [paymentMethod, setPaymentMethod] = useState<Opt<PaymentMethod>>(undefined);

    const updatePaymentMethodLabel = () => {
      const paymentMethodLabels = getPaymentMethodLabels();
      setPaymentMethod(
        paymentMethodLabels && paymentMethodLabels.length > 0
          ? paymentMethodLabels[0].method
          : undefined,
      );
    };

    // Order Info
    const [deliveryLocationFields, setDeliveryLocationFields] = useState<Opt<LocationField[]>>(
      OrdersHelper.getDeliveryCustomFieldsFromStorage(deliveryLocationCustomFields),
    );

    const [carInfo, setCarInfo] = useState<Opt<CarInfo>>(FBStorage.getCarInfo);
    const [paymentReference, setPaymentReference] = useState<string>('');
    const [nameError, setNameError] = useState(false);
    const [nameErrorMsg, setNameErrorMsg] = useState(undefined);
    const [phoneError, setPhoneError] = useState(false);
    const [name, setName] = useState<string>(guest?.name ?? '');
    const [showDeliveryLocationFields, setShowDeliveryLocationFields] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState<string>(guest?.phoneNumber ?? '');
    const { dineIn, setDineIn } = useDineIn();
    const totalForItems = CartHelper.getTotalPriceForItems(cart.items, discount);
    const {
      createOrder,
      createdOrder,
      success,
      error,
      setError,
      loading: loadingOrder,
      setLoading: setLoadingOrder,
    } = useCreateOrder();
    const defaultSelection =
      OrdersHelper.getLabelFromOrderType(
        OrdersHelper.getDefaultOrderTypeFromBrowseType(
          browseType,
          selectedStore?.settings?.orderTypes,
        ),
        selectedStore,
      ) || undefined;
    const confirm = useConfirm();
    const [orderTypeValue, setOrderTypeValue] = useState<Opt<Selection>>(
      defaultSelection ? defaultSelection[0] : undefined,
    );

    const showCarInfo = () =>
      orderTypeValue?.pickupType === PickupType.DriveThru ||
      orderTypeValue?.pickupType === PickupType.Curbside;

    const orderTypes = () => {
      const methods: Selection[] = [];
      if (selectedStore?.settings?.orderTypes) {
        selectedStore.settings.orderTypes.map(type => {
          const selections = OrdersHelper.getLabelFromOrderType(type, selectedStore);

          selections && methods.push(...selections);
        });
        return methods;
      }
      return methods;
    };

    const onStartCheck = async () => {
      setLoadingOrder(true);
      const savedCheck = await startCheck({
        location: {
          merchantId: selectedStore?.merchantId,
          storeId: selectedStore?.id,
          code: dineIn?.tableNumber,
          type: CheckLocationType.Table,
        },
        check: {
          merchantId: selectedStore?.merchantId,
          storeId: selectedStore?.id,
          customer: guest,
          isOpenTab: selectedStore?.settings?.orders?.dineIn?.isOpenTab,
        },
      });
      setCheckLocation(dineIn?.tableNumber);
      setLoadingOrder(false);
      // onClosePress();
      return savedCheck;
    };

    // Delivery
    const deliveryFee = selectedStore?.settings?.deliveryFee;
    const deliveryRadius = selectedStore?.settings?.orders?.delivery?.radius;
    const minimumDeliveryOrder = selectedStore?.settings?.minimumDeliveryOrder;
    const calculatedServiceFee = CartHelper.calculateServiceFee(
      cart.items,
      orderTypeValue?.value === OrderType.Delivery ? deliveryFee : undefined,
      serviceFee,
    );

    const paidTax = CartHelper.getPaidTax(
      cart.items,
      orderTypeValue?.value === OrderType.Delivery ? deliveryFee : undefined,
      tax,
      discount,
    );

    const total = CartHelper.getTotalPrice(
      cart.items,
      orderTypeValue?.value === OrderType.Delivery ? deliveryFee : undefined,
      serviceFee,
      tax,
      discount,
    );

    console.log('total', total);
    const subtotal = CartHelper.getTotalPriceForItems(cart.items, discount);

    const getDeliveryFee = () => {
      const deliveryFeeValue = CartHelper.getDeliveryFee(
        deliveryFee,
        deliveryRadius,
        selectedStore,
      );
      return orderTypeValue?.value === OrderType.Delivery && deliveryFeeValue > 0
        ? deliveryFeeValue
        : undefined;
    };

    const getEnablePayment = () => {
      if (!enablePaymentMethods) {
        return false;
      } else if (
        orderTypeValue?.value === OrderType.DineIn &&
        dineInPaymentMethods &&
        dineInPaymentMethods?.length > 0
      ) {
        return true;
      } else if (
        orderTypeValue?.value === OrderType.Pickup &&
        pickupPaymentMethods &&
        pickupPaymentMethods?.length > 0
      ) {
        return true;
      } else if (
        orderTypeValue?.value === OrderType.Delivery &&
        deliveryPaymentMethods &&
        deliveryPaymentMethods?.length > 0
      ) {
        return true;
      }

      return false;
    };

    const minimumDeliveryError = () =>
      minimumDeliveryOrder !== undefined &&
      subtotal < minimumDeliveryOrder &&
      orderTypeValue?.value === OrderType.Delivery;

    useEffect(() => {
      if (error) {
        setDisableSubmitButton(false);
        setError(undefined);
        setLoadingPayment(false);
      }
    }, [error]);

    // useEffect(() => {
    //   setPaymentReference(uuid());
    // }, []);

    useEffect(() => {
      updatePaymentMethodLabel();
      if (orderTypeValue?.value === OrderType.Delivery && nearestStore && setSelectedStore) {
        setSelectedStore(nearestStore);
      }
    }, [orderTypeValue, nearestStore]);

    useEffect(() => {
      if (check !== undefined && check?.storeId !== selectedStore?.id) {
        setCheckLocation(undefined);
      }
    }, [selectedStore]);

    useEffect(() => {
      deliveryTypes = selectedStore?.settings?.deliveryTypes;
      setShowDeliveryLocationFields(updateShowDeliveryLocationFields());
    }, [cart, selectedStore, orderTypeValue]);

    useEffect(() => {
      if (success && !error && createdOrder) {
        createdOrder.guest && setGuest && setGuest(createdOrder.guest);
        if (loadingPayment) {
          FBStorage.setOrderId(createdOrder.id!);
          setGoToPayment(true);
        } else {
          setTransitionToOrderPage(true);
          setOpen(false);
          createdOrder.dineIn && setDineIn(createdOrder.dineIn);
          setTimeout(() => {
            goToOrderPage(createdOrder);
          }, 1500);
        }
      }
      if (error) {
        setDisableSubmitButton(false);
        const asyncAlert = async () => {
          try {
            await confirm({
              title: t('common:alert'),
              description: error,
              confirmationText: t('common:ok'),
              cancellationText: '',
              confirmationButtonProps: {
                color: 'default',
              },
            });
          } catch (e) {
            console.log(e);
          }
        };
        asyncAlert();
      }
      // Check if the selected branch delivers to my location
      const isSupported = isLocationSupported(selectedStore, FBStorage.getLocation());
      setIsSupported && setIsSupported(isSupported);
    }, [success, error]);

    const goToOrderPage = (createdOrder: Order) => {
      const openTap = selectedStore?.settings?.orders?.dineIn?.isOpenTab;
      updateCart([], UpdateCartType.Clear);
      if (openTap === true && orderTypeValue?.value === OrderType.DineIn) {
        URLHelper.goToOrdersPage(createdOrder?.dineIn);
      } else {
        URLHelper.goToOrderPage(createdOrder);
      }
    };

    const validateDineInInput = () => {
      const errors = [];
      if (checkLocation && checkLocation.length > 0) {
        setNameTableNoError(false);
      } else {
        setNameTableNoError(true);
        errors.push(true);
      }

      return errors.length === 0;
    };

    const validateRequiredFields = () => {
      const errors = [];
      const { userFields } = selectedStore?.settings || {};
      const isPayingOnline =
        paymentMethod !== PaymentMethod.Cash && paymentMethod !== PaymentMethod.PayAtStore;
      if (!orderTypeValue) {
        errors.push(true);
      }
      // if (isPayingOnline && !name) {
      //   setNameError(true);
      //   setNameErrorMsg(t('order:nameRequired'));
      //   errors.push(true);
      // }
      if (orderTypeValue?.value === OrderType.Delivery) {
        deliveryLocationCustomFields?.forEach(field => {
          if ((!field.value || field.value.trim() === '') && field.isRequired) {
            field.error = true;
            deliveryLocationFields &&
              setDeliveryLocationFields([
                ...(selectedStore?.settings?.orders?.delivery?.locationFields ?? []),
              ]);
            errors.push(true);
          }
        });
      }
      userFields?.forEach(userField => {
        if (
          userField.type === UserFieldType.PhoneNumber &&
          userField.isRequired &&
          !validatePhone(phoneNumber)
        ) {
          errors.push(true);
        }

        if (userField.type === UserFieldType.Name && userField.isRequired && !validateName(name)) {
          errors.push(true);
        }
      });
      return errors.length === 0;
    };

    const validateDeliveryPickupInput = () => {
      const errors = [];
      if (!validatePhone(phoneNumber)) {
        errors.push(true);
      }
      if (minimumDeliveryError()) {
        errors.push(true);
      }
      if (!validateName(name)) {
        setNameError(true);
        errors.push(true);
      }

      if (
        orderTypeValue?.value === OrderType.Delivery &&
        showAddressInput() &&
        getLocationError()
      ) {
        errors.push(true);
      }

      return errors.length === 0;
    };

    // const trackedSources = URLHelper.getAllTrackingParams();

    console.log('trackedSources', window.location.href);

    const order = (paymentId: string): Opt<Order> => {
      const deliveryLocation = LocationHelper.generateUniqueCodeForUnknownAddressIfNeeded(location);
      if (
        !deliveryLocation &&
        orderTypeValue?.value === OrderType.Delivery &&
        (!deliveryTypes || deliveryTypes.find(type => type === DeliveryType.FleetManagement))
      ) {
        alert(t('location:unsupportedLocationLabel'));
        return;
      }
      return {
        items: cart.items,
        store: selectedStore,
        type: orderTypeValue?.value,
        merchantId: selectedStore?.merchantId ?? '',
        guest: {
          ...guest,
          name,
          phoneNumber: PhoneHelper.toE164Format(phoneNumber)!,
          language: rtl ? Language.Arabic : Language.English,
        },
        //   user: {
        //   ...guest,
        //   name,
        //   region: 'SA',
        //   phoneNumber: PhoneHelper.toE164Format(phoneNumber)!!,
        //   language: rtl ? Language.Arabic : Language.English,
        // },
        // TODO: Will we remove this for certain order types?
        deductions: discount && [
          {
            id: discount.id,
            amount: CartHelper.getDiscount(cart.items, discount),
            type: 'DISCOUNT',
          },
        ],
        serviceFee: serviceFee,
        check:
          check && orderTypeValue?.value === OrderType.DineIn
            ? {
                id: check.id,
              }
            : undefined,
        delivery:
          orderTypeValue?.value === OrderType.Delivery
            ? {
                deliveryFee,
                location: {
                  ...deliveryLocation!,
                  customFields: getDeliveryLocationFields(),
                },
                type: deliveryTypes ? deliveryTypes[0] : DeliveryType.FleetManagement,
              }
            : undefined,

        dineIn:
          orderTypeValue?.value === OrderType.DineIn
            ? {
                ...dineIn,
                id: check?.id,
                tableNumber: checkLocation ?? dineIn?.tableNumber,
              }
            : undefined,
        total,
        subtotal,
        tax: tax && {
          ...tax,
          value: paidTax,
        },
        referringUrl: encodeURI(window.location.href),
        pickup:
          orderTypeValue?.value === OrderType.Pickup
            ? {
                type: orderTypeValue.pickupType ?? PickupType.InStore,
                driveThru: carInfo && {
                  carInfo,
                },
              }
            : undefined,
        currency: selectedStore?.settings?.currency,
        paymentTransaction: paymentMethod && {
          id: paymentId,
          method: paymentMethod,
          storeId: selectedStore?.id,
          amount: total,
          status: paymentMethod === PaymentMethod.ApplePay ? PaymentStatus.Settled : undefined,
        },
      };
    };

    const getDeliveryLocationFields = () => {
      if (
        orderTypeValue?.value === OrderType.Delivery &&
        selectedStore?.settings?.orders?.delivery?.type === DeliveryType.OnDemand
      ) {
        return undefined;
      }
      return deliveryLocationFields;
    };

    const validateInput = () => {
      const errors = [];
      if (orderTypeValue?.value === OrderType.DineIn && !validateDineInInput()) {
        errors.push(true);
      } else if (orderTypeValue?.value !== OrderType.DineIn && !validateDeliveryPickupInput()) {
        errors.push(true);
      }
      if (!validateRequiredFields()) {
        errors.push(true);
      }
      if (!validatePhone(phoneNumber)) {
        errors.push(true);
      }
      return errors.length === 0;
    };

    const handleCloseClick = () => {
      setOpen(false);
      setLoadingOrder(false);
    };

    const handleTableNumberChange = (tableNumber: string) => {
      setDineIn({ ...dineIn, tableNumber });
      setCheckLocation(tableNumber);
    };

    const validateName = (name: string) => {
      if (name.length === 0) {
        setNameError(true);
        return false;
      } else {
        setNameError(false);
        return true;
      }
    };

    const validatePhone = (phone: string) => {
      const isValid = PhoneHelper.isValidPhone(phone);
      setPhoneError(!isValid);
      return isValid;
    };

    const handleNameChange = (name: string) => {
      if (nameError) {
        validateName(name);
      }
      setName(name);
    };

    const handlePhoneChange = (phone: string) => {
      if (phoneError) {
        validatePhone(phone);
      }
      setPhoneNumber(phone);
    };

    const handleStoreChange = async (storeId: string) => {
      const newSelection = stores.find(store => storeId === store.id);
      if (newSelection && setSelectedStore) {
        setSelectedStore(newSelection);
      }
    };

    const handleChangeAddressClick = () => locationInputRef.current?.setOpen(true);

    const getLocationError = () =>
      !isSupported ||
      !location ||
      location?.coordinates === undefined ||
      location?.coordinates === null;

    const minimumDeliveryErrorText = () => {
      if (minimumDeliveryError()) {
        return `${t('order:minimumDeliveryOrder')} ${minimumDeliveryOrder} SAR`;
      }
      return '';
    };

    const deliveryErrorText = () => {
      if (getLocationError()) {
        const isMissing = location?.coordinates === undefined || location?.coordinates === null;
        if (isMissing) {
          return `${t('location:generalLocationError')}`;
        } else {
          SafaryAnalytics.logEvent(Event.OutOfRadiusError);
          return `${t('location:unsupportedLocationLabel')} ${
            selectedStore?.settings?.orders?.delivery?.radius?.value ??
            selectedStore?.settings?.orders?.delivery?.maxRadius?.value
          } KM`;
        }
      }
      return '';
    };

    const handleOrderTypeChange = (key: number) => {
      const orderType = orderTypes().find(type => type.key === key);
      if (orderType) {
        const newURL = URLHelper.updateURLParameter(
          window.location.href,
          'type',
          OrdersHelper.getBrowseTypeFromOrderType(orderType?.value).toString(),
        );
        window.history.replaceState({}, '', newURL);
        setOrderTypeValue(orderType);
        SafaryAnalytics.logEvent(Event.ChangeOrderType);
      }
    };

    const handleDeliveryLocationFieldInput = (label: Label, value: string) => {
      for (let i = 0; i < deliveryLocationFields!.length; i++) {
        const field = deliveryLocationFields![i];
        if (JSON.stringify(field.label) === JSON.stringify(label)) {
          deliveryLocationFields![i].value = value;
        }
      }
      deliveryLocationFields && setDeliveryLocationFields([...deliveryLocationFields]);
      deliveryLocationFields && FBStorage.setDeliveryLocationFields(deliveryLocationFields);
    };

    const handlePaymentMethodChange = (value: PaymentMethod) => {
      setPaymentMethod(value);
    };

    const handleApplePayClick = async () => {
      if (validateInput()) {
        const id = uuid();
        const order1 = order(id);
        if (order1) {
          setPaymentReference(id);
          setLoadingPayment(true);
          console.log('newId', paymentReference);
          console.log('newId order1', order1);

          // Create a pending order first
          showApplePay(
            {
              label: selectedStore?.name.en ?? 'Foodbit',
              name: name ?? 'Unknown',
              amount: total,
              paymentReference: id,
            },
            order1!,
            (success: boolean, order?: Order, redirectUrl?: string) => {
              if (success && order) {
                // SafaryAnalytics.logEvent(Event.PreApplePayOrderLog, {});
                // handleCreateOrder(id);
                redirectUrl && URLHelper.goToPage(redirectUrl);
                console.log('handleCreateOrder', redirectUrl);
              }
              setLoadingPayment(false);
            },
          );
        }
      }
    };

    const handleGoToPaymentButtonClick = () => {
      const id = uuid();
      setPaymentReference(id);
      const order1 = order(id);
      if (validateInput() && order1) {
        setDisableSubmitButton(true);
        setLoadingPayment(true);
        createOrder(
          {
            ...order1!,
            status: OrderStatus.PendingPayment,
          },
          true,
        );
        // FBStorage.setOrderId(order1.id);
      }
    };

    const handleCarInfoChange = (_carInfo: CarInfo) => {
      const newCarInfo = { ...carInfo, ..._carInfo };
      setCarInfo(newCarInfo);
      FBStorage.setCarInfo(newCarInfo);
    };

    const handleCreateOrder = async (id: string | undefined) => {
      const id_ = id ?? uuid();
      setPaymentReference(id_);
      const order1 = order(id_);
      // debugger;
      if (validateInput() && order1) {
        if (orderTypeValue?.value === OrderType.DineIn && (dineIn?.tableNumber || checkLocation)) {
          // setShowStartCheck && setShowStartCheck(true);
          // debugger;
          let savedCheck = await checkIsCheckOpened(dineIn?.tableNumber ?? checkLocation ?? '');
          console.log('savedCheck', savedCheck);
          savedCheck && setCheck(savedCheck);
          // const cachedCheckLocation = FBStorage.getCheckLocation();
          // if (savedCheck && (!cachedCheckLocation || cachedCheckLocation !== dineIn?.tableNumber)) {
          //   setShowStartCheck && setShowStartCheck(true);
          // } else {
          if (!savedCheck) {
            savedCheck = await onStartCheck();
          }
          order1 &&
            createOrder({
              ...order1,
              dineIn: {
                ...dineIn,
                tableNumber: checkLocation ?? dineIn?.tableNumber,
                id: savedCheck?.id,
              },
              check: {
                id: savedCheck.id,
              },
            });
          // onClosePress();
          // }
        }

        // Other than dine in types
        else {
          setPaymentReference(id_);
          const order1 = order(id_);
          setError(undefined);
          order1 && createOrder(order1);
          setDisableSubmitButton(true);
        }
      } else {
        setDisableSubmitButton(false);
      }
    };

    const showAddressInput = (): boolean => {
      if (orderTypeValue?.value === OrderType.Delivery) {
        if (
          deliveryTypes?.find(
            type => type === DeliveryType.FleetManagement || type === DeliveryType.OnDemand,
          )
        ) {
          return true;
        }
        // return deliveryTypes?.find(
        //   type => type === DeliveryType.FleetManagement || type === DeliveryType.OnDemand,
        // );
        //
        return (
          deliveryTypes !== undefined &&
          (deliveryLocationFields?.length ?? 0) > 0 &&
          deliveryLocationFields?.find(field => field.type === LocationFieldType.Address) !==
            undefined &&
          orderTypeValue?.value === OrderType.Delivery
        );
      }
      return false;
    };

    const updateShowDeliveryLocationFields = () => {
      if (deliveryTypes?.find(type => type === DeliveryType.OnDemand)) {
        return false;
      }
      return (
        !!deliveryTypes &&
        orderTypeValue?.value === OrderType.Delivery &&
        (selectedStore?.settings?.orders?.delivery?.locationFields?.length ?? 0) > 0 &&
        selectedStore?.settings?.orders?.delivery?.locationFields?.find(
          locationField => locationField.type === LocationFieldType.Address,
        ) === undefined &&
        deliveryTypes?.find(
          type => type === DeliveryType.FleetManagement || type === DeliveryType.OnDemand,
        ) === undefined
      );
    };

    const handleChangeTableNumber = () => {};
    //setShowStartCheck(true);

    const handleMapClick = () =>
      selectedStore &&
      selectedStore?.location &&
      selectedStore &&
      URLHelper.goToMap(selectedStore!.location!.coordinates);
console.log('deliveryLocationFields', deliveryLocationFields);
    return (
      <>
        <FinalizeOnlineOrderForm
          onChangeTableNumber={handleTableNumberChange}
          deliveryLocationFields={deliveryLocationFields}
          showDeliveryLocationFields={showDeliveryLocationFields}
          onDeliveryLocationFieldsChange={handleDeliveryLocationFieldInput}
          showName
          showPhone
          nameErrorMessage={nameErrorMsg}
          showMapIcon={
            (orderTypeValue?.value === OrderType.Delivery ||
              orderTypeValue?.value === OrderType.Pickup) &&
            selectedStore !== undefined
          }
          carInfo={carInfo}
          showCarInput={showCarInfo()}
          onMapClick={handleMapClick}
          showApplePayButton={paymentMethod === PaymentMethod.ApplePay}
          showSubmitButton={
            paymentMethod === PaymentMethod.PayAtStore || paymentMethod === PaymentMethod.Cash
          }
          onCarInfoChange={handleCarInfoChange}
          orderTotal={total}
          intializeSelectedStore={orderTypeValue?.value === OrderType.Delivery && !!nearestStore}
          totalPriceForItems={totalForItems}
          showPaymentsButton={PaymentHelper.isPaymentDebitCreditCard(paymentMethod)}
          onGoToPaymentButtonClick={handleGoToPaymentButtonClick}
          items={cart.items}
          paymentMethod={paymentMethod}
          onPaymentMethodChange={handlePaymentMethodChange}
          hidePaymentMethods={!getEnablePayment()}
          paymentMethodLabels={getPaymentMethodLabels()}
          serviceFee={calculatedServiceFee}
          paidTax={paidTax}
          tax={tax}
          deliveryFee={getDeliveryFee()}
          selectedStore={selectedStore}
          orderTypeError={minimumDeliveryError() || !orderTypeValue}
          orderTypeErrorText={minimumDeliveryErrorText()}
          onStoreChange={handleStoreChange}
          stores={stores}
          tableNumber={checkLocation}
          // onTableNumberChange={handleTableNumberChange}
          open={open}
          tableError={tableNoError}
          onCloseClick={handleCloseClick}
          headerImageUrl={selectedStore?.profilePic ?? ''}
          loading={loadingOrder || loadingPayment}
          onCreateOrderClick={() => handleCreateOrder(uuid())}
          disableSubmitButton={disableSubmitButton}
          name={name}
          nameError={nameError}
          phoneError={phoneError}
          phoneNumber={phoneNumber}
          onNameChange={handleNameChange}
          onPhoneChange={handlePhoneChange}
          phoneCountryCode={'sa'}
          orderTypeValue={orderTypeValue}
          onChangeAddressClick={handleChangeAddressClick}
          onOrderTypeChange={handleOrderTypeChange}
          deliveryAddressError={getLocationError()}
          deliveryErrorText={deliveryErrorText()}
          showDeliveryAddress={showAddressInput()}
          deliveryAddress={location && LocationHelper.getReadableLocation(location)}
          locationInputRef={locationInputRef}
          showTableNumber={orderTypeValue?.value === OrderType.DineIn}
          orderTypes={orderTypes()}
          paymentReference={paymentReference}
          goToPayment={goToPayment}
          onApplePayClick={handleApplePayClick}
        />
        <StartCheckView
          tableNumber={dineIn?.tableNumber}
          open={showStartCheck}
          onClosePress={async (check?: Check) => {
            // debugger;
            const id = uuid();
            setPaymentReference(id);
            const order1 = order(id);
            setLoadingOrder(true);
            order1 &&
              (await createOrder(
                {
                  ...order1,
                  check: {
                    id: check?.id,
                  },
                  dineIn: {
                    ...dineIn,
                    tableNumber: dineIn?.tableNumber,
                    id: check?.id,
                  },
                },
                false,
              ));
            // setInternalCheck(savedCheck);
            setLoadingOrder(false);
            //   if (savedCheck) {
            //       setActiveStep(activeStep + 1);
            //   } else {
            //       await onStartCheck();
            setShowStartCheck(false);
          }}
          isUpdating={true}
        />
      </>
    );
  }),
);
