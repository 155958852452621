export const en = {
  storeClosed: 'Sorry, store is currently closed',
  notReceivingOrders: 'Sorry, the location is not receiving orders right now!',
  onlineStore: 'Online Store',
  discount: 'Enjoy the 50% discount on selected items. Expiring soon...',
};

export const ar: typeof en = {
  storeClosed: 'عفواً، المتجر حالياً مغلق.',
  notReceivingOrders: 'عفواً، الفرع لايستقبل طلبات حالياً',
  onlineStore: 'متجر الكتروني',
  discount:
    'خصم %50 لفترة محدوة على البانكيك والكوراسون والكريب والمشروبات الساخنة',
};
