import queryString from 'query-string';
import Cookies from 'universal-cookie';

import FBStorage from '@/utils/FBStorage';
import { isBrowser } from '@/utils/isBrowser';

export const setLangDirection = ({ initialData }: any, query?: any) => {
  const { stores } = initialData || {};
  const langParam = query?.lng;
  let language = langParam;
  if (stores && stores.length > 0 && !language) {
    const { languages } = stores[0].menu || {};
    language = languages?.primary;
  }
  if (language && language?.toString().length > 1) {
    FBStorage.setLanguage(language);
  }
};

export const getLang = (): string => {
  if (isBrowser()) {
    const paramLang = queryString.parse(window.location.search).lng;
    if (
      paramLang &&
      paramLang !== 'undefined' &&
      paramLang !== null &&
      paramLang !== 'null' &&
      paramLang !== ''
    ) {
      return paramLang.toString();
    }
  }
  const lang = FBStorage.getLanguage();
  return lang !== undefined && lang !== null ? lang : 'ar';
};

export const getLangDirection = (lang: Opt<string> = undefined) => {
  let language: Opt<string> = lang;
  if (!language) {
    language = getLang();
  }
  const dir =
    language === 'ar' ||
    language === 'fas' ||
    language === 'ku' ||
    language === 'he' ||
    language === 'ur'
      ? 'rtl'
      : 'ltr';
  return dir;
};
