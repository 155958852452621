import React, { useCallback, useState } from 'react';

import Api from '@/api/Api';

export const useFetchOrder = () => {
  const [error, setError] = useState();
  const fetchOrder = useCallback(async (orderId: string) => {
    try {
      const order = await Api.fetchOrder(orderId);
      return order;
    } catch (error) {
      setError(error);
    }
    return undefined;
  }, []);

  return { fetchOrder, error };
};
