import React, { memo, useEffect } from 'react';
import Geocode from 'react-geocode';
import { useCurrentPosition } from 'react-use-geolocation';

import { useLocation } from '@/location/hooks/useLocation';
import { isLocationSupported } from '@/location/providers/LocationProvider';
import LocationHelper from '@/location/utils/LocationHelper';
import { useStore } from '@/store/hooks/useStore';
import FBStorage from '@/utils/FBStorage';

export const RequestLocationPermission = memo(() => {
  const [position, error] = useCurrentPosition();
  const { setLocation, setIsSupported, setIsLocationPermitted } = useLocation();
  const { selectedStore } = useStore();

  useEffect(() => {
    if (position && setLocation && setIsSupported && setIsLocationPermitted) {
      const location = {
        coordinates: { latitude: position.coords.latitude, longitude: position.coords.longitude },
      };
      // setLocation(location);
      const { latitude, longitude } = location!!.coordinates;
      Geocode.fromLatLng(latitude.toString(), longitude.toString()).then(
        response => {
          const address = response.results[0].formatted_address,
            addressArray = response.results[0].address_components,
            city = LocationHelper.getCity(addressArray),
            state = LocationHelper.getState(addressArray);
          const newLocation = {
            address: {
              city,
              street: address,
              state,
              zipCode: '',
              country: '',
            },
            ...location!!,
          };
          setLocation(newLocation);
          const isSupported = isLocationSupported(selectedStore, newLocation);
          setIsSupported(isSupported);
          setIsLocationPermitted(true);
          FBStorage.setLocation(newLocation);
        },
        error => {
          console.error(error);
        },
      );
    }
  }, [position]);
  return <></>;
});
