export const en = {
  addItemToCart: 'Add item to cart',
  tempUnavailable: 'Temporarily Unavailable',
  minimumRequired: 'Selections Required: ',
  reachedMaximumSelections: 'Maximum selections:',
  minimumRequiredError: 'You have an option with minimum selections required: ',
  priceTBD: 'Price is TBD',
  calories: 'Calories',
  timeUnavailableWarning: 'Available during: ',
  selectionRequired: 'Selection Required',
  selectionRequiredError: 'Selection required for',
  items: 'Items',
  vatNotIncluded: '*Tax is not included in price',
  vatIncluded: '*Tax is included in price',
  sushiWarning: 'RAW FOOD',
};

export const ar: typeof en = {
  addItemToCart: 'اضف الى السلًة',
  sushiWarning: 'يحتوي على طعام بحري غير مطبوخ',
  tempUnavailable: 'غير متوفر حالياً',
  minimumRequired: 'عدد الخيارات المطلوبة: ',
  reachedMaximumSelections: 'عدد الأختيارات المسوحة:',
  minimumRequiredError: 'عفواً، يوجد اختيار بأقل من الحد الأدني: ',
  priceTBD: 'السعر حسب الأختيارات',
  calories: 'س.ح',
  timeUnavailableWarning: 'متواجد في الأوقات التالية: ',
  selectionRequired: 'الرجاء الأختيار',
  selectionRequiredError: 'الرجاء الأختيار',
  items: 'أطباق',
  vatNotIncluded: '*السعر لا يشمل الضريبة المضافة',
  vatIncluded: '*السعر يشمل الضريبة المضافة',
};
