import { BrowseType } from '@/const/enums';
import { Store } from '@/types';

export default class MetaHelper {
  static SITE_NAME = 'FoodBit';
  public static getTitle(store: Store) {
    if (store.name.ar) {
      return `${store.name.en} - ${store.name.ar}`;
    }
    return `${store.name.en}`;
  }

  public static getCanonicalURL(siteUrl: string, handle: string) {
    return `${siteUrl}/${handle}`;
  }

  public static getCanonicalURLAr(siteUrl: string, handle: string) {
    return `${siteUrl}/${handle}?lng=ar`;
  }

  public static getDescriptionEn(store: Store, browseType: Opt<BrowseType>) {
    if (browseType === BrowseType.dinein) {
      return `Browse ${store.name.en ?? ''}'s menu and order for dine-in`;
    } else if (browseType === BrowseType.online) {
      return `Browse ${store.name.en ?? ''}'s menu and order online`;
    } else return `Browse ${store.name.en ?? ''}'s menu`;
  }

  public static getDescriptionAr(store: Store, browseType: Opt<BrowseType>) {
    if (browseType === BrowseType.dinein) {
      return `تصفح المنيو واطلب محلي من ${store.name?.ar ?? ''} | ${store.about?.ar ?? ''}`;
    } else if (browseType === BrowseType.online) {
      return `تصفح المنيو و اطلب اونلاين من ${store.name?.ar ?? ''} | ${store.about?.ar ?? ''}`;
    } else return `تصفح منيو ${store.name?.ar ?? ''} | ${store.about?.ar ?? ''}`;
  }

  public static getDescription(store: Store, lng: string, browseType: Opt<BrowseType>) {
    return lng === 'ar'
      ? MetaHelper.getDescriptionAr(store, browseType)
      : MetaHelper.getDescriptionEn(store, browseType);
  }
}
