import { DeliveryFeeType } from '@/const/enums';
import { t } from '@/i18n/translate';
import MenuItemHelper from '@/menu/utils/MenuItemHelper';
import {
  Cart,
  Currency,
  DeliveryFee,
  DeliveryRadius,
  Discount,
  Distance,
  MenuItem,
  ServiceFee,
  SimilarItemInCart,
  Store,
  Tax,
} from '@/types';

export default class CartHelper {
  static TAX = 15;
  /**
   * Get the subtitle to show for the menu item.
   *
   * @param item The menu item
   * @return The string for the subtitle
   */
  public static getCartMenuItemSubtitle(item: MenuItem, currency?: Currency): string {
    let str = `${CartHelper.getCartMenuItemSelectedOptionsString(item, currency)}`;
    if (item.notes) {
      str = `${str}\n${t('cart:notesToKitchen')}:\n${item.notes}`;
    }

    return str;
  }

  // public static getDeliveryFee(cart: Cart): number {
  //   const { store } = cart;
  //   if (store.nameEn === 'LA RUSTICA') {
  //     return 0;
  //   } else return 15;
  // }

  public static getDiscount(items: MenuItem[], discount: Discount): number {
    if (discount && discount.value > 0) {
      const discountedItems = items.filter(
        item => !!discount.menuItems.find(discountedItem => discountedItem.id === item.id),
      );
      let discountedTotal = 0;
      discountedItems.forEach(item => {
        discountedTotal += (item.price * discount.value) / 100.0;
      });
      return discountedTotal;
    }
    return 0;
  }
  /**
   * Get the total price for the items.
   *
   * @param items The cart with the items
   * @param deliveryFee
   * @param serviceFee
   * @return The total price
   */
  public static getTotalPrice(
    items: MenuItem[],
    deliveryFee?: Opt<DeliveryFee>,
    serviceFee?: Opt<ServiceFee>,
    tax?: Opt<Tax>,
    discount?: Discount,
  ): number {
    let total = this.getTotalPriceForItems(items);
    const taxForItems = tax?.isTaxIncludedInPrices
      ? 0
      : (total * (tax?.percentage ?? this.TAX)) / 100;

    if (discount && discount.value > 0) {
      total = total - this.getDiscount(items, discount);
    }
    const deliveryFeeValue = this.getDeliveryFee(deliveryFee);
    const taxForDelivery = tax?.isTaxIncludedInPrices
      ? 0
      : (deliveryFeeValue * (tax?.percentage ?? this.TAX)) / 100;
    const serviceFeeValue = this.calculateServiceFee(items, deliveryFee, serviceFee);
    let totalPrice =
      taxForItems + taxForDelivery + total + deliveryFeeValue + (serviceFeeValue ?? 0);
    const nonTaxable = CartHelper.getTotalNonTaxedItems(items, tax);
    console.log('nonTaxable', nonTaxable);
    if (tax?.isTaxIncludedInPrices === false) {
      totalPrice = totalPrice - nonTaxable;
    }
    return Number(totalPrice.toFixed(2));
  }

  public static getTotalNonTaxedItems(items: MenuItem[], tax?: Tax): number {
    let nonTaxTotal = 0;
    items.forEach(item => {
      if (item.notTaxable) {
        nonTaxTotal += item.price * ((tax?.percentage ?? 0) / 100);
      }
      item.options?.forEach(options => {
        options.items?.forEach(optionItem => {
          if (optionItem.notTaxable && optionItem.selected) {
            nonTaxTotal += optionItem.price * ((tax?.percentage ?? 0) / 100);
            console.log(
              'tax: ',
              optionItem.name,
              optionItem.price * ((tax?.percentage ?? 0) / 100),
            );
          }
        });
      });
    });

    return nonTaxTotal;
  }

  public static getTotalPriceForItems(items: MenuItem[], discount?: Discount): number {
    let total = 0;
    items.forEach(item => {
      total = total + MenuItemHelper.getMenuItemWithOptionsPrice(item);
    });

    let subtotal = Number(total.toFixed(2));
    if (discount && discount.value > 0) {
      const discountedItems = items.filter(
        item => !!discount.menuItems.find(discountedItem => discountedItem.id === item.id),
      );
      let discountedTotal = 0;
      discountedItems.forEach(item => {
        discountedTotal += (item.price * discount.value) / 100.0;
      });

      subtotal = Number((subtotal - discountedTotal).toFixed(2));
    }
    return subtotal;
  }

  public static getPaidTax(
    items: MenuItem[],
    deliveryFee?: Opt<DeliveryFee>,
    tax?: Opt<Tax>,
    discount?: Discount,
  ): number {
    const taxIncrease = ((tax?.percentage ?? this.TAX) + 100) / 100;
    const taxValue = (tax?.percentage ?? this.TAX) / 100;

    // Items
    const totalForItems = this.getTotalPriceForItems(items, discount);
    const totalForItemsBeforeTax = totalForItems / taxIncrease;
    let taxPaidForItems = 0;
    if (tax?.isTaxIncludedInPrices) {
      taxPaidForItems = totalForItems - totalForItemsBeforeTax;
    } else {
      taxPaidForItems = totalForItems * taxValue;
    }

    // Delivery
    const deliveryFeeValue = deliveryFee ? this.getDeliveryFee(deliveryFee) : 0;
    const deliveryFeeBeforeTax = deliveryFeeValue / taxIncrease;
    let taxPaidForDelivery = 0;
    if (tax?.isTaxIncludedInPrices) {
      taxPaidForDelivery = deliveryFeeValue - deliveryFeeBeforeTax;
    } else {
      taxPaidForDelivery = deliveryFeeValue * taxValue;
    }

    // Non tax
    const nonTaxable = CartHelper.getTotalNonTaxedItems(items, tax);
    console.log('nonTaxable', nonTaxable);
    if (tax?.isTaxIncludedInPrices === false) {
      taxPaidForItems = taxPaidForItems - nonTaxable;
    }

    return Number((taxPaidForItems + taxPaidForDelivery).toFixed(2));
  }

  public static getDeliveryFee(
    deliveryFee?: Opt<DeliveryFee>,
    deliveryRadius?: Distance,
    selectedStore?: Store,
  ): number {
    const deliveryFeeValue = deliveryFee?.base ?? 0;
    let total = 0;
    if (deliveryFee?.type === DeliveryFeeType.Hybrid) {
      if ((selectedStore?.distance ?? 1000) < (deliveryRadius?.value ?? 0)) {
        return deliveryFeeValue;
      }
      total =
        deliveryFee.base +
        ((selectedStore?.distance ?? 0) - deliveryFee.base) * (deliveryFee.rate ?? 0);
      return total;
    }

    return deliveryFee ? deliveryFeeValue : 0;
  }

  public static calculateServiceFee(
    items: MenuItem[],
    deliveryFee?: Opt<DeliveryFee>,
    serviceFee?: Opt<ServiceFee>,
  ): Opt<number> {
    if (!serviceFee || (serviceFee.percentage === 0 && serviceFee.flatFee === 0)) {
      return undefined;
    }
    const total = CartHelper.getTotalPriceForItems(items);
    const deliveryFeeValue = CartHelper.getDeliveryFee(deliveryFee);
    const serviceFeeAfterApplyingPercentage = serviceFee
      ? (total + deliveryFeeValue) * (serviceFee.percentage / 100.0)
      : 0;
    const serviceFeeFlatFee = serviceFee ? serviceFee.flatFee : 0;
    return Number((serviceFeeAfterApplyingPercentage + serviceFeeFlatFee).toFixed(2));
  }

  public static getQuantity(items: MenuItem[]): number {
    if (!items || items.length === 0) {
      return 0;
    }
    items.forEach(item => {
      if (!item.quantity || item.quantity === 0) {
        item.quantity = 1;
      }
    });
    return items.map(item => item.quantity).reduce((prev, current) => prev + current);
  }

  /**
   * Get the total price for the item (with options)
   *
   * @param item The menu item
   * @param currency The currency to display
   * @return The total price
   */
  public static getMenuItemPrice(item: MenuItem, currency?: Currency, discount?: Discount): string {
    const isDiscountedItem = !!discount?.menuItems?.find(item => item.id === item.id);
    const quantity = item.quantity > 0 ? item.quantity : 1;
    let optionsPrice = 0;
    if (item.options) {
      item.options.forEach(option => {
        option.items &&
          option.items.forEach(item => {
            if (item.selected) {
              optionsPrice = optionsPrice + item.price;
            }
          });
      });
    }
    let value = (item.price + optionsPrice) * quantity;
    if (isDiscountedItem && discount && discount.value > 0) {
      value = value - (value * discount.value) / 100;
    }
    return MenuItemHelper.getPriceWithCurrency(Number(value.toFixed(2)), currency);
  }

  public static doItemsNotExistInSelectedStoreMenu(
    cartItems: MenuItem[],
    selectedStore: Store,
  ): boolean {
    const errors: boolean[] = [];
    cartItems.forEach(item => {
      let itemExists = false;
      selectedStore.menu?.items.forEach(storeItem => {
        if (item.id === storeItem.id) {
          itemExists = true;
        }
      });
      if (!itemExists) {
        errors.push(false);
      }
    });
    return errors.length > 0;
  }

  /**
   * Get the readable string for the selected options for the menu item.
   *
   * @param item The menu item
   * @return The string for the selected options
   */
  public static getCartMenuItemSelectedOptionsString(item: MenuItem, currency?: Currency): string {
    if (!item.options) {
      return '';
    }

    let stringBuilder = '';

    // If the options cost money, let's add the price of the original item just for clarification
    const optionsAreNotFree =
      item.options?.filter(
        options =>
          options.items &&
          options.items.filter(option => option.selected && option.price > 0).length > 0,
      ).length > 0;
    if (item.options && item.options?.length > 0 && optionsAreNotFree) {
      // Add the price of the item itself on separate line
      stringBuilder = stringBuilder.concat(
        `${MenuItemHelper.getTitle(item)} (${MenuItemHelper.getPriceWithCurrency(
          item.price,
          currency,
        )})`,
      );
      stringBuilder = stringBuilder.concat('\n');
    }

    item.options?.forEach(options => {
      let sectionAdded = false;
      options.items?.forEach(item => {
        if (item.selected) {
          if (!sectionAdded) {
            stringBuilder = stringBuilder.concat(MenuItemHelper.getOptionsTitle(options));
            stringBuilder = stringBuilder.concat(':\n');
            sectionAdded = true;
          }
          stringBuilder = stringBuilder.concat('- ');
          stringBuilder = stringBuilder.concat(
            MenuItemHelper.getOptionTitle(item, options, true, currency),
          );
          stringBuilder = stringBuilder.concat('\n');
        }
      });
      // Add space between each options' section
      if (sectionAdded) {
        stringBuilder = stringBuilder.substring(0, stringBuilder.length - 1);
        stringBuilder = stringBuilder.concat('\n');
      }
    });

    // Remove ',' at the end
    if (stringBuilder.length > 0) {
      stringBuilder = stringBuilder.substr(0, stringBuilder.length - 1);
      stringBuilder = stringBuilder.concat('\n');
    }

    return stringBuilder;
  }

  /**
   * Does the given item already exist in cart and does it include the same options.
   *
   * @param cart The cart to check.
   * @param item The item to check for.
   * @return True if the item exists or false otherwise and the index if it's true
   */
  public static doesItemExistInCart(cart: Opt<Cart>, item: MenuItem): SimilarItemInCart {
    const similarItemInCart: SimilarItemInCart = {
      isSimilar: false,
      index: 0,
    };

    if (!cart) {
      return similarItemInCart;
    }

    if (cart.items.length > 0) {
      for (let i = 0; i < cart.items.length; i++) {
        if (MenuItemHelper.areBothItemsSimilar(cart.items[i], item)) {
          similarItemInCart.isSimilar = true;
          similarItemInCart.index = i;
          break;
        }
      }
    }
    return similarItemInCart;
  }
}
