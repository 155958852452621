import {
  Box,
  Button,
  Card,
  CardContent,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { CheckCircle, Error } from '@material-ui/icons';
import React, { memo } from 'react';

import URLHelper from '@/common/utils/URLHelper';
import { t } from '@/i18n/translate';
import { isBrowser } from '@/utils/isBrowser';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    center: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
    card: {
      flex: 1,
      width: '100%',
      minWidth: 300,
      maxWidth: 400,
      maxHeight: 214,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    cardContent: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    field: {
      marginBottom: 10,
    },
    logoContainer: {
      marginBottom: 10,
    },
    logo: {
      width: 152,
      height: 53,
    },
    subHeading: {
      fontSize: 18,
      color: theme.palette.primary.light,
      marginBottom: 18,
    },
  }),
);

export const ReservationPaymentStatusPage = memo(() => {
  const classes = useStyles();
  const status = URLHelper.getURLParams('status');
  const TABLE_RESERVATION_REDIRECT = isBrowser()
        ? window.env.TABLE_RESERVATION_REDIRECT
        : process.env.TABLE_RESERVATION_REDIRECT!;

  return (
    <div className={classes.root}>
      <div className={classes.center}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            {status === '1' ? (
              <CheckCircle
                fontSize="large"
                style={{
                  color: 'green',
                }}
              />
            ) : (
              <Error fontSize="large" color="error" />
            )}
            <Box display="flex" alignItems="center" flexDirection="column" mt={2}>
              <Typography variant="h5">
                {status === '1'
                  ? t('payment:reservationSuccessTitle')
                  : t('payment:reservationFailTitle')}
              </Typography>
              <Typography align="center" variant="h6" color="textSecondary">
                {status === '1'
                  ? t('payment:reservationSuccessMessage')
                  : t('payment:reservationFailMessage')}
              </Typography>
            </Box>
          </CardContent>
        </Card>
          <Box display="flex" alignItems="center" flexDirection="column" mt={2}>
              <Button href={TABLE_RESERVATION_REDIRECT} variant="outlined">
                  {t('common:backToHome')}
              </Button>
          </Box>
      </div>
    </div>
  );
});

export default ReservationPaymentStatusPage;
