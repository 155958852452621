import React from 'react';

interface BranchProps {
  url: Opt<string>;
  params: Opt<any>;
  logBranchEvent?: (event: string, params: Dict) => void;
}
export const BranchContext = React.createContext<BranchProps>({
  url: undefined,
  params: undefined,
});
