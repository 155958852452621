import { Box, createStyles, Dialog, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { memo, useEffect, useState } from 'react';
import ReactCodeInput from 'react-code-input';

import Button from '@/common/components/CustomButton';
import { uuid } from '@/common/utils/uuid';
import { CheckLocationType } from '@/const/enums';
import { useGuest } from '@/guest/hooks/useGuest';
import { t } from '@/i18n/translate';
import { useDineIn } from '@/orders/hooks/useDineIn';
import { useStore } from '@/store/hooks/useStore';
import { useRTL } from '@/theme/hooks/useRTL';
import { Check, Order } from '@/types';
import DateHelper from '@/utils/DateHelper';
import FBStorage from '@/utils/FBStorage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      padding: theme.spacing(2),
    },
    formsContainer: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    form: {
      marginBottom: theme.spacing(2),
    },
    button: {
      fontWeight: 'bold',
      height: '7%',
      color: 'white',
      marginTop: theme.spacing(2),
      width: '100%',
      bottom: 1,
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    img: {
      maxWidth: '18%',
      borderRadius: 30,
    },
    formControl: {
      margin: theme.spacing(1),
    },
    title: {
      marginTop: theme.spacing(2),
    },
  }),
);

interface Props {
  open: boolean;
  onClosePress: (check?: Check) => void;
  isUpdating?: boolean;
  tableNumber?: string;
}

export interface SignupViewMethods {}

export const StartCheckView = memo<Props>(
  ({ tableNumber, open, onClosePress, isUpdating = false }) => {
    const { selectedStore } = useStore();
    const [loading, setLoading] = useState(false);
    const { checkIsCheckOpened, setCheck, setCheckLocation, startCheck, check } = useDineIn();
    const [activeStep, setActiveStep] = useState(0);
    // const [tableNumber, setTableNumber] = useState<string>(FBStorage.getCheckLocation() ?? '');
    const [internalCheck, setInternalCheck] = useState<Opt<Check>>(undefined);
    const { guest } = useGuest();

    const classes = useStyles();
    const rtl = useRTL();

    const renderHeader = () => (
      <div className={classes.header}>
        <img src={selectedStore?.profilePic} className={classes.img} />
      </div>
    );

    const onStartCheck = async () => {
      setLoading(true);
      const savedCheck = await startCheck({
        location: {
          merchantId: selectedStore?.merchantId,
          storeId: selectedStore?.id,
          code: tableNumber,
          type: CheckLocationType.Table,
        },
        check: {
          id: uuid(),
          merchantId: selectedStore?.merchantId,
          storeId: selectedStore?.id,
          customer: guest,
          isOpenTab: selectedStore?.settings?.orders?.dineIn?.isOpenTab,
        },
      });
      setCheckLocation(tableNumber);
      setLoading(false);
      onClosePress(savedCheck);
    };

    const onResumeCheck = async () => {
      // debugger;
      if (tableNumber) {
        if (!check) {
          const savedCheck = await checkIsCheckOpened(tableNumber);
          // setInternalCheck(savedCheck);
          savedCheck && setCheck(savedCheck);
          setCheckLocation(tableNumber);
          onClosePress(check ?? savedCheck);
          return;
        }
      }
      onClosePress(check);
    };

    const getStepsContent = () => {
      // if (activeStep === 0) {
      //   return tableNumberForm();
      // }
      return (
        <Typography>
          {t('order:existingCheck', {
            date:
              internalCheck &&
              DateHelper.elapsedTime(
                DateHelper.convertZFormatStringDateToDate(internalCheck!.createdDate!),
              ),
            total: internalCheck?.total,
          })}
        </Typography>
      );
    };

    // const onTableNumberChange = (tableNumber: string) => setTableNumber(tableNumber);

    // const tableNumberForm = () => (
    //   <Box ml={1} mr={1}>
    //     <div className={classes.formsContainer}>
    //       <ReactCodeInput
    //         value={tableNumber !== null && tableNumber ? tableNumber : 0}
    //         onChange={onTableNumberChange}
    //         type="number"
    //         autoFocus
    //         inputMode="numeric"
    //         fields={2}
    //         dir={'ltr'}
    //       />
    //     </div>
    //   </Box>
    // );

    // const next = async () => {
    //   setLoading(true);
    //   const savedCheck = await checkIsCheckOpened(tableNumber);
    //   setInternalCheck(savedCheck);
    //   setLoading(false);
    //   if (savedCheck) {
    //     setActiveStep(activeStep + 1);
    //   } else {
    //     await onStartCheck();
    //     onClosePress();
    //   }
    // };

    return (
      <Dialog
        fullWidth
        onBackdropClick={() => {
          setActiveStep(0);
        }}
        open={open}
        onClose={onClosePress}
        onEscapeKeyDown={() => onClosePress()}>
        <div className={classes.dialog} dir={rtl ? 'rtl' : 'ltr'}>
          {renderHeader()}

          <Typography color="textPrimary" variant="h6" className={classes.title}>
            {t('order:tableNumber')}
          </Typography>

          {/*<Stepper activeStep={activeStep} alternativeLabel orientation="horizontal">*/}
          {/*  {getSteps().map((label, key) => (*/}
          {/*    <Step key={label}>*/}
          {/*      <StepLabel>{label}</StepLabel>*/}
          {/*    </Step>*/}
          {/*  ))}*/}
          {/*</Stepper>*/}
          {getStepsContent()}

          <Button
            variant="contained"
            style={{
              marginTop: 20,
            }}
            color="default"
            onClick={onResumeCheck}>
            {t('order:resumeCheck')}
          </Button>

          <Box mt={2}>
            <Button
              color="secondary"
              loading={loading}
              variant="text"
              style={{
                marginTop: 5,
              }}
              onClick={onStartCheck}>
              {t('order:startNewCheck')}
            </Button>
          </Box>
        </div>
      </Dialog>
    );
  },
);
