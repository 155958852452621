/* eslint-disable react-native/no-raw-text */
// @ts-ignore
import {
  createStyles,
  makeStyles,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import React, { memo } from 'react';

import CustomButton from '@/common/components/CustomButton';
import { t } from '@/i18n/translate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stepper: {
      height: 'auto',
    },
    stepperContainer: {
      backgroundColor: theme.palette.type === 'light' ? 'white' : theme.palette.primary.dark,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
    },
    bottomContainer: {
      marginTop: theme.spacing(1),
    },
    trackText: {
      textDecoration: 'underline',
    },
  }),
);

interface Props {
  activeStep: number;
  steps: string[];
  stepsContent: string[];
  onTrackingLinkClick: () => void;
  showTrackingLink?: boolean;
  showCallCourier?: boolean;
  onContactCourier?: () => void;
  showRateCourier?: boolean;
  showReadyForPickup?: boolean;
  loadingReadyForPickup?: boolean;
  onReadyForPickupClick: () => void;
  arrivedAtStoreButtonLabel: string;
  disableReadyForPickupButton?: boolean;
}

export const OrderStepper = memo<Props>(
  ({
    activeStep,
    steps,
    stepsContent,
    loadingReadyForPickup,
    onTrackingLinkClick,
    showTrackingLink,
    onContactCourier,
    showCallCourier,
    showRateCourier,
    showReadyForPickup,
    onReadyForPickupClick,
    arrivedAtStoreButtonLabel,
    disableReadyForPickupButton,
  }) => {
    const theme = useTheme();
    const classes = useStyles();
    const getCurrentActiveStep = () => {
      if (activeStep === steps.length - 1) {
        return activeStep + 1;
      }
      return activeStep;
    };
    return (
      <div className={classes.stepper}>
        <Stepper
          className={classes.stepperContainer}
          activeStep={getCurrentActiveStep()}
          orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                <div>
                  <Typography style={{ whiteSpace: 'pre-line' }}>
                    {`${stepsContent[index]} ${
                      showTrackingLink ? t('order:trackDeliveryHere') : ''
                    }`}
                  </Typography>
                  <div className={classes.row}>
                    {showCallCourier && (
                      <Typography
                        style={{ marginRight: theme.spacing(2) }}
                        align="center"
                        className={classes.trackText}
                        color="secondary"
                        onClick={onContactCourier}>
                        {t('order:contactDriver')}
                      </Typography>
                    )}
                    {showReadyForPickup && (
                      <CustomButton
                        disabled={loadingReadyForPickup || disableReadyForPickupButton}
                        loading={loadingReadyForPickup}
                        containerStyle={classes.bottomContainer}
                        fullWidth
                        variant="contained"
                        size="large"
                        onClick={onReadyForPickupClick}>
                        {arrivedAtStoreButtonLabel}
                      </CustomButton>
                    )}
                    {showTrackingLink && (
                      <Typography
                        className={classes.trackText}
                        color="secondary"
                        onClick={onTrackingLinkClick}>
                        {t('order:trackDriver')}
                      </Typography>
                    )}
                    {showRateCourier && (
                      <Typography
                        align="center"
                        className={classes.trackText}
                        color="secondary"
                        onClick={onTrackingLinkClick}>
                        {t('order:rateCourier')}
                      </Typography>
                    )}
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </div>
    );
  },
);
