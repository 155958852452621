import React from 'react';

import {DeliveryLocation} from '@/types';

interface LocationProps {
  location: Opt<DeliveryLocation>;
  setLocation?: (location: DeliveryLocation) => void;
  isSupported: boolean;
  setIsSupported?: (isSupported: boolean) => void;
  isLocationPermitted?: boolean;
  setIsLocationPermitted?: (permitted: boolean) => void;
}
export const LocationContext = React.createContext<LocationProps>({
  location: undefined,
  isSupported: true,
});
