import React, { memo, useEffect, useState } from 'react';

import Api from '@/api/Api';
import { useGuest } from '@/guest/hooks/useGuest';
import { useDineIn } from '@/orders/hooks/useDineIn';
import { useFetchOrders } from '@/orders/hooks/useFetchOrders';
import { Order } from '@/types';

import { OrdersContext } from './OrdersContext';

interface Props {
  children: React.ReactChild;
}

export const OrdersProvider = memo<Props>(({ children }) => {
  const { fetchOrders } = useFetchOrders();
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(false);

  const { guest } = useGuest();

  useEffect(() => {
    setLoading(true);
    const async = async () => {
      if (guest && guest.id) {
        setOrders(guest ? await fetchOrders(guest?.id!) : []);
        setLoading(false);
      }
    };
    async();
  }, [guest]);

  return (
    <OrdersContext.Provider value={{ orders, setOrders, loading }}>
      {children}
    </OrdersContext.Provider>
  );
});
