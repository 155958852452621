export default [
  {
    username: '+966505498124',
    password: 'password',
  },
  {
    username: '+966505498123',
    password: 'password',
  },
];
