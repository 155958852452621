import React from 'react';
import Lottie from 'react-lottie';

import * as animationData from '../../../assets/images/location.json';

const LocationAnimation = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return <Lottie options={defaultOptions} height={100} width={100} />;
};

export default LocationAnimation;
