import {
  Badge,
  createStyles,
  Fab,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import React, { memo, useContext } from 'react';

import { URLThemeType } from '@/const/enums';
import { t } from '@/i18n/translate';
import { useStore } from '@/store/hooks/useStore';
import { SetThemeContext } from '@/theme/providers/ThemeProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flex: 1,
      position: 'fixed',
      bottom: 55,
      left: 20,
      alignItems: 'center',
      justifyContent: 'center',
    },
    button: {
      fontWeight: 'bold',
      justifyContent: 'center',
      borderRadius: 0,
      alignSelf: 'center',
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    badge: {
      marginRight: theme.spacing(1),
    },
  }),
);

interface Props {
  onClick: () => void;
  hide: boolean;
  label: Opt<string>;
}
export const CartButton = memo<Props>(({ onClick, label, hide }) => {
  const classes = useStyles();
  const { selectedStore } = useStore();
  const { settings } = selectedStore || {};
  const { theme: customTheme } = settings || {};
  const { basic } = customTheme || {};
  // const { themeColor } = settings || {};
  // const isUsingTheme = basic && themeType === URLThemeType.Brand;
  const StyledBadge = withStyles(theme => ({
    badge: {
      right: -5,
      top: -5,
      border: `0px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
      color: 'white',
      backgroundColor: 'red',
    },
  }))(Badge);

  if (hide) {
    return <></>;
  }

  return (
    <div className={classes.container}>
      <Fab
        style={basic?.color ? { backgroundColor: basic?.color, color: 'white' } : {}}
        color={basic?.color ? undefined : 'secondary'}
        variant="extended"
        onClick={onClick}>
        <StyledBadge
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          variant="standard"
          badgeContent={label}>
          <ShoppingCartIcon className={classes.extendedIcon} />
          <Typography variant="button" className={classes.badge}>
            {t('cart:viewCart')}
          </Typography>
        </StyledBadge>
      </Fab>
    </div>
  );
});
