import TwitterConvTrkr from 'fb-react-twitter-conversion-tracker';
import React, { memo, useEffect, useState } from 'react';
import reactReferer from 'react-referer';

import SafaryAnalytics from '@/analytics/SafaryAnalytics';
import Api from '@/api/Api';
import { getBrowserTypeFromStore } from '@/browse/providers/BrowseTypeProvider';
import { labelOrDefault } from '@/common/utils/tools';
import URLHelper from '@/common/utils/URLHelper';
import { BrowseType, ThemeType, URLThemeType } from '@/const/enums';
import StoreHelper from '@/store/utils/StoreHelper';
import { useSetThemeType } from '@/theme/hooks/useSetThemeType';
import { getThemeType } from '@/theme/utils/getThemeType';
import { Discount, Merchant, Store } from '@/types';
import FBStorage from '@/utils/FBStorage';
import { isBrowser } from '@/utils/isBrowser';

// const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
// const options = {
//   autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
//   debug: false, // enable logs
// };
import { StoreContext } from './StoreContext';

interface Props {
  defaultStores: Store[];
  defaultStore: Store;
  children: React.ReactChild;
  referer?: string;
}

export const StoreProvider = memo<Props>(({ defaultStores, defaultStore, children, referer }) => {
  const [stores, setStores] = useState(defaultStores);
  const [isMamaNoura, setIsMamaNoura] = useState(false);
  const [selectedStore, setSelectedStore] = useState(defaultStore);
  const [merchant, setMerchant] = useState<Opt<string>>(undefined);
  const [nearestStore, setNearestStore] = useState<Opt<Store>>(undefined);
  const [notAcceptingOrders, setNotAcceptingOrders] = useState(false);
  const [opensAt, setOpensAt] = useState<string>();
  const [storeClosed, setStoreClosed] = useState(false);
  const [discount, setDiscount] = useState<Opt<Discount>>(undefined);
  const setThemeType = useSetThemeType();

  useEffect(() => {
    const selectedStoreId = FBStorage.getStoreId();
    if (selectedStoreId) {
      const sortedStores = StoreHelper.sortStores(stores);
      const foundSelectedStore: Opt<Store> = sortedStores.find(
        store => store.id === selectedStoreId,
      )!;
      if (foundSelectedStore !== undefined) {
        setSelectedStore(foundSelectedStore);
      } else if (sortedStores.length > 0) {
        setSelectedStore(sortedStores[0]);
      }
    }

    if (isBrowser()) console.log('referrer', URLHelper.getReferrer());
  }, []);

  useEffect(() => {
    const type = getBrowserTypeFromStore(selectedStore);
    if (type !== BrowseType.basic && selectedStore.merchantId) {
      const storedMerchant = FBStorage.getMerchantName(URLHelper.getURLHandle());
      if (storedMerchant) {
        setMerchant(storedMerchant);
      } else {
        const async = async () => {
          const m = await Api.fetchMerchant(selectedStore.merchantId!);
          const label = labelOrDefault(m.businessName, 'en');
          m.businessName && FBStorage.setMerchantName(URLHelper.getURLHandle(), label);
          setMerchant(label);
        };
        async();
      }
    }

    if (isBrowser() && selectedStore.merchantId === '1a410b4e-f118-4fe4-b577-c4d1da0bbca7') {
      import('react-facebook-pixel')
        .then(module => module.default)
        .then(ReactPixel => {
          console.log('React Pixel Init');
          ReactPixel.init('3173764769535113');
          ReactPixel.pageView();
        });
      TwitterConvTrkr.init('o8fsf');
      TwitterConvTrkr.pageView();
      TwitterConvTrkr.track('Order');
    }
  }, [selectedStore]);

  useEffect(() => {
    // Is the store receiving orders
    const { isReceivingOrders } = selectedStore!;
    // const isOpenNow = selectedStore.settings?.hoursOfOperations?.isOpenNow ?? true;
    const isOpenNowV2 = selectedStore.settings?.businessHours?.isOpenNow ?? true;

    setNotAcceptingOrders(!isReceivingOrders);
    setStoreClosed(!isOpenNowV2);
    // Saudi corner
    console.log('selectedStore', selectedStore);
    if (selectedStore.discounts && selectedStore.discounts?.length > 0) {
      const discount = selectedStore.discounts[0];
      if (discount.availability?.isAvailableNow === true) {
        setDiscount(discount);
      }
    }

    // Theme
    if (isBrowser()) {
      const themeType = getThemeType();
      const { theme } = selectedStore.settings || {};
      const { basic } = theme || {};

      if (basic) {
        setThemeType(basic?.type === ThemeType.Dark ? URLThemeType.Dark : URLThemeType.Light);
      } else {
        setThemeType(themeType);
      }
    }

    setIsMamaNoura(selectedStore?.merchantId === '9d92e1df-c714-4c21-ba16-51e1b8f56363');
  }, [selectedStore]);

  const handleSetSelectedInStore = (store: Store) => {
    FBStorage.setStoreId(store.id);
    setSelectedStore(store);
  };

  return (
    <StoreContext.Provider
      value={{
        stores,
        discount,
        isMamaNoura,
        nearestStore,
        selectedStore,
        notAcceptingOrders,
        storeClosed,
        opensAt,
        setStores,
        merchant,
        setMerchant,
        setSelectedStore: handleSetSelectedInStore,
        setNearestStore,
      }}>
      {children}
    </StoreContext.Provider>
  );
});
