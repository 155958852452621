import { Typography } from '@material-ui/core';
import React from 'react';

import { t } from '@/i18n/translate';

const Tax = ({
  taxValue,
  isTaxIncluded,
  ...props
}: {
  taxValue: Opt<number>;
  isTaxIncluded: Opt<boolean>;
}) => {
  const text = isTaxIncluded ? t('menu:vatIncluded') : t('menu:vatNotIncluded');
  return (
    <Typography component="span" variant="caption" color="textSecondary" {...props}>
      {text} {taxValue && ` (${taxValue}%)`}
    </Typography>
  );
};

export default Tax;
