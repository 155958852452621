import { Button, createStyles, makeStyles, Modal, Theme, Typography } from '@material-ui/core';
import React, { memo } from 'react';

import { HowItWorksView } from '@/greet/mobile/components/HowItWorksView';
import { t } from '@/i18n/translate';

const left = 50;
const top = 50;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      position: 'absolute',
      width: '70%',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      display: 'flex',
      outline: 'none',
      top: '50%',
      left: '50%',
      maxWidth: '350px',
      transform: `translate(-50%, -50%)`,
    },
    img: {
      width: 80,
      height: 80,
      borderRadius: 10,
    },
    name: {
      marginTop: theme.spacing(1),
    },
    centerText: {
      alignText: 'center',
    },
    welcome: {
      marginTop: theme.spacing(2),
    },
    welcomeMessage: {
      marginTop: theme.spacing(4),
    },
    buttons: {
      marginTop: theme.spacing(4),
    },
    howItWorks: {
      marginTop: theme.spacing(1),
    },
  }),
);

interface Props {
  open: boolean;
  onClosePress: () => void;
  onHowItWorksClick: () => void;
  openHowItWorks: boolean;
  onCloseHowItWorks: () => void;
  headerProfilePic: string;
  headerTitle: string;
  rtl: boolean;
}

export const DineInGreetView = memo<Props>(
  ({
    open,
    onClosePress,
    onHowItWorksClick,
    openHowItWorks,
    onCloseHowItWorks,
    headerProfilePic,
    headerTitle,
    rtl,
  }) => {
    const classes = useStyles();
    return (
      <>
        <Modal open={open} onClose={onClosePress}>
          <div className={classes.dialog} dir={rtl ? 'rtl' : 'ltr'}>
            <img alt="Safary" src={headerProfilePic} className={classes.img} />
            <Typography variant="subtitle1" color="textSecondary" className={classes.name}>
              {headerTitle}
            </Typography>

            <Typography
              align={'center'}
              variant="h5"
              color="textPrimary"
              className={classes.welcome}>
              {t('greet:welcome')}
            </Typography>

            <Typography
              align={'center'}
              variant="body1"
              color="textPrimary"
              className={classes.welcomeMessage}
              style={{ whiteSpace: 'pre-line' }}>
              {t('greet:welcomeMessage')}
            </Typography>

            <div className={classes.buttons}>
              <Button
                fullWidth
                color="secondary"
                variant="contained"
                size="large"
                onClick={onClosePress}>
                {t('greet:startOrdering')}
              </Button>
              <Button
                className={classes.howItWorks}
                fullWidth
                variant="outlined"
                size="large"
                onClick={onHowItWorksClick}>
                {t('greet:howItWorks')}
              </Button>
            </div>
          </div>
        </Modal>
        <HowItWorksView open={openHowItWorks} onClosePress={onCloseHowItWorks} />
      </>
    );
  },
);
