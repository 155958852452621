import { Dict, Mixpanel } from 'mixpanel-browser';
import React from 'react';

import { Event } from '@/analytics/Event';

interface AnalyticsProps {
  logEvent: (event: Event, params?: Dict) => void;
  registerSuperProperties: (params: Dict) => void;
}
export const AnalyticsContext = React.createContext<AnalyticsProps>({
  logEvent: event => {},
  registerSuperProperties: params => {},
});
