import React from 'react';

import { Check, CheckLocation, DineIn, StartCheck } from '@/types';

interface DineInContext {
  dineIn: Opt<DineIn>;
  check: Opt<Check>;
  setCheck: (value: Check) => void;
  checkLocation: Opt<string>;
  checkIsCheckOpened: (value: string) => Promise<Opt<Check>>;
  setDineIn: (value: Opt<DineIn>) => void;
  setCheckLocation: (value: Opt<string>) => void;
  // isCheckOpened: boolean;
  // setIsCheckOpened: (value: boolean) => void;
  startCheck: (start: StartCheck) => Promise<Check>;
}
export const DineInContext = React.createContext<DineInContext>({
  dineIn: undefined,
  check: undefined,
  checkLocation: undefined,
  setCheck: value => {},
  // setCheckLocation: value => {},
  setDineIn: value => {},
  // isCheckOpened: false,
  setCheckLocation: value => {},
  checkIsCheckOpened: value => Promise.prototype,
  startCheck: (start: StartCheck) => Promise.prototype,
});
