import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import Lottie from 'react-lottie';

import { t } from '@/i18n/translate';

import * as animationData from '../../../assets/images/empty.json';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    lottie: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    text: {
      marginTop: theme.spacing(1),
    },
  }),
);

const EmptyAnimation = () => {
  const classes = useStyles();
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <div className={classes.lottie}>
      <Lottie options={defaultOptions} height={150} width={150} />
      <Typography className={classes.text} variant="h6" color="textSecondary">
        {t('cart:emptyCart')}
      </Typography>
    </div>
  );
};

export default EmptyAnimation;
