import axios from 'axios';
import React from 'react';

import { Event } from '@/analytics/Event';
import SafaryAnalytics from '@/analytics/SafaryAnalytics';
import URLHelper from '@/common/utils/URLHelper';
import { OrderStatus } from '@/const/enums';
import { useGuest } from '@/guest/hooks/useGuest';
import { t } from '@/i18n/translate';
import { useCreateOrder } from '@/orders/hooks/useCreateOrder';
import { getOrigin } from '@/payment/mobile/container/PaymentPageContainer';
import { usePayment } from '@/payment/mobile/hooks/usePayment';
import PaymentHelper from '@/payment/utils/PaymentHelper';
import { useStore } from '@/store/hooks/useStore';
import { ApplePay, Order } from '@/types';
import FBStorage from '@/utils/FBStorage';
import { isBrowser } from '@/utils/isBrowser';
import StringsHelper from '@/utils/StringsHelper';

export const useApplePay = () => {
  const PAYFORT_PAYMENT_HANDLER_API_URL = isBrowser()
    ? window.env.PAYFORT_PAYMENT_HANDLER_API_URL
    : process.env.PAYFORT_PAYMENT_HANDLER_API_URL!;
  const PAYFORT_PAYMENT_HANDLER_API_KEY = isBrowser()
    ? window.env.PAYFORT_PAYMENT_HANDLER_API_KEY
    : process.env.PAYFORT_PAYMENT_HANDLER_API_KEY!;
  const PAYFORT_API_URL = isBrowser() ? window.env.PAYFORT_API_URL : process.env.PAYFORT_API_URL!;
  const { payfort } = usePayment();
  const { selectedStore } = useStore();
  const { guest } = useGuest();

  // @ts-ignore
  const { ApplePaySession } = window;
  let isApplePayAvailable = false;
  try {
    isApplePayAvailable =
      'ApplePaySession' in window && ApplePaySession?.canMakePayments() === true;
  } catch (error) {
    console.log(error.message);
  }

  const { createOrder } = useCreateOrder();

  const submitPendingOrder = async (order: Order) => {
    const pendingOrder = await createOrder(
      {
        ...order!,
        status: OrderStatus.PendingPayment,
      },
      true,
    );
    pendingOrder && FBStorage.setOrderId(pendingOrder.id!);
    return pendingOrder;
  };

  const showApplePay = async (
    applePay: ApplePay,
    order: Order,
    onDone: (success: boolean, order?: Order, redirectUrl?: string) => void,
  ) => {
    const { amount, name, label, paymentReference } = applePay;
    const paymentRequest = {
      countryCode: 'SA',
      currencyCode: 'SAR',
      merchantIdentifier: 'merchant.com.foodbit.app',
      merchantCapabilities: ['supports3DS'],
      supportedNetworks: ['amex', 'masterCard', 'visa', 'mada'],
      total: {
        label,
        amount,
      },
    };

    const session = new ApplePaySession(6, paymentRequest);

    // eslint-disable-next-line require-atomic-updates
    session.onvalidatemerchant = async (event: any) => {
      try {
        /* Merchant validation implementation */
        const validationURL = event.validationURL;
        const applePayContext = isBrowser()
          ? window.env.APPLE_PAY_INITIATIVE_CONTEXT
          : process.env.APPLE_PAY_INITIATIVE_CONTEXT;

        const headers: any = {};
        headers['x-api-key'] = PAYFORT_PAYMENT_HANDLER_API_KEY;
        headers['Content-Type'] = 'application/x-www-form-urlencoded';
        const response = await axios({
          headers,
          method: 'post',
          url: `${PAYFORT_PAYMENT_HANDLER_API_URL}/verify-merchant`,
          params: {
            validationURL: encodeURI(validationURL),
            applePayContext: encodeURI(applePayContext),
          },
        });
        session.completeMerchantValidation(response.data);
      } catch (e) {
        onDone(false);
        SafaryAnalytics.logEvent(Event.ApplePayError, { event: e.message });
        await alert(t('payment:applePayError'));
      }
    };

    session.onpaymentauthorized = async (event: any) => {
      // Submit pending order
      const newOrder = await submitPendingOrder(order);

      const applePaymentData = event.payment.token.paymentData;
      const applePaymentMethod = event.payment.token.paymentMethod;

      const applePaymentDataValue = applePaymentData.data;
      const applePaymentSignature = applePaymentData.signature;
      const applePaymentTransactionId = applePaymentData.header.transactionId;
      const applePaymentEphemeralPublicKey = applePaymentData.header.ephemeralPublicKey;
      const applePaymentPublicKeyHash = applePaymentData.header.publicKeyHash;
      const applePaymentMethodDisplayName = applePaymentMethod.displayName;
      const applePaymentMethodNetwork = applePaymentMethod.network;
      const applePaymentMethodType = applePaymentMethod.type;
      const unsignedPayfortRequest = {
        digital_wallet: 'APPLE_PAY',
        command: 'PURCHASE',
        access_code: payfort?.applePayMerchantAccessCode,
        merchant_identifier: payfort?.applePayMerchantId,
        merchant_reference: paymentReference,
        amount: Number((amount * 100).toFixed(2)),
        currency: 'SAR',
        language: 'en',
        apple_data: applePaymentDataValue,
        apple_signature: applePaymentSignature,
        order_description: `Foodbit order`,
        customer_email: `${paymentReference}@foodbit.io`,
        apple_header: {
          apple_transactionId: applePaymentTransactionId,
          apple_ephemeralPublicKey: applePaymentEphemeralPublicKey,
          apple_publicKeyHash: applePaymentPublicKeyHash,
        },
        apple_paymentMethod: {
          apple_displayName: applePaymentMethodDisplayName,
          apple_network: applePaymentMethodNetwork,
          apple_type: applePaymentMethodType,
        },
        // customer_name: name?.replace(/[0-9]/g, '') ?? 'Customer',
        merchant_extra: URLHelper.getURLHandle().replace(/['%']/g, '--P--').replace('!', '--X--'),
        merchant_extra1: `${StringsHelper.replaceAll(
          getOrigin(),
          ':',
          '--SM--',
        )}/${URLHelper.getURLHandle()}/order/${FBStorage.getOrderId()}--Q--redirect--E--true`,
        merchant_extra2: guest?.id,
        merchant_extra3: selectedStore?.id,
        merchant_extra4:
          paymentReference && URLHelper.getQueryParamsForPayfortRequest(selectedStore),
      };

      const signature = await PaymentHelper.getPayfortSignature(
        unsignedPayfortRequest,
        payfort?.passPhrase,
      );
      const signedPayfortRequest = {
        ...unsignedPayfortRequest,
        signature,
      };

      const headers: any = {};
      headers['x-api-key'] = PAYFORT_PAYMENT_HANDLER_API_KEY;
      const payfortResponse = await axios({
        headers,
        method: 'post',
        url: `${PAYFORT_PAYMENT_HANDLER_API_URL}/payfort-authorization`,
        data: signedPayfortRequest,
        params: {
          payfortURL: encodeURI(PAYFORT_API_URL),
        },
      });
      const success = payfortResponse.data.payfort?.status === '14';
      const redirectUrl = payfortResponse.data?.redirectUrl;

      if (!success) {
        SafaryAnalytics.logEvent(Event.ApplePayError, { event });
        await alert(t('payment:applePayError'));
      }

      // Complete payment
      session.completePayment(
        success ? ApplePaySession.STATUS_SUCCESS : ApplePaySession.STATUS_FAILURE,
      );

      // Callback
      newOrder && onDone(success, newOrder, redirectUrl);
    };

    session.oncancel = (event: any) => {
      onDone(false);
    };

    session.begin();
  };

  return {
    showApplePay,
    isApplePayAvailable,
  };
};
