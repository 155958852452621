import { createStyles, DialogContent, makeStyles, Theme } from '@material-ui/core';
import React, { memo } from 'react';
import Lottie from 'react-lottie';

import * as paymentFailedAnimation from '@/assets/images/paymentFailed.json';
import Button from '@/common/components/CustomButton';
import { t } from '@/i18n/translate';
import HeaderContainer from '@/common/containers/HeaderContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
    },
    center: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
    lottie: {
      marginTop: theme.spacing(9),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    button: {
      fontWeight: 'bold',
      height: '7%',
      marginTop: theme.spacing(1),
      width: '100%',
      bottom: 1,
    },
  }),
);

interface Props {
  onHomeClick: () => void;
}

export const FailedPaymentRedirectPage = memo<Props>(({ onHomeClick }) => {
  const classes = useStyles();
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: paymentFailedAnimation.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <div className={classes.root}>
      <HeaderContainer hideElevation disableSelection={false} fixed />
      <div className={classes.center}>
        <Lottie options={defaultOptions} height={180} width={180} />
        <Button variant="outlined" color="default" onClick={onHomeClick} className={classes.button}>
          {t('common:backToHome')}
        </Button>
      </div>
    </div>
  );
});
