import { useCallback, useEffect, useState } from 'react';

import { Event } from '@/analytics/Event';
import { useAnalytics } from '@/analytics/hooks/useAnalytics';
import { useDineIn } from '@/orders/hooks/useDineIn';

import Api from '../../api/Api';

export const useCheckoutDineIn = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const { setDineIn, check, setCheck } = useDineIn();
  const { logEvent } = useAnalytics();

  const checkoutDineIn = useCallback(async (dineInId: string) => {
    setLoading(true);
    try {
      console.log('Check inside', check);

      const newDineIn = await Api.checkoutDineIn(dineInId);
      const newCheck = await Api.checkoutCheck(dineInId);
      setDineIn(newDineIn);
      setCheck(newCheck);

      // Log event
      logEvent(Event.CheckOutDineIn);

      setError(undefined);
      setLoading(false);
      setSuccess(true);
    } catch (e) {
      setError(e.message);
      setLoading(false);
      setSuccess(false);
      console.error(e.message);
    }
  }, []);

  useEffect(() => {
    console.log('Check', check);
  }, [check]);

  return {
    checkoutDineIn,
    loading,
    error,
    success,
    setLoading,
  };
};
