import { Dict } from 'mixpanel-browser';

import { isBrowser } from '@/utils/isBrowser';

import { Analytics } from './Analytics';
import { Event } from './Event';
import MixpanelAnalytics from './MixpanelAnalytics';

/*
 * Makes SafaryAnalytics available on both client side and server side
 */
const mixpanelKey = isBrowser() ? window.env.MIXPANEL_API_KEY : process.env.MIXPANEL_API_KEY!;

class SafaryAnalytics implements Analytics {
  private mixpanelAnalytics: MixpanelAnalytics;

  constructor(mixpanelKey: string) {
    this.mixpanelAnalytics = new MixpanelAnalytics(mixpanelKey);
  }

  public logEvent(event: Event, params: Dict = {}): void {
    this.mixpanelAnalytics.logEvent(event, params);
  }

  public loginUser(userId: string) {
    this.mixpanelAnalytics.loginUser(userId);
  }

  public logout(): void {
    this.mixpanelAnalytics.logout();
  }

  public registerSuperProperties(params: Dict): void {
    this.mixpanelAnalytics.registerSuperProperties(params);
  }

  public getProp(key: string): string {
    return this.mixpanelAnalytics.getProp(key);
  }
}

export default new SafaryAnalytics(mixpanelKey);
