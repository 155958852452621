export default class ObjectHelper {
  public static objectsDifferent(obj1: Opt<any>, obj2: Opt<any>): boolean {
    if (obj1 === obj2) {
      return false;
    }

    if (!obj1 && obj2) {
      return true;
    }

    if (!obj2 && obj1) {
      return true;
    }

    if (obj1 !== Object(obj1) && obj2 !== Object(obj2)) {
      return obj1 !== obj2;
    }

    if (obj1 && obj2 && Object.keys(obj1).length !== Object.keys(obj2).length) {
      return true;
    }

    for (const key in obj1) {
      if (obj2 && !(key in obj2)) {
        return true;
      }
      if (this.objectsDifferent(obj1[key], obj2[key])) {
        return true;
      }
    }
    return false;
  }

  public static isEmpty(obj: any) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  /**
   * Deep copy a given object.
   *
   * @param objSource The object to copy
   * @return The copied object
   */
  public static deepCopy(obj: any): any {
    let copy;

    // Handle the 3 simple types, and null or undefined
    if (!obj || typeof obj !== 'object') {
      return obj;
    }

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (let i = 0, len = obj.length; i < len; i++) {
        copy[i] = ObjectHelper.deepCopy(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      const copy: any = {};
      for (const attr in obj) {
        if (obj.hasOwnProperty(attr)) {
          copy[attr] = ObjectHelper.deepCopy(obj[attr]);
        }
      }
      return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
  }

  /**
   * Is the given object null or empty (i.e. {})
   *
   * @param obj
   * @return True if null or empty
   */
  public static isNullOrEmpty(obj: any): obj is undefined {
    return !obj || ObjectHelper.isEmpty(obj);
  }
}
