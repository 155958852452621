import React, { useCallback, useState } from 'react';

import Api from '@/api/Api';
import OrdersHelper from '@/orders/utils/OrdersHelper';
import { useStore } from '@/store/hooks/useStore';

export const useFetchOrders = () => {
  const [loading, setLoading] = useState(false);
  const { selectedStore } = useStore();
  const { merchantId } = selectedStore || {};

  const fetchOrders = useCallback(async (guestId: string) => {
    setLoading(true);
    let orders1 = await Api.fetchMyOrders(guestId);

    if (merchantId) {
      orders1 = orders1.filter(order => merchantId === order.merchantId);
    }
    // const cachedOrderIds = FBStorage.getOrderIds();
    // const cachedOrders = filterByCachedOrders(orders1, cachedOrderIds);
    const sortedOrders = OrdersHelper.sortOrders(orders1);
    setLoading(false);
    return sortedOrders;
  }, []);

  return { fetchOrders, loading };
};
