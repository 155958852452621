export const Images = {
  logo: require('./logo.svg'),
  greyLogo: require('./greyLogo.svg'),
  appStore: require('./appStore.svg'),
  whatsAppAr: require('./whatsappAr.svg'),
  whatsAppEn: require('./whatsappEn.svg'),
  marker: require('./marker.png'),
  poweredByLight: require('./poweredByLight.png'),
  poweredByDark: require('./poweredByDark.png'),
  madaIcon: require('./madaIcon.png'),
  alert: require('./alert.png'),
  visaIcon: require('./visaIcon.svg'),
  fishIcon: require('./fish.svg'),
  mastercardIcon: require('./mastercardIcon.svg'),
  mamaNoura: require('./mn-logo.svg'),
  quickMarket: require('./quickm.png'),
};
