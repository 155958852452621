import { MAX_DISTANCE } from '@/location/providers/LocationProvider';

export const en = {
  justBrowsing: 'Just Browsing',
  requestLocationTitle:
    'Please share you location to find out if delivery is supported in your area',
  requestLocation: 'Share Current Location',
  unsupportedLocationLabel: `Location out side of radius: `,
  notSupportedLocationMessage:
    'Sorry, your location is not within our delivery radius. Please use one of the supported delivery apps',
  generalLocationError: 'Error in delivery address',
  changeLocation: 'Change Location',
  searchLocation: 'Search Location',
  locateMe: 'Locate Me',
};

export const ar: typeof en = {
  justBrowsing: 'تصفح المنيو',
  requestLocationTitle: 'خدمة التوصيل متواجدة في نطاق معيّن. الرجاء السماح لنا في تحديد موقعك',
  requestLocation: 'حدد موقعي الحالي',
  unsupportedLocationLabel: `موقعك خارج نطاق التوصيل: `,
  generalLocationError: 'غلط في عنوان التوصي',
  notSupportedLocationMessage: 'عفواً، موقعك خارج نطاق التوصيل. الرجاء استخدام احد تطبيقات التوصيل',
  changeLocation: 'تغبر العنوان',
  searchLocation: 'ابحث عن العنوان',
  locateMe: 'حدد موقعي',
};
