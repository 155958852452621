export const en = {
  save: 'Save',
  alert: 'Alert',
  cancel: 'Cancel',
  quantity: 'العدد',
  yes: 'Yes',
  youSure: 'Are You Sure?',
  start: 'Start Your Order',
  cannotLocateSelf: 'Sorry, permission is denied! We are unable to locate you',
  submit: 'Submit',
  next: 'Next',
  currency: ' SAR',
  ok: 'OK',
  new: 'New',
  moreInfo: 'More Info',
  tax: `VAT`,
  backToHome: 'Go Back to Menu',
  tryAgain: 'Try again',
  contactSupport: 'Contact Support',
  clickHere: 'Click Here',
  taxIncludedInPrices: 'Already added to order price above',
  taxNotIncludedInPrices: 'Not added to order price',
  homeMenuTitle: 'Home',
  ordersMenuTitle: 'Orders',
  supportMenuTitle: 'Technical Support',
  contact: 'Customer Service',
  empty: 'No Items',
  close: 'Close',
  messageWhatsApp: 'WhatsApp',
  messageSMS: 'SMS',
  call: 'Call',
};

export const ar: typeof en = {
  save: 'احفظ',
  alert: 'تنبيه',
  cancel: 'إلغاء',
  quantity: 'Quantity',
  yes: 'نعم',
  youSure: 'هل انت متأكد؟',
  start: 'ابدأ طلبك',
  cannotLocateSelf: 'عفواً، لابد من اتاحة الموقع للتعرف على موقعك الحالي لتحديد مكانك',
  submit: 'تنفيذ الطلب',
  next: 'التالي',
  currency: 'ريـال',
  new: 'جديد',
  moreInfo: 'معلومات اكثر',
  tax: `الضريبة`,
  backToHome: 'العودة الى الموقع',
  tryAgain: 'حاول مرة اخرى',
  contactSupport: 'تواصل مع الفريق الفني',
  clickHere: 'اضغط هنا',
  taxIncludedInPrices: 'مضافة الى سعر الطلب',
  taxNotIncludedInPrices: 'غير مضاف الى سعر الطلب',
  homeMenuTitle: 'الصفحة الرئيسية',
  ordersMenuTitle: 'الطلبات',
  supportMenuTitle: 'الدعم الفني',
  contact: 'خدمة العملاء',
  empty: 'لايوجد محتويات',
  close: 'اغلاق',
  messageWhatsApp: 'واتساب',
  messageSMS: 'مسج',
  call: 'اتصال',
  ok: 'علم',
};
