import React, { memo, useEffect } from 'react';

import { useOrders } from '@/orders/hooks/useOrders';
import { OnlineOrdersPage } from '@/orders/mobile/components/OnlineOrdersPage';

export const OnlineOrdersPageContainer = memo(() => {
  const { orders, loading } = useOrders();

  useEffect(() => {}, [orders]);
  return <OnlineOrdersPage loading={loading} orders={orders} />;
});
