import { AppBar, createStyles, IconButton, makeStyles, Theme, Toolbar } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import MenuIcon from '@material-ui/icons/Menu';
import classNames from 'classnames';
import React, { forwardRef, memo, useCallback, useImperativeHandle } from 'react';

import { drawerWidth } from '@/common/components/SideMenu';
import { useStore } from '@/store/hooks/useStore';
import { useRTL } from '@/theme/hooks/useRTL';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    logo: {
      marginRight: 10,
    },
    title: {
      flexGrow: 1,
    },
  }),
);
export interface StorePageMethods {
  isSideMenuOpen: boolean;
  toggleDrawer: () => void;
}

interface Props {
  ref?: React.Ref<any>;
  setIsSideMenuOpen: (value: boolean) => void;
  isSideMenuOpen: boolean;
}

const StorePageHeader = memo<Props>(
  forwardRef<StorePageMethods, Props>(({ setIsSideMenuOpen, isSideMenuOpen }, ref) => {
    const { selectedStore } = useStore();
    const rtl = useRTL();
    const classes = useStyles();

    const toggleDrawer = useCallback(() => setIsSideMenuOpen(!isSideMenuOpen), [
      isSideMenuOpen,
      setIsSideMenuOpen,
    ]);

    useImperativeHandle(ref, () => ({ isSideMenuOpen, toggleDrawer }), [
      isSideMenuOpen,
      toggleDrawer,
    ]);

    const renderAppBar = () => (
      <AppBar
        position="fixed"
        className={classNames(isSideMenuOpen ? classes.appBarShift : classes.appBar)}>
        <Toolbar>
          {!isSideMenuOpen && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              edge="start"
              className={classes.menuButton}>
              <MenuIcon />
            </IconButton>
          )}
          <img className={classes.logo} src={selectedStore?.profilePic} height="35" width="35" />
          <div className={classes.title}>
            <Box fontSize={18} color="white" fontFamily="sans-serif" fontWeight="bold">
              {rtl ? selectedStore?.name.ar : selectedStore?.name.en}
            </Box>
          </div>
        </Toolbar>
      </AppBar>
    );

    return renderAppBar();
  }),
);

export default StorePageHeader;
