import {
  Avatar,
  createStyles,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import classNames from 'classnames';
import React, { useRef } from 'react';

import { Event } from '@/analytics/Event';
import { useAnalytics } from '@/analytics/hooks/useAnalytics';
import SushiWarning from '@/common/components/SushiWarning';
import Warning from '@/common/components/Warning';
import { labelOrDefault } from '@/common/utils/tools';
import { t } from '@/i18n/translate';
import {
  MenuItemViewContainer,
  MenuItemViewMethods,
} from '@/menu/mobile/containers/MenuItemViewContainer';
import MenuItemHelper from '@/menu/utils/MenuItemHelper';
import { useStore } from '@/store/hooks/useStore';
import { getLang } from '@/theme/utils/getLangDirection';
import { MenuCategory, MenuItem, TimeRange } from '@/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      alignItems: 'flex-start',
    },
    img: {
      height: 150,
      width: 150,
      marginRight: 9,
      borderRadius: 9,
      marginTop: 10,
      objectFit: 'contain',
    },
    greyImg: {
      filter: 'grayscale(100%)',
      opacity: 0.5,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    inline: {
      display: 'inline',
    },
    errorIcon: {
      fontSize: 14,
    },
    errorContainer: {
      marginBottom: theme.spacing(1),
    },
    verticalDivider: {
      width: 2,
      height: 20,
    },
    pricingContainer: {
      marginTop: theme.spacing(2),
    },
  }),
);

interface Props {
  menuItem: MenuItem;
  divider: boolean;
  defaultPic: string;
  showDefaultPic: boolean;
  category: MenuCategory;
  isCategoryAvailable: boolean;
  showCategoryTempUnavailable: boolean;
  categoryAvailability: Opt<TimeRange>;
  setShowStartCheck?: (value: boolean) => void;
}

export const warningItems: string[] = [
  // '07d85c6f-c8b6-485c-8edf-60e042d36530',
  // '89778ce5-1ca7-4ba3-8ee1-7f88f2f0c820',
  // 'dca92924-ad3f-4e9d-b23a-00e8f9e06555',
  // '3d5809d7-16e2-4cb3-a08f-58ad2920c1fb',
  // '0cc07d14-cde9-4654-b84d-dbeb449fae85',
  // 'b7780861-0770-42f0-bc7d-4cebfc89bdd2',
  // '27877c11-2b02-4e70-81bf-97fb51aba4c3',
  // 'eba5bc2f-5b64-4889-bda9-9a2cf31e7981',
  // '8d5acad2-66e8-4cbf-b7b0-94e5b92c5ea9',
  // 'dcdd5cb2-b9a6-4f25-9bae-bb6ba96a7f14',
  // '9e1a9047-914f-4ae3-ade8-828a382972c1',
  // '01d20daa-f0eb-4643-9a51-536d93d1769c',
  // 'cfb97e3b-34e4-4468-85dd-6aa47084ca61',
  // 'e75706a2-0fa5-4d29-b13f-c9960297bfd2',
  // '4037a2f5-f008-48ce-b5e5-70007444c00a',
];
const MenuItemRow = ({
  menuItem,
  divider,
  defaultPic,
  showDefaultPic,
  category,
  isCategoryAvailable,
  showCategoryTempUnavailable,
  categoryAvailability,
  setShowStartCheck,
}: Props) => {
  const classes = useStyles();
  const { logEvent } = useAnalytics();
  const { selectedStore, discount } = useStore();
  const { currency } = selectedStore?.settings || {};
  const { settings } = selectedStore || {};
  const { theme: customTheme } = settings || {};
  const { basic: basicTheme } = customTheme || {};
  const lang = getLang();
  const isDiscountedItem = !!discount?.menuItems?.find(item => item.id === menuItem.id);

  const menuItemViewRef = useRef<MenuItemViewMethods>();

  const theme = useTheme();

  const handleItemClick = () => {
    menuItemViewRef?.current?.setOpenModal(true);
    logEvent(Event.ClickMenuItem);
  };

  const showSushiWarning = warningItems.includes(menuItem?.id);

  const renderText = () => {
    const { name, description, id } = menuItem;
    const itemName = labelOrDefault(name, lang);
    const itemDescription = labelOrDefault(description, lang);
    //description && description[lang];
    const unavailable = MenuItemHelper.isItemUnavailable(menuItem, selectedStore?.id);
    return (
      <ListItemText
        primary={
          <Typography color="textPrimary" variant="subtitle1" style={{ fontWeight: 'bold' }}>
            {itemName}
          </Typography>
        }
        secondary={
          <>
            {(unavailable || showCategoryTempUnavailable) && (
              <Warning text={t('menu:tempUnavailable')} />
            )}
            <>
              {itemDescription && (
                <Typography
                  noWrap
                  variant="body2"
                  style={{ whiteSpace: 'pre-line', color: '#999999' }}>
                  {itemDescription}
                </Typography>
              )}
              <div className={classNames(classes.pricingContainer)}>
                <Grid container direction="row" spacing={1} xs={12}>
                  {showSushiWarning && (
                    <Grid>
                      <SushiWarning text={t('menu:sushiWarning')} />
                    </Grid>
                  )}
                  <Grid item xs={10}>
                    <Typography
                      color="textSecondary"
                      variant="subtitle1"
                      align="left"
                      style={{
                        whiteSpace: 'pre-line',
                        fontWeight: 'bold',
                        color: basicTheme?.color ?? theme.palette.text.primary,
                        textDecorationLine: isDiscountedItem ? 'line-through' : undefined,
                      }}>
                      {MenuItemHelper.getMenuItemPrice(menuItem, false, undefined, currency, false)}
                    </Typography>
                    {isDiscountedItem && (
                      <Typography variant="subtitle2" color="error">
                        {MenuItemHelper.getMenuItemPrice(
                          menuItem,
                          false,
                          undefined,
                          currency,
                          false,
                          discount,
                        )}
                      </Typography>
                    )}
                  </Grid>
                  {menuItem.calories || menuItem.calories === 0 ? (
                    <>
                      {/*<Grid container item xs={2} justify="center">*/}
                      {/*  <Divider className={classes.verticalDivider} />*/}
                      {/*</Grid>*/}
                      {/*<Grid item xs={5}>*/}
                      {/*  <Typography*/}
                      {/*    color="textSecondary"*/}
                      {/*    variant="body2"*/}
                      {/*    align="right"*/}
                      {/*    style={{*/}
                      {/*      marginRight: 5,*/}
                      {/*      textDecoration: 'bold',*/}
                      {/*    }}>*/}
                      {/*    {`${menuItem.calories} ${t('menu:calories')}`}*/}
                      {/*  </Typography>*/}
                      {/*</Grid>*/}
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </div>
            </>
          </>
        }
        secondaryTypographyProps={{
          color: 'textSecondary',
        }}
      />
    );
  };

  const renderRow = () => {
    const { profilePic, name } = menuItem;
    return (
      <div className={classes.container}>
        <ListItem button divider={false} onClick={handleItemClick}>
          <div className={classes.row}>
            {profilePic ? (
              <ListItemAvatar>
                <Avatar className={classes.img} src={profilePic} />
              </ListItemAvatar>
            ) : (
              showDefaultPic && (
                <ListItemAvatar>
                  <Avatar
                    className={classNames(classes.img, classes.greyImg)}
                    alt={name?.en}
                    src={defaultPic}
                  />
                </ListItemAvatar>
              )
            )}
            {renderText()}
          </div>
        </ListItem>
        {divider && <Divider />}
      </div>
    );
  };

  return (
    <>
      {renderRow()}
      <MenuItemViewContainer
        showSushiWarning={showSushiWarning}
        category={category}
        ref={menuItemViewRef}
        item={menuItem}
        setShowStartCheck={setShowStartCheck}
        showCategoryTempUnavailable={showCategoryTempUnavailable}
        isCategoryAvailable={isCategoryAvailable}
        categoryAvailability={categoryAvailability}
      />
    </>
  );
};

export default MenuItemRow;
