import {
  Avatar,
  createStyles,
  Divider,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import classNames from 'classnames';
import React, { memo } from 'react';

import { Event } from '@/analytics/Event';
import { useAnalytics } from '@/analytics/hooks/useAnalytics';
import CartHelper from '@/cart/utils/CartHelper';
import { Loader } from '@/common/components/Loader';
import MenuItemHelper from '@/menu/utils/MenuItemHelper';
import { useStore } from '@/store/hooks/useStore';
import { useRTL } from '@/theme/hooks/useRTL';
import { MenuItem } from '@/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      alignItems: 'flex-start',
    },
    img: {
      height: 60,
      width: 60,
      marginRight: 15,
      borderRadius: 15,
      marginTop: 10,
      objectFit: 'contain',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
    },
    space: {
      alignItems: 'space-between',
    },
    margin: {
      margin: theme.spacing(1),
      alignSelf: 'flex-start',
    },
    quantity: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    subTitle: {
      fontSize: 12,
    },
    greyImg: {
      filter: 'grayscale(100%)',
      opacity: 0.5,
    },
  }),
);

interface Props {
  menuItem: MenuItem;
  onRemoveItemClick?: () => void;
  onItemClick?: () => void;
  loading: boolean;
  showDefaultPic: boolean;
  defaultPic: string;
}

const CartMenuItemRow = memo<Props>(
  ({ menuItem, onRemoveItemClick, loading, onItemClick, showDefaultPic, defaultPic }) => {
    const classes = useStyles();
    const { selectedStore, discount } = useStore();
    const isDiscountedItem = !!discount?.menuItems?.find(item => item.id === menuItem.id);
    const { currency } = selectedStore?.settings || {};
    const { logEvent } = useAnalytics();
    const rtl = useRTL();

    const handleItemPress = () => {
      if (onItemClick) {
        onItemClick();
        logEvent(Event.ClickMenuItem);
      }
    };

    const { profilePic, quantity } = menuItem;
    const description = CartHelper.getCartMenuItemSubtitle(menuItem, currency);
    return (
      <>
        <ListItem button divider={false}>
          <div className={classNames(classes.row, classes.space)}>
            <div className={classes.row} onClick={handleItemPress}>
              <Typography className={classes.quantity} variant="subtitle1" color="textPrimary">
                {rtl ? `x${quantity ? quantity : 1}` : `${quantity ? quantity : 1}x`}
              </Typography>
              {profilePic ? (
                <ListItemAvatar>
                  <Avatar className={classes.img} src={profilePic} />
                </ListItemAvatar>
              ) : (
                showDefaultPic && (
                  <ListItemAvatar>
                    <Avatar className={classNames(classes.img, classes.greyImg)} src={defaultPic} />
                  </ListItemAvatar>
                )
              )}
              <ListItemText
                className={classes.listItem}
                primary={
                  <Typography component="span" variant="subtitle1" color="textPrimary">
                    {MenuItemHelper.getTitle(menuItem)}
                  </Typography>
                }
                secondary={
                  <div>
                    <Typography
                      component="span"
                      variant="subtitle2"
                      color="textSecondary"
                      style={{ whiteSpace: 'pre-line' }}
                      className={classes.subTitle}>
                      {description}
                    </Typography>
                    {description && <br />}
                    <Typography
                      variant="subtitle1"
                      color={isDiscountedItem ? 'textSecondary' : 'textPrimary'}
                      style={{
                        textDecorationLine: isDiscountedItem ? 'line-through' : undefined,
                      }}>
                      {CartHelper.getMenuItemPrice(menuItem, currency)}
                    </Typography>
                    {isDiscountedItem && (
                      <Typography variant="subtitle1" color="textPrimary">
                        {CartHelper.getMenuItemPrice(menuItem, currency, discount)}
                      </Typography>
                    )}
                  </div>
                }
              />
            </div>
            {onRemoveItemClick && (
              <IconButton
                color={'secondary'}
                aria-label="delete"
                size="small"
                className={classes.margin}
                onClick={onRemoveItemClick}>
                {loading ? <Loader size={20} /> : <RemoveCircleOutlineIcon fontSize="small" />}
              </IconButton>
            )}
          </div>
        </ListItem>
        <Divider variant="inset" />
      </>
    );
  },
);

export default CartMenuItemRow;
