import SafaryAnalytics from '@/analytics/SafaryAnalytics';
import Api from '@/api/Api';
import { Coordinates, DineIn, Order, Store } from '@/types';
import FBStorage from '@/utils/FBStorage';
import StringsHelper from '@/utils/StringsHelper';

export default class URLHelper {
  public static updateURLParameter(uri: string, key: string, value: string) {
    const decodedURI = decodeURIComponent(uri);
    const re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
    const separator = decodedURI.indexOf('?') !== -1 ? '&' : '?';
    if (decodedURI.match(re)) {
      return decodedURI.replace(re, '$1' + key + '=' + value + '$2');
    } else {
      return decodedURI + separator + key + '=' + value;
    }
  }

  public static paramsToObject(entries: any) {
    const result = [];
    for (const [key, value] of entries) {
      // each 'entry' is a [key, value] tupple
      // @ts-ignore
      result.push({ sourceName: key, sourceValue: value });
    }
    return result;
  }

  public static getAllTrackingParams(): any {
    const params = new URLSearchParams(window.location.search);
    const handle = params.get('fb_short_link_code') ?? '';
    let linkTracking = [{ sourceName: 'LINK', sourceValue: URLHelper.getURLHandle() }];
    if (handle) linkTracking = [...linkTracking, { sourceName: 'SHORT_LINK', sourceValue: handle }];

    params.delete('fb_short_link_code');
    params.delete('storeId');
    params.delete('type');
    params.delete('theme');
    params.delete('lng');
    if (!params.get('referrer')) {
      params.set('referrer', this.getReferrer());
    }
    return {
      foodbit: linkTracking,
      utm: this.paramsToObject(params),
    };
  }

  public static getReferrer(): string {
    return this.getURLParams('referrer') ?? SafaryAnalytics.getProp('$initial_referring_domain');
  }

  public static getURLParams(param: string): Opt<string> {
    const params = new URLSearchParams(window.location.search);
    const storeId = params.get(param);
    return storeId ?? undefined;
  }

  public static goToMap(coordinates: Coordinates) {
    const { latitude, longitude } = coordinates;
    window.open(`https://maps.google.com/maps?daddr=${latitude},${longitude}&amp;ll=`, '_blank');
  }

  public static hasQueryParam(param: string): boolean {
    const uri = window.location.search;
    const re = new RegExp('([?&])' + param + '=.*?(&|$)', 'i');
    const match = uri.match(re);
    return match ? match!.length > 0 : false;
  }

  public static goHome() {
    let homePage = `${window.location.protocol}/${window.location.pathname.split('/')[1]}${
      window.location.search
    }`;
    homePage = URLHelper.removeURLParameter(homePage, 'ref');
    window.location.assign(homePage);
  }

  public static getURLHandle(): string {
    const pathArray = window.location.pathname.split('/');
    return pathArray[1];
  }

  public static removeURLParameter(url: string, parameter: string) {
    //prefer to use l.search if you have a location/link object
    const urlparts = url.split('?');
    if (urlparts.length >= 2) {
      const prefix = encodeURIComponent(parameter) + '=';
      const pars = urlparts[1].split(/[&;]/g);

      //reverse iteration as may be destructive
      for (let i = pars.length; i-- > 0; ) {
        //idiom for string.startsWith
        if (pars[i].lastIndexOf(prefix, 0) !== -1) {
          pars.splice(i, 1);
        }
      }

      return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
    }
    return url;
  }

  public static goToTechnicalSupport() {
    window.open('https://wa.me/966555846106');
  }

  public static goToWhatsApp(phone: string) {
    window.open('https://wa.me/' + phone.substring(1, phone.length));
  }

  public static goToMessage(phone: string) {
    window.open(`sms:${phone}`, '_self');
  }

  public static goToStoreSupport(link: string) {
    link.startsWith('http') || link.startsWith('www')
      ? window.open(link, '_blank')
      : window.open(link);
  }

  public static goToOrderPage(order: Order) {
    const handle = URLHelper.getURLHandle();
    let ordersPage = `${window.location.origin}/${handle}/order/${order?.id}/${window.location.search}`;
    ordersPage = URLHelper.removeURLParameter(ordersPage, 'ref');
    window.location.assign(ordersPage);
  }

  public static goToPage(page: string) {
    window.location.assign(page);
  }

  public static getQueryParamsForPayfortRequest(store?: Store) {
    let newParams = '';
    //window.location.search;
    const urlParams = new URLSearchParams(window.location.search);
    const lng = urlParams.get('lng');
    const type = urlParams.get('type');
    const theme = urlParams.get('theme');
    const orderId = FBStorage.getOrderId();
    // let origin = window.location.origin;
    // if (origin.includes('localhost')) {
    //   origin = 'https://www.stage.app.foodbit.io';
    // }
    const token = Api.token;
    const sessionId = Api.sessionId;
    if (orderId) {
      newParams = newParams + '&orderId=' + orderId;
    }
    if (token?.access_token && token?.access_token !== '') {
      newParams = newParams + '&token=' + token.access_token;
    }
    if (sessionId) {
      newParams = newParams + '&sessionId=' + sessionId;
    }
    if (lng) {
      newParams = newParams + '&lng=' + lng;
    }
    if (type) {
      newParams = newParams + '&type=' + type;
    }
    if (theme) {
      newParams = newParams + '&theme=' + theme;
    }
    if (store) {
      newParams = newParams + '&merchantId=' + store.merchantId;
    }
    // if (redirectUrl) {
    //   newParams = newParams + 'redirectUrl--E--https--SM----S----S--www--D--stage';
    // }
    // newParams = this.removeURLParameter(newParams, 'lng');
    newParams = newParams.replace('?', '');
    newParams = StringsHelper.replaceAll(newParams, '&', '--A--');
    newParams = StringsHelper.replaceAll(newParams, '/', '--S--');
    newParams = StringsHelper.replaceAll(newParams, ':', '--SM--');
    newParams = StringsHelper.replaceAll(newParams, '=', '--E--');
    newParams = StringsHelper.replaceAll(newParams, '.', '--D--');
    return newParams;
  }

  public static goToPhone(phone: string) {
    window.open(`tel:${phone}`, '_self');
  }

  public static goToOrdersPage(dineIn?: DineIn) {
    if (dineIn) {
      this.goToDineInOrdersPage(dineIn);
    } else {
      this.goToRegularOrdersPage();
    }
  }

  public static goToRegularOrdersPage = () => {
    let search = window.location.search;
    if (search.length === 0) {
      search = '?';
    }
    // if (!search.includes('&type')) {
    //   // TODO hacking this orders page view to make it look like online orders page
    //   search = search + '&type=0';
    // } else {
    //   search = URLHelper.updateURLParameter(
    //     search,
    //     'type',
    //     OrdersHelper.getBrowseTypeFromOrderType(OrderType.Delivery).toString(),
    //   );
    // }
    const handle = URLHelper.getURLHandle();
    const ordersPage = `${window.location.origin}/${handle}/orders${search}`;
    window.location.assign(ordersPage);
  };

  public static goToDineInOrdersPage = (dineIn: DineIn) => {
    let search = window.location.search;
    if (search.length === 0) {
      search = '?';
    }
    const handle = URLHelper.getURLHandle();
    if (!window.location.search.includes('&type')) {
      search = search + '&type=1';
    }
    let ordersPage = `${window.location.origin}/${handle}/dine-in/${dineIn.id}/${search}`;
    ordersPage = URLHelper.removeURLParameter(ordersPage, 'ref');
    window.location.assign(ordersPage);
  };
}
