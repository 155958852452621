import { useConfirm } from 'material-ui-confirm';
import React, { memo, useRef, useState } from 'react';

import { Event } from '@/analytics/Event';
import { useAnalytics } from '@/analytics/hooks/useAnalytics';
import { BottomActions } from '@/bottomActions/mobile/components/BottomActions';
import { useBrowseType } from '@/browse/hooks/useBrowseType';
import { useCart } from '@/cart/hooks/useCart';
import { useUpdateCart } from '@/cart/hooks/useUpdateCart';
import CartHelper from '@/cart/utils/CartHelper';
import { BrowseType, UpdateCartType } from '@/const/enums';
import { t } from '@/i18n/translate';
import { useOrders } from '@/orders/hooks/useOrders';
import { FinalizeOrderContainerMethods } from '@/orders/mobile/containers/FinalizeOnlineOrderContainer';
import { useStore } from '@/store/hooks/useStore';

export const BottomActionsContainer = memo(() => {
  const [openCart, setOpenCart] = useState(false);
  const [checkingOut, setCheckingOut] = useState(false);
  const confirm = useConfirm();
  const { type: browseType } = useBrowseType();
  const { cart } = useCart();
  const { orders } = useOrders();
  const { updateCart, loading } = useUpdateCart();
  const finalizeOrderRef = useRef<FinalizeOrderContainerMethods>();
  const [transitionToOrderPage, setTransitionToOrderPage] = useState(false);
  const { logEvent } = useAnalytics();
  const { selectedStore } = useStore();
  const { tax } = selectedStore?.settings || selectedStore?.menu || {};
  const storeContext = useStore();
  const { notAcceptingOrders, storeClosed } = storeContext;
  const minimumDeliveryOrder = selectedStore?.settings?.minimumDeliveryOrder;

  if (browseType === BrowseType.basic) {
    return <></>;
  }

  if (!cart) {
    return <></>;
  }

  const handleCloseCartClick = () => {
    setOpenCart(false);
    setCheckingOut(false);
  };

  const handleClearCartClick = async () => {
    try {
      await confirm({
        title: t('common:youSure'),
        description: t('cart:clearCartConfirmation'),
        confirmationText: t('common:yes'),
        cancellationText: t('common:cancel'),
        confirmationButtonProps: {
          color: 'default',
        },
      });
      updateCart([], UpdateCartType.Clear);
    } catch (e) {
      console.log(e);
    }
  };
  const handleCancelCheckoutClick = () => setCheckingOut(false);

  const handleNextClick = () => {
    finalizeOrderRef.current?.setOpen(true);
    logEvent(Event.ClickCheckout);
  };

  const handleCartButtonClick = () => setOpenCart(true);

  return (
    <BottomActions
      showMinimumDeliveryOrder={
        minimumDeliveryOrder !== undefined &&
        minimumDeliveryOrder !== null &&
        browseType === BrowseType.online
      }
      transitionToOrderPage={transitionToOrderPage}
      setTransitionToOrderPage={setTransitionToOrderPage}
      openCart={openCart}
      showEmptyCart={cart && cart.items.length === 0}
      editCart={!checkingOut}
      loading={loading}
      cart={cart}
      hide={!cart && !orders}
      cartButtonLabel={
        cart && cart.items.length > 0 ? CartHelper.getQuantity(cart.items!).toString() : undefined
      }
      onCartCancelCheckoutClick={handleCancelCheckoutClick}
      onCartButtonClick={handleCartButtonClick}
      onClearCartClick={handleClearCartClick}
      onCloseCartClick={handleCloseCartClick}
      hideCartButton={!cart || notAcceptingOrders || storeClosed}
      finalizeOrderRef={finalizeOrderRef}
      onNextClick={handleNextClick}
      tax={tax}
      showCartCancelButton={browseType === BrowseType.dinein && checkingOut}
      showCartNextButton={cart && cart.items.length > 0}
      minimumDeliveryOrder={minimumDeliveryOrder}
    />
  );
});
