import React, { forwardRef, memo, useImperativeHandle, useState } from 'react';

import URLHelper from '@/common/utils/URLHelper';
import ContactCourierDialog from '@/orders/mobile/components/ContactUserDialog';
import { IFrameOrderTrackingPage } from '@/payment/mobile/components/IFrameOrderTrackingPage';
import { useRTL } from '@/theme/hooks/useRTL';
import { User } from '@/types';

interface Props {
  url: string;
  courier?: User;
  showContactCourier: boolean;
  ref?: React.Ref<any>;
}

export interface OrderTrackingPageContainerMethods {
  setOpen: (open: boolean) => void;
}

export const IFrameOrderTrackingPageContainer = memo<Props>(
  forwardRef<OrderTrackingPageContainerMethods, Props>(
    ({ url, courier, showContactCourier }, ref) => {
      useImperativeHandle(ref, () => ({ setOpen }), []);
      const [showContactCourierDialog, setShowContactCourierDialog] = useState(false);
      const [, updateState] = useState();

      // Modal
      const [open, setOpen] = useState(false);

      const handleRefreshClick = () => {
        const iFrame: any = document.getElementById('iframeid');
        if (iFrame && iFrame.src) {
          iFrame.src += '';
        }
      };

      const rtl = useRTL();

      const handleCloseClick = () => setOpen(false);

      const handleMessageCourier = () => setShowContactCourierDialog(true);

      const handleCallCourier = () =>
        courier?.phoneNumber && URLHelper.goToPhone(courier?.phoneNumber);

      return (
        <>
          <IFrameOrderTrackingPage
            open={open}
            rtl={rtl}
            showContactCourier={showContactCourier}
            onMessageCourier={handleMessageCourier}
            onCallCourier={handleCallCourier}
            onCloseClick={handleCloseClick}
            onRefreshClick={handleRefreshClick}
            url={url}
          />
          <ContactCourierDialog
            onClose={() => setShowContactCourierDialog(false)}
            open={showContactCourierDialog}
            onWhatsAppClick={() =>
              courier?.phoneNumber && URLHelper.goToWhatsApp(courier?.phoneNumber)
            }
            onMessageClick={() =>
              courier?.phoneNumber && URLHelper.goToMessage(courier?.phoneNumber)
            }
          />
        </>
      );
    },
  ),
);
