import {createStyles, Dialog, Fab, makeStyles, Slide, Theme, Typography} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import React, { memo } from 'react';

import CustomButton from '@/common/components/CustomButton';
import { t } from '@/i18n/translate';
import HeaderContainer from '@/common/containers/HeaderContainer';
import { useRTL } from '@/theme/hooks/useRTL';

const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  // @ts-ignore
  <Slide direction="up" ref={ref} {...props} />
));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      flex: 1,
      backgroundColor: theme.palette.type === 'light' ? 'white' : theme.palette.primary.dark,
    },
    section: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      margin: theme.spacing(2),
    },
    title: {
      marginTop: theme.spacing(1),
    },
    buttonContainer: {
      display: 'flex',
      position: 'absolute',
      width: '100%',
      bottom: 0,
    },
    button: {
      borderRadius: 0,
      bottom: 0,
      marginBottom: 10,
    },
  }),
);

interface Props {
  open: boolean;
  onClosePress: () => void;
}

export const HowItWorksView = memo<Props>(({ open, onClosePress }) => {
  const classes = useStyles();
  const rtl = useRTL();

  const section = (number: string, title: string, body: string) => (
    <div className={classes.section}>
      <Fab size="small" color="secondary" aria-label="add">
        {number}
      </Fab>
      <Typography align="center" variant="h6" className={classes.title}>
        {title}
      </Typography>
      <Typography align="center" variant="body1">
        {body}
      </Typography>
    </div>
  );
  return (
    <Dialog fullScreen open={open} onClose={onClosePress} TransitionComponent={Transition}>
      <HeaderContainer onCloseClick={onClosePress} />
      <div className={classes.dialog} dir={rtl ? 'rtl' : 'ltr'}>
        {section('1', t('greet:step1Title'), t('greet:step1Body'))}
        {section('2', t('greet:step2Title'), t('greet:step2Body'))}
        {section('3', t('greet:step3Title'), t('greet:step3Body'))}
        {section('4', t('greet:step4Title'), t('greet:step4Body'))}
        <CustomButton
          containerStyle={classes.buttonContainer}
          buttonStyle={classes.button}
          fullWidth
          onClick={onClosePress}
          variant="text"
          size="large">
          {t('common:cancel')}
        </CustomButton>
      </div>
    </Dialog>
  );
});
