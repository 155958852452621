import { jssPreset, MuiThemeProvider, StylesProvider, Theme } from '@material-ui/core/styles';
import i18next from 'i18next';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { ConfirmProvider } from 'material-ui-confirm';
import React, { useCallback, useEffect, useState } from 'react';
import { positions, Provider as AlertProvider, transitions } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';

import { BranchProvider } from '@/analytics/providers/BranchProvider';
import { URLThemeType } from '@/const/enums';
import { createTheme } from '@/theme/theme';
import { getLang, getLangDirection } from '@/theme/utils/getLangDirection';
import FBStorage from '@/utils/FBStorage';

interface SetThemeContextProps {
  setTheme: (theme: Theme) => void;
  themeType?: URLThemeType;
  setThemeType?: (themeType: URLThemeType) => void;
}

interface Props {
  direction: 'rtl' | 'ltr';
  defaultThemeType: URLThemeType;
  children: React.ReactChild;
}

export const SetThemeContext = React.createContext<SetThemeContextProps>({
  setTheme: () => null,
  setThemeType: () => null,
  themeType: URLThemeType.Light,
});

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

export const getDefaultTheme = (theme: URLThemeType): 'light' | 'dark' => {
  return theme === 'dark' ? 'dark' : 'light';
};

export const ThemeProvider = ({ children, direction, defaultThemeType }: Props) => {
  // const { themeType } = useContext(SetThemeContext);
  const [themeType, setThemeType] = useState(defaultThemeType);
  const [theme, setTheme] = useState(createTheme({ direction }, getDefaultTheme(themeType!)));
  const lang = getLang();

  const setBackground = () => {
    let color = 'white';
    if (getDefaultTheme(themeType!) === 'dark') {
      color = '#0c0b0b';
    }
    document.body.style.background = color;
  };

  useEffect(() => {
    FBStorage.setTheme(
      getDefaultTheme(themeType!) === 'dark' ? URLThemeType.Dark : URLThemeType.Light,
    );
  }, [themeType]);

  useEffect(() => {
    setBackground();
  }, [setBackground]);

  useEffect(() => {
    i18next.dir(getLangDirection());
    i18next.changeLanguage(lang);
  }, []);

  // const handleSetTheme = useCallback(
  //   (newTheme: Theme) => {
  //     // const isThemeRTL = newTheme.direction === 'rtl';
  //     setTheme(newTheme);
  //
  //     // let params = window.location.search;
  //     // const url = window.location.pathname + window.location.search;
  //     // const newUrl = updateURLParameter(url, 'lng', isThemeRTL ? 'ar' : 'en');
  //     // window.history.replaceState(null, '', newUrl);
  //     // setDirection('ltr');
  //   },
  //   [themeType],
  // );

  // optional cofiguration
  const alertOptions = {
    // you can also just use 'bottom center'
    position: positions.BOTTOM_CENTER,
    offset: '30px',
    // you can also just use 'scale'
    transition: transitions.FADE,
  };

  return (
    <MuiThemeProvider theme={theme}>
      <BranchProvider>
        <ConfirmProvider>
          <StylesProvider jss={jss}>
            <SetThemeContext.Provider value={{ setTheme, themeType, setThemeType }}>
              <AlertProvider template={AlertTemplate} {...alertOptions}>
                <div dir={getLangDirection()}>{children}</div>
              </AlertProvider>
            </SetThemeContext.Provider>
          </StylesProvider>
        </ConfirmProvider>
      </BranchProvider>
    </MuiThemeProvider>
  );
};
