import React, { memo, useEffect, useState } from 'react';

import Api from '@/api/Api';
import { useStore } from '@/store/hooks/useStore';
import { PayfortSettings, PaymentTransaction } from '@/types';
import { isBrowser } from '@/utils/isBrowser';

import { Payfort, PaymentContext } from './PaymentContext';

interface Props {
  paymentTransactionId?: string;
  children: React.ReactChild;
}
const defaultMerchantId = isBrowser()
  ? window.env.PAYFORT_MERCHANT_ID
  : process.env.PAYFORT_MERCHANT_ID!;
const defaultAccessCode = isBrowser()
  ? window.env.PAYFORT_ACCESS_CODE
  : process.env.PAYFORT_ACCESS_CODE!;
const applePayMerchantId = isBrowser()
  ? window.env.PAYFORT_APPLE_PAY_MERCHANT_ID
  : process.env.PAYFORT_APPLE_PAY_MERCHANT_ID!;
const appleAccessCode = isBrowser()
  ? window.env.PAYFORT_APPLE_PAY_ACCESS_CODE
  : process.env.PAYFORT_APPLE_PAY_ACCESS_CODE!;
const passPhrase = isBrowser() ? window.env.PAYFORT_PASS_PHRASE : process.env.PAYFORT_PASS_PHRASE!;

export const PaymentProvider = memo<Props>(({ paymentTransactionId, children }) => {
  const [paymentTransaction, setPaymentTransaction] = useState<Opt<PaymentTransaction>>(undefined);
  const [payfort, setPayfort] = useState<PayfortSettings>({
    merchantId: defaultMerchantId,
    accessCode: defaultAccessCode,
    applePayMerchantId: applePayMerchantId,
    applePayMerchantAccessCode: appleAccessCode,
    passPhrase: passPhrase,
  });
  const [error, setError] = useState();
  const { selectedStore } = useStore();

  const fetchPayment = async () => {
    if (paymentTransactionId) {
      try {
        const value = await Api.fetchPayment(paymentTransactionId);
        setPaymentTransaction(value);
      } catch (e) {
        console.error(e.message);
        setError(e.message);
      }
    }
  };

  useEffect(() => {
    if (paymentTransactionId) {
      fetchPayment();
    }
  }, []);

  useEffect(() => {
    const paymentSettings =
      selectedStore?.settings?.integrations?.payments &&
      selectedStore?.settings.integrations?.payments[0];

    paymentSettings &&
      setPayfort({
        merchantId: paymentSettings?.userSettings['merchantId']?.toString() ?? payfort.merchantId,
        applePayMerchantAccessCode:
          paymentSettings?.userSettings['applePayAccessCode']?.toString() ??
          payfort.applePayMerchantAccessCode,
        accessCode: paymentSettings?.userSettings['accessCode']?.toString() ?? payfort.accessCode,
        applePayMerchantId:
          paymentSettings?.userSettings['merchantId']?.toString() ?? payfort.merchantId,
        passPhrase: payfort.passPhrase,
      });
    console.log('');
  }, [selectedStore]);

  return (
    <PaymentContext.Provider value={{ paymentTransaction, error, payfort }}>
      {children}
    </PaymentContext.Provider>
  );
});
