import { createStyles, Dialog, makeStyles, Slide, Theme } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import React, { memo } from 'react';

import CartMenuItemsList from '@/cart/mobile/components/CartMenuItemsList';
import CartHelper from '@/cart/utils/CartHelper';
import HeaderContainer from '@/common/containers/HeaderContainer';
import { OrderType } from '@/const/enums';
import { useRTL } from '@/theme/hooks/useRTL';
import { Order } from '@/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: 'flex',
      position: 'absolute',
      width: '100%',
      bottom: 0,
    },
    button: {
      borderRadius: 0,
      bottom: 0,
    },
    dialog: {
      flex: 1,
      backgroundColor: theme.palette.type === 'light' ? 'white' : theme.palette.primary.dark,
    },
    checkoutButtonContainer: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      width: '100%',
      bottom: 5,
    },
    checkoutButton: {
      borderRadius: 0,
    },
  }),
);

interface Props {
  open: boolean;
  onClosePress: () => void;
  order: Order;
}

const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  // @ts-ignore
  <Slide direction="up" ref={ref} {...props} />
));

export const OrderView = memo<Props>(({ open, onClosePress, order }) => {
  const classes = useStyles();
  const rtl = useRTL();
  return (
    <Dialog fullScreen open={open} onClose={onClosePress} TransitionComponent={Transition}>
      <div className={classes.dialog} dir={rtl ? 'rtl' : 'ltr'}>
        <HeaderContainer onCloseClick={onClosePress} />
        <CartMenuItemsList
          quantity={CartHelper.getQuantity(order.items!!)}
          totalPriceForItems={CartHelper.getTotalPriceForItems(order.items!!)}
          menuItems={order.items!!}
          editable={false}
          showMinimumDeliveryOrder={false}
          showDelivery={order.type === OrderType.Delivery}
          rtl={rtl}
        />
      </div>
    </Dialog>
  );
});
