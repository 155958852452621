import { Event } from '@/analytics/Event';
import EventProperties from '@/analytics/EventProperties';
import { useAnalytics } from '@/analytics/hooks/useAnalytics';
import { useBranch } from '@/analytics/hooks/useBranch';
import CartHelper from '@/cart/utils/CartHelper';
import { useGuest } from '@/guest/hooks/useGuest';
import { useStore } from '@/store/hooks/useStore';
import { Order } from '@/types';

export const useTrackOrder = () => {
  const { guest } = useGuest();
  const { selectedStore } = useStore();
  const { logEvent } = useAnalytics();
  const { logBranchEvent } = useBranch();

  const trackOrder = (order: Order) => {
    const { delivery } = order || {};
    logEvent(Event.NewOrder, EventProperties.getOrderProps(order, selectedStore, guest));
    const event_and_custom_data = {
      transaction_id: order.id,
      currency: 'SAR',
      orderType: order.type,
      revenue: order.total,
      delivery: delivery?.deliveryFee ? delivery.deliveryFee?.base : 0,
    };
    logBranchEvent && logBranchEvent('PURCHASE', event_and_custom_data);
  };

  return trackOrder;
};
