import { LanguageDetectorModule } from 'i18next';

import { getLang } from '@/theme/utils/getLangDirection';

export const languageDetector: LanguageDetectorModule = {
  type: 'languageDetector',
  init: () => {},
  cacheUserLanguage: () => {
    /*
     * TODO: Cache language
     */
  },
  detect: getLang,
};
