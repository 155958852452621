import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import { SentimentSatisfied } from '@material-ui/icons';
import React, { memo } from 'react';

import { t } from '@/i18n/translate';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      flex: 1,
      height: '100vh',
      width: '100vw',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

interface Props {
  text?: string;
}

export const EmptyView = memo<Props>(({ text }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <SentimentSatisfied fontSize="large" color="disabled" />
      <Typography variant="h6" color="textSecondary">
        {text || t('common:empty')}
      </Typography>
    </div>
  );
});
