import { useConfirm } from 'material-ui-confirm';
import React, { memo, useRef } from 'react';

import { useUpdateCart } from '@/cart/hooks/useUpdateCart';
import CartMenuItemRow from '@/cart/mobile/components/CartMenuItemRow';
import { UpdateCartType } from '@/const/enums';
import { t } from '@/i18n/translate';
import { warningItems } from '@/menu/mobile/components/MenuItemRow';
import {
  MenuItemViewContainer,
  MenuItemViewMethods,
} from '@/menu/mobile/containers/MenuItemViewContainer';
import { useStore } from '@/store/hooks/useStore';
import { MenuItem } from '@/types';

interface Props {
  menuItem: MenuItem;
  editable?: boolean;
  showDefaultPic: boolean;
}

export const CartMenuItemRowContainer = memo<Props>(({ menuItem, editable, showDefaultPic }) => {
  const { updateCart, loading, success } = useUpdateCart();
  const { selectedStore } = useStore();
  const menuItemViewRef = useRef<MenuItemViewMethods>();
  const confirm = useConfirm();

  const showSushiWarning = warningItems.includes(menuItem.id);

  const handleEditItemClick = () => {
    menuItemViewRef?.current?.setOpenModal(true);
  };

  const handleRemoveItem = async () => {
    try {
      await confirm({
        title: t('common:youSure'),
        description: t('cart:deleteItemConfirm'),
        confirmationText: t('common:yes'),
        cancellationText: t('common:cancel'),
        confirmationButtonProps: {
          color: 'default',
        },
      });
      updateCart([menuItem], UpdateCartType.Remove);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <CartMenuItemRow
        showDefaultPic={showDefaultPic}
        onItemClick={editable ? handleEditItemClick : undefined}
        loading={loading}
        menuItem={menuItem}
        defaultPic={selectedStore?.profilePic ?? ''}
        onRemoveItemClick={editable ? handleRemoveItem : undefined}
      />
      <MenuItemViewContainer
        showSushiWarning={showSushiWarning}
        isUpdating
        ref={menuItemViewRef}
        item={menuItem}
      />
    </>
  );
});
