import { MAX_DISTANCE } from '@/location/providers/LocationProvider';

export const en = {
  welcome: 'Welcome',
  startOrdering: 'Start Your Dine-in Order',
  deliveryWarning: `Please note that delivery provided by the restaurant is supported within ${MAX_DISTANCE} KM radius`,
  howItWorks: 'How it works?',
  welcomeMessage:
    '✅ We have recognized where you are sitting.\n\n You can start browsing the menu now and placing your order right here. We will bring your the food right to you',
  step1Title: 'Scan QR Code',
  step1Body:
    'Scan QR code to pull the menu. Once you scan the QR Code, wil will know which table you are setting at',
  step2Title: 'Browse',
  step2Body: 'Browse the menu and add items for you and your party',
  step3Title: 'Order',
  step3Body:
    'Place an order and it will be sent to the kitchen and once it’s ready, one of our team members will bring your food right to your table',
  step4Title: 'Checkout',
  step4Body: 'When you’re full and happy, you can checkout right from your phone',
  justBrowsing: 'Just Browsing',
  onlineOrderGreetTitle: 'To server you better, please choose from the following',
};

export const ar: typeof en = {
  welcome: 'أهلاً وسهلاً',
  startOrdering: 'ابدأ طلبك المحلًي',
  deliveryWarning: `نوًد التنويه الى ان نطاق توصيل الطلبات من قبل المطعم هو ${MAX_DISTANCE} كم.`,
  howItWorks: 'كيفيًة الأستخدام؟',
  welcomeMessage:
    '✅ تم التعًرف على مكان جلوسك.\n\nبإمكانك الان عرض قائمة الطعام و الطلب من هذه الخدمة وسيتم احضار طلبك الى طاولتك',
  step1Title: 'استخدم كامرة الجوال لنسخ الكود',
  step1Body: 'بعد نسخك للكود، بإمكانك تصفح المنيو',
  step2Title: 'تصفح المنيو',
  step2Body: 'تصح قائمة الطعام وابدأ طلبك الداخلي',
  step3Title: 'اطلب',
  step3Body: 'سيتم إرسال طلبك الداخلي الى المطبخ وسنقوم بإحضار طلبك الى الطاولة',
  step4Title: 'أطلب الحساب',
  step4Body: 'اطلب حسابك وحاسب عن طريق الجوال',
  justBrowsing: 'اتصفح فقط',
  onlineOrderGreetTitle: 'لخدمتك بشكل افضل، الرجاء الأختيار من التالي',
};
