import React, { memo } from 'react';

import { StorePage } from '@/store/desktop/components/StorePage';

interface Props {
  showAppBar: boolean;
}
export const StorePageContainer = memo<Props>(({ showAppBar }) => {
  return <StorePage showAppBar={showAppBar} />;
});
