import { BranchError, SessionData } from 'branch-sdk';
import React, { memo, useEffect, useState } from 'react';

import { isBrowser } from '@/utils/isBrowser';

import { BranchContext } from './BranchContext';

const branch = isBrowser() ? require('branch-sdk') : undefined;

export const BranchProvider = memo(({ children }) => {
  const [url, setURL] = useState<Opt<string>>();
  const [params, setParams] = useState<any>();

  const logBranchEvent = (event: string, params: Dict) => {
    branch?.logEvent(event, params);
  };

  useEffect(() => {
    branch?.init(window.env.BRANCH_KEY, {}, (err: BranchError, data: SessionData | null) => {
      if (data?.data_parsed) {
        // @ts-ignore
        setURL(data.data_parsed['~referring_link']);
        setParams(data.data_parsed);
      }
    });
  }, []);

  return (
    <BranchContext.Provider value={{ url, params, logBranchEvent }}>
      {children}
    </BranchContext.Provider>
  );
});
