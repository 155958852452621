import React from 'react';

class RedirectPage extends React.Component {
  componentDidMount() {
    window.location.replace('https://www.foodbit.io');
  }

  render() {
    return <></>;
  }
}

export default RedirectPage;
