import React from 'react';

import { BrowseType } from '@/const/enums';

interface BrowserTypeProps {
  type: BrowseType;
  setType?: (type: BrowseType) => void;
}

export const BrowseTypeContext = React.createContext<BrowserTypeProps>({
  type: BrowseType.basic,
});
