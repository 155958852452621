import React, { memo, useCallback, useContext, useEffect, useState } from 'react';

import Api from '@/api/Api';
import { useBrowseType } from '@/browse/hooks/useBrowseType';
import { BrowseType } from '@/const/enums';
import { useUpdateGuest } from '@/guest/hooks/useUpdateGuest';
import { useStore } from '@/store/hooks/useStore';
import StoreHelper from '@/store/utils/StoreHelper';
import { useRTL } from '@/theme/hooks/useRTL';
import { SetThemeContext } from '@/theme/providers/ThemeProvider';
import { getLang } from '@/theme/utils/getLangDirection';
import { Guest } from '@/types';
import FBStorage from '@/utils/FBStorage';
import { isBrowser } from '@/utils/isBrowser';

import { GuestContext } from './GuestContext';

interface GuestProps {
  shouldCreateGuest?: boolean;
  children: React.ReactChild;
}

export const GuestProvider = memo<GuestProps>(({ shouldCreateGuest, children }) => {
  const [guest, setGuest] = useState<Opt<Guest>>(undefined);
  const { updateGuest } = useUpdateGuest();
  const { type: browseType } = useBrowseType();
  const { selectedStore } = useStore();
  const { themeType } = useContext(SetThemeContext);

  const handleSetGuest = useCallback((guest: Guest) => {
    FBStorage.setGuest(guest);
    setGuest(guest);
    // const language = FBStorage.getLanguage();
  }, []);

  const saveNewGuest = async (merchantId: Opt<string>) => {
    const newGuest = await Api.generateGuestId(merchantId, themeType);
    handleSetGuest(newGuest);
  };

  // const updateCurrentGuest = async (guest: Guest) => {
  //   console.log('updateCurrentGuest', guest);
  //   const updatedGuest = await Api.updateGuest(guest);
  //   handleSetGuest(updatedGuest);
  // };

  useEffect(() => {
    if (
      isBrowser() &&
      (browseType != BrowseType.basic ||
        (!browseType && StoreHelper.hasOnlineOrdering(selectedStore)))
    ) {
      const storageGuest = FBStorage.getGuest();
      if (shouldCreateGuest && selectedStore && !storageGuest) {
        // Create a new guest
        saveNewGuest(selectedStore.merchantId);
      }
    }
  }, [guest, selectedStore, browseType]);

  useEffect(() => {
    const language = getLang();
    const storageGuest = FBStorage.getGuest();
    if (storageGuest) {
      setGuest(storageGuest);
    }
    if (
      storageGuest &&
      storageGuest.id &&
      language &&
      storageGuest.language !== language &&
      language !== 'undefined'
    ) {
      storageGuest!!.language = language;
      updateGuest(storageGuest);
    }
  }, []);

  return (
    <GuestContext.Provider
      value={{
        guest,
        setGuest: handleSetGuest,
      }}>
      {children}
    </GuestContext.Provider>
  );
});
