import React, { memo, useEffect, useState } from 'react';

import URLHelper from '@/common/utils/URLHelper';
import { BrowseType, OrderType } from '@/const/enums';
import OrdersHelper from '@/orders/utils/OrdersHelper';
import { useStore } from '@/store/hooks/useStore';
import { Store, StoreSettings } from '@/types';

import { BrowseTypeContext } from './BrowseTypeContext';

interface Props {
  children: React.ReactChild;
  browseType: Opt<BrowseType>;
}

export const getBrowserTypeFromStore = (selectedStore: Store) => {
  const { orderTypes } = selectedStore?.settings || {};
  let defaultBrowseType = BrowseType.basic;
  if (orderTypes) {
    let orderType: Opt<OrderType> = orderTypes[0];
    const typeParam = URLHelper.getURLParams('type');
    if (typeParam !== undefined) {
      orderType = OrdersHelper.getDefaultOrderTypeFromBrowseType(
        parseInt(typeParam) as BrowseType,
        orderTypes,
      );
    }
    if (orderType) {
      defaultBrowseType = OrdersHelper.getBrowseTypeFromOrderType(orderType);
    }
  }
  if (selectedStore.merchantId === '9d92e1df-c714-4c21-ba16-51e1b8f56363') {
    defaultBrowseType = BrowseType.online;
  }
  return defaultBrowseType;
};

export const BrowseTypeProvider = memo<Props>(({ children, browseType }) => {
  const [type, setType] = useState(browseType);
  const { selectedStore } = useStore();

  useEffect(() => {
    if (selectedStore) {
      setType(getBrowserTypeFromStore(selectedStore));
    }
    // const newURL = URLHelper.updateURLParameter(
    //   window.location.href,
    //   'type',
    //   defaultBrowseType.toString(),
    // );
    // window.history.replaceState({}, '', newURL);
  }, [selectedStore]);

  return (
    <BrowseTypeContext.Provider value={{ type: type ?? BrowseType.basic, setType }}>
      {children}
    </BrowseTypeContext.Provider>
  );
});
