import React, { memo, useEffect, useState } from 'react';

import { useBrowseType } from '@/browse/hooks/useBrowseType';
import Header, { Selection } from '@/common/components/Header';
import { useStore } from '@/store/hooks/useStore';
import { getLang, getLangDirection } from '@/theme/utils/getLangDirection';

interface Props {
  onCloseClick?: () => void;
  fixed?: boolean;
  hideElevation?: boolean;
  hideSelection?: boolean;
  disableSelection?: boolean;
  hideLanguage?: boolean;
  hideMenu?: boolean;
}

const HeaderContainer = memo<Props>(
  ({
    onCloseClick,
    fixed,
    hideElevation,
    disableSelection,
    hideSelection,
    hideLanguage,
    hideMenu,
  }) => {
    const lang = getLang();
    const rtl = getLangDirection();
    const [showLanguageButton, setShowLanguageButton] = useState(false);
    const { selectedStore, stores, setSelectedStore, isMamaNoura } = useStore();
    const { type: browseType } = useBrowseType();

    const handleSelectionChange = (valueType: any) => {
      const newSelection = stores.find(store => {
        const { id } = store;
        return (valueType as Selection).value === id;
      });
      if (newSelection && setSelectedStore) {
        setSelectedStore(newSelection);
      }
    };

    const showOrHidLanguageButton = () => {
      const { languages } = selectedStore?.menu || {};
      if (languages?.supported) {
        setShowLanguageButton(languages?.supported.length > 0);
      }
    };

    useEffect(() => {
      showOrHidLanguageButton();
    }, [showOrHidLanguageButton, selectedStore]);

    if (!selectedStore) {
      return <></>;
    }
    const { profilePic } = selectedStore!;

    return (
      <Header
        showMenu={!hideMenu}
        title={(lang && selectedStore.name[lang]) ?? ''}
        hideSelection={hideSelection || stores.length == 1}
        disableSelection={disableSelection}
        onCloseClick={onCloseClick}
        showLanguageButton={showLanguageButton}
        fixed={fixed}
        selectedStore={selectedStore}
        allStores={stores}
        onSelectionChange={handleSelectionChange}
        logo={profilePic}
        rtl={rtl === 'rtl'}
        isMamaNoura={isMamaNoura}
        hideElevation={hideElevation}
        isQuickMarket={
          selectedStore.merchantId === 'f0181bef-77fc-41c1-9e76-fe312fb3ceb6' ||
          selectedStore.merchantId === 'a08bef8b-4b7f-45db-b1fd-10a659bef86b'
        }
      />
    );
  },
);

export default HeaderContainer;
