import { labelOrDefault } from '@/common/utils/tools';
import { MenuItemOptionsType } from '@/const/enums';
import { t } from '@/i18n/translate';
import { getLang } from '@/theme/utils/getLangDirection';
import {Currency, Discount, MenuCategory, MenuItem, MenuItemOption, MenuItemOptions} from '@/types';
import ObjectHelper from '@/utils/ObjectHelper';

export default class MenuItemHelper {
  public static isOptionUnavailable(option: MenuItemOption, storeId?: string): boolean {
    const temp =
      !!option.availability?.isUnAvailable &&
      (option.availability?.unavailableForStores?.find(store => store.id === storeId) !==
        undefined ||
        option.availability?.unavailableForStores === undefined ||
        option.availability?.unavailableForStores.length === 0);
    console.log('isOptionUnavailable: ', option, storeId, temp);
    return temp;
  }
  public static isCategoryUnavailable(category: MenuCategory, storeId?: string): boolean {
    const temp =
      !!category.availability?.isUnAvailable &&
      (category.availability?.unavailableForStores?.find(store => store.id === storeId) !==
        undefined ||
        category.availability?.unavailableForStores === undefined ||
        category.availability?.unavailableForStores.length === 0);

    return (
      temp ||
      (category.availability?.unavailableForStores === undefined &&
        category.availability?.isUnAvailable === true)
    );
  }

  public static isItemHidden(item: MenuItem, storeId?: string): boolean {
    return item.availability?.hideFromStores?.find(store => store.id === storeId) !== undefined;
  }

  public static isOptionHidden(item: MenuItemOption, storeId?: string): boolean {
    return item.availability?.hideFromStores?.find(store => store.id === storeId) !== undefined;
  }

  public static isItemUnavailable(item: MenuItem, storeId?: string): boolean {
    const temp =
      item.availability?.isUnAvailable === true &&
      (item.availability?.unavailableForStores?.find(store => store.id === storeId) !== undefined ||
        item.availability?.unavailableForStores === undefined ||
        item.availability?.unavailableForStores.length === 0);
    return (
      temp ||
      (item.isTemporarilyUnavailable &&
        (item.availability?.unavailableForStores?.length === 0 ||
          item.availability?.unavailableForStores === undefined))
    );
  }

  /**
   * Get the localized name for a given item
   *
   * @param item The menu item
   * @return The name localized
   */
  public static getTitle(item: MenuItem): string {
    const lang = getLang();
    if (!item.name) {
      return '';
    }
    // if (language === 'en' && !StringsHelper.isNullOrEmpty(item.name.en)) {
    //   return item.name.en;
    // } else if (language === 'ar' && !StringsHelper.isNullOrEmpty(item.name.ar)) {
    //   return item.name.ar;
    // }
    // return item.name[language];
    return labelOrDefault(item.name, lang);
  }

  public static sortMenuItems(category: MenuCategory): MenuCategory {
    if (category.sortedItems) {
      return category;
    }
    category.items = category.items.sort((a: MenuItem, b: MenuItem) => {
      if (a.price > b.price) {
        return -1;
      } else if (a.price < b.price) {
        return 1;
      }
      return 0;
    });
    return category;
  }

  public static shouldShowDefaultPic(items: MenuItem[]): boolean {
    let imagesCount = 0;
    items.forEach(item => {
      if (item.profilePic) {
        imagesCount++;
      }
    });
    return imagesCount > 0;
  }

  public static sortItemsInOptions(options: MenuItemOptions): MenuItemOptions {
    // TODO: Deprecated
    if (options && options.items) {
      options.items.sort((a: MenuItemOption, b: MenuItemOption) => {
        if (a.price === b.price) {
          return MenuItemHelper.getOptionName(a)!.localeCompare(MenuItemHelper.getOptionName(b)!);
        }
        return a.price > b.price ? 1 : -1;
      });
    }
    return options;
  }

  public static getMenuItemOptionsWithDefaultSelection(options: MenuItemOptions): MenuItemOptions {
    // Sort the items in the options
    options = MenuItemHelper.sortItemsInOptions(options);

    if (
      !options ||
      (options.type !== MenuItemOptionsType.Single &&
        (!options.items || options.items!.length === 0))
    ) {
      return options;
    }
    // If we don't have a selection for the single item options, select the first one
    let foundSelection = false;
    if (options.items && options.items.length > 0) {
      options.items.forEach(item => {
        if (item.selected) {
          foundSelection = true;
        }
      });
    }

    return options;
  }

  public static getSelectedRequiredOptionsForMenuItem(item: MenuItem): number {
    let selectedOptions = 0;
    item.options?.forEach(options => {
      if (options.isRequired || options.minimumNumberOfSelections > 0) {
        options.items?.forEach(optionItem => {
          if (optionItem.selected) selectedOptions++;
        });
      }
    });

    return selectedOptions;
  }

  public static getListOfMenuItemOptionsWithDefaultSelections(
    optionsList: Opt<MenuItemOptions[]>,
  ): MenuItemOptions[] {
    if (!optionsList) {
      return [];
    }

    return optionsList.map(options =>
      MenuItemHelper.getMenuItemOptionsWithDefaultSelection(options),
    );
  }

  /**
   * Get the selected options
   *
   * @param options The menu item options
   * @return string of selected options separated by comma
   */
  public static getSelectedOptionString(options: MenuItemOptions, currency?: Currency) {
    let stringBuilder = '';
    let foundSelection = 0;
    // For items
    if (options.items && options.items.length > 0) {
      options.items?.forEach(option => {
        if (option.selected) {
          foundSelection++;
          stringBuilder = stringBuilder.concat(
            `${MenuItemHelper.getOptionTitle(option, options, false, currency)}`,
          );
          stringBuilder = stringBuilder.concat(', ');
        }
      });

      // Default
      if (foundSelection === 0 && options.type === MenuItemOptionsType.Single) {
        options.items?.forEach(option => {
          if (option.isDefault) {
            foundSelection++;
            stringBuilder = stringBuilder.concat(
              `${MenuItemHelper.getOptionTitle(option, options, false, currency)}`,
            );
            option.selected = true;
            stringBuilder = stringBuilder.concat(', ');
          }
        });
      }
    }

    if (stringBuilder.length > 0) {
      stringBuilder = stringBuilder.substr(0, stringBuilder.length - 2);
    }
    return { stringBuilder, foundSelection };
  }

  public static getDiscountValue(total: number, discount: number, currency?: Currency): string {
    const value = Number(((total * discount) / 100).toFixed(2));

    if (currency) {
      const { value, isPostLabel } = currency || {};
      if (isPostLabel) {
        return `${value} ${value.trim()}`;
      }

      return `${value.trim()}${value}`;
    }

    return `${value} ${t('common:currency')}`;
  }

  public static getPriceWithCurrency(
    price: number,
    currency?: Currency,
    discount?: Discount,
  ): string {
    let valuePrice = price;
    if (discount && discount.value > 0) {
      valuePrice = valuePrice - (valuePrice * discount.value) / 100;
    }
    if (currency) {
      const { value, isPostLabel } = currency || {};
      if (isPostLabel) {
        return `${valuePrice} ${value.trim()}`;
      }

      return `${value.trim()}${price}`;
    }

    return `${valuePrice} ${t('common:currency')}`;
  }

  public static getMenuItemPrice(
    item: MenuItem,
    calculateWithOptions = false,
    priceIncludesTax = true,
    currency?: Currency,
    showStar = true,
    discount?: Discount,
  ): string {
    const { price } = item;
    // If we have required options that can change the price of the item, return TBD
    const hasRequiredOptions = this.hasRequiredOptions(item);
    const hasOptionsWithPrices = this.hasOptionsWithPrices(item);
    const isMenuItem0 = item.price === 0;

    // If we don't need to calculate the price with options, just return the item price
    if (!calculateWithOptions) {
      return hasRequiredOptions && hasOptionsWithPrices && isMenuItem0
        ? t('menu:priceTBD') + (showStar ? '*' : '')
        : `${this.getPriceWithCurrency(price, currency, discount)}` + (showStar ? '*' : '');
    }

    // Otherwise, calculate the price with options, and return that
    const selectedRequiredOptionsCount = this.getSelectedRequiredOptionsForMenuItem(item);
    const priceWithOptions = this.getMenuItemWithOptionsPrice(item);
    const returnValue =
      selectedRequiredOptionsCount === 0 && hasRequiredOptions && hasOptionsWithPrices
        ? t('menu:priceTBD') + (showStar ? '*' : '')
        : `${this.getPriceWithCurrency(priceWithOptions, currency, discount)}` +
          (showStar ? '*' : '');

    return returnValue;
  }

  public static getOptionsError(item: MenuItem, rtl: boolean, currency?: Currency): Opt<string> {
    const lang = getLang();
    if (item.options) {
      for (const option of item.options) {
        const { foundSelection } = MenuItemHelper.getSelectedOptionString(option, currency);
        const selectionsLeft =
          option.enableMinimumSelections === true || option.enableMinimumSelections === undefined
            ? option.minimumNumberOfSelections - foundSelection
            : 0;
        if (selectionsLeft > 0) {
          return `${labelOrDefault(option.name, lang)}: ${t(
            'menu:minimumRequiredError',
          )} ${selectionsLeft}`;
        }
        const requiredSelections = MenuItemHelper.getRequiredOptionSelections(option);
        if (option.isRequired && requiredSelections === 0) {
          return `${t('menu:selectionRequiredError')} ${option.name[lang]}`;
        }
      }
    }
    return undefined;
  }

  public static getRequiredOptionSelections(option: MenuItemOptions): number {
    return option.items?.filter(each => each.selected).length ?? 0;
  }

  public static hasOptionsWithPrices(item: MenuItem): boolean {
    const optionsWithPricesGreaterThan0 = [];
    if (item.options) {
      item.options.forEach(options => {
        options?.items?.forEach(item => {
          if (item.price > 0) {
            optionsWithPricesGreaterThan0.push(true);
          }
        });
      });
    }
    return optionsWithPricesGreaterThan0.length > 0;
  }

  public static hasRequiredOptions(item: MenuItem): boolean {
    const requiredOptions = [];
    if (item.options) {
      item.options.forEach(options => {
        if (options.isRequired || options.minimumNumberOfSelections > 0) {
          requiredOptions.push(true);
        }
      });
    }
    return requiredOptions.length > 0;
  }
  /**
   * Get the total price for the item (with options)
   *
   * @param item The menu item
   * @return The total price
   */
  public static getMenuItemWithOptionsPrice(item: MenuItem): number {
    if (!item) {
      return 0;
    }
    const quantity = item.quantity > 0 ? item.quantity : 1;
    let optionsPrice = 0;

    if (item.options) {
      item.options.forEach(option => {
        option.items &&
          option.items.forEach(item => {
            if (item.selected) {
              optionsPrice = optionsPrice + item.price;
            }
          });
      });
    }
    const value = (item.price + optionsPrice) * quantity;
    const returnValue = Number(value.toFixed(2));
    return returnValue;
  }

  public static getOptionName(option: Opt<MenuItemOption>): Opt<string> {
    if (!option || !option.name) {
      return;
    }
    const lang = getLang();
    // return language === 'en' ? option.name.en : option.name.ar;
    // return option.name[language];
    return labelOrDefault(option.name, lang);
  }

  public static getSelectedSingleOption(options: MenuItemOptions): Opt<MenuItemOption> {
    const selectedOption = options?.items?.find(opt => opt.selected);
    const defaultOption = options?.items?.find(opt => opt.isDefault);
    return selectedOption ? selectedOption : defaultOption;
  }

  /**
   * Get the localized option name for a given item
   *
   * @param option The menu item option
   * @param allOptions All the other options for this item
   * @param showSubOption Show the sub option in the title
   * @return The name localized
   */
  public static getOptionTitle(
    option: MenuItemOption,
    allOptions: MenuItemOptions,
    showSubOption = false,
    currency?: Currency,
  ): string {
    const name = MenuItemHelper.getOptionName(option);
    const price = MenuItemHelper.getOptionPrice(option, allOptions);
    let sign = price > 0 ? '+' : '';
    // If we already selected this option, do not show a sign
    if (option.selected) {
      sign = '';
    }
    return `${name} (${sign}${MenuItemHelper.getPriceWithCurrency(price, currency)})`;
  }

  /**
   * Get the price for the menu item options. If we have other options selected, show
   * -/+ accordingly.
   *
   * @param option The menu item option
   * @param allOptions All the other options for this item
   * @return The price of the option based on other selections
   */
  public static getOptionPrice(option: MenuItemOption, allOptions: MenuItemOptions): number {
    return option.price;
  }

  /**
   * Are the given items similar, meaning, they both have the same
   * options and are the same items.
   *
   * @param item1 The first item
   * @param item2 The second item
   * @return True of false
   */
  public static areBothItemsSimilar(item1: MenuItem, item2: MenuItem): boolean {
    // Items are not similar
    if (
      item1.id !== item2.id ||
      (item1.options && item2.options && item1.options.length !== item2.options.length)
    ) {
      return false;
    }
    // Both items are similar but have different options
    else if (
      item1.id === item2.id &&
      item1.options &&
      item2.options &&
      item1.options.length !== 0 &&
      item2.options.length !== 0 &&
      item1.options.length === item2.options.length
    ) {
      const copyItem1: MenuItem = ObjectHelper.deepCopy(item1);
      const copyItem2: MenuItem = ObjectHelper.deepCopy(item2);
      const resetMenuItem = (itemToReset: MenuItem) => {
        itemToReset.id = '';
        itemToReset.secondaryId = '';
        itemToReset.quantity = 1;
        itemToReset.options!.forEach(optionListItem => {
          optionListItem.id = '';
          optionListItem.items?.forEach(item => (item.id = ''));
        });
      };

      resetMenuItem(copyItem1);
      resetMenuItem(copyItem2);

      const areSimilar = !ObjectHelper.objectsDifferent(copyItem1, copyItem2);
      return areSimilar;
    }

    return true;
  }

  /**
   * Get the localized options name for a given item
   *
   * @param options The menu item options
   * @return The name localized
   */
  public static getOptionsTitle(options: MenuItemOptions): string {
    const lang = getLang();
    // return language === 'en' ? options.name?.en : options.name?.ar ?? '';
    // return options.name ? options.name[language] : '';
    return labelOrDefault(options.name, lang);
  }
}
