export enum Event {
  LaunchSite = 'Launch Site',
  ClickMenuItem = 'Click Menu Item',
  ClickOrderItem = 'Click Order Item',
  ClickCheckout = 'Click Checkout',
  ChangeLanguageClick = 'Change Language Click',
  ViewOrderPage = 'View Order Page',
  ClickFooter = 'Click On Footer',
  NewOrder = 'New Order',
  CheckOutDineIn = 'Checkout Dine In',
  AddItemToCart = 'Add Item To Cart',
  ApplePayError = 'Apple Pay Error',
  PreApplePayOrderLog = 'Pre Apple Pay Order Log',
  RemoveItemToCart = 'Remove Item From Cart',
  ClearCart = 'Clear Cart',
  StartNewTab = 'Start New Tab',
  ShowCustomerSignup = 'Show Customer Signup',
  ClickCustomerSignup = 'Click Customer Signup',
  CustomerSignup = 'Customer Signup',
  CloseCustomerSignupView = 'Close Customer Signup',
  ChangeOrderType = 'Change Order Type',
  CancelCustomerSignup = 'Cancel Customer Signup',
  OutOfRadiusError = 'Out Of Delivery Radius Error',
}
