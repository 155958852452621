import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import axios from 'axios';
import React from 'react';

import { Event } from '@/analytics/Event';
import SafaryAnalytics from '@/analytics/SafaryAnalytics';
import { Loader } from '@/common/components/Loader';
import { ShortLink } from '@/types';

const styles = createStyles({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});

interface State {
  openDialog: boolean;
}

export interface Props extends WithStyles<typeof styles> {
  shortLink: ShortLink;
}

class ShortLinkPageRouter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openDialog: false,
    };
  }

  public static getInitialProps = async ({ _, __, match, req }: any) => {
    try {
      const { shortLinkCode } = match.params;
      const apiUrl = process.env.API_URL;
      const url = `${apiUrl}/shortlinks/${shortLinkCode}`;
      const { data } = await axios.get(url, {});
      return {
        shortLink: data,
      };
    } catch (error) {
      return { error: true };
    }
  };

  componentDidMount(): void {
    SafaryAnalytics.logEvent(Event.ViewOrderPage);
  }

  public render() {
    const isSSR = typeof window === 'undefined';
    if (isSSR) {
      return <></>;
    }
    const { shortLink } = this.props;
    if (!shortLink) {
      return <></>;
    }
    window.location.replace(shortLink?.url);
    return <Loader />;
  }
}

export default withStyles(styles)(ShortLinkPageRouter);
