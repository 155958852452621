import React, { memo } from 'react';

import { CartButton } from '@/cart/mobile/components/CartButton';
import { CartView } from '@/cart/mobile/components/CartView';
import { FinalizeOnlineOrderContainer } from '@/orders/mobile/containers/FinalizeOnlineOrderContainer';
import { Cart, Tax } from '@/types';

interface Props {
  cart: Opt<Cart>;
  hide: boolean;
  loading: boolean;
  openCart: boolean;
  editCart: boolean;
  onCartButtonClick: () => void;
  onCloseCartClick: () => void;
  onNextClick: () => void;
  onCartCancelCheckoutClick: () => void;
  onClearCartClick: () => void;
  cartButtonLabel: Opt<string>;
  hideCartButton: boolean;
  showEmptyCart: boolean;
  finalizeOrderRef?: React.Ref<any>;
  showCartCancelButton: boolean;
  setTransitionToOrderPage: (value: boolean) => void;
  transitionToOrderPage: boolean;
  tax: Opt<Tax>;
  showCartNextButton: boolean;
  minimumDeliveryOrder: Opt<number>;
  showMinimumDeliveryOrder: boolean;
}
export const BottomActions = memo<Props>(
  ({
    cart,
    hide,
    loading,
    openCart,
    showEmptyCart,
    onCartButtonClick,
    editCart,
    onCloseCartClick,
    onNextClick,
    onCartCancelCheckoutClick,
    onClearCartClick,
    cartButtonLabel,
    hideCartButton,
    finalizeOrderRef,
    showCartCancelButton,
    setTransitionToOrderPage,
    transitionToOrderPage,
    tax,
    showCartNextButton,
    minimumDeliveryOrder,
    showMinimumDeliveryOrder,
  }) => {
    if (hide) {
      return <></>;
    }

    return (
      <>
        {cart && (
          <>
            <CartButton label={cartButtonLabel} hide={hideCartButton} onClick={onCartButtonClick} />
            <CartView
              transitionToOrderPage={transitionToOrderPage}
              loading={loading}
              showEmpty={showEmptyCart}
              showNextButton={showCartNextButton}
              open={openCart}
              cart={cart}
              onClosePress={onCloseCartClick}
              onClearCartClick={onClearCartClick}
              onNextClick={onNextClick}
              onCancelCheckoutClick={onCartCancelCheckoutClick}
              editable={editCart}
              showCancelButton={showCartCancelButton}
              minimumDeliveryOrder={minimumDeliveryOrder}
              showMinimumDeliveryOrder={showMinimumDeliveryOrder}
              tax={tax}
            />
          </>
        )}
        {cart && (
          <FinalizeOnlineOrderContainer
            ref={finalizeOrderRef}
            cart={cart}
            setTransitionToOrderPage={setTransitionToOrderPage}
          />
        )}
      </>
    );
  },
);
