import {
  Box,
  createStyles,
  Dialog,
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  makeStyles,
  Slide,
  TextField,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React, { memo, useContext, useEffect, useState } from 'react';
import CounterInput from 'react-counter-input';
import { Picture } from 'react-responsive-picture';

import Button from '@/common/components/CustomButton';
import { Space } from '@/common/components/Space';
import SushiWarning from '@/common/components/SushiWarning';
import Warning from '@/common/components/Warning';
import HeaderContainer from '@/common/containers/HeaderContainer';
import { TaxContainer } from '@/common/containers/TaxContainer';
import { labelOrDefault } from '@/common/utils/tools';
import { MenuItemOptionsType } from '@/const/enums';
import { t } from '@/i18n/translate';
import MenuItemHelper from '@/menu/utils/MenuItemHelper';
import { useStore } from '@/store/hooks/useStore';
import { useRTL } from '@/theme/hooks/useRTL';
import { SetThemeContext } from '@/theme/providers/ThemeProvider';
import { getLang } from '@/theme/utils/getLangDirection';
import { MenuItem, MenuItemOptions, TimeRange } from '@/types';
import DateHelper from '@/utils/DateHelper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      flex: 1,
      backgroundColor: theme.palette.type === 'light' ? 'white' : theme.palette.primary.dark,
    },
    content: {
      marginTop: 50,
    },
    listItem: {
      alignItems: 'flex-start',
    },
    contentContainer: {
      marginRight: '3%',
      marginLeft: '3%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    optionsContainer: {
      marginRight: '3%',
      marginLeft: '3%',
    },
    title: {
      textDecoration: 'bold',
      fontSize: 19,
      minWidth: 110,
    },
    secondContainer: {
      marginRight: '3%',
      marginLeft: '2%',
    },
    space: {
      marginBottom: '3%',
    },
    thirdContainer: {
      marginTop: '3%',
      marginRight: '3%',
      marginLeft: '3%',
    },
    caloriesContainer: {
      marginRight: '3%',
      marginLeft: '3%',
      marginTop: '3%',
    },
    image: {
      width: '100%',
      maxHeight: 500,
      height: 'auto',
      objectFit: 'cover',
      resizeMode: 'center',
    },
    buttonContainer: {
      position: 'fixed',
      backgroundColor: theme.palette.type === 'light' ? 'white' : theme.palette.primary.dark,
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: theme.spacing(1),
      bottom: 0,
    },
    buttonsRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'center',
    },
    addButtonContainer: {
      width: 215,
    },
    button: {
      fontWeight: 'bold',
      marginLeft: theme.spacing(2),
      width: 220,
    },
    footerSpace: {
      height: 55,
    },
    cancel: {
      width: 150,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    cancelButtonContainer: {
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
    },
    notesContainer: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
    taxContainer: {
      marginLeft: theme.spacing(3),
    },
  }),
);

export interface CounterProps {
  state: { count: number; inputValue: string };
}

interface Props {
  open: boolean;
  menuItem: MenuItem;
  onClosePress: () => void;
  onOptionsClick: (options: MenuItemOptions) => void;
  onAddItemClick: () => void;
  loading: boolean;
  isUpdating?: boolean;
  quantityRef?: React.Ref<any>;
  showTempUnavailable: boolean;
  showTimeUnavailable: boolean;
  isTimeUnavailableError: boolean;
  hideAddToCartButton: boolean;
  timeAvailability: Opt<TimeRange>;
  onNotesChange: (item: string) => void;
  notes: string;
  showCalories: boolean;
  hideNotes: boolean;
  showSushiWarning: boolean;
}

const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  // @ts-ignore
  <Slide direction="up" ref={ref} {...props} />
));

const MenuItemView = memo<Props>(
  ({
    open,
    menuItem,
    onClosePress,
    onAddItemClick,
    loading,
    onOptionsClick,
    isUpdating,
    showTempUnavailable,
    showTimeUnavailable,
    hideAddToCartButton,
    timeAvailability,
    isTimeUnavailableError,
    showCalories,
    onNotesChange,
    hideNotes,
    notes,
    showSushiWarning,
    quantityRef,
  }) => {
    const classes = useStyles();
    const { selectedStore, discount } = useStore();
    const isDiscountedItem = !!discount?.menuItems?.find(item => item.id === menuItem.id);
    const { settings } = selectedStore || {};
    const { theme: customTheme } = settings || {};
    const lang = getLang();
    const { basic } = customTheme || {};
    // const { themeColor } = settings || {};
    const { themeType } = useContext(SetThemeContext);
    const { currency, tax } = selectedStore?.settings || {};
    // const isUsingTheme = themeColor && themeType === URLThemeType.Brand;

    const theme = useTheme();

    const rtl = useRTL();
    const [quantity, setQuantity] = useState(menuItem.quantity > 0 ? menuItem.quantity : 1);

    const renderOptions = () => {
      return menuItem.options?.map(eachOption => {
        const { stringBuilder, foundSelection } = MenuItemHelper.getSelectedOptionString(
          eachOption,
          currency,
        );
        // Hack! If the store for some reason has more than one min selection required but it's a single selection, handle it
        if (
          eachOption?.minimumNumberOfSelections > 1 &&
          eachOption.type === MenuItemOptionsType.Single
        ) {
          eachOption.minimumNumberOfSelections = 1;
        }
        const selectionsLeft = eachOption.minimumNumberOfSelections - foundSelection;
        const selectionsLeftStr = `${t('menu:minimumRequired')}${selectionsLeft}`;
        // Report error to parent
        return (
          <ListItem key={eachOption.id} divider onClick={() => onOptionsClick(eachOption)}>
            <ListItemText className={classes.listItem}>
              <div className={classes.optionsContainer}>
                <div>
                  <Typography component="span" variant="subtitle1" color="textPrimary">
                    {MenuItemHelper.getOptionsTitle(eachOption)}
                  </Typography>
                </div>
                <div>
                  <Typography component="span" variant="caption" color="textSecondary">
                    {stringBuilder}
                  </Typography>
                </div>
                {selectionsLeft > 0 &&
                  (eachOption.enableMinimumSelections === undefined ||
                    eachOption.enableMinimumSelections === true) && (
                    <div>
                      <Typography component="span" variant="caption" color="error">
                        {selectionsLeftStr}
                      </Typography>
                    </div>
                  )}
                {selectionsLeft === 0 &&
                  eachOption.isRequired &&
                  MenuItemHelper.getRequiredOptionSelections(eachOption) === 0 && (
                    <div>
                      <Typography component="span" variant="caption" color="error">
                        {t('menu:selectionRequired')}
                      </Typography>
                    </div>
                  )}
              </div>
            </ListItemText>

            <IconButton onClick={() => onOptionsClick(eachOption)}>
              {rtl ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </ListItem>
        );
      });
    };

    useEffect(() => {}, [menuItem]);

    const renderTitleDescription = () => {
      const { name, description } = menuItem;
      return (
        <ListItem divider={false}>
          <ListItemText className={classes.listItem}>
            <div className={classes.contentContainer}>
              <div>
                <Typography
                  style={{ fontWeight: 'bold' }}
                  component="span"
                  variant="subtitle1"
                  className={classes.title}
                  color="textPrimary">
                  {labelOrDefault(name, lang)}
                </Typography>
              </div>
              <div>
                <Typography
                  className={classes.title}
                  color="textPrimary"
                  align={'right'}
                  style={{
                    textDecorationLine: isDiscountedItem ? 'line-through' : undefined,
                  }}>
                  {MenuItemHelper.getMenuItemPrice(
                    menuItem,
                    false,
                    tax?.isTaxIncludedInPrices ?? true,
                    currency,
                  )}
                </Typography>
                {isDiscountedItem && (
                  <Typography variant="subtitle2" color="error" align={'right'}>
                    {MenuItemHelper.getMenuItemPrice(
                      menuItem,
                      false,
                      tax?.isTaxIncludedInPrices ?? true,
                      currency,
                      false,
                      discount,
                    )}
                  </Typography>
                )}
              </div>
            </div>

            {showSushiWarning && (
              <div style={{ flex: 1, display: 'flex', marginLeft: 10 }}>
                <SushiWarning text={t('menu:sushiWarning')} />
              </div>
            )}

            {/*)}*/}
            {(showTempUnavailable || showTimeUnavailable) && (
              <div className={classes.secondContainer}>
                {showTempUnavailable && <Warning text={t('menu:tempUnavailable')} />}
                {showTimeUnavailable && !showTempUnavailable && (
                  <Warning
                    textDir="ltr"
                    color={isTimeUnavailableError ? 'error' : 'grey'}
                    text={`${DateHelper.convertHourToDateAndFormatIt(
                      timeAvailability?.start,
                    )} - ${DateHelper.convertHourToDateAndFormatIt(timeAvailability?.end)}`}
                  />
                )}
                <div className={classes.space} />
              </div>
            )}
            <div className={classes.thirdContainer}>
              <Typography
                component="span"
                variant="subtitle1"
                color="textSecondary"
                style={{ whiteSpace: 'pre-line' }}>
                {labelOrDefault(description, lang)}
              </Typography>
            </div>
            {showCalories && (
              <div className={classes.caloriesContainer}>
                <Typography component="span" variant="subtitle1" color="textSecondary">
                  {`${menuItem.calories} ${t('menu:calories')}`}
                </Typography>
              </div>
            )}
          </ListItemText>
        </ListItem>
      );
    };

    const itemPrice = MenuItemHelper.getMenuItemPrice(
      {
        ...menuItem,
        quantity,
      },
      true,
      tax?.isTaxIncludedInPrices ?? true,
      currency,
      true,
      discount,
    );
    const isTBD = itemPrice && !itemPrice.includes('TBD') && !itemPrice.includes('الأختيارات');
    return (
      <Dialog
        fullScreen
        open={open}
        onClose={onClosePress}
        onEscapeKeyDown={onClosePress}
        TransitionComponent={Transition}>
        <div className={classes.dialog} dir={rtl ? 'rtl' : 'ltr'}>
          <HeaderContainer hideMenu fixed onCloseClick={onClosePress} />
          <Space height={8} />
          {menuItem.backgroundPic && (
            <Picture
              className={classes.image}
              sources={[
                {
                  srcSet: menuItem.backgroundPic,
                  media: '(min-width: 320px)',
                },
                {
                  srcSet: menuItem.backgroundPic,
                  media: '(max-width: 380px)',
                },
              ]}
            />
          )}
          {/* Content */}
          {renderTitleDescription()}

          <Divider />
          {/* Options */}
          {renderOptions()}
          {/*Notes*/}
          {!hideNotes && (
            <div className={classes.notesContainer}>
              <TextField
                fullWidth
                label={t('order:notes')}
                multiline
                rowsMax={4}
                value={notes}
                onChange={event => onNotesChange(event.target.value)}
              />
            </div>
          )}

          {/* Space */}
          <Space height={22} />

          {/* Add Item Button */}
          <div className={classes.buttonContainer}>
            {/*Tax*/}
            <TaxContainer className={classes.taxContainer} />
            <Divider className={classes.space} />
            {!hideAddToCartButton && (
              <div className={classes.buttonsRow}>
                <CounterInput
                  ref={quantityRef}
                  onCountChange={setQuantity}
                  disabled
                  min={1}
                  inputStyle={{
                    color: theme.palette.type === 'dark' ? 'white' : 'black',
                    fontSize: '1.5em',
                  }}
                  btnStyle={{
                    color: theme.palette.type === 'dark' ? 'white' : 'black',
                    fontSize: '1.5em',
                  }}
                  count={quantity}
                  max={100}
                />
                <Space width={1} />
                <Button
                  size="large"
                  loading={loading}
                  color="default"
                  style={basic?.color ? { backgroundColor: basic?.color, color: 'white' } : {}}
                  variant="contained"
                  containerStyle={classes.addButtonContainer}
                  className={classes.button}
                  onClick={onAddItemClick}>
                  <div>
                    <Typography variant="button">
                      {isUpdating ? t('common:save') : t('menu:addItemToCart')}
                    </Typography>
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      alignItems={'center'}
                      justifyContent={'center'}>
                      <Typography
                        variant="subtitle2"
                        style={{
                          textDecorationLine:
                            isDiscountedItem && isTBD ? 'line-through' : undefined,
                        }}>
                        {`(${MenuItemHelper.getMenuItemPrice(
                          {
                            ...menuItem,
                            quantity,
                          },
                          true,
                          tax?.isTaxIncludedInPrices ?? true,
                          currency,
                        )})`}
                      </Typography>
                      <Space width={1} />
                      {isDiscountedItem && isTBD ? (
                        <Typography variant="subtitle2" color="error">
                          {`(${itemPrice})`}
                        </Typography>
                      ) : null}
                    </Box>
                  </div>
                </Button>
              </div>
            )}
            <Button
              variant="text"
              size="large"
              className={classes.cancel}
              containerStyle={classes.cancelButtonContainer}
              onClick={onClosePress}>
              {t('common:cancel')}
            </Button>
          </div>
        </div>
      </Dialog>
    );
  },
);

export default MenuItemView;
