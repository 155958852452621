import {
  createStyles,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  NoSsr,
  Select,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import { DirectionsCar, Map } from '@material-ui/icons';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AppleIcon from '@material-ui/icons/Apple';
import EditIcon from '@material-ui/icons/Edit';
import PaymentIcon from '@material-ui/icons/Payment';
import SendIcon from '@material-ui/icons/Send';
import classNames from 'classnames';
// let MuiPhoneNumber2: any = null;
// if (typeof window !== 'undefined') {
//   const { MuiPhoneNumber } = require('material-ui-phone-number');
//   MuiPhoneNumber2 = MuiPhoneNumber;
// }
import MuiPhoneNumber from 'mui-phone-input-ssr';
import React, { memo, useContext, useEffect, useState } from 'react';
import ReactCodeInput from 'react-code-input';

import CartHelper from '@/cart/utils/CartHelper';
import Button from '@/common/components/CustomButton';
import { PickupSelection, Selection } from '@/common/components/Header';
import { uuid } from '@/common/utils/uuid';
import { Language, PaymentMethod, PickupType, URLThemeType } from '@/const/enums';
import { t } from '@/i18n/translate';
import { LocationInputMapContainer } from '@/location/mobile/containers/LocationInputMapContainer';
import MenuItemHelper from '@/menu/utils/MenuItemHelper';
import { PaymentPageContainer } from '@/payment/mobile/container/PaymentPageContainer';
import { useStore } from '@/store/hooks/useStore';
import StoreHelper from '@/store/utils/StoreHelper';
import { useRTL } from '@/theme/hooks/useRTL';
import { SetThemeContext } from '@/theme/providers/ThemeProvider';
import {
  CarInfo,
  Label,
  LocationField,
  MenuItem as FDMenuItem,
  PaymentMethodLabel,
  Store,
  Tax,
} from '@/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableNoContainer: {
      alignItems: 'center',
      width: '100%',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(0.5),
    },
    tableInputContainer: {
      display: 'flex',
    },
    formsContainer: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      marginRight: theme.spacing(1),
      justifyContent: 'center',
      alignItems: 'center',
    },
    button: {
      fontWeight: 'bold',
      height: '7%',
      marginTop: theme.spacing(1),
      width: '100%',
      bottom: 1,
    },
    applePayButton: {
      backgroundColor: 'black',
      color: 'white',
    },
    greenButton: {
      backgroundColor: '#2aad4b',
      color: 'white',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    payment: {
      display: 'flex',
      alignItems: 'center',
    },
    paymentIcon: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
    img: {
      maxWidth: '18%',
      borderRadius: 30,
    },
    title: {
      marginTop: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      flexGrow: 1,
    },
    tableTitle: {
      fontSize: 13,
      marginLeft: theme.spacing(0.5),
      marginBottom: theme.spacing(1),
    },
    feesContainer: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },
    feeRow: {
      marginBottom: theme.spacing(1),
      display: 'flex',
      flexDirection: 'row',
      alignSelf: 'center',
      justifyContent: 'space-between',
    },
    bold: {
      fontWeight: 'bold',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
    },
    locationContainer: {
      width: '100%',
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      flexDirection: 'row',
    },
    locationMenuItemContainer: {
      width: '100%',
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'space-between',
    },
  }),
);

interface Props {
  open: boolean;
  onCloseClick: () => void;
  tableNumber: Opt<string>;
  onChangeTableNumber: (value: string) => void;
  loading: boolean;
  headerImageUrl: string;
  onCreateOrderClick: () => void;
  tableError: boolean;
  disableSubmitButton: boolean;
  nameError: boolean;
  phoneError: boolean;
  phoneCountryCode: string;
  phoneNumber: string;
  onPhoneChange: (value: string) => void;
  onNameChange: (value: string) => void;
  onCarInfoChange: (car: CarInfo) => void;
  showName: boolean;
  showPhone: boolean;
  name: string;
  onOrderTypeChange: (value: number) => void;
  orderTypeValue: Opt<Selection>;
  orderTypes: Selection[];
  showDeliveryAddress: boolean;
  deliveryAddress: Opt<string>;
  onChangeAddressClick: () => void;
  deliveryAddressError: boolean;
  selectedStore?: Store;
  stores: Store[];
  totalPriceForItems: number;
  deliveryErrorText: string;
  onStoreChange: (store: string) => void;
  locationInputRef?: React.Ref<any>;
  showTableNumber: boolean;
  orderTypeError: boolean;
  orderTypeErrorText: string;
  serviceFee: Opt<number>;
  paidTax: number;
  deliveryFee: Opt<number>;
  items: FDMenuItem[];
  paymentMethodLabels: Opt<PaymentMethodLabel[]>;
  paymentMethod: Opt<PaymentMethod>;
  onPaymentMethodChange: (value: PaymentMethod) => void;
  hidePaymentMethods: boolean;
  goToPayment: boolean;
  onApplePayClick: () => void;
  showSubmitButton: boolean;
  showApplePayButton: boolean;
  showPaymentsButton: boolean;
  onGoToPaymentButtonClick: () => void;
  paymentReference: string;
  intializeSelectedStore: boolean;
  orderTotal: number;
  onMapClick: () => void;
  showMapIcon: boolean;
  showCarInput: boolean;
  carInfo: Opt<CarInfo>;
  tax: Opt<Tax>;
  deliveryLocationFields: Opt<LocationField[]>;
  onDeliveryLocationFieldsChange: (label: Label, value: string) => void;
  showDeliveryLocationFields: boolean;
  nameErrorMessage?: string;
  // onChangeTableNumber: () => void;
}

export const FinalizeOnlineOrderForm = memo<Props>(
  ({
    open,
    carInfo,
    onCloseClick,
    loading,
    headerImageUrl,
    tableNumber,
    // onTableNumberChange,
    onCreateOrderClick,
    tableError,
    disableSubmitButton,
    items,
    serviceFee,
    deliveryFee,
    paidTax,
    nameError,
    phoneError,
    phoneCountryCode,
    phoneNumber,
    onPhoneChange,
    onNameChange,
    showName,
    showPhone,
    name,
    onOrderTypeChange,
    orderTypeValue,
    orderTypes,
    paymentMethodLabels,
    paymentMethod,
    showDeliveryAddress,
    deliveryAddress,
    onChangeAddressClick,
    deliveryAddressError,
    selectedStore,
    stores,
    deliveryErrorText,
    onStoreChange,
    locationInputRef,
    showTableNumber,
    orderTypeError,
    orderTypeErrorText,
    onPaymentMethodChange,
    hidePaymentMethods,
    goToPayment,
    onApplePayClick,
    showSubmitButton,
    showApplePayButton,
    showPaymentsButton,
    onGoToPaymentButtonClick,
    paymentReference,
    totalPriceForItems,
    orderTotal,
    intializeSelectedStore,
    onMapClick,
    showMapIcon,
    showCarInput,
    tax,
    onCarInfoChange,
    deliveryLocationFields,
    onDeliveryLocationFieldsChange,
    showDeliveryLocationFields,
    onChangeTableNumber,
    nameErrorMessage,
  }) => {
    const classes = useStyles();
    const theme = useTheme();
    const rtl = useRTL();
    const lang = rtl ? Language.Arabic : Language.English;

    const { themeType } = useContext(SetThemeContext);

    const quantity = CartHelper.getQuantity(items);
    const [internalSelectedStore, setInternalSelectedStore] = useState<Opt<string>>(
      stores.length > 1 ? undefined : selectedStore?.id,
    );
    const { selectedStore: store, discount } = useStore();
    const { currency, themeColor } = store?.settings || {};

    const renderHeader = () => (
      <div className={classes.header}>
        <img alt="Safary" src={headerImageUrl} className={classes.img} />
      </div>
    );

    useEffect(() => {
      if (
        (internalSelectedStore || intializeSelectedStore) &&
        internalSelectedStore != selectedStore?.id
      ) {
        setInternalSelectedStore(selectedStore?.id);
      }
    }, [selectedStore, intializeSelectedStore]);

    const handleSelectStore = (store: string) => {
      setInternalSelectedStore(store);
      onStoreChange(store);
    };

    const props = {
      inputStyle: {
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'monospace',
        margin: '4px',
        MozAppearance: 'textfield',
        width: '35px',
        borderRadius: '3px',
        fontSize: '28px',
        height: '40px',
        textAlign: 'center',
      },
      inputStyleInvalid: {
        fontFamily: 'monospace',
        margin: '4px',
        MozAppearance: 'textfield',
        width: '35px',
        borderRadius: '3px',
        fontSize: '28px',
        height: '40px',
        borderWidth: 2,
        borderColor: 'red',
      },
    };

    const iconColor = theme.palette.type === 'dark' ? '#fafafa' : '#161617';
    const subtotal = MenuItemHelper.getPriceWithCurrency(totalPriceForItems, currency);
    return (
      <>
        <Dialog fullWidth open={open} onClose={onCloseClick} onEscapeKeyDown={onCloseClick}>
          <DialogContent dir={rtl ? 'rtl' : 'ltr'}>
            {renderHeader()}
            <Typography align={'center'} color="textPrimary" variant="h6" className={classes.title}>
              {t('order:finalizeOnlineOrderTitle')}
            </Typography>
            <div className={classes.formsContainer}>
              {/* Order Type */}
              <FormControl fullWidth className={classes.formControl} error={orderTypeError}>
                <InputLabel>{t('order:type')}</InputLabel>
                <Select
                  value={orderTypeValue?.key}
                  onChange={e => onOrderTypeChange(e.target.value! as number)}>
                  {orderTypes.map(orderType => (
                    <MenuItem key={orderType.key} value={orderType.key}>
                      {orderType.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{orderTypeErrorText}</FormHelperText>
              </FormControl>
              {/* Store */}
              <Grid container direction="row" alignItems="flex-end" spacing={1}>
                <Grid item xs={showMapIcon ? 11 : 12}>
                  <FormControl
                    error={internalSelectedStore === undefined}
                    fullWidth
                    className={classes.formControl}>
                    <InputLabel>{t('order:storeLocation')}</InputLabel>
                    <Select
                      value={internalSelectedStore ?? ''}
                      onChange={e => handleSelectStore(e.target.value! as string)}>
                      {StoreHelper.sortStoresByDistance(stores).map(store => {
                        const { id } = store;
                        return (
                          <MenuItem dir={rtl ? 'rtl' : 'ltr'} key={store.id} value={id}>
                            <Grid
                              xs={11}
                              container
                              direction="row"
                              justify="space-between"
                              spacing={1}>
                              <Grid item xs={9}>
                                <div
                                  style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'pre-line',
                                  }}>
                                  <Typography variant="subtitle2">
                                    {StoreHelper.getStoreDeliveryLocationName(
                                      store,
                                      rtl ? 'ar' : 'en',
                                    )}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item container xs={2} alignItems="center">
                                {store.distance !== undefined &&
                                  store.distance > 0 &&
                                  showMapIcon && (
                                    <Typography
                                      color="textSecondary"
                                      variant="subtitle2">{`${store.distance} KM`}</Typography>
                                  )}
                              </Grid>
                            </Grid>
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {!internalSelectedStore && (
                      <FormHelperText>{t('order:confirmSelectStoreError')}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                {showMapIcon && (
                  <Grid item xs={1}>
                    <IconButton size="small" onClick={onMapClick}>
                      <Map />
                    </IconButton>
                  </Grid>
                )}
              </Grid>

              {/* Table No. */}
              {showTableNumber && (
                <div className={classes.tableNoContainer}>
                  <InputLabel error={tableError} className={classes.tableTitle}>
                    {selectedStore?.merchantId === '69668a9b-fde3-463b-8ec5-005f1bc98133'
                      ? t('order:officeNo')
                      : t('order:tableNo')}
                  </InputLabel>
                  <div
                    dir="ltr"
                    className={classes.tableInputContainer}
                    style={{
                      justifyContent: rtl ? 'flex-end' : 'flex-start',
                    }}>
                    {/*<Button*/}
                    {/*  endIcon={<EditIcon />}*/}
                    {/*  variant={'text'}*/}
                    {/*  size={'large'}*/}
                    {/*  onClick={onChangeTableNumber}>*/}
                    {/*  {tableNumber !== null && tableNumber ? tableNumber : 0}*/}
                    {/*</Button>*/}
                    <ReactCodeInput
                      value={tableNumber !== null && tableNumber ? tableNumber : 0}
                      onChange={onChangeTableNumber}
                      isValid={!tableError}
                      type="number"
                      autoFocus
                      inputMode="numeric"
                      fields={2}
                      dir={'ltr'}
                      {...props}
                    />
                  </div>
                </div>
              )}
              {/* Name */}
              {showName && (
                <FormControl fullWidth className={classes.formControl}>
                  {selectedStore?.merchantId === '4c8c8a00-0431-42cc-b583-1dca4d4af098' ? (
                    <InputLabel>{t('order:nameYo')}</InputLabel>
                  ) : (
                    <InputLabel>{t('signup:name')}</InputLabel>
                  )}
                  <Input
                    value={name}
                    error={nameError}
                    onChange={event => onNameChange(event.target.value)}
                    startAdornment={
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    }
                  />
                  <FormHelperText>{nameErrorMessage}</FormHelperText>
                </FormControl>
              )}
              {/* Car */}
              {showCarInput && (
                <Grid container direction="row" xs={12} spacing={1}>
                  <Grid item xs={7}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel>{t('order:carMake')}</InputLabel>
                      <Input
                        value={carInfo?.make}
                        error={nameError}
                        onChange={event => onCarInfoChange({ make: event.target.value })}
                        startAdornment={
                          <InputAdornment position="start">
                            <DirectionsCar />
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={5}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel>{t('order:carColor')}</InputLabel>
                      <Input
                        value={carInfo?.color}
                        error={nameError}
                        onChange={event => onCarInfoChange({ color: event.target.value })}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              )}
              {/* Phone */}
              {showPhone && (
                <FormControl fullWidth className={classes.formControl}>
                  <MuiPhoneNumber
                    dir={'ltr'}
                    countryCodeEditable={true}
                    disableDropdown
                    error={phoneError}
                    name="phone"
                    label={t('signup:phoneNumber')}
                    data-cy="user-phone"
                    defaultCountry={phoneCountryCode}
                    value={phoneNumber}
                    onChange={onPhoneChange}
                  />
                </FormControl>
              )}
              {/* Delivery Location Fields */}
              {showDeliveryLocationFields &&
                deliveryLocationFields?.map(
                  field =>
                    field.label && (
                      <FormControl
                        key={field.label?.toString()}
                        fullWidth
                        className={classes.formControl}>
                        <InputLabel>{field?.label[lang]}</InputLabel>
                        <Input
                          value={field?.value}
                          error={field?.error}
                          onChange={event =>
                            onDeliveryLocationFieldsChange(field?.label, event.target.value)
                          }
                        />
                      </FormControl>
                    ),
                )}

              {/* Store Location */}
              <Grid container direction="row" alignItems="flex-end" spacing={1}>
                {/* Address */}
                {showDeliveryAddress && (
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    error={deliveryAddressError}>
                    <InputLabel id="demo-simple-select-label">
                      {t('order:deliveryAddress')}
                    </InputLabel>
                    <Input
                      readOnly
                      multiline
                      value={deliveryAddress ?? '   '}
                      error={deliveryAddressError}
                      onClick={event => {
                        onChangeAddressClick();
                        event.preventDefault();
                      }}
                      endAdornment={
                        <InputAdornment position="start" onClick={onChangeAddressClick}>
                          <EditIcon />
                        </InputAdornment>
                      }
                    />
                    <FormHelperText>{deliveryErrorText}</FormHelperText>
                  </FormControl>
                )}
              </Grid>
              {/* PaymentTransaction */}
              {!hidePaymentMethods && (
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel>{t('payment:paymentMethod')}</InputLabel>
                  <Select
                    value={paymentMethod}
                    onChange={e => onPaymentMethodChange(e.target.value! as PaymentMethod)}>
                    {paymentMethodLabels?.map(pmLabel => {
                      const { label, method, PaymentIcons } = pmLabel;
                      return (
                        <MenuItem dir={rtl ? 'rtl' : 'ltr'} key={method} value={method}>
                          <div className={classes.payment}>
                            {PaymentIcons.map(PaymentIcon => (
                              <PaymentIcon
                                key={uuid()}
                                className={classes.paymentIcon}
                                fillColor={iconColor}
                              />
                            ))}
                            {label}
                          </div>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            </div>

            {/*<ListItem className={classes.feesContainer} button divider={false}>*/}
            <ListItemText className={classes.feesContainer}>
              <div className={classes.feeRow}>
                <Typography component="span" variant="subtitle2" color="textPrimary">
                  {rtl
                    ? `${t('order:orderPrice')}`
                    : `${t('order:orderPrice')} (${quantity ? quantity : 1}x ${t('menu:items')})`}
                </Typography>

                <Typography component="span" variant="subtitle2" color="textPrimary">
                  {subtotal}
                </Typography>
              </div>
              <Divider />
              {/*{discount && (*/}
              {/*  <div>*/}
              {/*    <div className={classes.feeRow}>*/}
              {/*      <Typography component="span" variant="subtitle2" color="textPrimary">*/}
              {/*        {`${t('order:discount')} (${discount}%)`}*/}
              {/*      </Typography>*/}
              {/*      <Typography component="span" variant="subtitle2" color="textPrimary">*/}
              {/*        {MenuItemHelper.getDiscountValue(totalPriceForItems, discount)}*/}
              {/*      </Typography>*/}
              {/*    </div>*/}
              {/*    <Divider />*/}
              {/*  </div>*/}
              {/*)}*/}
              {deliveryFee && (
                <>
                  <div className={classes.feeRow}>
                    <Typography component="span" variant="subtitle2" color="textPrimary">
                      {t('cart:deliveryFee')}
                    </Typography>

                    <Typography component="span" variant="subtitle2" color="textPrimary">
                      {MenuItemHelper.getPriceWithCurrency(deliveryFee, currency)}
                    </Typography>
                  </div>
                  <Divider />
                </>
              )}
              {serviceFee && (
                <>
                  <div className={classes.feeRow}>
                    <Typography component="span" variant="subtitle2" color="textPrimary">
                      {t('cart:serviceFee')}
                    </Typography>

                    <Typography component="span" variant="subtitle2" color="textPrimary">
                      {MenuItemHelper.getPriceWithCurrency(serviceFee, currency)}
                    </Typography>
                  </div>
                  <Divider />
                </>
              )}
              <>
                <div className={classes.feeRow}>
                  <div className={classes.column}>
                    <Typography component="span" variant="subtitle2" color="textPrimary">
                      {`${t('common:tax')} (${tax?.percentage ?? CartHelper.TAX}%)`}
                    </Typography>
                    <Typography component="span" variant="caption" color="textSecondary">
                      {tax && !tax.isTaxIncludedInPrices
                        ? t('common:taxNotIncludedInPrices')
                        : t('common:taxIncludedInPrices')}
                    </Typography>
                  </div>

                  <Typography component="span" variant="subtitle2" color="textPrimary">
                    {MenuItemHelper.getPriceWithCurrency(paidTax, currency)}
                  </Typography>
                </div>
                <Divider />
              </>
              <div className={classes.feeRow}>
                <Typography
                  className={classes.bold}
                  component="span"
                  variant="subtitle2"
                  color="textPrimary">
                  {t('cart:total')}
                </Typography>

                <Typography
                  className={classes.bold}
                  component="span"
                  variant="subtitle2"
                  color="textPrimary">
                  {MenuItemHelper.getPriceWithCurrency(orderTotal, currency)}
                </Typography>
              </div>
            </ListItemText>
            {/*</ListItem>*/}
            {showSubmitButton && (
              <Button
                loading={loading}
                variant="contained"
                onClick={onCreateOrderClick}
                disabled={disableSubmitButton || internalSelectedStore === undefined}
                startIcon={<SendIcon />}
                className={classNames(classes.button, classes.greenButton)}
                style={
                  themeType === URLThemeType.Brand
                    ? { backgroundColor: themeColor, color: 'white' }
                    : {}
                }>
                {t('order:submitOrder')}
              </Button>
            )}
            {showPaymentsButton && (
              <Button
                loading={loading}
                variant="contained"
                onClick={onGoToPaymentButtonClick}
                disabled={disableSubmitButton}
                startIcon={<PaymentIcon />}
                className={classNames(classes.button, classes.greenButton)}>
                {t('payment:goToPayment')}
              </Button>
            )}
            {showApplePayButton && (
              <Button
                loading={loading}
                variant="contained"
                startIcon={<AppleIcon />}
                onClick={onApplePayClick}
                className={classNames(classes.applePayButton, classes.button)}>
                {t('payment:applePay')}
              </Button>
            )}

            <Button
              variant="text"
              color="default"
              disabled={loading}
              onClick={onCloseClick}
              className={classes.button}>
              {t('common:cancel')}
            </Button>
          </DialogContent>
        </Dialog>
        <LocationInputMapContainer ref={locationInputRef} />
        {goToPayment && (
          <PaymentPageContainer total={orderTotal} paymentReference={paymentReference} />
        )}
      </>
    );
  },
);
