import { createStyles, makeStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';
import React, { memo } from 'react';

import { OrderItemContainer } from '@/orders/mobile/containers/OrderItemContainer';
import { Order } from '@/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    orders: {
      // height: '42vh',
      overflowY: 'scroll',
    },
    fullScreen: {
      // height: '100%',
    },
    space: {
      height: 55,
    },
  }),
);

interface Props {
  orders: Order[];
  fullScreen?: boolean;
  showMoreInfo?: boolean;
}

export const OrdersList = memo<Props>(({ orders, showMoreInfo, fullScreen }) => {
  const classes = useStyles();
  return (
    <div className={classNames(classes.orders, fullScreen ? classes.fullScreen : undefined)}>
      {orders.map(order => (
        <OrderItemContainer key={order.id} order={order} showMoreInfo={showMoreInfo} />
      ))}
      <div className={classes.space} />
    </div>
  );
});
