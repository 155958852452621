import {
  createMuiTheme,
  createStyles,
  Divider,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { memo, useEffect } from 'react';
import Lottie from 'react-lottie';

import Button from '@/common/components/CustomButton';
import { Loader } from '@/common/components/Loader';
import { Space } from '@/common/components/Space';
import HeaderContainer from '@/common/containers/HeaderContainer';
import { DineInStatus } from '@/const/enums';
import { t } from '@/i18n/translate';
import MenuItemHelper from '@/menu/utils/MenuItemHelper';
import { OrdersList } from '@/orders/mobile/components/OrdersList';
import OrdersHelper from '@/orders/utils/OrdersHelper';
import { useStore } from '@/store/hooks/useStore';
import { Order, Tax } from '@/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      backgroundColor: theme.palette.type === 'light' ? 'white' : theme.palette.primary.dark,
    },
    orders: {
      marginBottom: theme.spacing(3),
      height: '50vh',
      overflowY: 'scroll',
    },
    space: {
      height: theme.spacing(3),
    },
    button: {
      fontWeight: 'bold',
      borderRadius: 0,
    },
    whatsAppButton: {
      color: 'white',
    },
    bottomContainer: {
      position: 'fixed',
      width: '100%',
      bottom: 0,
    },
    orderButtonsContainer: {
      justifyContent: 'stretch',
      flex: 1,
      display: 'flex',
      zIndex: 1,
    },
    checkoutButtonsContainer: {
      justifyContent: 'stretch',
      flex: 1,
      width: '100%',
      zIndex: 1,
    },
    half: {
      width: '100%',
    },
    stepperContainer: {
      backgroundColor: theme.palette.type === 'light' ? 'white' : theme.palette.primary.dark,
    },
    total: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(1),
    },
    lottie: {
      marginTop: theme.spacing(9),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    text: {
      marginTop: theme.spacing(1),
    },
    totalContainer: {
      backgroundColor: theme.palette.type === 'light' ? 'white' : theme.palette.primary.dark,
      paddingBottom: theme.spacing(2),
    },
    dineInStatus: {
      textAlign: 'center',
      marginTop: -theme.spacing(1),
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
    },
  }),
);

interface Props {
  dineInStatus: DineInStatus;
  orders: Order[];
  activeStep: number;
  onAddToOrderClick: () => void;
  onCheckoutClick: () => void;
  onNewTabClick: () => void;
  showOrders: boolean;
  showNewTapButton: boolean;
  showLoading: boolean;
  showFeedbackButton: boolean;
  serviceFee?: Opt<number>;
  paidTax: number;
  orderTotal: number;
  totalPriceForItems: number;
  rtl: boolean;
  quantity: number;
  onFeedbackClick: () => void;
  onRequestWaiterClick: () => void;
  showAddToOrderButton: boolean;
  tax: Opt<Tax>;
}

export const DineInOrdersPage = memo<Props>(
  ({
    dineInStatus,
    orders,
    onAddToOrderClick,
    onCheckoutClick,
    onNewTabClick,
    showOrders,
    showNewTapButton,
    showFeedbackButton,
    showLoading,
    serviceFee,
    paidTax,
    orderTotal,
    totalPriceForItems,
    rtl,
    quantity,
    showAddToOrderButton,
    onFeedbackClick,
    onRequestWaiterClick,
    tax,
  }) => {
    const classes = useStyles();
    const { selectedStore } = useStore();
    const { currency } = selectedStore?.settings || {};
    const theme = useTheme();

    useEffect(() => {}, [dineInStatus]);

    const { text, animation, animationSpeed, loopAnimation } =
      OrdersHelper.getDineInStatusTextAndAnimation(dineInStatus, orders);

    const defaultOptions = {
      loop: loopAnimation,
      autoplay: true,
      animationData: animation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
    const renderTotal = () => (
      <ListItem className={classes.totalContainer} divider={false}>
        <ListItemText>
          <div className={classes.row}>
            <Typography component="span" variant="subtitle1" color="textPrimary">
              {rtl ? `${t('order:orderPrice')}` : `${quantity ? quantity : 1}x ${t('menu:items')}`}
            </Typography>

            <Typography component="span" variant="subtitle1" color="textPrimary">
              {MenuItemHelper.getPriceWithCurrency(totalPriceForItems, currency)}
            </Typography>
          </div>
          <Divider />
          {serviceFee && (
            <>
              <div className={classes.row}>
                <Typography component="span" variant="subtitle1" color="textPrimary">
                  {t('cart:serviceFee')}
                </Typography>

                <Typography component="span" variant="subtitle1" color="textPrimary">
                  {MenuItemHelper.getPriceWithCurrency(serviceFee, currency)}
                </Typography>
              </div>
              <Divider />
            </>
          )}
          <div className={classes.row}>
            <div className={classes.column}>
              <Typography component="span" variant="subtitle1" color="textPrimary">
                {t('common:tax')}
              </Typography>
              {tax?.isTaxIncludedInPrices && (
                <Typography component="span" variant="caption" color="textSecondary">
                  {t('common:taxIncludedInPrices')}
                </Typography>
              )}
            </div>

            <Typography component="span" variant="subtitle1" color="textPrimary">
              {MenuItemHelper.getPriceWithCurrency(paidTax, currency)}
            </Typography>
          </div>
          <Divider />
          <div className={classes.total}>
            <Typography component="span" variant="h6" color="textPrimary">
              {t('cart:total')}
            </Typography>
            <Typography component="span" variant="h6" color="textPrimary">
              {MenuItemHelper.getPriceWithCurrency(orderTotal, currency)}
            </Typography>
          </div>
        </ListItemText>
      </ListItem>
    );

    const feedbackTheme = createMuiTheme({
      palette: {
        primary: {
          main: '#4CAF50',
        },
        secondary: {
          main: '#4CAF50',
        },
      },
    });

    return (
      <div className={classes.root}>
        <HeaderContainer fixed />
        <div className={classes.lottie}>
          <Lottie options={defaultOptions} height={180} width={180} speed={animationSpeed} />
          <Typography
            className={classes.dineInStatus}
            variant="h6"
            color="textSecondary"
            style={{ whiteSpace: 'pre-line' }}>
            {text}
          </Typography>
        </div>
        <div className={classes.space} />
        <Divider />
        {showLoading && <Loader />}

        {/*Orders*/}
        {showOrders && <OrdersList orders={orders} showMoreInfo={false} />}

        {/*Space*/}
        <Space height={theme.spacing(3)} />

        {/* Bottom */}
        {showOrders && (
          <div className={classes.bottomContainer}>
            {renderTotal()}
            {showAddToOrderButton && (
              <div className={classes.orderButtonsContainer}>
                {/*<Button*/}
                {/*  containerStyle={classes.half}*/}
                {/*  size="large"*/}
                {/*  variant="contained"*/}
                {/*  className={classes.button}*/}
                {/*  onClick={onRequestWaiterClick}>*/}
                {/*  {t('order:requestWaiter')}*/}
                {/*</Button>*/}
                <Button
                  size="large"
                  containerStyle={classes.half}
                  variant="contained"
                  className={classes.button}
                  onClick={onCheckoutClick}>
                  {t('order:requestBill')}
                </Button>
              </div>
            )}
            <Button
              color="secondary"
              size="large"
              variant="contained"
              className={classes.button}
              onClick={onAddToOrderClick}>
              {t('order:addToOrder')}
            </Button>
            {showFeedbackButton && (
              <MuiThemeProvider theme={feedbackTheme}>
                <Button
                  size="large"
                  color={'primary'}
                  variant="contained"
                  buttonStyle={classNames(classes.button, classes.whatsAppButton)}
                  onClick={onFeedbackClick}>
                  {t('order:experienceFeedbackButton')}
                </Button>
              </MuiThemeProvider>
            )}
          </div>
        )}
        {/* New Tap */}
        {showNewTapButton && (
          <div className={classes.bottomContainer}>
            {renderTotal()}
            <div className={classes.checkoutButtonsContainer}>
              {showFeedbackButton && (
                <MuiThemeProvider theme={feedbackTheme}>
                  <Button
                    size="large"
                    color={'primary'}
                    variant="contained"
                    buttonStyle={classNames(classes.button, classes.whatsAppButton)}
                    onClick={onFeedbackClick}>
                    {t('order:experienceFeedbackButton')}
                  </Button>
                </MuiThemeProvider>
              )}
              <Button
                buttonStyle={classes.button}
                fullWidth
                variant="contained"
                size="large"
                onClick={onNewTabClick}>
                {t('order:newTab')}
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  },
);
