export const en = {
  pendingReview: 'Pending',
  newOrder: 'Sent To Kitchen',
  nameYo: 'Name & Office Number',
  inProgress: 'In Progress',
  rejected: 'Order Rejected',
  checkout: 'Checkout',
  addToOrder: 'Add to Order',
  requestWaiter: 'REQUEST WAITER',
  requestWaiterSuccess: 'Waiter has been requested',
  status: 'Order Status',
  cancelOrder: 'Cancel Order',
  orderStep1Title: 'Started a Check.',
  orderStep1Body:
    'Your first order is processing. You can still order more items at anytime and we will bring it to your table',
  checkRequested: 'Your check is coming soon.\nThank you.',
  existingCheck:
    'There is a check that is opened {{date}} with existing orders {{total}}. Do you want to start a new check or resume with the existing check',
  resumeCheck: 'Add Orders to Existing Check',
  orderStep2Title: 'Checkout',
  tableNoError: 'Please enter table number',
  officeNo: 'Code No.',
  orderReceivedTitle: 'Order Received',
  orderReceivedBody: 'Your order has been received. You should receive an update soon',
  orderInProgressTitle: 'Order Confirmed',
  orderInProgressBody: 'Your order has been confirmed and is now being prepared.',
  orderReadyForPickupAtArrival: 'Once you arrive at the store, click this button 👇🏼',
  notifiedReadyForPickup: 'Notified Store ✅',
  orderCancelledTitle: 'Order Cancelled',
  orderCancelledBody: 'Your order has been cancelled.',
  orderRejectedTitle: 'Order Rejected',
  orderRejectedBody: 'Your order has been rejected by the store.',
  orderInDeliveryTitle: 'Delivery in Progress',
  orderInDeliveryBody: 'Your order will delivered soon.',
  orderCompletedTitle: 'Order Complete',
  orderCompletedBody: 'Your order has been completed successfully. Thank you - Enjoy!',
  order: 'Order',
  cancelled: 'Order Cancelled',
  roomDelivery: 'Room Delivery',
  served: 'Served',
  newTab: 'Start New Check',
  checkoutConfirmDescription: 'Are you sure you want to close your check and checkout?',
  startNewTabConfirmDescription:
    ' You sure you want to start a new check? Once you start a new check, you will not be able to go back to this order',
  startNewCheck: 'Start New Check',
  deliveryLocationTitle: 'Delivery Address',
  deliveryLocationBody: 'We will reach out to you by phone/text to get your address',
  storeLocation: 'Store',
  type: 'Order Type',
  carMake: 'Car Make & Model',
  carModel: 'Car Model',
  carColor: 'Car Color',
  driveThruTitle: 'Drive Thru Pickup',
  selfPickupTitle: 'Pickup inside the store',
  windowPickupTitle: 'Pickup from window',
  readyForPickup: 'Arrived at Store',
  pickupType: 'How to Pickup?',
  pickup: 'Pickup',
  delivery: 'Delivery',
  dineIn: 'Table Order',
  nearbyDelivery: 'Nearby Delivery',
  freeDelivery: 'Free Delivery',
  deliveryWithFee: 'Delivery (15 SAR)',
  contactStore: 'Contact Store',
  map: 'Map',
  finalizeOnlineOrderTitle: 'Please Enter Info',
  finalizeDineInOrderTitle: 'Enter Your Table No.',
  tableNumber: 'Table Number',
  finalizeDineInOrderDescription:
    "Your order will be sent to the kitchen and be brought to your table once it's ready",
  cancelOrderConfirm: 'You sure you want to cancel the order?',
  cannotCancelOrder: 'Sorry, we cannot cancel your order now',
  deliveryAddress: 'Delivery Address',
  trackDeliveryHere: 'You can track the driver here:',
  orderError: 'Sorry, there was a problem placing your order',
  tableNo: 'Table No.',
  eta: 'ETA',
  preparing: 'Preparing',
  minimumDeliveryOrder: 'Minimum Delivery Order: ',
  singleDineInOrderPendingDescription: 'Your order will be ready soon\nThank you for your patience',
  multipleDineInOrderPendingDescription:
    'Your orders will be ready soon. Thank you for your patience',
  dineInOrderPreparingDescription:
    'Your order is being prepared and will be ready soon. Buon Appettito 😊',
  dineInOrderCompleteDescription: 'Your order is completed',
  experienceFeedbackButton: 'Please Review Your Experience',
  serving: 'Serving',
  complete: 'Complete',
  orderDelivered: 'Order Delivered',
  deliveryInProgress: 'Delivery in Progress',
  markDeliveryInProgress: 'Mark Delivery in Progress',
  markDelivered: 'Mark Delivered',
  notes: 'Notes',
  orderPrice: 'Subtotal',
  submitOrder: 'Submit Order',
  failedToSubmit: 'Failed to submit order',
  trackDriver: 'Track Driver',
  payYourBill: 'Pay Your Bill',
  requestBill: 'Request Bill',
  messageDriver: 'Message Driver',
  confirmSelectStoreError: 'Please confirm the store selection',
  callDriver: 'Call Driver',
  contactDriver: 'Contact Driver',
  contactCustomer: 'Contact Customer',
  orderDate: 'Date',
  cartDoesNotMatchStoreError:
    'One or more items in the cart are not available in the selected store. You can try to clear the cart and add items again',
  selectDineInePayment: 'How do you want to pay?',
  payBillOnlineTitle: 'Pay Online',
  payBillOnlineDescription: 'Instantly pay your bill online',
  requestBillInStoreTitle: 'Request Physical Bill',
  requestBillInStoreDescription: 'Request the bill and pay at the store',
  empty: 'No Orders',
  rateCourier: 'Rate Driver',
  discount: 'Discount',
  orderSecureCourierTitle: 'Assigned Delivery Agent',
  orderCancelRefundPayment:
    'If you cancel your order, we will refund your payment. Please note that refunds can take up to 6 business days',
  deliveryOrderSentToStoreTitle: 'Assigned Delivery Agent',
  deliveryOrderSentToStoreDescription:
    'A driver has been assigned. Sending your order to {{store}}...',
  orderSentToStoreTitle: 'Order Sent to Store',
  orderSentToStoreDescription: 'Order is sent to {{store}} and pending approval',
  refundedPayment:
    'We refunded you {{payment}}. Please allow up to 5 days for the refund to reflect on your account',
  nameRequired: 'Name is required for online payments',
};

export const ar: typeof en = {
  pendingReview: 'قيد الانتظار',
  newOrder: 'في المطبخ',
  nameYo: 'الاسم و رقم المكتب',
  inProgress: 'الطلب قيد التحضير',
  rejected: 'تم رفض الطلب',
  checkout: 'اطلب الحساب',
  addToOrder: 'اضف الى الطلب',
  requestWaiter: 'اطلب النادل',
  requestWaiterSuccess: 'تم ارسال تنبيه للنادل',
  status: 'وضع الطلب',
  cancelOrder: 'الغاء الطلب',
  orderStep1Title: 'تم فتح حساب محلًى',
  orderStep1Body:
    'جاري تحضير أول طلب محلُي. بإمكانك إضافة طلبات في أي وقت وسنقوم بإحضارها الى طالوتك',
  checkRequested: 'سيتم احضار الحساب لك. شكراً.',
  existingCheck:
    'تم فتح حساب من فترة {{date}} بنفس رقم الطاولة وتنفيذ طلبات بقيمة {{total}}. هل تريد فتح فاتورة جديدة أو اضافة طلبات لنفس الفاتورة',
  resumeCheck: 'اضف طلبات للفاتورة الحالية',

  orderStep2Title: 'اقفل الطلب واطلب الحساب',
  tableNoError: 'الرجاء ادخال رقم الطاولة',

  orderReceivedTitle: 'تم استلام طلبك',
  orderReceivedBody: 'سنقوم بتحديث الطلب قريباً',
  orderInProgressTitle: 'تم تأكيد الطلب',
  orderInProgressBody: 'الطلب قيد التحضير.',
  orderReadyForPickupAtArrival: 'عند وصولك للمنشأة، اضغط على الزر أدناه  👇',
  notifiedReadyForPickup: 'تم تنبيه المنشأة ✅',
  orderCancelledTitle: 'تم الغاء الطلب',
  orderCancelledBody: '',
  orderRejectedTitle: 'تم رفض الطلب',
  orderRejectedBody: 'تم رفض الطلب من قبل المطعم',
  orderInDeliveryTitle: 'الطلب جاري التوصيل',
  orderInDeliveryBody: 'سيقوم المندوب بتجميع الطلب وتوصيله.',
  orderCompletedTitle: 'تم اكمال طلبك',
  orderCompletedBody: 'تم اكمال طلبك بنجاح. شكراً لك وبالعافية',
  served: 'تم التقديم',
  order: 'طلب',
  cancelled: 'تم الغاء الطلب',
  roomDelivery: 'توصيل للغرفة',
  newTab: 'ابدأ طلب جديد',
  checkoutConfirmDescription: 'هل تريد اقفال الحساب وطلب الفاتورة؟',
  startNewTabConfirmDescription: 'هل ترغب بفتح طلب جديد؟',
  startNewCheck: 'افتح فاتورة جديدة',
  deliveryLocationTitle: 'عنوان التوصيل',
  deliveryLocationBody: 'سنقوم بالتواصل معك عن طريق الجوال لمعرفة عنوان التوصيل',
  storeLocation: 'المتجر',
  type: 'نوع الطلب',
  carMake: 'ماركة ونوع السيارة',
  carModel: 'نوع السيارة',
  carColor: 'لون السيارة',
  driveThruTitle: 'استلام الطلب من السيارة',
  selfPickupTitle: 'الاستلام من الفرع',
  windowPickupTitle: 'الاستلام من الشباك',
  readyForPickup: 'وصلت للمنشأة',
  pickupType: 'طريقة الاستلام',
  pickup: 'استلام',
  delivery: 'توصيل',
  dineIn: 'طلب محلي',
  nearbyDelivery: 'توصيل مجاور',
  freeDelivery: 'توصيل مجاني',
  deliveryWithFee: 'توصيل (15 SAR)',
  contactStore: 'اتصل بالمحل',
  map: 'الخريطة',
  finalizeOnlineOrderTitle: 'الرجاء ادخال معلومات الطلب',
  finalizeDineInOrderTitle: 'ادخل رقم الطاولة',
  tableNumber: 'ادخل رقم الطاولة',
  finalizeDineInOrderDescription: 'سيتم ارسال طلبك للمطبخ واحضاره لطاولتك مباشرة',
  cancelOrderConfirm: 'هل تريد الغاء الطلب؟',
  cannotCancelOrder: 'عفوا، لايمكن الغاء الطلب في الوضع الحالي',
  deliveryAddress: 'عنوان التوصيل',
  trackDeliveryHere: 'بإمكانك متابعة المندوب من هنا : ',
  orderError: 'عفواً، حدث خطأ في عملية تنفيذ الطلب',
  officeNo: 'رقم الكود',
  tableNo: 'رقم طاولتك',
  eta: 'ETA',
  preparing: 'جاري التحضير',
  minimumDeliveryOrder: 'الحد الأدنى لطلبات التوصيل: ',
  singleDineInOrderPendingDescription: 'طلبك سيكون جاهز قريباً. نشكركم على تعاونكم',
  multipleDineInOrderPendingDescription: 'طلباتك ستكون جاهزة قريباً. نشكركم على تعاونكم',
  dineInOrderPreparingDescription: 'الطلب في قيد التحضير وسيكون جاهز  قريباً. بالعافية 😊',
  dineInOrderCompleteDescription: 'تم اكمال الطلب',
  experienceFeedbackButton: 'اعطنا رأيك عن التجربة',
  serving: 'جاري تقديم الطلب',
  complete: 'تم الأكمال',
  orderDelivered: ' تم التوصيل',
  deliveryInProgress: 'جاري التوصيل',
  markDeliveryInProgress: 'تحديث الطلب :قيد التوصيل',
  markDelivered: 'تحديث الطلب :تم التوصيل',
  notes: 'ملاحظات',
  orderPrice: 'سعر الطلب',
  submitOrder: 'تنفيذ الطلب',
  failedToSubmit: 'فشل في عملية ارسال الطلب',
  trackDriver: 'تابع المندوب',
  payYourBill: 'ادفع الفاتورة',
  requestBill: 'اطلب الفاتورة',
  messageDriver: 'ارسل مسج للمندوب',
  callDriver: 'اتصل بالمندوب',
  contactDriver: 'تواصل مع المندوب',
  contactCustomer: 'تواصل مع العميل',
  orderDate: 'تاريخ الطلب',
  confirmSelectStoreError: 'الرجاء تأكيد اختيار المحل',
  cartDoesNotMatchStoreError:
    'يوجد محتويات في السلّة غير موجودة في المحل الذي تم اختياره. بإمكانك حذف محتويات السلة وإعادة اضافة المحتويات',
  selectDineInePayment: 'اختر طريقة الدفع',
  payBillOnlineTitle: 'ادفع اونلاين',
  payBillOnlineDescription: 'ادفع الحساب اونلاين ومن دون انتظار',
  requestBillInStoreTitle: 'اطلب الفاتورة',
  requestBillInStoreDescription: 'اطلب الفاتورة وادفعها عن طريق المنشأة',
  empty: 'لايوجد طلبات',
  rateCourier: 'قيم المندوب',
  discount: 'خصم',
  orderSecureCourierTitle: 'تم تعين المندوب',
  orderCancelRefundPayment:
    'اذا الغيت الطلب، سيتم استرجاع المبلغ. نود التنويه الى ان عملية الأسترجاع قد تستغرق ٦ ايام عمل',
  orderSentToStoreTitle: 'تم ارسال الطلب',
  deliveryOrderSentToStoreTitle: 'تم تعين المندوب',
  deliveryOrderSentToStoreDescription: 'تم تعين المندوب وجاري ارسال الطلب الى {{store}}',
  orderSentToStoreDescription: 'تم ارسال الطلب الى {{store}} وجاري التأكيد',
  refundedPayment: 'تم استرجاع المبلغ {{payment}}. مدة الاسترجاع قد تستغرق ٥ ايام عمل.',
  nameRequired: 'الأسم مطلوب لطلبات ابل بي',
};
