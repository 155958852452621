/* eslint-disable @typescript-eslint/camelcase */
import axios from 'axios';

import { URLThemeType } from '@/const/enums';
import {
  Cart,
  Check,
  CheckLocation,
  DineIn,
  Feedback,
  Guest,
  Menu,
  MenuCategory,
  MenuItem,
  MenuItemOptions, Merchant,
  Order,
  PaymentTransaction,
  Query,
  ShortLink,
  StartCheck,
  Store,
  StoreMenuSubscription,
  Token,
  User,
  UserRegistration,
} from '@/types';
import DateHelper from '@/utils/DateHelper';
import FBStorage from '@/utils/FBStorage';
import { isBrowser } from '@/utils/isBrowser';
import StringsHelper from '@/utils/StringsHelper';

import ApiError from './ApiError';

export class Api {
  private static SessionId = 'X-MenuX-SessionId';
  public API_URL = isBrowser() ? window.env.API_URL : process.env.API_URL!;
  public API_CLIENT_KEY = isBrowser() ? window.env.API_CLIENT_KEY : process.env.API_CLIENT_KEY!;
  public API_SECRET_KEY = isBrowser() ? window.env.API_SECRET_KEY : process.env.API_SECRET_KEY!;

  /**
   * Generate a random id as a sessionId every time you create an instance of this class
   * @type {string}
   */
  public sessionId: string = StringsHelper.generateGuid();

  /**
   * The date when the access_token will expire and will no longer be valid.
   *
   * @type {null}
   */
  private tokenExpirationDate: Opt<Date> = undefined;

  public token: Opt<Token> = {
    access_token: '',
    token_type: '',
    refresh_token: '',
    expires_in: 0,
  };

  /**
   * True when the token is being refreshed.
   * @type {boolean}
   */
  private refreshing = false;

  /**
   * True if there is a token and it has expired and false otherwise.
   *
   * @returns {boolean|*}
   */
  public async isTokenExpired(): Promise<boolean> {
    if (this.tokenExpirationDate == null) {
      this.tokenExpirationDate = await FBStorage.getTokenExpirationDate();
    }
    const isExpired =
      this.token != null &&
      this.tokenExpirationDate != null &&
      DateHelper.isDateExpired(this.tokenExpirationDate);
    return isExpired;
  }

  /**
   * Load access token if it's stored in FBStorage.
   *
   * @returns {Promise<*>}
   */
  public async loadAccessToken(throwError: boolean): Promise<Opt<Token>> {
    if (this.token == null || this.token.access_token === '') {
      this.token = await FBStorage.getAccessToken();
    }
    if (!this.isUserLoggedIn() && throwError) {
      throw Error('token is not present');
    }
    return this.token;
  }

  /**
   * Get the access token if it's stored in FBStorage.
   *
   * @returns The access token or null {Promise<*>}
   */
  public async getAccessToken(): Promise<Opt<Token>> {
    let token;
    try {
      token = await this.loadAccessToken(true);
    } catch (e) {
      console.error(e.message);
    }
    return token;
  }

  /**
   * Refreshes the token and stores it once refreshed.
   *
   * @returns {Promise<void>}
   */
  public async refreshToken(): Promise<any> {
    this.refreshing = true;

    await this.loadAccessToken(true);

    try {
      const headers: any = {};
      headers.ContentType = 'application/x-www-form-urlencoded';
      headers[Api.SessionId] = this.sessionId;
      const response = await axios({
        headers,
        method: 'post',
        url: this.API_URL.concat('/oauth/token'),
        params: {
          refresh_token: (this.token && this.token.refresh_token) || null,
          grant_type: 'refresh_token',
        },
      });
      if (response.status === 200) {
        await this.saveAccessToken(response.data);
      }

      return response.data;
    } catch (error) {
      throw new ApiError(error);
    }
  }

  /**
   * Get a dine in by its id
   *
   * @param id The id for the dine in.
   * @return {Promise<PaymentTransaction>}
   */
  public async requestWaiter(id: string): Promise<Check> {
    const headers: any = {};
    headers[Api.SessionId] = this.sessionId;

    const apiCall = axios({
      headers,
      method: 'post',
      url: this.API_URL.concat(`/checks/${id}/assistance`),
    });

    return this.executeApiCall(0, 'requestWaiter', false, apiCall);
  }

  /**
   * Get a dine in by its id
   *
   * @param id The id for the dine in.
   * @return {Promise<PaymentTransaction>}
   */
  public async fetchDineIn(id: string): Promise<DineIn> {
    const headers: any = {};
    headers[Api.SessionId] = this.sessionId;

    const apiCall = axios({
      headers,
      method: 'get',
      url: this.API_URL.concat(`/orders/dineIn/${id}`),
    });

    return this.executeApiCall(0, 'fetchDineIn', false, apiCall);
  }

  public async fetchCheck(id: string): Promise<Check> {
    const headers: any = {};
    headers[Api.SessionId] = this.sessionId;

    const apiCall = axios({
      headers,
      method: 'get',
      url: this.API_URL.concat(`/checks/${id}`),
    });

    return this.executeApiCall(0, 'fetchCheck', false, apiCall);
  }

  /**
   * Get a payment by its id
   *
   * @param paymentId The id for the payment.
   * @return {Promise<PaymentTransaction>}
   */
  public async fetchPayment(paymentId: string): Promise<PaymentTransaction> {
    const headers: any = {};
    headers[Api.SessionId] = this.sessionId;

    const apiCall = axios({
      headers,
      method: 'get',
      url: this.API_URL.concat(`/payments/${paymentId}`),
    });

    return this.executeApiCall(0, 'fetchPayment', false, apiCall);
  }

  public async fetchMerchant(id: string): Promise<Merchant> {
    const headers: any = {};
    headers[Api.SessionId] = this.sessionId;

    const apiCall = axios({
      headers,
      method: 'get',
      url: this.API_URL.concat(`/merchants/${id}`),
    });
    return this.executeApiCall(0, 'fetchMerchant', false, apiCall);
  }

  /**
   * Fetches the short link
   *
   * @param code The code for the short link
   * @return {Promise<ShortLink>}
   */
  public async fetchShortLink(code: string): Promise<ShortLink> {
    const headers: any = {};
    headers[Api.SessionId] = this.sessionId;

    const apiCall = axios({
      headers,
      method: 'get',
      url: this.API_URL.concat(`/shortlinks/${code}`),
    });

    return this.executeApiCall(0, 'fetchShortLink', false, apiCall);
  }

  /**
   * Generate new guest id
   *
   * @param merchantId The new guest
   * @param theme The selected theme
   * @return {Promise<Guest>}
   */
  public async generateGuestId(merchantId: Opt<string>, theme: Opt<URLThemeType>): Promise<Guest> {
    const headers: any = {};
    headers[Api.SessionId] = this.sessionId;

    const apiCall = axios({
      headers,
      method: 'post',
      url: this.API_URL.concat(`/guests/generateId`),
      params: {
        merchantId,
        theme,
      },
    });

    return this.executeApiCall(0, 'generateGuestId', false, apiCall);
  }

  /**
   * Get an order
   *
   * @param orderId The orderId for the order to accept.
   * @return {Promise<Order>}
   */
  public async fetchOrder(orderId: string): Promise<Order> {
    const headers: any = {};
    headers[Api.SessionId] = this.sessionId;

    const apiCall = axios({
      headers,
      method: 'get',
      url: this.API_URL.concat(`/v1/orders/${orderId}`),
    });

    return this.executeApiCall(0, 'fetchOrder', false, apiCall);
  }

  /**
   * Update guest
   *
   * @param guest The guest to update.
   * @return {Promise<Order>}
   */
  public async updateGuest(guest: Guest): Promise<Guest> {
    const headers: any = {};
    // headers.Authorization = `Bearer ${(this.token && this.token.access_token) || ''}`;
    headers[Api.SessionId] = this.sessionId;

    const apiCall = axios({
      headers,
      method: 'put',
      data: guest,
      url: this.API_URL.concat(`/guests`),
    });

    return this.executeApiCall(0, 'updateGuest', false, apiCall);
  }

  /**
   * Cancel an order
   *
   * @param orderId The orderId for the order to accept.
   * @return {Promise<Order>}
   */
  public async cancelOrder(orderId: string): Promise<Order> {
    const headers: any = {};
    // headers.Authorization = `Bearer ${(this.token && this.token.access_token) || ''}`;
    headers[Api.SessionId] = this.sessionId;

    const apiCall = axios({
      headers,
      method: 'post',
      url: this.API_URL.concat(`/v1/orders/${orderId}/cancel`),
    });

    return this.executeApiCall(0, 'cancelOrder', false, apiCall);
  }

  public async checkoutCheck(id: string): Promise<Check> {
    const headers: any = {};
    // headers.Authorization = `Bearer ${(this.token && this.token.access_token) || ''}`;
    headers[Api.SessionId] = this.sessionId;

    const apiCall = axios({
      headers,
      method: 'post',
      url: this.API_URL.concat(`/checks/${id}/checkout`),
    });

    return this.executeApiCall(0, 'checkoutDineIn', false, apiCall);
  }

  public async startCheck(data: StartCheck): Promise<Check> {
    const headers: any = {};
    // headers.Authorization = `Bearer ${(this.token && this.token.access_token) || ''}`;
    headers[Api.SessionId] = this.sessionId;

    const apiCall = axios({
      headers,
      method: 'post',
      url: this.API_URL.concat(`/checks`),
      data,
    });

    return this.executeApiCall(0, 'checkoutDineIn', false, apiCall);
  }

  public async findCheckByStoreAndCode(
    storeId: string,
    code: string,
  ): Promise<Query<CheckLocation>> {
    const headers: any = {};
    // headers.Authorization = `Bearer ${(this.token && this.token.access_token) || ''}`;
    headers[Api.SessionId] = this.sessionId;

    const apiCall = axios({
      headers,
      method: 'get',
      url: this.API_URL.concat(`/checks/locations?storeId=${storeId}&code=${code}&include=CHECK`),
    });

    return this.executeApiCall(0, 'checkoutDineIn', false, apiCall);
  }

  /**
   * Checkout dineIn
   *
   * @param dineInId The id of dineIn
   * @return {Promise<Order>}
   */
  public async checkoutDineIn(dineInId: string): Promise<DineIn> {
    const headers: any = {};
    // headers.Authorization = `Bearer ${(this.token && this.token.access_token) || ''}`;
    headers[Api.SessionId] = this.sessionId;

    const apiCall = axios({
      headers,
      method: 'post',
      url: this.API_URL.concat(`/orders/dineIn/${dineInId}/checkout`),
    });

    return this.executeApiCall(0, 'checkoutDineIn', false, apiCall);
  }

  /**
   * Saves an order
   *
   * @param order The order to save.
   * @param isPending Is the payment pending
   * @return {Promise<Order>}
   */
  public async saveOrder(order: Order, isPending: boolean): Promise<Order> {
    const headers: any = {};
    // headers.Authorization = `Bearer ${(this.token && this.token.access_token) || ''}`;
    headers[Api.SessionId] = this.sessionId;

    const apiCall = axios({
      headers,
      method: 'post',
      url: this.API_URL.concat('/orders'),
      params: {
        isPending,
      },
      data: order,
    });

    return this.executeApiCall(0, 'saveOrder', false, apiCall);
  }

  /**
   * Save the new access token.
   *
   * @param token new access token
   */
  public async saveAccessToken(token: Token) {
    this.token = token;
    await FBStorage.setAccessToken(this.token);
    this.tokenExpirationDate = DateHelper.addSecondsToToday(this.token.expires_in);
    await FBStorage.setTokenExpirationDate(this.tokenExpirationDate);
  }

  /**
   * Login user using username and password. The login will store the access_token into FBStorage.
   *
   * @param username
   * @param password
   * @returns {Promise<void>}
   */
  public async loginUser(username: string, password: string): Promise<Opt<Token>> {
    try {
      const headers: any = {};
      headers.Authorization = `Basic ${StringsHelper.encodeToB64(
        `${this.API_CLIENT_KEY}:${this.API_SECRET_KEY}`,
      )}`;
      headers['Content-Type'] = 'application/x-www-form-urlencoded';
      headers[Api.SessionId] = this.sessionId;

      const response = await axios({
        headers,
        method: 'post',
        url: this.API_URL.concat(
          `/oauth/token?username=${encodeURIComponent(username)}&password=${encodeURIComponent(
            password,
          )}&grant_type=password`,
        ),
      });
      // Store the token
      if (response) {
        this.saveAccessToken(response.data);
        return response.data;
      }
      return undefined;
    } catch (error) {
      console.warn('error', error);
      throw new ApiError(error);
    }
  }

  /**
   * Fetch all menu item options
   *
   * @param menuId: The menu identifier
   * @returns {Promise<void>}
   */
  public async fetchMenuItemOptions(menuId: string): Promise<MenuItemOptions[]> {
    await this.loadAccessToken(true);
    const headers: any = {};
    headers[Api.SessionId] = this.sessionId;
    headers.Authorization = `Bearer ${(this.token && this.token.access_token) || ''}`;

    const apiCall = axios({
      headers,
      method: 'get',
      url: this.API_URL.concat(`/menus/${menuId}/menuItem/options`),
    });
    return this.executeApiCall(0, 'fetchMenuItemOptions', true, apiCall);
  }

  /**
   * Fetch the menu item by menu item id and category id
   *
   * @param categoryId: The category identifier
   * @param menuItemId: Menu Item identifier
   * @returns {Promise<void>}
   */
  public async fetchMenuItem(categoryId: string, menuItemId: string): Promise<MenuItem> {
    await this.loadAccessToken(true);
    const headers: any = {};
    headers[Api.SessionId] = this.sessionId;
    headers.Authorization = `Bearer ${(this.token && this.token.access_token) || ''}`;

    const apiCall = axios({
      headers,
      method: 'get',
      url: this.API_URL.concat(`/menus/menuItem/${menuItemId}`),
    });
    return this.executeApiCall(0, 'fetchMenuItem', true, apiCall);
  }

  /**
   * Fetch the categories by menu id
   *
   * @param id: Menu identifier
   * @returns {Promise<void>}
   */
  public async fetchStoresByHandle(handle: Opt<string>): Promise<Store[]> {
    await this.loadAccessToken(true);
    const headers: any = {};
    headers[Api.SessionId] = this.sessionId;

    const apiCall = axios({
      headers,
      method: 'get',
      url: this.API_URL.concat(`/stores/handle2/${handle}`),
    });

    return this.executeApiCall(0, 'fetchStoresByHandle', true, apiCall);
  }

  /**
   * Fetch all my orders
   *
   * @return {Promise<Order[]>}
   */
  public async fetchMyOrders(guestId: string): Promise<Order[]> {
    const headers: any = {};
    // headers.Authorization = `Bearer ${(this.token && this.token.access_token) || ''}`;
    headers[Api.SessionId] = this.sessionId;

    const apiCall = axios({
      headers,
      method: 'get',
      url: this.API_URL.concat(`/v1/users/self/orders?guestId=${guestId}`),
    });

    return this.executeApiCall(0, 'fetchMyOrders', false, apiCall);
  }

  public async fetchDineInOrders(dineInId: string): Promise<Order[]> {
    const headers: any = {};
    // headers.Authorization = `Bearer ${(this.token && this.token.access_token) || ''}`;
    headers[Api.SessionId] = this.sessionId;

    const apiCall = axios({
      headers,
      method: 'get',
      url: this.API_URL.concat(`/dineIns/${dineInId}/orders`),
    });

    return this.executeApiCall(0, 'fetchDineInOrders', false, apiCall);
  }

  /**
   * Fetch the categories by menu id
   *
   * @param id: Menu identifier
   * @returns {Promise<void>}
   */
  public async fetchCategories(id: Opt<string>): Promise<MenuCategory[]> {
    await this.loadAccessToken(true);
    const headers: any = {};
    headers[Api.SessionId] = this.sessionId;
    headers.Authorization = `Bearer ${(this.token && this.token.access_token) || ''}`;

    const apiCall = axios({
      headers,
      method: 'get',
      url: this.API_URL.concat(`/menus/${id!}/categories`),
    });

    return this.executeApiCall(0, 'fetchCategoriesMenuById', true, apiCall);
  }

  /**
   * Register a new user
   *
   * @param registration: User object
   * @returns {Promise<void>}
   */
  public async registerUser(registration: UserRegistration): Promise<User> {
    try {
      const headers: any = {};
      headers.Authorization = `Basic ${StringsHelper.encodeToB64(
        `${this.API_CLIENT_KEY}:${this.API_SECRET_KEY}`,
      )}`;
      headers[Api.SessionId] = this.sessionId;

      const response = await axios({
        headers,
        method: 'post',
        url: this.API_URL.concat('/users'),
        data: registration,
      });
      return response.data;
    } catch (error) {
      throw new ApiError(error);
    }
  }

  /**
   * Save a store menu subscription
   *
   * @param data: The subscription
   * @returns {Promise<void>}
   */
  public async saveStoreMenuSubscription(
    data: StoreMenuSubscription,
  ): Promise<StoreMenuSubscription> {
    const headers: any = {};
    headers[Api.SessionId] = this.sessionId;

    const apiCall = axios({
      method: 'post',
      data,
      url: this.API_URL.concat('/menus/subscription'),
    });

    return this.executeApiCall(0, 'saveStoreMenuSubscription', false, apiCall);
  }

  /**
   * Fetch the menu by its id
   *
   * @param id: Menu identifier
   * @returns {Promise<void>}
   */
  public async fetchMenu(id: string): Promise<Menu> {
    await this.loadAccessToken(true);
    const headers: any = {};
    headers[Api.SessionId] = this.sessionId;
    headers.Authorization = `Bearer ${(this.token && this.token.access_token) || ''}`;

    const apiCall = axios({
      headers,
      method: 'get',
      url: this.API_URL.concat(`/menus/${id}`),
    });

    return this.executeApiCall(0, 'fetchMenu', true, apiCall);
  }

  /**
   * Send the user a short code
   *
   * @param user: User object
   * @returns {Promise<void>}
   */
  public async sendShortCode(user: User): Promise<any> {
    try {
      const headers: any = {};
      headers[Api.SessionId] = this.sessionId;

      const response = await axios({
        headers,
        method: 'post',
        url: this.API_URL.concat('/users/sendCode'),
        data: user,
      });
      return response.data;
    } catch (error) {
      throw new ApiError(error);
    }
  }

  public async readyForPickup(id: string): Promise<any> {
    try {
      const headers: any = {};
      headers[Api.SessionId] = this.sessionId;

      const response = await axios({
        headers,
        method: 'post',
        url: this.API_URL.concat(`/v1/orders/${id}/pickup-ready`),
      });
      return response.data;
    } catch (error) {
      throw new ApiError(error);
    }
  }

  /**
   * Save a new upload
   *
   * @param upload: Upload object
   * @returns {Promise<void>}
   */
  public async saveUpload(url: string): Promise<any> {
    await this.loadAccessToken(true);
    const headers: any = {};
    headers.Authorization = `Bearer ${(this.token && this.token.access_token) || ''}`;
    headers[Api.SessionId] = this.sessionId;
    headers['Content-Type'] = 'form-data';

    const apiCall = axios({
      headers,
      method: 'post',
      url: this.API_URL.concat(`/uploads?url=${url}`),
    });
    return this.executeApiCall(0, 'saveUpload', true, apiCall);
  }

  /**
   * Upload an image by requesting a PUT url first to directly upload to it.
   *
   * @param imageUri The image file uri path.
   * @param imageName The image file name.
   * @param uploadType The upload type (i.e. USER_PHOTO, PLACE_PHOTO).
   * @returns {Promise<Upload>}
   */
  public async uploadImage(file: File, imageName: string, uploadType: string): Promise<string> {
    const preApiCall = axios({
      method: 'get',
      url: this.API_URL.concat('/uploads/presigned'),
      headers: {
        'X-Safary-SessionId': this.sessionId,
      },
      params: {
        name: imageName,
        type: uploadType,
        v2: 'true',
      },
    });
    const preResponse = await this.executeApiCall(0, 'preSignedUrl', true, preApiCall);
    return new Promise((resolve: any, reject: any) => {
      const { preSignedUrl } = preResponse;
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', preSignedUrl);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            // resolve(preResponse.second);
            resolve(preResponse.finalUrl);
          } else {
            reject(new Error('Error while sending the image to S3'));
          }
        }
      };
      xhr.setRequestHeader('Content-Type', 'image/jpeg');
      xhr.setRequestHeader('X-Safary-SessionId', this.sessionId);
      xhr.setRequestHeader('x-amz-acl', 'public-read');

      xhr.send(file);
    });
  }

  /**
   * Updates user current user profile
   * @param profile User
   * @param shouldUpdateProfilePic boolean
   * @returns {Promise<User>}
   */
  public async updateMyProfile(profile: User): Promise<User> {
    await this.loadAccessToken(true);
    // save in new variable so we don't mutate readonly input
    const putData = profile;

    const headers: any = {};
    headers.Authorization = `Bearer ${(this.token && this.token.access_token) || ''}`;
    headers[Api.SessionId] = this.sessionId;
    headers['Content-Type'] = 'application/json';

    const apiCall = axios({
      headers,
      method: 'put',
      url: this.API_URL.concat('/users/self'),
      data: putData,
    });
    const response = await this.executeApiCall(0, 'updateMyProfile', true, apiCall);
    return response;
  }

  public async markDeliveryInProgress(order: Order, courierId: string): Promise<any> {
    const headers: any = {};
    headers[Api.SessionId] = this.sessionId;

    const apiCall = axios({
      headers,
      method: 'post',
      url: this.API_URL.concat(`/orders/${order.id}/inDelivery`),
      params: {
        courierId,
      },
    });

    return this.executeApiCall(0, 'markDeliveryInProgress', false, apiCall);
  }

  public async isStoreReceivingOrders(storeId: string): Promise<{ isReceivingOrders: string }> {
    const headers: any = {};
    headers[Api.SessionId] = this.sessionId;

    const apiCall = axios({
      headers,
      method: 'get',
      url: this.API_URL.concat(`/v1/stores/${storeId}/isReceivingOrders`),
    });

    return this.executeApiCall(0, 'isStoreReceivingOrders', false, apiCall);
  }

  public async markDelivered(order: Order, courierId: string): Promise<any> {
    const headers: any = {};
    headers[Api.SessionId] = this.sessionId;

    const apiCall = axios({
      headers,
      method: 'post',
      url: this.API_URL.concat(`/orders/${order.id}/complete`),
      params: {
        courierId,
      },
    });

    return this.executeApiCall(0, 'markDeliveryInProgress', false, apiCall);
  }

  /**
   * Get myself as a user object
   *
   * @returns {Promise<void>}
   */
  public async fetchMyself(): Promise<any> {
    await this.loadAccessToken(true);

    const headers: any = {};
    headers.Authorization = `Bearer ${(this.token && this.token.access_token) || ''}`;
    headers[Api.SessionId] = this.sessionId;

    const apiCall = axios({
      headers,
      method: 'get',
      url: this.API_URL.concat('/users/self'),
    });

    return this.executeApiCall(0, 'fetchMyself', true, apiCall);
  }

  /**
   * Delete MenuX Category.
   *
   * @param id The Menu Category identifier
   * @returns {Promise<Array>}
   */
  public async deleteCategory(id: string): Promise<any> {
    await this.loadAccessToken(true);
    const headers: any = {};
    headers.Authorization = `Bearer ${(this.token && this.token.access_token) || ''}`;
    headers[Api.SessionId] = this.sessionId;
    const apiCall = axios({
      headers,
      method: 'delete',
      url: this.API_URL.concat(`/menus/categories/${id}`),
    });

    return this.executeApiCall(0, 'deleteCategory', true, apiCall);
  }

  /**
   * Delete MenuX Item.
   *
   * @param id The Menu Item identifier
   * @returns {Promise<Array>}
   */
  public async deleteMenuItem(id: string): Promise<any> {
    await this.loadAccessToken(true);
    const headers: any = {};
    headers.Authorization = `Bearer ${(this.token && this.token.access_token) || ''}`;
    headers[Api.SessionId] = this.sessionId;
    const apiCall = axios({
      headers,
      method: 'delete',
      url: this.API_URL.concat(`/menus/menuItem/${id}`),
    });

    return this.executeApiCall(0, 'deleteMenuItem', true, apiCall);
  }

  /**
   * Updates MenuX Item.
   *
   * @param item The Menu Item
   * @returns {Promise<Array>}
   */
  public async updateMenuItem(item: MenuItem): Promise<any> {
    await this.loadAccessToken(true);
    const headers: any = {};
    headers.Authorization = `Bearer ${(this.token && this.token.access_token) || ''}`;
    headers[Api.SessionId] = this.sessionId;
    const apiCall = axios({
      headers,
      method: 'put',
      url: this.API_URL.concat('/menus/menuItem'),
      data: item,
    });

    return this.executeApiCall(0, 'updateMenuItem', true, apiCall);
  }

  /**
   * Save MenuX Item Options.
   *
   * @param itemId The Menu Item Id
   * @param options The Menu Item Options
   * @returns {Promise<Array>}
   */
  public async saveMenuItemOptions(
    itemId: string,
    options: MenuItemOptions[],
  ): Promise<MenuItemOptions[]> {
    await this.loadAccessToken(true);
    const headers: any = {};
    headers.Authorization = `Bearer ${(this.token && this.token.access_token) || ''}`;
    headers[Api.SessionId] = this.sessionId;
    const apiCall = axios({
      headers,
      method: 'post',
      url: this.API_URL.concat(`/menus/menuItem/${itemId}/options`),
      data: options,
    });

    return this.executeApiCall(0, 'saveMenuItemOptions', true, apiCall);
  }

  /**
   * Deletes MenuX Item Options.
   *
   * @param options The Menu Item Options
   * @returns {Promise<Array>}
   */
  public async deleteMenuItemOptions(options: MenuItemOptions[]): Promise<MenuItem> {
    await this.loadAccessToken(true);
    const headers: any = {};
    headers.Authorization = `Bearer ${(this.token && this.token.access_token) || ''}`;
    headers[Api.SessionId] = this.sessionId;
    const apiCall = axios({
      headers,
      method: 'delete',
      url: this.API_URL.concat('/menus/menuItem/options'),
      data: options,
    });

    return this.executeApiCall(0, 'deleteMenuItemOptions', true, apiCall);
  }

  /**
   * Updates MenuX Item Options.
   *
   * @param options The Menu Item Options
   * @returns {Promise<Array>}
   */
  public async updateMenuItemOptions(options: MenuItemOptions[]): Promise<MenuItem> {
    await this.loadAccessToken(true);
    const headers: any = {};
    headers.Authorization = `Bearer ${(this.token && this.token.access_token) || ''}`;
    headers[Api.SessionId] = this.sessionId;
    const apiCall = axios({
      headers,
      method: 'put',
      url: this.API_URL.concat('/menus/menuItem/options'),
      data: options,
    });

    return this.executeApiCall(0, 'updateMenuItemOptions', true, apiCall);
  }

  /**
   * Get the user form the backend
   *
   * @param id The user identifier.
   * @return {Promise<Object>}
   */
  public async fetchUser(email: string): Promise<User> {
    await this.loadAccessToken(true);

    const headers: any = {};
    headers.Authorization = `Bearer ${(this.token && this.token.access_token) || ''}`;
    headers[Api.SessionId] = this.sessionId;

    const apiCall = axios({
      headers,
      method: 'get',
      url: this.API_URL.concat(`/users/${email}`),
    });

    return this.executeApiCall(0, 'fetchUser', true, apiCall);
  }

  /**
   * Reset password an order
   *
   * @param phoneNumber The phone number.
   * @return {Promise<Order>}
   */
  public async requestResetPassword(phoneNumber: string): Promise<any> {
    const headers: any = {};
    headers[Api.SessionId] = this.sessionId;

    const apiCall = axios({
      headers,
      method: 'get',
      url: this.API_URL.concat('//users/password/reset'),
      params: {
        phoneNumber,
      },
    });

    return this.executeApiCall(0, 'requestResetPassword', true, apiCall);
  }

  /**
   * Post user feedback
   *
   * @param data The user feedback
   * @returns {Promise<void>}
   */
  public async sendFeedback(data: Feedback): Promise<any> {
    await this.loadAccessToken(true);

    const headers: any = {};
    headers.Authorization = `Bearer ${(this.token && this.token.access_token) || ''}`;
    headers[Api.SessionId] = this.sessionId;

    const apiCall = axios({
      headers,
      data,
      method: 'post',
      url: this.API_URL.concat('/users/feedback'),
    });
    // await apiCall();
    return this.executeApiCall(0, 'sendFeedback', true, apiCall);
  }

  /**
   * Execute a given call. Before checking the call, check if the token has
   * expired and try to refresh it. Queues up the calls if the token is expired
   * until the token is refreshed.
   *
   * @param numberOfRetries The number of retries we have made so far for the @call
   * @param name Name of the api call.
   * @param needsAuth If true, the function will attach the access_token and refreshes it if needed.
   * @param call The api call.
   * @returns {Promise<void>}
   */
  public async executeApiCall(
    numberOfRetries: number,
    name: string,
    needsAuth: boolean,
    call: Promise<any>,
  ): Promise<any> {
    if (numberOfRetries >= 5) {
      throw Error('Reached max retries');
    }

    if (needsAuth && (await this.isTokenExpired())) {
      if (!this.refreshing) {
        await this.refreshToken();
      } else {
        // Wait for 2 seconds and try again
        await sleep(2000);
        return this.executeApiCall(numberOfRetries + 1, name, needsAuth, call);
      }
    }

    try {
      const response = await call;
      if (response.data) {
        return response.data;
      }
      return;
    } catch (error) {
      throw new ApiError(error);
    }
  }

  /**
   * Update my cart
   *
   * @param cart The user cart.
   * @return {Promise<User>}
   */
  public async updateCart(cart: Cart): Promise<Cart> {
    await this.loadAccessToken(true);

    const headers: any = {};
    headers.Authorization = `Bearer ${(this.token && this.token.access_token) || ''}`;
    headers['X-Safary-SessionId'] = this.sessionId;

    const apiCall = axios({
      headers,
      method: 'post',
      url: this.API_URL.concat('/users/self/cart'),
      data: cart,
    });
    return this.executeApiCall(0, 'updateCart', true, apiCall);
  }

  /**
   * True if there is an access token and false otherwise.
   *
   * @returns {boolean}
   */
  public isUserLoggedIn(): boolean {
    return this.token != null && this.token.access_token !== '';
  }

  /**
   * Logs out the user
   *
   */
  public async logout() {
    await FBStorage.reset();
  }
}

export default new Api();
export const sleep = async (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
