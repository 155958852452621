import React, { memo, useEffect, useState } from 'react';

import { DeliveryType, DistanceType, OrderType } from '@/const/enums';
import LocationHelper from '@/location/utils/LocationHelper';
import { useStore } from '@/store/hooks/useStore';
import StoreHelper from '@/store/utils/StoreHelper';
import { DeliveryLocation, Store } from '@/types';
import FBStorage from '@/utils/FBStorage';
import { isBrowser } from '@/utils/isBrowser';

import { LocationContext } from './LocationContext';

interface Props {
  children: React.ReactChild;
}

export const MAX_DISTANCE = 20;

export const isLocationSupported = (store: Opt<Store>, location?: DeliveryLocation) => {
  if (!store) {
    return false;
  }
  if (!location || !location.coordinates) {
    return true;
  }

  const { radius, maxRadius } = store.settings?.orders?.delivery || {};
  const { coordinates } = location;
  const storeCoordinate = store.location.coordinates;
  const maxDistance = radius?.value ?? maxRadius?.value ?? MAX_DISTANCE;
  const myDistance = LocationHelper.distance(
    coordinates,
    storeCoordinate,
    radius?.type === DistanceType.KM ? 'K' : 'M',
  );
  const isSupported = myDistance < maxDistance;
  console.log('maxDistance', myDistance, maxDistance, isSupported);

  return isSupported;
};

export const LocationProvider = memo<Props>(({ children }) => {
  const [location, setLocation] = useState<Opt<DeliveryLocation>>(undefined);
  const [isLocationPermitted, setIsLocationPermitted] = useState(false);
  const [isSupported, setIsSupported] = useState(false);
  const { setNearestStore, setSelectedStore, stores, selectedStore, setStores } = useStore();

  useEffect(() => {
    if (isBrowser()) {
      const savedLocation = FBStorage.getLocation();
      setLocation(savedLocation);
      setIsSupported(isLocationSupported(selectedStore, savedLocation));
    }
  }, [selectedStore]);

  useEffect(() => {
    if (stores && location?.coordinates) {
      const nearestStore = StoreHelper.nearestStore(location?.coordinates, stores);

      if (nearestStore) {
        setNearestStore(nearestStore);
        setSelectedStore && setSelectedStore(nearestStore);
      }
    }
  }, []);

  useEffect(() => {
    if (stores && location?.coordinates) {
      StoreHelper.nearestStore(location?.coordinates, stores);
      setStores([...stores]);
    }
  }, [location]);

  return (
    <LocationContext.Provider
      value={{
        location,
        setLocation,
        isSupported,
        setIsSupported,
        isLocationPermitted,
        setIsLocationPermitted,
      }}>
      {children}
    </LocationContext.Provider>
  );
});
