export enum StoreType {
  CoffeeShop = 'COFFEE_SHOP',
  Restaurant = 'RESTAURANT',
  OnlineRestaurant = 'ONLINE_RESTAURANT',
  OnlineCoffeeShop = 'ONLINE_COFFEE_SHOP',
  FoodTuck = 'FOOD_TRUCK',
  CoffeeShopTruck = 'COFFEE_SHOP_TRUCK',
}

export enum MenuItemOptionsType {
  Single = 'SINGLE',
  Multiple = 'MULTIPLE',
}

export enum DeliveryFeeType {
  Fixed = 'FIXED',
  Dynamic = 'DYNAMIC',
  Hybrid = 'HYBRID',
}

export enum LocationFieldType {
  Address = 'ADDRESS',
  TwoDigit = 'TWO_DIGIT_CODE',
  CustomText = 'CUSTOM_TEXT',
}

export enum DriveThruStatus {
  ReadyForPickup = 'READY_FOR_PICKUP',
}

export enum DeliveryType {
  FleetManagement = 'FLEET_MANAGEMENT',
  NoFleeManagement = 'NO_FLEET_MANAGEMENT',
  OnDemand = 'ON_DEMAND',
}

export enum PickupType {
  DriveThru = 'DRIVE_THRU',
  Curbside = 'CURBSIDE',
  InPerson = 'IN_PERSON',
  InStore = 'IN_STORE',
}

export enum DistanceType {
  Meter = 'METER',
  KM = 'KM',
  Mile = 'MILE',
  Degree = 'DEGREE',
}

export enum ThemeType {
  Dark = 'DARK',
  Light = 'LIGHT',
  Original = 'ORIGINAL',
}

export enum URLThemeType {
  Dark = 'dark',
  Light = 'light',
  Brand = 'brand',
}

export enum CheckLocationType {
  Table = 'TABLE',
  Seat = 'SEAT',
}

export enum DineInStatus {
  NewTap = 'NEW_TAP',
  New = 'NEW',
  InProgress = 'IN_PROGRESS',
  RequestCheckout = 'REQUEST_CHECKOUT',
  Serving = 'SERVING',
  Completed = 'COMPLETED',
  RequestWaiter = 'REQUEST_WAITER',
}

//TODO merge order type and browse type
export enum OrderType {
  Delivery = 'DELIVERY',
  Pickup = 'PICKUP',
  DineIn = 'DINE_IN',
}

export enum BrowseType {
  online,
  dinein,
  basic,
}

export enum UserFieldType {
  Name = 'NAME',
  PhoneNumber = 'PHONE_NUMBER',
}

export enum CardType {}

export enum PaymentMethod {
  Visa = 'VISA',
  MasterCard = 'MASTERCARD',
  Mada = 'MADA',
  ApplePay = 'APPLE_PAY',
  AmericanExpress = 'AMERICAN_EXPRESS',
  Cash = 'CASH',
  Discover = 'DISCOVER',
  PayAtStore = 'PAY_AT_STORE',
  Online = 'ONLINE',
  Unknown = 'UNKNOWN',
}

export enum FeedbackTypeValues {
  Other = 'OTHER',
}

export enum PaymentStatus {
  Authorized = 'AUTHORIZED',
  Failed = 'FAILED',
  Settled = 'SETTLED',
}

export enum MenuItemOptionsSelectionType {
  Radio = 'Radio',
  Checkbox = 'Checkbox',
  Counter = 'Counter',
}

export enum SortType {
  Name = 'NAME',
  Distance = 'DISTANCE',
  MostRecent = 'MOST_RECENT',
}

export enum DeviceType {
  App = 'APP',
  POS = 'POS',
  Courier = 'COURIER',
}

export enum OrderStatus {
  New = 'NEW',
  PendingReview = 'PENDING_REVIEW',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED',
  DeliveryInProgress = 'DELIVERY_IN_PROGRESS',
  AssignedCourier = 'ASSIGNED_COURIER',
  Delivered = 'DELIVERED',
  DriveThruReadyForPickup = 'CURBSIDE_READY_FOR_PICKUP',
  InStoreReadyForPickup = 'IN_STORE_READY_FOR_PICKUP',
  SecuringCourier = 'SECURING_COURIER',
  PendingPayment = 'PENDING_PAYMENT',
}

export enum UpdateCartType {
  Add = 'ADD',
  ClearAndAdd = 'CLEAR_ADD',
  Remove = 'REMOVE',
  Update = 'UPDATE',
  Clear = 'CLEAR',
}

export enum Language {
  Arabic = 'ar',
  English = 'en',
}

export enum PushNotificationType {
  Message = 'MESSAGE',
  Inactive = 'INACTIVE',
}

export enum IconType {
  Material = 'Material',
  Evil = 'Evil',
  Ant = 'Ant',
  SimpleLine = 'SimpleLine',
}

export interface ButtonOption {
  iconName: string;
  text: string;
  value: string;
}

export interface TabOption {
  text: string;
  badge?: number;
  badgeColor?: string;
}
